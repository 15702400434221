import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MoreDetailsService } from 'src/app/services/more-details.service';
import { RequestService } from 'src/app/services/request.service';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2'
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ViewChekingService } from 'src/app/services/view-cheking.service';
import { EnvSelectionService } from 'src/app/services/env-selection.service';

@Component({
  selector: 'app-new-intransit',
  templateUrl: './new-intransit.component.html',
  styleUrls: ['./new-intransit.component.scss']
})
export class NewIntransitComponent implements OnInit {
  filterlspOrg: any;

  constructor(private requestService: RequestService, private messageService: MoreDetailsService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private viewChecking: ViewChekingService,
    private envSelection: EnvSelectionService
  ) { }

  RowData: any = [];
  RowDHLData: any = [];
  RowExpressData: any = [];
  userRole: any;


  codearrayCustomer = [];
  CodearrayDataCustomer = [];
  CustomerdropdownSettings: IDropdownSettings = {};
  tableCustomerDropdownValues: any;
  CustomerselectedItems: null;


  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};

  model = {
    CustomerselectedItems: null
  };

  selectedlogisticsValue: any;
  HAWBSearchField: any;
  customersData: any;
  ShipmentReferencesSearchField: any;


  dropdownListOrigin = [];
  dropdownListDestination = [];
  dropdownListMOT = [];


  selectedItemsOrigin = [];
  selectedItemsDestination = [];
  selectedItemsMOT = [];


  dropdownSettingsDestination = {};
  dropdownSettingsOrigin = {};
  dropdownSettingsMOT = {};


  disableDestination: boolean = true;
  disableMOT: boolean = true;


  itemoriginCode: any;
  itemDestinatioCode: any;
  itemMOTCode: any;


  filter_ver1_ui: any;
  view_ver1_ui: any;


  ngOnInit(): void {
    this.filter_ver1_ui = this.viewChecking.filterChecking(); // checking filter access based on user list.
    this.view_ver1_ui = this.viewChecking.viewCheking(); // checking view access based on user list.
    console.log('filter check==', this.filter_ver1_ui);
    console.log('view check==', this.view_ver1_ui);

    this.userRole = environment.userType;
    // this.loadData();
    this.logisticsSelectDropdown();
    this.customer_dropDown();
    this.logisticsSelectDropdown();
    this.originDataCall();

  }

  HAWBSearch() {
    this.loadData()
  }

  ShipmentReferencesSearch() {
    this.loadData()
  }

  customer_dropDown() {
    this.CustomerdropdownSettings = {
      singleSelection: true,
      idField: 'codeCustomer',
      textField: 'codeCustomerName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  onCustomerItemSelect(item) {
    this.model.CustomerselectedItems = item.codeCustomer
    this.tableCustomerDropdownValues = this.model.CustomerselectedItems;
    this.loadData()
  }

  onCustomerDeSelect(item) {
    this.tableCustomerDropdownValues = null;
    this.loadData();
  }

  logisticsSelectDropdown() {
    if (this.userRole === 'Cargotec') {
      this.dropdownList = [
        { item_id: 1, item_text: 'Express' },
        { item_id: 2, item_text: 'Forwarding' },
        { item_id: 3, item_text: 'Freight' },
        // { item_id: 4, item_text: 'ALL' }
      ];
    }
    if (this.userRole === 'SLB') {
      this.dropdownList = [
        { item_id: 1, item_text: 'Express' },
        { item_id: 2, item_text: 'Forwarding' },
        { item_id: 3, item_text: 'ARAMEX' },
        { item_id: 5, item_text: 'SCHENKER' },
        { item_id: 6, item_text: 'DSV' },
        { item_id: 7, item_text: 'GEODIS' },
        { item_id: 8, item_text: 'EXPEDITORS' },
        { item_id: 9, item_text: 'KN' },
        { item_id: 10, item_text: 'CRANE' }
        // { item_id: 4, item_text: 'ALL' }
      ];
    }
    else {
      this.dropdownList = [
        { item_id: 1, item_text: 'Express' },
        { item_id: 2, item_text: 'Forwarding' },
        // { item_id: 3, item_text: 'ALL' }
      ];
    }
    // if (this.userRole === 'HM' || this.userRole === 'APPLE') {
    console.log("logo:::", this.filter_ver1_ui, this.selectedlogisticsValue)

    if (this.filter_ver1_ui) { // checking filter access.
      if (this.view_ver1_ui) {
        this.selectedItems = [
          { item_id: 1, item_text: 'Express' }
        ];
      }
      else {
        this.selectedItems = [
          { item_id: 2, item_text: 'Forwarding' }
        ];
      }


    }
    else {
      this.selectedItems = [
        { item_id: 2, item_text: 'Express' }
      ];
    }

    if (this.userRole === 'SLB') {
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true,
        closeDropDownOnSelection: true
      };
    }
    if (this.userRole !== 'SLB') {
      this.dropdownSettings = {
        singleSelection: true,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true,
        closeDropDownOnSelection: true
      };
    }
  }

  onItemSelect(item: any) {
    this.selectedlogisticsValue = []
    console.log("item.item_text", this.selectedItems)
    const arr = this.selectedItems.map(object => object.item_text);
    console.log("arr", arr)
    if (arr.includes('Forwarding')) {
      arr.splice(arr.indexOf('Forwarding'), 1, 'DGF')

    }
    if (arr.includes('Express')) {
      arr.splice(arr.indexOf('Express'), 1, 'DHLE')
    }
    this.selectedlogisticsValue = arr;
    console.log(" this.selectedlogisticsValue", this.selectedlogisticsValue)
    // this.loadData()
    this.originDataCall()
  }

  onSelectAll(items: any) {
  }

  onItemDeSelect(itemdeslected: any) {
    console.log('onItemDeSelect', itemdeslected['item_text'], this.selectedlogisticsValue);
    this.selectedlogisticsValue = this.selectedlogisticsValue.filter((value: any) => {
      console.log("3333")
      if (itemdeslected['item_text'] === 'Express') {
        itemdeslected['item_text'] = 'DHLE'
      }
      else if (itemdeslected['item_text'] === 'Forwarding') {
        itemdeslected['item_text'] = 'DGF'
      }
      console.log("itemdeslected['item_text']", itemdeslected['item_text'])
      return value !== itemdeslected['item_text']
    })
    console.log(' this.selectedlogisticsValue', this.selectedlogisticsValue)
    this.loadData()
  }


  origin_dropDown() {
    console.log("11111")
    this.dropdownSettingsOrigin = {
      singleSelection: true,
      idField: 'origin_code',
      textField: 'origin_code',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  originDataCall() {
    const dis = this;
    let req = null;
    let localstorageUserOrg = this.authService.getLoggedUser();
    console.log("this.selectedItems", this.selectedItems)
    let lspOrgId;
    let filter: any = {};
    // if (this.selectedItems[0]['item_text'] === "Forwarding" || this.selectedItems[0]['item_text'] === 'Express') {
    //   this.tableCustomerDropdownValues = localstorageUserOrg.userOrgs;
    //   if (this.selectedItems[0]['item_text'] === "Forwarding") {
    //     this.selectedlogisticsValue = 'DGF'
    //   }
    //   else if (this.selectedItems[0]['item_text'] === 'Express') {
    //     this.selectedlogisticsValue = 'DHLE'
    //   }
    // }
    // else {
    //   this.tableCustomerDropdownValues = localstorageUserOrg.userOrg
    //   this.selectedlogisticsValue = this.selectedItems[0]['item_text']
    // }

    if (this.selectedlogisticsValue !== null && this.selectedlogisticsValue !== undefined && this.selectedlogisticsValue !== '') {
      if (typeof this.selectedlogisticsValue === 'string') {
        filter['lspOrg'] = [this.selectedlogisticsValue]
      }
      else {
        filter['lspOrg'] = this.selectedlogisticsValue;
      }
    }
    else {
      // if (localstorageUserOrg.userOrg === 'HM' || localstorageUserOrg.userOrg === 'APPLE') {
      if (this.filter_ver1_ui) { // checking filter access based on enum users list.
        if (this.view_ver1_ui) {
          filter['lspOrg'] = ['DGF']
        }
        else {
          filter['lspOrg'] = ['DHLE']

        }
      }
      else {
        filter['lspOrg'] = ['DGF']
      }
      if (localstorageUserOrg.userOrg === 'HM'){
        filter['lspOrg'] = ['DHLE']
      }
    }
    filter['customerOrg'] = [localstorageUserOrg.userOrg]
    filter['emailId'] = [localstorageUserOrg.emailId]
    req = dis.requestService.originsByLsp(filter);
    req.subscribe(resp => {
      if (resp['Message'] === 'Sucess') {
        console.log("originDropdownData::::", resp)
        if (resp['result'].length !== 0) {
          this.origin_dropDown();
          let originList = [];
          let x = resp['result'].sort();
          x.map((y: any) => {
            let obj = {
              "origin_code": y
            }
            originList.push(obj)
          })
          this.dropdownListOrigin = originList
        }
        else {
          this.dropdownListOrigin = []
        }
      }
    });
    this.loadData();
  }
  onItemSelectOrigin(item: any) {
    console.log(item.origin_code);
    this.itemoriginCode = item.origin_code;
    // if (this.selectedItemsDestination.length !== 0) {
    //   this.selectedItemsDestination = [];
    // }
    this.DestinationDataCall(item.origin_code)
    this.loadData();
  }

  onItemDeSelectOrigin(item) {
    console.log("onItemDeSelectOrigin", item)
    this.itemoriginCode = null;
    this.loadData()
  }

  destination_dropDown() {
    this.dropdownSettingsDestination = {
      singleSelection: true,
      idField: 'destination_code',
      textField: 'destination_code',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }



  MOTData() {
    this.dropdownSettingsMOT = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    this.dropdownListMOT = [
      { item_id: 1, item_text: 'AIR' },
      { item_id: 2, item_text: 'ROAD' },
      { item_id: 3, item_text: 'SEA' },
      { item_id: 4, item_text: 'EXP' },
    ];
    this.disableMOT = false

  }

  DestinationDataCall(origin_code) {
    const dis = this;
    let req = null;
    let localstorageUserOrg = this.authService.getLoggedUser();
    let filter: any = {};
    // if (this.userRole === 'DHL' || this.userRole === 'Express') {
    //   this.tableCustomerDropdownValues = this.tableCustomerDropdownValues;
    //   if (this.tableCustomerDropdownValues === 'CARGOTEC_FRT') {
    //     filter['lspOrg']  = 'FREIGHT'
    //   }
    //   else {
    //     if (this.userRole === 'DHL') {
    //       filter['lspOrg']  = 'DGF'
    //     }
    //     else if (this.userRole === 'Express') {
    //       filter['lspOrg']  = 'DHLE'
    //     }
    //   }

    // }
    // else {
    //   this.tableCustomerDropdownValues = localstorageUserOrg.userOrg
    //   this.selectedlogisticsValue = this.selectedlogisticsValue
    // }
    if (this.selectedlogisticsValue !== null && this.selectedlogisticsValue !== undefined && this.selectedlogisticsValue !== '') {
      if (typeof this.selectedlogisticsValue === 'string') {
        filter['lspOrg'] = [this.selectedlogisticsValue]
      }
      else {
        filter['lspOrg'] = this.selectedlogisticsValue;
      }
    }
    else {
      // if (localstorageUserOrg.userOrg === 'HM' || localstorageUserOrg.userOrg === 'APPLE') {
      if (this.filter_ver1_ui) { // cheking filter access, based on enum users list.
        if (this.view_ver1_ui) {
          filter['lspOrg'] = ['DGF']
        }
        else {
          filter['lspOrg'] = ['DHLE']

        }
      }
      else {
        filter['lspOrg'] = ['DGF']
      }

    }
    filter['customerOrg'] = [localstorageUserOrg.userOrg]
    filter['emailId'] = [localstorageUserOrg.emailId]
    filter['origin'] = [origin_code]
    let params = {
      email: localstorageUserOrg.emailId,
      custOrgId: this.tableCustomerDropdownValues,
      originCode: origin_code,
      lspOrg: this.selectedlogisticsValue
    }
    req = dis.requestService.destinationsByOriginLsp(filter);
    req.subscribe(resp => {
      if (resp['Message'] === 'Sucess') {
        if (resp['result'].length !== 0) {
          this.disableDestination = false
          this.destination_dropDown();
          // this.dropdownListDestination = resp['result'][0].orig_dest_array.destination_code.sort();
          let destinList = []
          let x = resp['result'].sort();
          x.map((y: any) => {
            if (y !== null) {
              let obj = {
                destination_code: y
              }
              destinList.push(obj)
            }

          })
          this.dropdownListDestination = destinList
        }
        else {
          this.dropdownListDestination = []
        }
      }
    });
  }

  onItemSelectDestination(item: any) {
    // this.loadData();
    // console.log(item.destination_code);
    // this.title = this.searchTitle;
    // console.log("searchTitle", this.searchTitle)
    this.itemDestinatioCode = item.destination_code;
    if (this.selectedItemsMOT.length !== 0) {
      this.selectedItemsMOT = [];
    }
    this.MOTData()
    this.loadData();
    // this.tabService.openTab(new Tab(this.title, 'Search'));
    // this.messageService.SearchData(this.itemcustomerOrgId, this.itemoriginCode, this.itemDestinatioCode, this.itemLsP, null)
  }


  onItemSelectMOT(item: any) {
    this.itemMOTCode = item['item_text'];
    this.loadData()
  }

  filterUserList: any = [
    'NISSAN', 'BMX', 'ASTRAZENECA', 'BMS', 'TOTAL', 'GSK', 'IBM', 'LLP', 'MAXION', 'PFIZER', 'MERITOR', 'CATERPILLAR', 'SLB', 'DELL', 'HOLOGIC', 'BOSCH', 'CISCO', 'HM', 'METSO', 'NOVONORDISK', 'SALMAR', 'ROCHE', 'APPLE', 'ARAMCO', 'SHELL', 'ASML', 'COMMSCOPE', 'FORD', 'ORGANON', 'STELLANTIS', 'BAKERHUGHES', 'DSM', 'MICHELIN', 'SANOFI'
  ];

  loadData() {
    const dis = this;
    let req = null;
    let filter: any = {};
    let localstorageUserOrg = this.authService.getLoggedUser();
    let checkFilterUser = this.filterUserList.includes(localstorageUserOrg.userOrg);
    // if (localstorageUserOrg.userOrg === 'NISSAN' || localstorageUserOrg.userOrg === 'BMX' || localstorageUserOrg.userOrg === 'ASTRAZENECA' || localstorageUserOrg.userOrg === 'BMS' || localstorageUserOrg.userOrg === 'TOTAL' || localstorageUserOrg.userOrg === 'GSK' || localstorageUserOrg.userOrg === 'IBM'
    //   || localstorageUserOrg.userOrg === 'LLP' || localstorageUserOrg.userOrg === 'MAXION' || localstorageUserOrg.userOrg === 'PFIZER' || localstorageUserOrg.userOrg === 'MERITOR' || localstorageUserOrg.userOrg === 'CATERPILLAR' || localstorageUserOrg.userOrg === 'SLB' || localstorageUserOrg.userOrg === 'DELL' || localstorageUserOrg.userOrg === 'HOLOGIC' || localstorageUserOrg.userOrg === 'BOSCH' || localstorageUserOrg.userOrg === 'CISCO' || localstorageUserOrg.userOrg === 'HM' || localstorageUserOrg.userOrg === 'METSO' || localstorageUserOrg.userOrg === 'NOVONORDISK' || localstorageUserOrg.userOrg === 'SALMAR' || localstorageUserOrg.userOrg === 'ROCHE' || localstorageUserOrg.userOrg === 'APPLE' || localstorageUserOrg.userOrg === 'ARAMCO' || localstorageUserOrg.userOrg === 'SHELL') {
    if (checkFilterUser) { // checking user access from filterUserList array.
      filter['customerOrg'] = [localstorageUserOrg.userOrg];
      filter['emailId'] = [localstorageUserOrg.emailId];
      if (this.selectedlogisticsValue !== null && this.selectedlogisticsValue !== undefined && this.selectedlogisticsValue !== '') {
        if (typeof this.selectedlogisticsValue === 'string') {
          filter['lspOrg'] = [this.selectedlogisticsValue]
        }
        else {
          filter['lspOrg'] = this.selectedlogisticsValue;
        }
      }
      else {
        // if (localstorageUserOrg.userOrg === 'HM' || localstorageUserOrg.userOrg === 'APPLE') {
        if (this.filter_ver1_ui) { // checking filter access, based on enum users list.
          if (this.view_ver1_ui) {
            filter['lspOrg'] = ['DGF']
          }
          else {
            filter['lspOrg'] = ['DHLE']

          }
        }
        else {
          filter['lspOrg'] = ['DGF']
        }
        if (localstorageUserOrg.userOrg === 'HM'){
            filter['lspOrg'] = ['DHLE']
        }
      }
    }
    if (localstorageUserOrg.userOrg === 'DHL') {
      filter['emailId'] = [localstorageUserOrg.emailId];
    }
    if (localstorageUserOrg.userOrg === 'CARGOTEC') {
      filter['customerOrg'] = [localstorageUserOrg.userOrg, 'CARGOTEC_FRT'];
      filter['emailId'] = [localstorageUserOrg.emailId]
    }
    let status = 'INTRANSIT';
    this.RowData = [];
    let customerdropdownValue = dis.tableCustomerDropdownValues;
    if (customerdropdownValue !== null && customerdropdownValue !== undefined && Object.keys(customerdropdownValue).length !== 0) {
      filter['customerOrg'] = [customerdropdownValue];
    }
    if (this.HAWBSearchField !== null && this.HAWBSearchField !== undefined && this.HAWBSearchField !== '') {
      filter['houseBillNumber'] = [this.HAWBSearchField];
    }
    if (this.ShipmentReferencesSearchField !== null && this.ShipmentReferencesSearchField !== undefined && this.ShipmentReferencesSearchField !== '') {
      filter['LIKE-referenceValue'] = [this.ShipmentReferencesSearchField];
    }

    if (this.itemoriginCode !== null && this.itemoriginCode !== undefined && this.itemoriginCode !== '') {
      filter['origin'] = [this.itemoriginCode];
    }

    if (this.itemDestinatioCode !== null && this.itemDestinatioCode !== undefined && this.itemDestinatioCode !== '') {
      filter['destination'] = [this.itemDestinatioCode];
    }

    if (this.itemMOTCode !== null && this.itemMOTCode !== undefined && this.itemMOTCode !== '') {
      filter['MOT'] = [this.itemMOTCode];
    }

    if (this.selectedlogisticsValue !== null && this.selectedlogisticsValue !== undefined && this.selectedlogisticsValue !== '') {
      if (typeof this.selectedlogisticsValue === 'string') {
        filter['lspOrg'] = [this.selectedlogisticsValue]
      }
      else {
        filter['lspOrg'] = this.selectedlogisticsValue;
      }
    }
    if (sessionStorage.getItem('access-token') != null) {
      this.spinner.show();
    }
    this.filterlspOrg = filter['lspOrg'];

    const eventGroupMap = new Map();

    req = dis.requestService.getLatestEventCounts(status, filter);
    req.subscribe(resp => {
      if (resp.Message === 'Sucess') {
        this.spinner.hide()
        let resultData;
        // if (this.userRole === 'BMX' || this.userRole === 'Nissan' || this.userRole === 'ASTRAZENECA') {
        //   const messageResult = resp['result'];
        //   // this.customersData = resp.result.customers;
        //   console.log("messageResult", messageResult)
        //   resultData = messageResult;
        // }

        const messageResult = resp['result'].data;
        this.customersData = resp.result.customers;
        resultData = messageResult;
        let statusArray = ['OTHERS', 'FREIGHT PROCESS', 'MAIN FREIGHT', 'LAST-MILE DELIVERY',
          'ORDER', 'PRE-CARRIAGE', 'IMPORT CLEARANCE', 'EXCEPTIONS', 'CANCEL', 'EXPORT CLEARANCE', 'ON-CARRIAGE', 'NOU'];
        let incomingStatus: any = [];
        // resultData.sort((a, b) => (b.eventGroupId > a.eventGroupId) ? -1 : 1);
        for (let data of resultData) {
          data.CheckpointDateTime = data.CheckpointDateTime.split(".")[0];
          data.CheckpointDateTime = data.CheckpointDateTime.replace("T", " ")
          console.log("data.activeOrders", data.activeOrders, data.moreThanADayOrders)
          if (data.activeOrders === undefined) {
            data['activeOrders'] = 0;
          }
          if (data.moreThanADayOrders === undefined) {
            data['moreThanADayOrders'] = 0;
          }

          if (eventGroupMap.has(data.eventGroupId)) {
            const record = this.RowData.filter(evt => evt.eventGroupId === data.eventGroupId);
            if (record.length > 0) { record[0].Count++; record[0].moreThanADayOrders++; }
          }
          else {
            eventGroupMap.set(data.eventGroupId, true);
            this.RowData.push(data);
          }


        }
        let unmatchedData = statusArray.filter(value => {
          return !incomingStatus.includes(value);
        });
        console.log("unmatchedData", unmatchedData)
        this.objectCreation(unmatchedData, 'Forwarding')
        this.DropdownRows(this.RowData);
      }
    });
  }

  objectCreation(unmatchedData, dropdownStatus) {

    let obj: any = {};
    console.log("dropdownStatus", dropdownStatus)
    unmatchedData.forEach(element => {
      if (dropdownStatus === 'Forwarding') {
        console.log("IFFF")
        obj = {
          CheckpointDateTime: "",
          Count: 0,
          customerOrgId: "",
          customerOrgName: "",
          eventGroup: "",
          eventGroupId: 0,
          lspOrgId: "DGF",
          lspOrgName: "DHL GLOBAL FORWARDING",
          activeOrders: 0,
          moreThanADayOrders: 0
        }
      }
      else {
        console.log("ELSE")
        obj = {
          CheckpointDateTime: "",
          Count: 0,
          customerOrgId: "",
          customerOrgName: "",
          eventGroup: "",
          eventGroupId: 0,
          lspOrgId: "DHLE",
          lspOrgName: "DHL EXPRESS",
          activeOrders: 0,
          moreThanADayOrders: 0
        }
      }
      obj.eventGroup = element;
      // this.RowData.push(obj);
      var indexPush = this.RowData.findIndex(i => i.eventGroup === element);
      if (indexPush === -1) {
        this.RowData.push(obj);
      }
    });
    console.log("this.rowData", this.RowData)
  }


  DropdownRows(rows) {
    const dis = this;
    dis.codearrayCustomer = [];
    for (let i = 0; i < this.customersData.length; i++) {
      const raws = this.customersData[i];
      dis.codearrayCustomer[i] = { codeCustomer: raws['customerOrgId'], codeCustomerName: raws['customerOrgName'] };
    }
    let arrayduplicateremoveOrigin = this.removeDuplicates(dis.codearrayCustomer, "codeCustomer");
    dis.CodearrayDataCustomer = arrayduplicateremoveOrigin;
  }

  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};
    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  clear() {
    this.CustomerselectedItems = null;
    this.tableCustomerDropdownValues = null;
    this.HAWBSearchField = null;
    this.ShipmentReferencesSearchField = null;
    this.selectedItemsOrigin = null
    this.itemoriginCode = null;
    this.selectedItemsDestination = null
    this.itemDestinatioCode = null;
    this.selectedItemsMOT = null;
    this.itemMOTCode = null;
    this.loadData();
  }

  Details(checkpointcode, lspOrgId, lspOrgName, checkpointDescription, customerOrgId, customerOrgName) {
    if (checkpointcode !== 0) {
      this.messageService.checkpointCodeMessage(checkpointcode, this.filterlspOrg, lspOrgName, checkpointDescription, customerOrgId, customerOrgName, this.HAWBSearchField, this.itemoriginCode, this.itemDestinatioCode, this.ShipmentReferencesSearchField, this.itemMOTCode);
      this.router.navigate(['/inTransit'])
    }
    if (checkpointcode === 0) {
      Swal.fire({
        icon: 'error',
        heightAuto: false,
        html: 'NO SHIPMENTS in' + ' ' + checkpointDescription,
      })
    }
  }

  specificDetails(checkpointcode, lspOrgId, lspOrgName, checkpointDescription, customerOrgId, customerOrgName, specificDetails, count) {
    console.log("count", count)
    if (count !== 0) {
      this.messageService.specificDetailscheckpointCodeMessage(checkpointcode, this.filterlspOrg, lspOrgName, checkpointDescription, customerOrgId, customerOrgName, this.HAWBSearchField, this.itemoriginCode, this.itemDestinatioCode, this.ShipmentReferencesSearchField, this.itemMOTCode, specificDetails);
      this.router.navigate(['/inTransit'])
    }
    if (count === 0) {
      if (specificDetails === 'activeOrders') {
        Swal.fire({
          icon: 'error',
          heightAuto: false,
          html: 'No shipments updated in the last 2 hours' + ' ' + checkpointDescription,
        })
      }
      else if (specificDetails === 'moreThanADayOrders') {
        Swal.fire({
          icon: 'error',
          heightAuto: false,
          html: 'No updated for more than 24 hours in' + ' ' + checkpointDescription,
        })
      }

    }
  }

}
