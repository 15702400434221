export const logos = {
    // initial_header refers to add logo at login screen. 
    // If you need logo at login page it's TRUE other wise FALSE.
    // width refers logo width at header.
    logo: [
        { id: 0, logo_src: './assets/images/Apple/logo.png', user: 'APPLE', initial_header: false, width: 'auto' },
        { id: 1, logo_src: './assets/images/Aramco/logo.png', user: 'ARAMCO', initial_header: false, width: 'auto' },
        { id: 2, logo_src: './assets/images/ASTRAZENECA/logo.png', user: 'ASTRAZENECA', initial_header: false, width: 'auto' },
        { id: 3, logo_src: './assets/images/BMS/logo.svg', user: 'BMS', initial_header: false, width: 'auto' },
        { id: 4, logo_src: './assets/images/BMX/logo.jpg', user: 'BMX', initial_header: false, width: 'auto' },
        { id: 5, logo_src: './assets/images/BOSCH/logo.png', user: 'BOSCH', initial_header: false, width: 'auto' },
        { id: 6, logo_src: './assets/images/DHL/logo.svg', user: 'BlessLLP', initial_header: true, width: 'auto' },
        { id: 7, logo_src: './assets/images/CARGOTEC/logo.png', user: 'CARGOTEC', initial_header: false, width: 'auto' },
        { id: 8, logo_src: './assets/images/CARGOTEC/logo.png', user: 'Cargotec', initial_header: false, width: 'auto' },
        { id: 9, logo_src: './assets/images/CARGOTEC/logo.png', user: 'CARGOTEC_FRT', initial_header: false, width: 'auto' },
        { id: 10, logo_src: './assets/images/CARGOTEC/logo.png', user: 'CARGOTEC_FRT', initial_header: false, width: 'auto' },
        { id: 11, logo_src: './assets/images/CATERPILLAR/logo.png', user: 'CATERPILLAR', initial_header: false, width: '100px' },
        { id: 12, logo_src: './assets/images/CISCO/logo.png', user: 'CISCO', initial_header: false, width: 'auto' },
        { id: 13, logo_src: './assets/images/DELL/logo.png', user: 'DELL', initial_header: true, width: '100px' },
        { id: 14, logo_src: './assets/images/DHL/logo.svg', user: 'DHL', initial_header: false, width: 'auto' },
        { id: 15, logo_src: './assets/images/DHL/logo.svg', user: 'EXPRESS', initial_header: false, width: 'auto' },
        { id: 16, logo_src: './assets/images/GSK/logo.jpeg', user: 'GSK', initial_header: false, width: '50px' },
        { id: 17, logo_src: './assets/images/H&M/logo.png', user: 'HM', initial_header: false, width: 'auto' },
        { id: 18, logo_src: './assets/images/Hologic/logo.png', user: 'HOLOGIC', initial_header: true, width: '100px' },
        { id: 19, logo_src: './assets/images/IBM/logo.png', user: 'IBM', initial_header: true, width: '100px' },
        { id: 20, logo_src: './assets/images/DHL/logo.svg', user: 'LLP', initial_header: true, width: 'auto' },
        { id: 21, logo_src: './assets/images/MAXION/logo.png', user: 'MAXION', initial_header: false, width: '100px' },
        { id: 22, logo_src: './assets/images/MERITOR/logo.jpg', user: 'MERITOR', initial_header: false, width: 'auto' },
        { id: 23, logo_src: './assets/images/METSO/logo.jpg', user: 'METSO', initial_header: false, width: 'auto' },
        { id: 24, logo_src: './assets/images/NISSAN/logo.png', user: 'NISSAN', initial_header: false, width: '60px' },
        { id: 25, logo_src: './assets/images/NovoNordisk/logo.jpg', user: 'NOVONORDISK', initial_header: false, width: 'auto' },
        { id: 26, logo_src: './assets/images/PFIZER/logo.jpg', user: 'PFIZER', initial_header: false, width: 'auto' },
        { id: 27, logo_src: './assets/images/Roche/logo.png', user: 'ROCHE', initial_header: false, width: '50%' },
        { id: 28, logo_src: './assets/images/Salmar/logo.png', user: 'SALMAR', initial_header: false, width: 'auto' },
        { id: 29, logo_src: './assets/images/Shell/logo.jpg', user: 'SHELL', initial_header: false, width: 'auto' },
        { id: 30, logo_src: './assets/images/SLB/logo.png', user: 'SLB', initial_header: false, width: '100px' },
        { id: 31, logo_src: './assets/images/TOTAL/logo.jpg', user: 'TOTAL', initial_header: false, width: 'auto' },
        { id: 32, logo_src: './assets/images/Commscope/logo.png', user: 'COMMSCOPE', initial_header: false, width: 'auto' },
        { id: 33, logo_src: './assets/images/Ford/logo.png', user: 'FORD', initial_header: false, width: 'auto' },
        { id: 34, logo_src: './assets/images/Organon/logo.png', user: 'ORGANON', initial_header: false, width: 'auto' },
        { id: 35, logo_src: './assets/images/Stellantis/logo.png', user: 'STELLANTIS', initial_header: false, width: 'auto' },
        { id: 36, logo_src: './assets/images/ASML/logoHeader.png', user: 'ASML', initial_header: false, width: 'auto' },
        { id: 37, logo_src: './assets/images/BakerHughes/logo.png', user: 'BAKERHUGHES', initial_header: false, width: 'auto' },
        { id: 38, logo_src: './assets/images/DSM/logo.png', user: 'DSM', initial_header: false, width: 'auto' },
        { id: 39, logo_src: './assets/images/Michelin/Michelin-Header.jpg', user: 'MICHELIN', initial_header: false, width: 'auto' },
        { id: 39, logo_src: './assets/images/Sanofi/Sanofi-Header.jpg', user: 'SANOFI', initial_header: false, width: 'auto' },
        { id: 39, logo_src: './assets/images/BAYER/logo.png', user: 'BAYER', initial_header: false, width: 'auto' },
    ],
};