import { Component, OnInit } from '@angular/core';
import { TreeTableHeaderObject, TreeTableRow, TreeTableData, TtDataType, TreeTableRowAction } from 'angular-tree-table';
import { concat } from 'rxjs';
declare var $: any;


@Component({
  selector: 'app-shipment-booking',
  templateUrl: './shipment-booking.component.html',
  styleUrls: ['./shipment-booking.component.scss']
})
export class ShipmentBookingComponent implements OnInit {
  tableData: TreeTableData = null;
  tableHeaders: TreeTableHeaderObject[] = [];
  MilestoneCollectionTableHeaders = [];
  MilestoneCollectionTableData = new TreeTableData();
  tableConfig = {
    rowClickablesContext: this,
    rowClickables: {},
    columnFilters: {},
    columnVisibilityDropDown: true,
    showPageLengthDropdown: true,
    showExpandAllArrows: true,
    commonSearch: false,
    excelExportButton: true,
    visibleColumnFiltersVisibility: true,
  };
  popuptableConfig = {
    rowClickablesContext: this,
    rowClickables: {},
    columnFilters: {},
    columnVisibilityDropDown: false,
    showPageLengthDropdown: false,
    showExpandAllArrows: true,
    commonSearch: false,
    excelExportButton: false,
    visibleColumnFiltersVisibility: false,
  };
  constructor() { }

  ngOnInit(): void {
    this.conncat()
    this.tableData = new TreeTableData(this.tableConfig);
    this.tableInit();
    this.loadData();
  }

  conncat() {
    const arr1 = [1, 2, 3, 4, 5, 6, 7];
    const arr2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const mycon = new Set([...arr1, ...arr2]);
    const final = [...mycon]
    console.log("final", final);
  }


  tableInit() {
    this.tableHeaders.splice(0, this.tableHeaders.length);
    this.tableHeaders.push(new TreeTableHeaderObject('HAWB', 'HAWB', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('MAWB', 'MAWB', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('Flight number', 'Flightnumber', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('Transport Mode', 'TransportMode', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('AWB Issue Date', 'AWBIssueDate', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('INCO term', 'INCOterm', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('Latest Tracking Event', 'LatestTrackingEvent', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('Latest Tracking Timestamp', 'LatestTrackingTimestamp', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('Forwarder', 'Forwarder', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('Event Department', 'EventDepartment', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('Quantity', 'Quantity', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('Package Code', 'PackageCode', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('Package Type', 'PackageType', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('Goods Description', 'GoodsDescription', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('Total Weight', 'TotalWeight', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('Weight Unit', 'WeightUnit', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('Total Volume', 'TotalVolume', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('Volume Unit', 'VolumeUnit', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('Chargeable Weight', 'ChargeableWeight', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('Actual Departure', 'ActualDeparture', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('Actual Arrival', 'ActualArrival', '', true, false));
    this.tableHeaders.push(new TreeTableHeaderObject('Forwarding Shipment', 'S2100801921', '', false, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Forwarding Consol', 'C2100387450', '', false, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Required Minimum Temperature', '2.0', '', false, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Required Maximum Temperature', '8.0', '', false, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Payment Method', 'Prepaid', '', false, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Screening Status', 'Clear', '', false, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Dangerous Goods', 'TBA', '', false, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Customer Account Code', 'FRBIE011', '', false, true));
    this.tableData.headers = this.tableHeaders;
  }


  loadData() {
    const data = [];
    for (let i = 0; i < 3; i++) {
      const row = new TreeTableRow(i + '', {
        HAWB: 'Q776307',
        MAWB: '172-46545284',
        Flightnumber: 'CV8587',
        TransportMode: 'Air Freight',
        AWBIssueDate: '2021-02-26T00:00:00',
        INCOterm: 'CIP',
        LatestTrackingEvent: 'Freight Recovered',
        LatestTrackingTimestamp: '2021-02-26T00:00:00',
        Forwarder: 'DHL GLOBAL FORWARDING (THAILAND) LIMITED',
        EventDepartment: 'Forwarding Import Air',
        Quantity: '1',
        PackageCode: 'PKG',
        PackageType: 'Package',
        GoodsDescription: 'TEMP LOGGER INCLUDED',
        TotalWeight: '38',
        WeightUnit: 'KG' + 'Kilograms',
        TotalVolume: 0.729,
        VolumeUnit: 'M3' + 'Cubic Meters',
        ChargeableWeight: '0',
        ActualDeparture: '2021-02-26T19:59:00',
        ActualArrival: '2021-03-01T09:25:00',
      }, false, null);
      row.clickablesContext = this;
      row.clickables = {
        HAWB: this.HAWB,
      };
      data.push(row);
    }
    this.tableData.data = data;
  }

  HAWB(rowData: any) {
    $('#newModal2').modal('show');
    this.MilestoneCollectionTableData.isLoading = true;
    this.MilestoneCollectionDataPopulate(rowData)
  }

  MilestoneCollectionDataPopulate(rowData: any) {
    let data = [];
    for (let i = 0; i < 3; i++) {
      const row = new TreeTableRow(i + '', {
        MilestoneDescription: 'Proof of Delivery',
        EventCode: 'DCF',
        ActualDate: '2021-03-01T15:46:00',
        EstimatedDate: '2021-03-02T09:43:00',
        ActualTemperatureReading: ''
      }, false, null);
      data.push(row);
    }
    this.MilestoneCollectionTableData = new TreeTableData(this.popuptableConfig);
    this.MilestoneCollectionInit()
    this.MilestoneCollectionTableData.pageSize = 5;
    this.MilestoneCollectionTableData.data = data;
    this.MilestoneCollectionTableData.isLoading = false;
  }

  MilestoneCollectionInit() {
    this.MilestoneCollectionTableHeaders.splice(0, this.MilestoneCollectionTableHeaders.length);
    this.MilestoneCollectionTableHeaders.push(new TreeTableHeaderObject('Milestone Description', 'MilestoneDescription', null, true, false));
    this.MilestoneCollectionTableHeaders.push(new TreeTableHeaderObject('Event Code', 'EventCode', null, true, false));
    this.MilestoneCollectionTableHeaders.push(new TreeTableHeaderObject('Actual Date', 'ActualDate', null, true, false));
    this.MilestoneCollectionTableHeaders.push(new TreeTableHeaderObject('Estimated Date', 'EstimatedDate', null, true, false));
    this.MilestoneCollectionTableHeaders.push(new TreeTableHeaderObject('Actual Temperature Reading', 'ActualTemperatureReading', null, true, false));
    this.MilestoneCollectionTableData.headers = this.MilestoneCollectionTableHeaders;
  }

}
