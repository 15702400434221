export class MapData {
    mapType: string;
    streetViewControl: boolean;
    markers: any[];
    constructor(mapType: string, streetViewControl: boolean, markers: any[]) {
        this.mapType = mapType;
        this.streetViewControl = streetViewControl;
        this.markers = markers;
    }
}
