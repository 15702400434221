export enum customer { DHL, BMX, EXPRESS, LLP, NISSAN, ASTRAZENECA, BMS, CARGOTEC, CARGOTEC_FRT, TOTAL, GSK, IBM, MERITOR, PFIZER, MAXION, CATERPILLAR, SLB, DELL, HOLOGIC, CISCO, BOSCH, HM, METSO, NOVONORDISK, SALMAR, ROCHE, APPLE, SHELL, ARAMCO, COMMSCOPE, FORD, ORGANON, STELLANTIS, ASML, BAKERHUGHES, DSM, MICHELIN, SANOFI, BAYER }

export enum filter_ver1_ui { SLB, HM, APPLE, SHELL, ASML, GSK, FORD, ORGANON, STELLANTIS, }

export enum view_ver1_ui { HM, APPLE, SHELL, ASML, GSK, FORD, ORGANON, STELLANTIS, }



// $listclient:('dhl', 'non-dhl-BMS', 'non-dhl-GSK', 'non-dhl-Astrazeneca', 'non-dhl-IBM', 'non-dhl-Maxion', 'non-dhl-Dell', 'non-dhl-SLB', 'non-dhl-Hologic', 'non-dhl-Cisco',
// 'non-dhl-HM', 'non-dhl-metso', 'non-dhl-bmx', 'non-dhl-NovoNordisk', 'non-dhl-Salmar', 'non-dhl-Roche', 'non-dhl-APPLE',
// 'non-dhl-ARAMCO', 'non-dhl-Shell', 'non-dhl-Commscope', 'non-dhl-Ford', 'non-dhl-Organon', 'non-dhl-Stellantis', 'non-dhl-ASML', 'non-dhl-BakerHughes');
