import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { MoreDetailsService } from 'src/app/services/more-details.service';
import { RequestService } from 'src/app/services/request.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
import * as FusionCharts from "fusioncharts";
import { environment } from '../../../environments/environment';
import { FirstDataRenderedEvent, GridReadyEvent, IDetailCellRendererParams, ColumnApi, ValueGetterParams, GridOptions } from 'ag-grid-community';
import * as fileSaver from 'file-saver';
import { ViewChekingService } from 'src/app/services/view-cheking.service';
import { EnvSelectionService } from 'src/app/services/env-selection.service';
import { DatePipe } from '@angular/common';


declare var $: any;
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  dropdownListCustomerOrg = [];
  dropdownListOrigin = [];
  dropdownListDestination = [];
  dropdownListLsp = [];
  selectedItemsCustomerOrg = [];
  selectedItemsOrigin = [];
  selectedItemsDestination = [];
  selectedItemsLsp = [];
  dropdownSettingsDestination = {};
  dropdownSettingsOrigin = {};
  customerOrgdropdownSettings = {};
  LspdropdownSettings = {};
  itemcustomerOrgId: any;
  itemoriginCode: any;
  itemDestinatioCode: any;
  itemLsP: any;
  disableOrigin: boolean = true;
  disableDestination: boolean = true;
  columnDefs: any;
  DocumentscolumnDefs: any
  RowData: any;
  DocumentsRowData: any

  public detailCellRendererParams: any = {

    detailGridOptions: {
      columnDefs: [
        // { field: 'attachmentHash', headerName: 'Attachment Hash' },
        { field: 'evidenceId', headerName: 'Document Name' },
        {
          field: 'downloadUrl', headerName: 'Download Url',
          onCellClicked: this.downloadUrl.bind(this),
          cellRenderer: function (params) {
            let keyData = params.value;
            let newLink = `<a href="javascript:void(0)" style="
            padding: 0px;
            font-size: 12px;
            color: #007bff;
        ">${keyData}</a>`;
            return newLink;
          }
        },

      ],
      defaultColDef: {
        flex: 1,
      },
    },
    getDetailRowData: function (params) {
      params.successCallback(params.data.attachments);
    },
  } as IDetailCellRendererParams;

  domLayout = 'autoHeight';
  IsColumnsToFit: boolean;
  AgLoad: boolean;
  onRowClickedValue = null;
  gridApi: any;
  gridColumnApi: any;

  start_end_mark = [];

  latlng = [];
  eventparsing: any = [];
  referencesData: any = [];
  flightLegSequenceData: any = [];
  OE_ED: any = [];
  SideOverlay = false;
  SideOverlay2 = false;
  houseWayBilldetails: any;
  shipmentDetails: any;
  partDetails: any;
  packageDetails: any = [];
  uniqueIDD: any;
  dealerArrayData: any[];
  dealerIdd: any;
  clickedDealerStatus: boolean;
  showNextbutton: boolean = false;
  HAWBresult: any;
  l: number;
  showBackbutton: boolean;
  openReferencesBody: boolean = false;
  openSegmentDetailsBody: boolean = false;
  openDocumentsBody: boolean = false;
  openMapBody: boolean = false;
  masterID: any;
  mwb: any;
  legsArray = []
  icon = {
    url: 'http://developerdrive.developerdrive.netdna-cdn.com/wp-content/uploads/2013/08/ddrive.png',
    scaledSize: { height: 40, width: 40 }
  }
  searchCustOrgID: any;
  searchOriginID: any;
  searchDestinationID: any;
  searchLsp: any;
  dashboardHAWBSearchField: any;
  userType = null;
  userId: any;
  userOrg: any;
  HAWBSearchField: any;
  ShipmentReferencesSearchField: any;
  historyData = [];

  options: any;
  graphData: any;

  dropdownListAsserId = [];
  selectedItemsAsserId = [];
  dropdownSettingsAsserId = {};

  dropdownListDateFilter = [];
  selectedItemsDateFilter = [];
  dropdownSettingsDateFilter = {};

  codearrayAssertID = [];
  codearrayDateFilter = [];
  CodearrayDataAssertID = [];
  CodearrayDataDateFilter = [];

  MapMarkerClickedValue: string;
  previous = null;
  transportMode: any;

  tempidentifier: any;
  dataSource: any;
  type: string;
  width: string;
  height: string;
  fusionChartsEventData: any;

  columnApi: ColumnApi = new ColumnApi();
  gridOptionsDocuments: GridOptions = {
    defaultColDef: {
      filter: true,
      sortable: true,
      resizable: true,
    },
  };
  eventchainv2Resp: any = [];
  endPoint: any;

  MasterAirwayBillNumber: any;
  groupedFlightEventsDLV: any = []
  groupedFlightEvents: any = [];


  fsuEvent = [];
  fsuResponse: any = [];
  fsu_details: Object;

  startClockValue :any;
  stopClockValue:any
  signature:any
  deliveredPacakageSign: boolean;


  constructor(
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private messageService: MoreDetailsService,
    private requestService: RequestService,
    private viewChecking: ViewChekingService,
    private envSelection: EnvSelectionService
  ) {
    this.endPoint = this.envSelection.getEndPoint(); // It returns the current running env related base end point url.

    this.type = "timeseries";
    this.width = "100%";
    this.height = "500";

    // This is the dataSource of the chart
    this.dataSource = {
      chart: {},
      caption: {
        text: "Temperature Reading"
      },
      subcaption: {
        text: "in °C , <strong>Threshold - 15°C - 20°C</strong> "
      },
      yaxis: [
        {
          plot: [
            {
              value: "Temperature",
              connectnulldata: true,
              style: {
                "plot.null": {
                  "stroke-dasharray": "none",
                  stroke: "#d6d6d6"
                }
              }
            }
          ],
          format: {
            suffix: '°C',
          },
          title: "Temperature"
        }
      ],
      datamarker: [],
      xaxis: {
        plot: "Time",
        timemarker: [

        ]
      }
    };

  }
  MDlspOrgId = this.messageService.lspOrgId;

  filter_ver1_ui: any;
  view_ver1_ui: any;
  ngOnInit(): void {

    this.filter_ver1_ui = this.viewChecking.filterChecking(); // checking filter access based on user list.
    this.view_ver1_ui = this.viewChecking.viewCheking(); // checking view access based on user list.

    this.userType = environment.userType;
    if (this.userType === 'DHL' || this.userType === 'Express') {
      this.customerData();
    }
    else {
      this.lspData()
    }
    this.searchCustOrgID = this.messageService.searchCustOrgID;
    this.searchOriginID = this.messageService.searchOriginID;
    this.searchDestinationID = this.messageService.searchDestinationID;
    this.searchLsp = this.messageService.searchLsp;
    this.dashboardHAWBSearchField = this.messageService.dashboardHAWBSearchField;
    this.ShipmentReferencesSearchField = this.messageService.shipmentReferencesSearchField;
  }


  fetchData() {

    var dataFetch = this.fusionData();
    setTimeout(() => {
      console.log("dataFetch", dataFetch)
      var schemaFetch = this.fusionSchema();

      console.log("schemaFetch", schemaFetch)
      // console.log("dataFetch", dataFetch)
      Promise.all([dataFetch, schemaFetch]).then(res => {
        const [data, schema] = res;
        // const schema = res;
        // First we are creating a DataStore
        const fusionDataStore = new FusionCharts.DataStore();
        // After that we are creating a DataTable by passing our data and schema as arguments
        const fusionTable = fusionDataStore.createDataTable(data, schema);
        // Afet that we simply mutated our timeseries datasource by attaching the above
        // DataTable into its data property.
        this.xAxisData();
        this.dataSource.data = fusionTable;
        console.log("this.dataSoure", this.dataSource.data)
      });
    }, 1000);
  }


  fusionSchema() {
    let obj = [
      {
        column: "Time",
        format: "%d-%b-%Y %I:%M:%S %p",
        index: 0,
        name: "Time",
        outputAs: undefined,
        type: "date",
        anchorBorderColor: "#0372AB",
        anchorBgColor: "#E1f5ff"
      },
      {
        column: "Temperature",
        index: 1,
        name: "Temperature",
        type: "number",
        anchorBorderColor: "#0372AB",
        anchorBgColor: "#E1f5ff"
      },
      {
        name: "_row_id",
        type: "string"
      }
    ]
    return obj;
  }

  fusionData() {
    this.dataSource.datamarker = [];
    console.log("11")
    let id = 811;
    let data: any = [];
    this.requestService.tracktemps(this.uniqueIDD).subscribe((resp: any) => {
      if (resp.Message === 'Sucess') {
        console.log("if")
        resp.result.sort((a, b) => (b.latest_reading_date > a.latest_reading_date) ? -1 : 1);
        let identifierColor: any
        for (let [i, element] of resp.result.entries()) {
          if (element.latest_reading_temp >= 20) {
            this.tempidentifier = 'H'
            identifierColor = '#ff0000'
          }
          else if (element.latest_reading_temp <= 15) {
            this.tempidentifier = 'L',
              identifierColor = '#ff0000'
          }
          else {
            this.tempidentifier = '',
              identifierColor = '#008000'
          }
          let dataArray: any = [];
          dataArray = [
            moment(element.latest_reading_date.split('.')[0]).format('DD-MMM-YYYY HH:mm:ss A'),
            element.latest_reading_temp,
            i
          ]
          data.push(dataArray);
          let identifierArray = {
            value: "Temperature",
            time: moment(element.latest_reading_date.split('.')[0]).format('DD-MMM-YYYY HH:mm:ss A'),
            type: "pin",
            identifier: '',
            timeformat: "%d-%b-%Y %I:%M:%S %p",
            style: {
              text: {
                "fill": identifierColor,
              },
              marker: {
                "fill": identifierColor,
                "font-weight": "bold",
                "stroke": identifierColor
              }
            }
          }
          this.dataSource.datamarker.push(identifierArray)
        }
        this.graphData = data
        console.log("ifLOOP", data)
        return data;
      }
    })
    return data;

  }

  stopClockvalueGetter(params:ValueGetterParams){
    let keyData
    if(params.data.eventCode == "OK"){
      keyData = moment.utc(params.data.latestTrackingTimeStamp).format('YYYY-MM-DD HH:mm')      
        return keyData;;
    }
    else
    return keyData; 
  }

  startClockValueGetter(params:ValueGetterParams){
    let keyData=params.data;
    keyData = moment.utc(params.data.awbIssueDate).format('YYYY-MM-DD HH:mm');
    return keyData
  }

  xAxisData() {
    let data: any = [];
    this.dataSource.xaxis.timemarker = [];
    let id = 811;
    let resp1 = this.fusionChartsEventData;
    // this.requestService.eventChainDetails(this.uniqueIDD).subscribe((resp1: any) => {
    resp1.result.sort((a, b) => (b.sortDate > a.sortDate) ? -1 : 1);
    for (let element1 of resp1.result) {

      let obj = {
        start: moment(element1.sortDate.split('.')[0]).format('DD-MMM-YYYY HH:mm:ss A'),
        label: element1.eventCode + ' - ' + element1.description,
        timeformat: "%d-%b-%Y %I:%M:%S %p"
      }
      data.push(obj);
    }
    this.dataSource.xaxis.timemarker = this.removeDuplicates(data, "label");
    console.log("xaxis", this.dataSource.xaxis.timemarker)
    // });
  }


  gridOptions = {
    rowData: this.GetTableData(),
    columnDefs: this.GetAgColumns(),
  }
  defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    // filter: true,
    searchable: true,
    floatingFilter: true,
    resizable: true,
    flex: 1
  };
  GetAgColumns() {
    const dis = this;
    let filter = {};
    this.spinner.show();
    let localstorageUserOrg = this.authService.getLoggedUser();
    this.userId = localstorageUserOrg.userId;
    this.userOrg = localstorageUserOrg.userOrg
    console.log("this.messageService.dashboardHAWBSearchField", this.messageService.dashboardHAWBSearchField)
    if (this.messageService.dashboardHAWBSearchField) {
      filter = {
        houseBillNumber: [this.messageService.dashboardHAWBSearchField],
        emailId: [localstorageUserOrg.emailId],
        // customerOrg: [this.userId]
      }
    }
    else if (this.messageService.shipmentReferencesSearchField) {
      filter = {
        'LIKE-awbIssuePlace': [this.messageService.shipmentReferencesSearchField],
        emailId: [localstorageUserOrg.emailId],
      }
    }
    else {
      filter = {
        customerOrg: [this.messageService.searchCustOrgID],
        origin: [this.messageService.searchOriginID],
        destination: [this.messageService.searchDestinationID],
        lspOrg: [this.messageService.searchLsp],
        emailId: [localstorageUserOrg.emailId],
      }
    }
    this.requestService.List('', filter).toPromise().then(resp => {
      this.RowData = resp['result'];
      this.spinner.hide();
    });
  }
  GetTableData() {
    // let localstorageUserOrg = this.authService.getLoggedUser();
    if (this.messageService.searchCustOrgID === 'CARGOTEC_FRT') {
      this.columnDefs = [
        { headerName: 'Account No', field: 'accountCode', sortable: true },
        {
          headerName: 'Order Code', field: 'HAWB', sortable: true, suppressFiltersToolPanel: true,
          onCellClicked: this.onRowClicked.bind(this),
          // cellRenderer: 'agGroupCellRenderer'
          cellRenderer: function (params) {
            let keyData = params.value;
            let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
            return newLink;
          }

        },
        {
          headerName: 'Customer Reference', field: 'awbIssuePlace', sortable: true, suppressFiltersToolPanel: true,
          onCellClicked: this.onRowClicked.bind(this),
          // cellRenderer: 'agGroupCellRenderer'
          cellRenderer: function (params) {
            let keyData = params.value;
            if (params.data.lspOrgId === 'DGF') {
              keyData = params.data.forwardingShipment
            }
            if (keyData === null) {
              keyData = 'Not Assigned'
            }
            let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
            return newLink;
          }
        },
        { headerName: 'Shipper', field: 'companyName', sortable: true },
        { headerName: 'Customer', field: 'customerOrgName', sortable: true, suppressFiltersToolPanel: true },

        { headerName: 'MAWB', field: 'mawb', sortable: true, suppressFiltersToolPanel: true, hide: true },
        { headerName: 'Checkpoint Date', field: 'latestTrackingTimeStamp', valueFormatter: this.checkPointDateFormate, sortable: true, suppressFiltersToolPanel: true, hide: true },
        // { headerName: 'Invoice Number', field: '', sortable: true, suppressFiltersToolPanel: true },       
        { headerName: 'Origin', field: 'originCode', sortable: true, suppressFiltersToolPanel: true },
        { headerName: 'Destination', field: 'airportOfDestinationCode', sortable: true, suppressFiltersToolPanel: true },
        { headerName: 'Service Type', field: 'incoDescription', sortable: true, suppressFiltersToolPanel: true },
        { headerName: 'Est Delivery', field: 'shipment_eta', sortable: true },
        { headerName: 'Number of Pieces', field: 'quantity', sortable: true, suppressFiltersToolPanel: true },
        { headerName: 'Weight', field: 'totalWeight', sortable: true, valueFormatter: this.DecimalFormate, suppressFiltersToolPanel: true },
        { headerName: 'Volume', field: 'totalVolume', sortable: true, valueFormatter: this.DecimalFormate, suppressFiltersToolPanel: true },
      ];
    }
    if (this.messageService.searchCustOrgID === 'CARGOTEC') {
      this.columnDefs = [
        { headerName: 'Account Code', field: 'accountCode', sortable: true },
        {
          headerName: 'Transport Mode', field: 'transportMode', sortable: true,
        },
        {
          headerName: 'Hawb /Tracking No/Order Code', field: 'HAWB', sortable: true,
          tooltipValueGetter: (params) => params.data.latestTrackingEvent,
          onCellClicked: this.onRowClicked.bind(this),
          // cellRenderer: 'agGroupCellRenderer'
          cellRenderer: function (params) {
            let keyData = params.value;
            let newLink = `<a href="javascript:void(0)" >${keyData}</a>`;
            return newLink;
          }
        },
        {
          headerName: 'Customer Reference', field: 'awbIssuePlace', sortable: true, suppressFiltersToolPanel: true,
          onCellClicked: this.onRowClicked.bind(this),
          // cellRenderer: 'agGroupCellRenderer'
          cellRenderer: function (params) {
            let keyData = params.value;
            if (params.data.lspOrgId === 'DGF') {
              keyData = params.data.forwardingShipment
            }
            if (keyData === null) {
              keyData = 'Not Assigned'
            }
            let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
            return newLink;
          }
        },
        { headerName: 'Shipper', field: 'companyName', sortable: true },
        { headerName: 'Customer/Consignee', field: 'customerOrgName', sortable: true, suppressFiltersToolPanel: true },
        { headerName: 'Origin', field: 'originCode', sortable: true },
        { headerName: 'Destination', field: 'airportOfDestinationCode', sortable: true },
        // { headerName: 'Service Type', field: 'incoDescription', sortable: true },
        // { headerName: 'Inco Term', field: 'incoTerm', sortable: true },
        { headerName: 'Est Delivery', field: 'shipment_eta', sortable: true },
        // { headerName: 'Awb Number', field: 'awbNumber', sortable: true },
        { headerName: 'Number of Pieces/Quantity', field: 'quantity', sortable: true },
        { headerName: 'Weight', field: 'totalWeight', sortable: true, valueFormatter: this.DecimalFormate },
        { headerName: 'Volume', field: 'totalVolume', sortable: true, valueFormatter: this.DecimalFormate },
        { headerName: 'Latest Tracking Event', field: 'latestTrackingEvent', sortable: true },
        // { headerName: 'Latest Tracking Time Stamp', field: 'latestTrackingTimeStamp', valueFormatter: this.checkPointDateFormate, sortable: true },
        // { headerName: 'Event Code', field: 'eventCode', sortable: true },

        // { headerName: 'Company Name', field: 'companyName', sortable: true },
        // { headerName: 'Customer OrgId', field: 'customerOrgId', sortable: true },
        // { headerName: 'Customer OrgName', field: 'customerOrgName', sortable: true },
        // { headerName: 'lspOrg Id', field: 'lspOrgId', sortable: true },
        // { headerName: 'lspOrg Name', field: 'lspOrgName', sortable: true },
        // { headerName: 'Pickup Company Name', field: 'pickupCompanyName', sortable: true },
        // { headerName: 'Delivery Company Name', field: 'deliveryCompanyName', sortable: true },

      ]
    }
    else {
      this.columnDefs = [
        { headerName: 'Shipment References', field: 'awbIssuePlace', sortable: true },
        { headerName: 'MOT', field: 'transportMode', sortable: true },
        {
          headerName: 'HAWB/Tracking No', field: 'HAWB', sortable: true, suppressFiltersToolPanel: true,
          onCellClicked: this.onRowClicked.bind(this),
          cellRenderer: function (params) {
            let keyData = params.value;
            let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
            return newLink;
          }
        },
        { headerName: 'Shipper', field: 'companyName', sortable: true },
        { headerName: 'Consignee', field: 'customerOrgName', sortable: true },
        {
          headerName: 'Origin', valueGetter: this.OriginValueGetter.bind(this), sortable: true, suppressFiltersToolPanel: true,
        },
        {
          headerName: 'Destination', valueGetter: this.DestinationValueGetter.bind(this), sortable: true, suppressFiltersToolPanel: true,
        },
        {
          headerName: 'Latest Tracking Event', valueGetter: this.latestTrackingEventValueGetter.bind(this), sortable: true,
        },
        { headerName: 'Checkpoint Date', field: 'latestTrackingTimeStamp', valueFormatter: this.checkPointDateFormate, sortable: true, suppressFiltersToolPanel: true, hide: true },
        { headerName: 'Forwarder', field: 'lspOrgName', sortable: true },
        { headerName: 'Inco Term', field: 'incoTerm', sortable: true, suppressFiltersToolPanel: true },
        { headerName: 'Number of Pieces', field: 'quantity', sortable: true, suppressFiltersToolPanel: true },
        {
          headerName: 'Volume', field: 'Volume', sortable: true, filter: false,
          cellRenderer: function (params) {
            let keyData = params.data;
            if (keyData.volumeUnit !== null) {
              let Volume = Number(keyData.totalVolume).toFixed(3) + ' ' + keyData.volumeUnit
              return Volume;
            }
            else {
              let Volume = Number(keyData.totalVolume).toFixed(3)
              return Volume
            }

          }
        },
        {
          headerName: 'Weight', field: 'Weight', sortable: true, filter: false,
          cellRenderer: function (params) {
            let keyData = params.data;
            if (keyData.WeightUnit !== null) {
              let Weight = Number(keyData.totalWeight).toFixed(3) + ' ' + keyData.WeightUnit
              return Weight;
            }
            else {
              let Weight = Number(keyData.totalWeight).toFixed(3)
              return Weight;
            }

          }
        },
        { headerName: 'Startclock Date', field: 'awbIssueDate', valueGetter: this.startClockValueGetter.bind(this) ,sortable: true, filter: false},
        { headerName: 'Stopclock Timestamp', colId:'latestTrackingTimeStamp', valueGetter: this.stopClockvalueGetter.bind(this), sortable: true, filter: false},
        {
          headerName: 'Account No', field: 'accountCode', sortable: true,
          cellRenderer: function (params) {
            let keyData = params.data;
            if (keyData.lspOrgId === 'ARAMEX') {
              let data = '';
              return data
            }
            else {
              return params.data.accountCode
            }
          }
        },
        {
          headerName: 'Account Description', field: 'companyName', sortable: true,
          cellRenderer: function (params) {
            let keyData = params.data;
            if (keyData.lspOrgId === 'ARAMEX') {
              let data = '';
              return data
            }
            else {
              return params.data.companyName
            }
          }
        },
        { headerName: 'Est Delivery', field: 'shipment_eta', sortable: true },
        // { headerName: 'Startclock Date', field: 'awbIssueDate', valueGetter: this.startClockValueGetter.bind(this) ,sortable: true, filter: false},
        // { headerName: 'Stopclock Timestamp', colId:'latestTrackingTimeStamp', valueGetter: this.stopClockvalueGetter.bind(this), sortable: true, filter: false}
      ];
    };

  }

  getDocumentsTableHeaders() {
    this.DocumentscolumnDefs = [
      { field: 'document_type', headerName: 'Document Type', cellRenderer: 'agGroupCellRenderer' },
      { field: 'document_id', headerName: 'Document ID' },
      { field: 'event_code', headerName: 'Event Code' },
      { field: 'file_name', headerName: 'File Name' },

    ]
  }

  latestTrackingEventValueGetter(params: ValueGetterParams) {
    return params.data.latestTrackingEvent.substring(params.data.latestTrackingEvent.indexOf('/') + 1);
  }

  OriginValueGetter(params: ValueGetterParams) {
    let keyData = params.data;
    if (keyData.pickupCity !== null) {
      let originCode = keyData.pickupCity + ',' + keyData.pickupCountryCode
      return originCode;
    }
    else {
      let originCode = keyData.pickupCountryCode;
      return originCode
    }
  }
  DestinationValueGetter(params: ValueGetterParams) {
    let keyData = params.data;
    if (keyData.deliveryCity !== null) {
      let airportOfDestinationCode = keyData.deliveryCity + ',' + keyData.deliveryCountryCode
      return airportOfDestinationCode;
    }
    else {
      let airportOfDestinationCode = keyData.deliveryCountryCode;
      return airportOfDestinationCode
    }
  }



  DecimalFormate(value: any): any {
    if (value !== null) {
      var num = new Number(value.value);
      return (Math.round(value.value * 100) / 100).toFixed(3);;
    }
  }

  onRowClicked(event: any) {
    this.clickedDealerStatus = false;
    // this.onRowClickedValue = event.value;
    this.startClockValue = moment.utc(event.data.awbIssueDate).format("YYYY-MM-DD HH:mm");
    if(event.data.eventCode =="OK"){
      this.stopClockValue = moment.utc(event.data.latestTrackingTimeStamp).format("YYYY-MM-DD HH:mm");
    }
    console.log("this.onRowClickedValue", event.data.houseawbid)
    // let def = 'customStats';
    // event.api.openToolPanel(def);
    // this.moreDetails.changeMessage(this.onRowClickedValue);
    this.mwb = event.data.mawb
    let uniqueId = event.data.ushipmentid;
    if (event.data.masterId !== null) {
      this.masterID = event.data.masterId
    }
    else if (event.data.masterId === null) {
      this.masterID = '101' + event.data.ushipmentid + '101'
    }
    this.transportMode = event.data.transportMode;
    this.popupData(event.data.houseawbid, event.data.HAWB, uniqueId, event.data.ext_dealerId, event.data.ext_dealerOrder)
    // $("#newModal2").modal("show");

  }

  checkPointDateFormate(value: any): any {
    if (value !== null) {
      return moment.utc(value.value).format('YYYY-MM-DD HH:mm');
    }
  }

  popupData(houseAwb, hawb, uniqueId, dealerId, dealerOrder) {

    const dis = this;
    let req = null;
    if (this.clickedDealerStatus) {
      let req1 = {
        dealerId, dealerOrder
      }
      this.spinner.show();
      req = dis.requestService.popup(req1);
      req.subscribe(resp => {
        this.HAWBresult = resp.result;
        console.log("result1::::::", this.HAWBresult)
        for (let i = 0; i < this.HAWBresult.length; i++) {
          if (this.HAWBresult.length === 1) {
            console.log('# ', this.showNextbutton);
            this.onRowClickedValue = this.HAWBresult[0];
            this.uniqueIDD = this.HAWBresult[0].ushipmentid
            uniqueId = this.uniqueIDD;
          } else if (this.HAWBresult.length > 1) {
            this.showNextbutton = true;
            this.showBackbutton = false;
            this.l = 1
            console.log('## ', this.showNextbutton);
            this.onRowClickedValue = this.HAWBresult[0];
            this.uniqueIDD = this.HAWBresult[0].ushipmentid
            uniqueId = this.uniqueIDD;
          }
        }
        console.log("messageResult", this.onRowClickedValue)
        dis.eventparsing = [];
        this.eventchainloop(uniqueId);
        this.referencesEvent(uniqueId);
        this.mapData();
        if (this.userType !== 'Cargotec') {
          this.trackTempDetails();
        }
        this.SideOverlay = true;
        // this.spinner.hide();
      });
    } else {
      this.uniqueIDD = uniqueId;
      let req2 = {
        houseAwb, hawb
      }
      this.spinner.show();
      req = dis.requestService.popup(req2);
      req.subscribe(resp => {
        let result = resp.result;
        console.log("result::::::", result)
        for (let i = 0; i < result.length; i++) {
          if (i === 0) {
            this.onRowClickedValue = result[0];
          }
        }
        console.log("messageResult", this.onRowClickedValue)
        dis.eventparsing = [];
        this.eventchainloop(uniqueId);
        this.referencesEvent(uniqueId)
        this.mapData();
        if (this.userType !== 'Cargotec') {
          this.trackTempDetails();
        }
        this.SideOverlay = true;
        // this.spinner.hide();
      });
    }

  }

  referencesEvent(uniqueId) {
    const dis = this;
    let req = null;
    req = this.requestService.referencesMoreDetails(uniqueId);
    req.subscribe(resp => {
      dis.referencesData = resp.result;
    });
  }

  referencesClicked() {
    this.openReferencesBody = !this.openReferencesBody;
  }
  SegmentDetailsClicked() {
    this.openSegmentDetailsBody = !this.openSegmentDetailsBody;
  }

  DocumentsClicked() {
    // this.DocumentscolumnDefs = [
    //   { field: 'document_type', headerName: 'Document Type' },
    // ]
    this.openDocumentsBody = !this.openDocumentsBody;
    this.requestService.DocumentsAPI(this.uniqueIDD).subscribe((resp: any) => {
      this.DocumentsRowData = resp['result'];
      this.getDocumentsTableHeaders();
    })
  }


  mapClicked() {
    this.latlng = [];
    this.openMapBody = !this.openMapBody;
    this.mapData()
  }



  asserIDDropdownMethod() {
    this.dropdownSettingsAsserId = {
      singleSelection: true,
      text: "Select Asset/Container",
      enableSearchFilter: true,
    };
  }

  onItemSelectAssertID(item: any) {
    console.log(item.id);
    // this.selectedItemsAsserId = item.id;
    this.trackTempDetails()
  }
  OnItemDeSelectAssertID(item: any) {
    console.log(item);
    this.selectedItemsAsserId = [];
    this.trackTempDetails();
  }

  DateFilterDropdownMethod() {
    this.dropdownSettingsDateFilter = {
      singleSelection: true,
      text: "Select Date",
      enableSearchFilter: true,
    };
  }

  onItemSelectDateFilter(item: any) {
    console.log(item.id);
    // this.selectedItemsDateFilter = item.itemName;
    this.trackTempDetails();
  }
  OnItemDeSelectDateFilter(item: any) {
    console.log(item);
    this.selectedItemsDateFilter = [];
    this.trackTempDetails();
  }


  trackTempClicked() {
    this.fetchData();
  }

  trackTempDetails() {
    console.log('this.graphData1111::::::', typeof this.graphData)

    const dis = this;
    this.requestService.tracktemps(this.uniqueIDD).subscribe((resp: any) => {
      if (resp.Message === 'Sucess') {
        console.log("tracktemp:::", this.selectedItemsDateFilter, this.selectedItemsDateFilter.length);
        if (this.selectedItemsDateFilter.length !== 0) {
          console.log("if");
          let dateFilter: any = [];
          for (let data of resp['result']) {
            console.log("data:::", data['latest_reading_date'])
            if (data['latest_reading_date'].includes(this.selectedItemsDateFilter[0]['itemName'])) {
              console.log("if1:::");
              dateFilter.push(data);
              console.log("dateFilter", dateFilter)
            }
          }
          this.graphData = dateFilter;
        }
        else if (this.selectedItemsAsserId.length !== 0) {
          let assertFilter: any = [];
          for (let data of resp['result']) {
            if (data.ship_asset_id.includes(this.selectedItemsAsserId[0]['itemName'])) {
              assertFilter.push(data);
            }
          }
          this.graphData = assertFilter
        }
        else {
          this.CodearrayDataDateFilter = [];
          this.CodearrayDataAssertID = [];
          this.codearrayDateFilter = [];
          this.codearrayAssertID = [];
          this.graphData = resp['result'];
          this.graphData.sort((a, b) => (a.latest_reading_date > b.latest_reading_date) ? -1 : 1);
          for (let i = 0; i < this.graphData.length; i++) {
            const raws = this.graphData[i];
            dis.codearrayAssertID[i] = { id: raws['ship_asset_id'], itemName: raws['ship_asset_id'] };
            dis.codearrayDateFilter[i] = { id: raws['latest_reading_date'], itemName: raws['latest_reading_date'].split('T')[0] };
          }
          let arrayduplicateremoveAsserID = this.removeDuplicates(dis.codearrayAssertID, "id");
          dis.CodearrayDataAssertID = arrayduplicateremoveAsserID;
          let arrayduplicateremoveDateFilter = this.removeDuplicates(dis.codearrayDateFilter, "itemName");
          dis.CodearrayDataDateFilter = arrayduplicateremoveDateFilter;
          console.log("CodearrayDataAssertID,CodearrayDataDateFilter", dis.CodearrayDataAssertID, dis.CodearrayDataDateFilter)
        }
        console.log('this.graphData::::::', typeof this.graphData)
        this.chartData();

      }
    })
  }



  chartData() {
    this.graphData.sort((a, b) => (b.latest_reading_date > a.latest_reading_date) ? -1 : 1);
    for (let data of this.graphData) {
      data.latest_reading_temp = parseFloat(data.latest_reading_temp);
      if (this.selectedItemsDateFilter.length === 0) {
        data.latest_reading_date = data.latest_reading_date.split('T')[0]
        let date = new Date(data.latest_reading_date);
        let format = moment(date).format("YY-MM-DD");
        data.latest_reading_date = format;
      }
      else if (this.selectedItemsDateFilter.length !== 0) {
        let formatDate = data.latest_reading_date.split('T')[1];
        data.latest_reading_date = formatDate.split('.')[0];
      }
    }
    this.options = {
      title: {
        text: 'Temperature Reading in °C',
        fontWeight: 'bold'
      },
      autoSize: true,
      data: this.graphData,

      series: [
        {
          xKey: 'latest_reading_date',
          yKey: 'latest_reading_temp',
        },
      ],
      legend: {
        enabled: false
      },
      axes: [
        {
          type: 'category',
          position: 'bottom',
          title: {
            text: 'Event - Timestamp',
            enabled: true
          },
          label: {
            rotation: 90
          },
        },
        {
          type: 'number',
          position: 'left',
          title: {
            text: 'in Celsius',
            enabled: true,
          },

        }
      ]
    };
    // console.log("chartDate", this.options)

  }

  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};
    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }



  mapData() {
    this.latlng = [];
    this.start_end_mark = [];
    this.legsArray = [];
    this.previous = null;
    this.spinner.show();
    this.requestService.moreDetailsMapData(this.masterID).subscribe((resp: any) => {
      if (resp.Message === 'Sucess') {
        this.spinner.hide();
        console.log("mapData:::", resp['result']);
        this.flightLegSequenceData = resp['result']
        if (resp['result'].length !== 0) {
          let lastCode = resp['result'][resp['result'].length - 1]['destination_code']
          for (let loop of resp['result']) {
            this.latlng.push([+loop.orig_latitude, +loop.orig_longitude])
            this.latlng.push([+loop.dest_latitude, +loop.dest_longitude])
            if (resp['result'].length === 1) {
              console.log("if:::::1")
              this.legsArray.push(loop.origin_code, loop.destination_code)
              console.log("if:::::Result", this.legsArray)
            } else if (resp['result'].length > 1) {
              console.log("ELSE if:::::2")
              this.legsArray.push(loop.origin_code);
              if (resp['result'].length - 1 === resp['result'].indexOf(loop)) {
                this.legsArray.push(lastCode)
              }
              console.log("ELSE if:::::Result", this.legsArray)

            }
          }
          console.log('array', this.latlng);
          for (let i = 0; i < this.latlng.length; i++) {
            if (i === 0) {
              let icon = {
                url: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ffcd36',
                scaledSize: {
                  width: 30,
                  height: 45
                }
              }
              this.latlng[i].push(icon)

            }
          }
          this.start_end_mark.push(this.latlng[0]);
          this.start_end_mark.push(this.latlng[this.latlng.length - 1]);
          console.log("this.start_end_mark", this.start_end_mark)
        }
        else {
          this.latlng = [];
        }
      }
    })

  }

  nextHAWB() {

    if (this.HAWBresult.length > this.l) {
      this.l++
      console.log('# ', this.l);
      this.onRowClickedValue = this.HAWBresult[this.l - 1];
      this.uniqueIDD = this.onRowClickedValue.ushipmentid
      this.eventparsing = [];
      this.eventchainloop(this.uniqueIDD);
    }
    if (this.l === this.HAWBresult.length) {
      this.showNextbutton = false;
      this.showBackbutton = true;
    }
  }

  backHAWB() {
    if (this.l > 1) {
      this.l--
      console.log('# ', this.l);
      this.onRowClickedValue = this.HAWBresult[this.l - 1];
      this.uniqueIDD = this.onRowClickedValue.ushipmentid
      this.eventparsing = [];
      this.eventchainloop(this.uniqueIDD);
    }
    if (this.l == 1) {
      this.showNextbutton = true;
      this.showBackbutton = false;
    }
  }

  openHWABdetails() {
    this.packageDetails = [];
    this.spinner.show();
    this.requestService.airWayBillDetails(this.onRowClickedValue.houseawbid, this.onRowClickedValue.HAWB, true).subscribe((resp: any) => {
      console.log('hawbill details:', resp);
      this.spinner.hide();
      if (resp.Message === 'Sucess') {
        this.houseWayBilldetails = resp.result.houseBills[0];
        if (this.houseWayBilldetails.pickupAddressOverride !== null) {
          let timeHrs = this.houseWayBilldetails.pickupAddressOverride.split(':')[0];
          let timeMin = this.houseWayBilldetails.pickupAddressOverride.split(':')[1];
          let fullTime = timeHrs + ':' + timeMin;
          this.houseWayBilldetails.pickupAddressOverride = fullTime.replace('T', ' ');
        }
        if (this.messageService.lspOrgId[0] === "DHLE" || this.messageService.lspOrgId[0] === 'DGF') {
          this.houseWayBilldetails.pickupAccountCode = this.houseWayBilldetails.pickupAccountCode
          this.houseWayBilldetails.deliveryAccountCode = this.houseWayBilldetails.deliveryAccountCode
        }
        else {
          this.houseWayBilldetails.pickupAccountCode = ''
          this.houseWayBilldetails.deliveryAccountCode = ''
        }
        this.shipmentDetails = resp.result.shipment[0];
        this.shipmentDetails.weight = Number(this.shipmentDetails.weight).toFixed(3);
        this.shipmentDetails.volume = Number(this.shipmentDetails.volume).toFixed(3);
        for (let data of resp.result.packages) {
          if (data.commodity !== null || data.container_number !== null || data.package_count !== null) {
            this.packageDetails.push(data);
            console.log(this.packageDetails, this.packageDetails.length)
          }
          else {
            this.packageDetails = [];
          }
        }

        this.partDetails = resp.result.partDetails;
      }
      else {
        alert(resp.Message);
      }
    });

    this.SideOverlay = false;
    this.SideOverlay2 = true;
  }

  closeHAWBdetails() {
    this.SideOverlay = true;
    this.eventparsing = [];
    this.eventchainloop(this.uniqueIDD);
    this.SideOverlay2 = false;
  }


  clear() {
    this.dealerIdd = null;
    this.spinner.show();
    this.GetTableData();
  }



  eventchainloopV2(uniqueId) {
    this.eventchainv2Resp = [];
    const dis = this;
    let req = null;
    req = this.requestService.eventChainDetailsV2(uniqueId);
    req.subscribe((resp: any) => {
      console.log("resp", resp);
      if(resp.result['LAST-MILE DELIVERY']){
        this.deliveredPacakageSign = true
        let events = resp.result['LAST-MILE DELIVERY'].events
        events.forEach((ele)=>{
          if(ele.eventCode == "OK"){
            this.signature = ele.signature
          }
        })          
        } 

      const objArray = [];
      Object.keys(resp['result']).forEach(key => objArray.push({
        name: key,
        result: resp['result'][key]
      }));
      console.log(objArray);
      for (let data of objArray) {
        for (let data1 of data['result']['events']) {
          let date = new DatePipe('en-US').transform(data1.sortDate, 'dd-MMM-YYYY HH:mm', 'UTC');
          data1['sortDate'] = date
        }
      }
      this.eventchainv2Resp = objArray
      document.getElementById('reverseCountV2').style.setProperty('--ulLength', dis.eventchainv2Resp.length + 1);
      this.spinner.hide();
      // let ul = document.querySelector('li')
      // ul.style.setProperty('--ulLength', '10')
    })
  }



  async eventchainloop(uniqueId) {
    const dis = this;
    let raws;
    let req = null;
    dis.eventparsing = [];
    dis.OE_ED = [];
    dis.fsuEvent = [];
    await this.getFsuDataOnRowClicked();
    req = this.requestService.eventChainDetails(uniqueId);
    req.subscribe(resp => {
      this.fusionChartsEventData = resp;
      let messageResult = resp['result'];
      // messageResult.forEach((data)=>{
      //   if(data.eventCode === "OK"){
      //     this.signature = data.signature;
      //   }
      // })
      messageResult = [...messageResult, ...dis.fsuEvent]
      for (let i = 0; i < messageResult.length; i++) {
        raws = messageResult[i];

        if (raws.sortDate !== null && raws.sortDate.includes("T")) {

          let date = raws.sortDate.split('T')[0];
          let time = raws.sortDate.split('T')[1];
          let hrs = time.split(':')[0];
          let min = time.split(':')[1];
          let finalTime = hrs + ':' + min
          raws.sortDate = date + ' ' + finalTime
          // let time = raws.EVENTDATE.split('T')[0];
          // let hrs = raws.actualDate.split(':')[0];
          // let min = raws.actualDate.split(':')[1];
          // raws.actualDate = hrs + ':' + min;
          console.log("::::::", raws.sortDate);
        }
        // if (raws.actualDate === null) {
        //   raws.actualDate = "Actual event is yet to happen"
        // }
        if (raws.estimatedDate !== null && raws.estimatedDate.includes("T")) {
          console.log("::::::", raws.estimatedDate)
          // let time = raws.EVENTDATE.split('T')[0];
          let hrs = raws.estimatedDate.split(':')[0];
          let min = raws.estimatedDate.split(':')[1];
          raws.estimatedDate = hrs + ':' + min;
          console.log("DateTime::", raws.estimatedDate)
        }
        if (raws.estimatedDate === null) {
          raws.estimatedDate = ""
        }
        // raws.EVENTDATE = new Date();
        // raws.EVENTDATE = (this.datePipe.transform(raws.EVENTDATE, 'dd/MM/yyyyTHH:mm') as any);
        if (raws.sortDate !== null) {
          dis.eventparsing.push(raws);
          // }
        }
        else if (raws.sortDate === null && raws.estimatedDate !== null) {
          if (raws.description === 'Departed') {
            raws.description = 'Departure'
          }
          else if (raws.description === 'Arrived') {
            raws.description = 'Arrival'
          }
          dis.OE_ED.push(raws);
        }
      }
      console.log('-----------------------------::::', dis.eventparsing.length);
      // document.getElementById('reverseCount').style.setProperty('--ulLength', dis.eventparsing.length + 1);
      // let ul = document.querySelector('li')
      // ul.style.setProperty('--ul-length', '10')
      if (this.userType === 'HM' || this.userType === 'GSK' || this.userType === 'APPLE' || this.userType === 'SHELL' || this.userType === 'ASML'
        || this.userType === 'FORD' || this.userType === 'ORGANON' || this.userType === 'STELLANTIS') {
        this.eventchainloopV2(uniqueId)
      }
    });
  }

 getFsuDataOnRowClicked() {
    let temp = this.requestService.getFsuData(this.onRowClickedValue.HAWB);
    temp.subscribe((resp) => {
      if (resp["result"].length != 0) {
        this.fsuResponse = resp["result"];
        console.log(resp);
        this.MasterAirwayBillNumber = resp["result"].MasterAirwayBillNumber;
        this.groupedFlightEventsDLV = resp["result"].groupedFlightEventsDLV;
        this.groupedFlightEvents = resp["result"].groupedFlightEvents;
        this.groupedFlightEventsDLV[0].events.forEach((el) => {
          this.fsuEvent.push({
            actualDate: null,
            description: el.checkpointCode,
            estimatedDate: null,
            eventCode: null,
            eventGroup: null,
            eventGroupOderId: null,
            id: null,
            location: null,
            sequence: null,
            sortDate: el.checkpointDatetime,
          });
        });
        for (let data of this.groupedFlightEvents) {
          for (let val of data.events) {
            val["checkpointDatetime"] = moment(val["checkpointDatetime"]).format(
              "DD MMM / hh:mm"
            );
            if (val.checkpointCode == "BKD") {
              val["imageSRC"] = "./assets/images/fsu_images/booking.png";
            } else if (val.checkpointCode == "RCS") {
              val["imageSRC"] = "./assets/images/fsu_images/received.png";
            } else if (val.checkpointCode == "DEP") {
              val["imageSRC"] = "./assets/images/fsu_images/departure.png";
            } else if (val.checkpointCode == "ARR") {
              val["imageSRC"] = "./assets/images/fsu_images/arrival.png";
            } else if (val.checkpointCode == "NFD") {
              val["imageSRC"] = "./assets/images/fsu_images/notified.png";
            } else if (val.checkpointCode == "DLV") {
              val["imageSRC"] = "./assets/images/fsu_images/delivered.png";
            } else {
              val["imageSRC"] = "./assets/images/fsu_images/default.png";
            }
          }
        }
      }
    });
  }




  closemodal() {
    console.log("close")
    this.SideOverlay = false;

  }

  lspData() {
    this.LspdropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    this.dropdownListLsp = [
      { item_id: 1, item_text: 'DGF' },
      { item_id: 2, item_text: 'DHLE' },
      { item_id: 3, item_text: 'FREIGHT' },
    ];
  }

  onItemSelectLsp(item: any) {
    console.log("item.", item.item_text);
    this.itemLsP = item.item_text;
    if (this.selectedItemsOrigin.length !== 0) {
      this.selectedItemsOrigin = [];
      this.selectedItemsDestination = [];
    }
    this.originDataCall()
  }



  customer_dropDown() {
    this.customerOrgdropdownSettings = {
      singleSelection: true,
      idField: 'customerOrgId',
      textField: 'customerOrgId',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  customerData() {
    const dis = this;
    let req = null;
    req = dis.requestService.customerDropdownData();
    req.subscribe(resp => {
      console.log("customerORG::::", resp)
      if (resp['Message'] === 'Sucess') {
        this.customer_dropDown();
        this.dropdownListCustomerOrg = resp['result']
      }

    });
  }

  onItemSelectCustomerOrg(item: any) {
    console.log(item.customerOrgId);
    // this.selectedItemsCustomerOrg = item.customerOrgId
    if (this.selectedItemsOrigin.length !== 0) {
      this.selectedItemsOrigin = [];
      this.selectedItemsDestination = [];
    }
    this.itemcustomerOrgId = item.customerOrgId;
    this.originDataCall()
  }

  origin_dropDown() {
    this.dropdownSettingsOrigin = {
      singleSelection: true,
      idField: 'origin_code',
      textField: 'origin_code',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  originDataCall() {
    const dis = this;
    let req = null;
    let localstorageUserOrg = this.authService.getLoggedUser();
    if (this.userType === 'DHL' || this.userType === 'Express') {
      this.itemcustomerOrgId = this.itemcustomerOrgId;
      if (this.userType === 'DHL') {
        this.itemLsP = 'DGF'
      }
      else if (this.userType === 'Express') {
        this.itemLsP = 'DHLE'
      }
    }
    else {
      this.itemcustomerOrgId = localstorageUserOrg.userOrg
      this.itemLsP = this.itemLsP
    }
    let params = {
      email: localstorageUserOrg.emailId,
      custOrgId: this.itemcustomerOrgId,
      lspOrg: this.itemLsP
    }
    let filter: any = {};
    filter['lspOrg'] = [this.itemLsP];
    filter['customerOrg'] = [this.itemcustomerOrgId]
    filter['emailId'] = [localstorageUserOrg.emailId]
    req = dis.requestService.originsByLsp(filter);
    req.subscribe(resp => {
      if (resp['Message'] === 'Sucess') {
        console.log("originDropdownData::::", resp)
        this.disableOrigin = false;
        this.origin_dropDown();
        this.dropdownListOrigin = resp['result'].sort();
      }
    });
  }
  onItemSelectOrigin(item: any) {
    console.log(item);
    this.itemoriginCode = item;
    if (this.searchDestinationID.length !== 0) {
      this.selectedItemsDestination = [];
    }
    this.DestinationDataCall(item)
  }

  destination_dropDown() {
    this.dropdownSettingsDestination = {
      singleSelection: true,
      idField: 'destination_code',
      textField: 'destination_code',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  DestinationDataCall(origin_code) {
    const dis = this;
    let req = null;
    let localstorageUserOrg = this.authService.getLoggedUser();
    if (this.userType === 'DHL' || this.userType === 'Express') {
      this.itemcustomerOrgId = this.itemcustomerOrgId;
      if (this.userType === 'DHL') {
        this.itemLsP = 'DGF'
      }
      else if (this.userType === 'Express') {
        this.itemLsP = 'DHLE'
      }
    }
    else {
      this.itemcustomerOrgId = localstorageUserOrg.userOrg
      this.itemLsP = this.itemLsP
    }
    let params = {
      email: localstorageUserOrg.emailId,
      custOrgId: this.itemcustomerOrgId,
      originCode: origin_code,
      lspOrg: this.itemLsP
    }
    let filter: any = {};
    filter['lspOrg'] = [this.itemLsP];
    filter['customerOrg'] = [this.itemcustomerOrgId]
    filter['emailId'] = [localstorageUserOrg.emailId]
    filter['origin'] = [origin_code]
    req = dis.requestService.destinationsByOriginLsp(filter);
    req.subscribe(resp => {
      if (resp['Message'] === 'Sucess') {
        this.disableDestination = false;
        console.log("originDropdownData::::", resp)
        this.destination_dropDown();
        this.dropdownListDestination = resp['result'].sort()
      }
    });
  }

  onItemSelectDestination(item: any) {
    console.log(item);
    this.itemDestinatioCode = item;
    this.searchOriginID = this.itemoriginCode;
    this.searchDestinationID = this.itemDestinatioCode;
    this.messageService.SearchData(this.itemcustomerOrgId, this.itemoriginCode, this.itemDestinatioCode, this.itemLsP, null)
    this.GetAgColumns()
  }

  HAWBSearch() {
    this.messageService.SearchData(this.itemcustomerOrgId, this.itemoriginCode, this.itemDestinatioCode, this.itemLsP, this.HAWBSearchField)
    this.GetAgColumns()
  }

  ShipmentReferencesSearch() {
    this.messageService.SearchData(this.itemcustomerOrgId, this.itemoriginCode, this.itemDestinatioCode, this.itemLsP, this.HAWBSearchField, this.ShipmentReferencesSearchField);
    this.GetAgColumns()
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    // arbitrarily expand a row for presentational purposes
    setTimeout(function () {
      params.api.getDisplayedRowAtIndex(1)!.setExpanded(false);
    }, 0);
  }



  onGridReadyDocuments(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.columnApi.autoSizeAllColumns();
    this.gridApi.sizeColumnsToFit();
  }


  BindData(params) {
    this.gridApi = params.api;
    // var allColumnIds = [];
    // this.gridColumnApi.getAllColumns().forEach(function (column) {
    //   allColumnIds.push(column.colId);
    // });
    // this.gridColumnApi.autoSizeColumns(allColumnIds, false);
    params.columnApi.autoSizeAllColumns();
    this.gridColumnApi = params.columnApi;
    // this.gridApi.getToolPanelInstance('filters').expandFilters();
    // var columnState = JSON.parse(localStorage.getItem('myIntransitColumnState'));
    // if (columnState) {
    //   params.columnApi.setColumnState(columnState);
    // }
  }

  onColumnMoved(params) {
    console.log("params:::")
    var columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem('myIntransitColumnState', columnState);
  }

  onGridReady(params) {
    var columnState = JSON.parse(localStorage.getItem('myIntransitColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }

  resetState() {
    this.gridColumnApi.resetColumnState();
    console.log('column state reset');
  }

  eta_etd_serviceCode_history(shipId) {
    this.historyData = []
    console.log("eta_etd_serviceCode_history")
    const dis = this;
    let req = null;
    req = dis.requestService.eta_etd_serviceCode_history(shipId);
    req.subscribe(resp => {
      for (let data of resp['result']) {
        if (data.eta !== null) {
          let DateTime = data.eta.split('.')[0];
          DateTime = DateTime.split(':')[0];
          DateTime = DateTime + ':' + data.eta.split(':')[1];
          data.eta = DateTime.replace('T', ' ');
        }
        if (data.etd !== null) {
          let DateTime = data.etd.split('.')[0];
          DateTime = DateTime.split(':')[0];
          DateTime = DateTime + ':' + data.etd.split(':')[1];
          data.etd = DateTime.replace('T', ' ');
        }
      }
      this.historyData = resp['result']
    });
  }

  clickedMarker(i, infowindow) {
    console.log("iiii", i);
    if (i === 0) {
      this.MapMarkerClickedValue = 'Origin'
    }
    if (i === 1) {
      this.MapMarkerClickedValue = 'Destination'
    }

    if (this.previous === infowindow) {
      console.log("1111", infowindow)
      return;
    }

    if (this.previous !== null) {
      console.log("2222", infowindow)
      this.previous.close();
    }
    this.previous = infowindow;
  }

  downloadUrl(event: any) {
    // this.requestService.DocumentBlessAPICall()
    //   .subscribe((resp: any) => {
    fileSaver.saveAs(this.endPoint + '/bless/api/v3/messages/attachment/' + event.value)
    // });
  }


}
