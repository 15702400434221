import { Component, OnInit } from '@angular/core';
import { MoreDetailsService } from 'src/app/services/more-details.service';
import { RequestService } from 'src/app/services/request.service';
import * as agCharts from 'ag-charts-community';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';

@Component({
  selector: 'app-transport-request',
  templateUrl: './transport-request.component.html',
  styleUrls: ['./transport-request.component.scss']
})
export class TransportRequestComponent implements OnInit {
  columnDefs: any;
  RowData: any;
  IsColumnsToFit: boolean;
  AgLoad: boolean;
  getFromDate;

  gridApi: any;
  gridColumnApi: any;
  eventparsing: any = [];
  OE_ED: any = [];
  SideOverlay = false;
  domLayout = 'autoHeight';
  graphData: any;
  dateRange: any;
  periodLabel: any = 'Last 24 Hrs';
  constructor(private requestService: RequestService, private messageService: MoreDetailsService, private spinner: NgxSpinnerService) { }

  lspType = this.messageService.lspType;
  widgetStatus = this.messageService.widgetStatus;
  status = this.messageService.status;
  options: any;
  // options = {
  //   data: this.chartData(),
  //   series: this.chartData()
  // }

  // orderData: any = [

  //   {
  //     duration: '24 hours',
  //     Total_records: 10
  //   },
  //   {
  //     duration: 'Last 7 days',
  //     Total_records: 40
  //   },
  //   {
  //     duration: 'Last 15 days',
  //     Total_records: 60
  //   },
  //   {
  //     duration: 'Last 30 days',
  //     Total_records: 100,
  //   },
  //   {
  //     duration: 'Last 90 days',
  //     Total_records: 200
  //   },
  // ]

  chartData() {
    let key;
    let value;
    let cData: any = [];
    for ([key, value] of Object.entries(this.graphData)) {
      if (value !== 0) {
        cData.push(
          {
            duration: key,
            Orders: value
          }
        )
      }
    }

    console.log('CData:', cData);
    this.options = {
      // title: { text: "New Orders" },
      // subtitle: { text: 'in billion U.S. dollars' },

      data: cData,
      series: [
        {
          type: 'column',
          xKey: 'duration',
          yKeys: ['Orders'],
          // yNames: ['Orders'],
          listeners: {
            nodeClick: (event) => {
              // console.log('clickedonchart:', datum);
              this.basedOnChart(event)
              // this.basedOnChart.bind(this)
              // this.gridOptions = 
              // this.gridOptions = {
              //   rowData: this.GetTableData(),
              //   columnDefs: this.GetAgColumns(),
              // }
            },
          },
          tooltip: {
            renderer: function (params) {
              return {
                title: params.xValue,
                content: params.yValue.toFixed(0),
              };
            },
          },
        },
      ],
      legend: {
        position: 'top',
      },
      axes: [
        {
          type: 'category',
          position: 'bottom',

          label: {
            // padding: 30,
            rotation: 90
          },
          // tick: { count: 20 },
        },
        {
          type: 'number',
          position: 'left'
        }
      ]
    };
  }


  BindData(params) {
    this.gridApi = params.api;
    params.columnApi.autoSizeAllColumns();
    this.gridColumnApi = params.columnApi;
    // this.gridApi.getToolPanelInstance('filters').expandFilters();
    var columnState = JSON.parse(localStorage.getItem('myBookingColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }


  onColumnMoved(params) {
    console.log("params:::")
    var columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem('myBookingColumnState', columnState);
  }

  onGridReady(params) {
    var columnState = JSON.parse(localStorage.getItem('myBookingColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }

  resetState() {
    this.gridColumnApi.resetColumnState();
    console.log('column state reset');
  }

  GetAgColumns() {
    // if (this.messageService.widgetStatus === 'PartialORFullCancel') {
    //   this.columnDefs = [

    //     { headerName: 'Status', field: 'pe_status', sortable: true, suppressFiltersToolPanel: true, },
    //     {
    //       headerName: 'Reply Date', field: 'pe_reply_date', sortable: true,
    //       cellRenderer: function (params) {
    //         let data = params.value
    //         data = data.split('T')[0];
    //         return data
    //       }
    //     },
    //     {
    //       headerName: 'Order Date', field: 'pe_order_date', sortable: true, suppressFiltersToolPanel: true, hide: true,
    //       cellRenderer: function (params) {
    //         let data = params.value
    //         data = data.split('T')[0];
    //         return data
    //       }
    //     },
    //     { headerName: 'Invoice No', field: 'pe_invoice_no', sortable: true, suppressFiltersToolPanel: true },
    //     { headerName: 'Item No', field: 'pe_item_no', sortable: true, suppressFiltersToolPanel: true, },
    //     { headerName: 'Buyer', field: 'pe_buyer', sortable: true, suppressFiltersToolPanel: true },
    //     { headerName: 'Part Number', field: 'pe_part_number', sortable: true, suppressFiltersToolPanel: true },
    //     { headerName: 'Part Name', field: '', sortable: true, suppressFiltersToolPanel: true },
    //     { headerName: 'Confirmed Order Qty', field: 'pe_confirmed_order_qty', sortable: true, suppressFiltersToolPanel: true },
    //     { headerName: 'Shipped Pno', field: 'pe_shipped_pno', sortable: true, suppressFiltersToolPanel: true },
    //     { headerName: 'Manufacturer', field: 'pe_manufacturer', sortable: true, suppressFiltersToolPanel: true },
    //     {
    //       headerName: 'ETD', field: 'pe_etd', sortable: true, suppressFiltersToolPanel: true,
    //       cellRenderer: function (params) {
    //         let data = params.value
    //         data = data.split('T')[0];
    //         return data
    //       }
    //     },
    //     { headerName: 'Shipper Comment', field: 'pe_shipper_comment', sortable: true, suppressFiltersToolPanel: true },
    //   ];

    // }
    // else {
    this.columnDefs = [
      {
        headerName: 'Order Date', field: 'po_order_date', sortable: true, suppressFiltersToolPanel: true,
        cellRenderer: function (params) {
          let data = params.value
          data = data.split('T')[0];
          return data
        }
      },
      { headerName: 'Dealer No', field: 'po_dealer_no', sortable: true },
      { headerName: 'Dealer Name', field: 'po_order_by', sortable: true, suppressFiltersToolPanel: true, },
      { headerName: 'Dealer Order No', field: 'po_order_number', sortable: true },
      { headerName: 'Invoice No', field: 'po_invoice_no', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Item No', field: 'po_item_no', sortable: true, suppressFiltersToolPanel: true },
      // { headerName: 'Order status', field: 'po_order_status', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'MOT', field: 'po_mot', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'SPEC', field: 'po_spec', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Part Number', field: 'po_part_number', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'PDC', field: 'po_pdc', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Quantity', field: 'po_quantity', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Sec Flag', field: 'po_sec_flag', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'DD flag', field: 'po_dd_flag', sortable: true, suppressFiltersToolPanel: true },
      {
        headerName: 'Order Received Date', field: 'po_order_received_date', sortable: true, suppressFiltersToolPanel: true,
        cellRenderer: function (params) {
          let data = params.value
          data = data.split('T')[0];
          return data
        }
      },
    ];
    // }
  }

  defaultColDef = {
    sortable: true,
    resizable: true,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    // filter: true,
    // searchable: true
  };

  // sideBar = {
  //   hiddenByDefault: false,
  //   toolPanels: [
  //     {
  //       id: 'columns',
  //       labelDefault: 'Columns',
  //       labelKey: 'columns',
  //       iconKey: 'columns',
  //       toolPanel: 'agColumnsToolPanel',
  //       toolPanelParams: {
  //         suppressRowGroups: true,
  //         suppressValues: true,
  //         allowDragFromColumnsToolPanel: true
  //       },
  //     },
  //     // {
  //     //   id: 'filters',
  //     //   labelDefault: 'Shipper Account',
  //     //   labelKey: 'filters',
  //     //   iconKey: 'filter',
  //     //   toolPanel: 'agFiltersToolPanel',
  //     // }
  //   ]
  // }

  sideBar = false;

  gridOptions = {
    rowData: this.GetTableData(),
    columnDefs: this.GetAgColumns(),
    // options: this.chartData()
  }

  GetTableData() {
    // this.AgLoad = true;;
    const dis = this;
    let req = null;
    let filter: any = {};
    this.RowData = []
    // let getDateRange = basedOnChart()
    // if (this.messageService.widgetStatus === 'PartialORFullCancel') {
    //   req = dis.requestService.purchaseOrderPartial_Full(this.messageService.region);
    //   req.subscribe(resp => {
    //     const messageResult = resp;
    //     console.log("messageResult", messageResult)
    //     this.RowData = messageResult;
    //   });
    // }
    // else {
    // this.spinner.show();
    // const dateRange = ''

    // this.dateRange = null;
    req = dis.requestService.purchaseOrders(this.messageService.region, this.messageService.status, this.dateRange);
    req.subscribe(resp => {
      const messageResult = resp.data;
      console.log("messageResult", messageResult)
      this.RowData = messageResult;
      // if (this.RowData === []) {
      //   this.gridApi.showNoRowsOverlay();
      // }
      this.graphData = resp.graphData;
      this.chartData();
      this.spinner.hide();
    });
    // }

  }

  onRowClicked(event: any) {
    // this.onRowClickedValue = event.value;
    console.log("this.onRowClickedValue", event.data.houseawbid)
    // let def = 'customStats';
    // event.api.openToolPanel(def);
    // this.moreDetails.changeMessage(this.onRowClickedValue);
    let uniqueId = event.data.forwardingConsol + event.data.forwardingShipment;
    // $("#newModal2").modal("show");

  }



  clear() {

  }

  ngOnInit(): void {
    // this.spinner.show();
    this.chartData()
  }

  basedOnChart(e) {
    this.spinner.show();
    console.log('clickedOnChart:', e);
    const currentDate = new Date();

    let timeFrames = {
      "Last 24 Hrs": [new Date((new Date()).setDate(currentDate.getDate() - 1)), currentDate],
      "Last 7 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7))), new Date((new Date()).setDate(currentDate.getDate() - 1))],
      "Last 15 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 7)))],
      "Last 30 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15 + 30))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15)))],
      "Last 90 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15 + 30 + 90))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15 + 30)))],
    }
    let fromDate: any;
    let toDate: any

    if (e.datum.duration === 'Last 30 Days') {
      fromDate = new Date(timeFrames["Last 30 Days"][0]).toISOString();
      toDate = new Date(timeFrames["Last 30 Days"][1]).toISOString()
    }
    if (e.datum.duration === 'Last 90 Days') {
      fromDate = new Date(timeFrames["Last 90 Days"][0]).toISOString();
      toDate = new Date(timeFrames["Last 90 Days"][1]).toISOString()
    }
    if (e.datum.duration === 'Last 15 Days') {
      fromDate = new Date(timeFrames["Last 15 Days"][0]).toISOString();
      toDate = new Date(timeFrames["Last 15 Days"][1]).toISOString()
    }
    if (e.datum.duration === 'Last 7 Days') {
      fromDate = new Date(timeFrames["Last 7 Days"][0]).toISOString();
      toDate = new Date(timeFrames["Last 7 Days"][1]).toISOString()
    }
    if (e.datum.duration === 'Last 24 Hrs') {
      fromDate = new Date(timeFrames["Last 24 Hrs"][0]).toISOString();
      toDate = new Date(timeFrames["Last 24 Hrs"][1]).toISOString()
    }
    this.periodLabel = e.datum.duration;
    fromDate = moment(fromDate).format('YYYY-MM-DD HH:mm:ss');
    toDate = moment(toDate).format('YYYY-MM-DD HH:mm:ss');
    console.log(fromDate, toDate);
    this.dateRange = fromDate + ', ' + toDate;
    this.GetTableData();
    this.GetAgColumns();

  }

}

