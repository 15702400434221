<div class="test-container">
    <div class="test-header">
        <div class="example-section">
            <div class="row mt-3">
                <div class="col-3 ml-3">
                    <div class="view-icons span float-left">
                        Courier/Express Shipping Instructions
                    </div>
                </div>
                <div class="ml-4">
                    <div class="view-icons span float-left">
                        {{periodLabel}}
                    </div>
                </div>
                <div class="col">

                </div>
                <div class="col">

                </div>
                <div class="col-auto float-right ml-auto">
                    <a class="btn btn-secondary mb-3" style="float: right;" (click)="resetState()"> Original Layout </a>
                    <div class="view-icons">
                        <a (click)="clear()" title="Clear" class="grid-view btn btn-link"><i
                                class="fa fa-refresh"></i></a>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-sm-3 graphHeight border" *ngIf="graphData !== undefined">
            <ag-charts-angular [options]="options"></ag-charts-angular>
        </div>
        <div class="col-sm-9">
            <ag-grid-angular class="ag-theme-balham" [columnDefs]="columnDefs" [domLayout]="domLayout"
                [rowData]="RowData" [debug]="true" [masterDetail]="true" [sideBar]="false" [enableRangeSelection]="true"
                [animateRows]="true" [pagination]="true" [defaultColDef]="defaultColDef" [paginationPageSize]="25"
                [floatingFilter]="false" [enableCharts]="true" (gridReady)="BindData($event)"
                (columnMoved)="onColumnMoved($event)">
            </ag-grid-angular>
        </div>
    </div>

</div>

<ng-container *ngIf="SideOverlay">
    <div id="myNavResize" class="overlay" mwlResizable [enableGhostResize]="true" *ngIf="onRowClickedValue!=undefined"
        [resizeEdges]="{bottom: true, right: true, top: true, left: true}" (resizeEnd)="onResizeEnd($event)"
        [ngStyle]="style">
        <!-- <div class="resize-handle-top" mwlResizeHandle [resizeEdges]="{ top: true }"></div>
        <div class="resize-handle-left" mwlResizeHandle [resizeEdges]="{ left: true }"></div>
        <div class="resize-handle-right" mwlResizeHandle [resizeEdges]="{ right: true }"></div>
        <div class="resize-handle-bottom" mwlResizeHandle [resizeEdges]="{ bottom: true }"></div> -->

        <div class="container">
            <div class="row" style="border-bottom: 1px solid #dee2e6;">

                <div class="col-2 mt-4 mx-auto" id="sideOverlayHeader" *ngIf="clickedOnINV">
                    <h5 class="font-weight-bold">Invoice #: {{si_invoice_no}}</h5>
                </div>
                <div class="col-2 mt-4 mx-auto" id="sideOverlayHeader" *ngIf="clickedOnDealer">
                    <h5 class="font-weight-bold">Dealer Order #: {{siDearlerOrderNo}}</h5>
                </div>
                <div class="col-1">
                    <a href="javascript:void(0)" class="" style="text-align:right;" (click)="closemodal()">&times;</a>
                </div>
            </div>
        </div>

        <div class="container mt-3">
            <div class="row">
                <div class="col-sm-12">
                    <table class="table table-bordered">
                        <thead class="tableBackground">
                            <tr>
                                <th scope="col" *ngIf="clickedOnDealer">Invoice Number</th>
                                <th scope="col">HAWB Number</th>
                                <th scope="col">Item Number</th>
                                <th scope="col">Part Number</th>
                                <th scope="col">Part Description</th>
                                <th scope="col">TotalWT Actual</th>
                                <th scope="col">Total Dimen WT</th>
                                <th scope="col">Total Billing WT</th>
                                <th scope="col">DEST SCC</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of onRowClickedValue">
                                <td *ngIf="clickedOnDealer">{{data.si_invoice_no}}</td>
                                <td>{{data.si_houseway_waybill_no}}</td>
                                <td>{{data.si_item_no}}</td>
                                <td>{{data.si_allocated_part}}</td>
                                <td>{{data.si_item_desc}}</td>
                                <td>{{data.si_total_wt_actual}}</td>
                                <td>{{data.si_total_dimen_wt}}</td>
                                <td>{{data.si_total_billing_wt}}</td>
                                <td>{{data.si_dest_ssc}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>