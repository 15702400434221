import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MoreDetailsService } from 'src/app/services/more-details.service';
import { RequestService } from 'src/app/services/request.service';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-checkpoints-intransit',
  templateUrl: './checkpoints-intransit.component.html',
  styleUrls: ['./checkpoints-intransit.component.scss']
})
export class CheckpointsIntransitComponent implements OnInit {
  customersData: any;

  constructor(private requestService: RequestService, private messageService: MoreDetailsService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private authService: AuthService) { }
  RowData: any = [];
  RowDHLData: any = [];
  RowExpressData: any = [];
  userRole: any;

  codearrayCustomer = [];
  CodearrayDataCustomer = [];
  CustomerdropdownSettings = {};
  tableCustomerDropdownValues: any;
  CustomerselectedItems: null;


  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  model = {
    CustomerselectedItems: null
  };

  selectedlogisticsValue: any;
  HAWBSearchField: any;
  ngOnInit(): void {
    this.userRole = environment.userType;
    this.loadData();
    this.logisticsSelectDropdown();
    this.customer_dropDown();
    this.logisticsSelectDropdown();
  }

  HAWBSearch() {
    // console.log("HAWBEntered", this.HAWBSearchField);
    this.loadData()
  }

  customer_dropDown() {
    // console.log("customer_dropDown")
    this.CustomerdropdownSettings = {
      singleSelection: true,
      idField: 'codeCustomer',
      textField: 'codeCustomerName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  onCustomerItemSelect(item) {
    // console.log("item.codeCustomer", item.codeCustomer)
    this.model.CustomerselectedItems = item.codeCustomer
    this.tableCustomerDropdownValues = this.model.CustomerselectedItems;
    this.loadData()
  }

  onCustomerDeSelect(item) {
    this.tableCustomerDropdownValues = null;
    this.loadData();
  }

  logisticsSelectDropdown() {
    if (this.userRole === 'Cargotec') {
      this.dropdownList = [
        { item_id: 1, item_text: 'Express' },
        { item_id: 2, item_text: 'Forwarding' },
        { item_id: 3, item_text: 'Freight' },
        { item_id: 4, item_text: 'ALL' }
      ];
    } else {
      this.dropdownList = [
        { item_id: 1, item_text: 'Express' },
        { item_id: 2, item_text: 'Forwarding' },
        { item_id: 3, item_text: 'ALL' }
      ];
    }
    this.selectedItems = [
      { item_id: 2, item_text: 'Forwarding' }
    ];
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  onItemSelect(item: any) {
    // console.log(item.item_text);
    this.selectedlogisticsValue = item.item_text
    this.loadData()
  }
  onSelectAll(items: any) {
    // console.log(items);
  }


  loadData() {
    const dis = this;
    let req = null;
    let filter: any = {};
    let localstorageUserOrg = this.authService.getLoggedUser();
    if (localstorageUserOrg.userOrg === 'NISSAN' || localstorageUserOrg.userOrg === 'BMX' || localstorageUserOrg.userOrg === 'ASTRAZENECA' || localstorageUserOrg.userOrg === 'BMS' || localstorageUserOrg.userOrg === 'TOTAL' || localstorageUserOrg.userOrg === 'GSK' || localstorageUserOrg.userOrg === 'IBM'
      || localstorageUserOrg.userOrg === 'MAXION' || localstorageUserOrg.userOrg === 'PFIZER' || localstorageUserOrg.userOrg === 'MERITOR' || localstorageUserOrg.userOrg === 'CATERPILLAR' || localstorageUserOrg.userOrg === 'SLB') {
      filter['customerOrg'] = [localstorageUserOrg.userOrg];
    }
    if (localstorageUserOrg.userOrg === 'CARGOTEC') {
      filter['customerOrg'] = [localstorageUserOrg.userOrg, 'CARGOTEC_FRT'];
    }
    let status = 'INTRANSIT';
    this.RowData = [];
    let customerdropdownValue = dis.tableCustomerDropdownValues;
    if (customerdropdownValue !== null && customerdropdownValue !== undefined && Object.keys(customerdropdownValue).length !== 0) {
      // console.log("if")
      filter['customerOrg'] = [customerdropdownValue];
    }
    if (this.HAWBSearchField !== null && this.HAWBSearchField !== undefined && this.HAWBSearchField !== '') {
      filter['houseBillNumber'] = [this.HAWBSearchField];
    }
    if (sessionStorage.getItem('access-token') != null) {
      this.spinner.show();
    }
    req = dis.requestService.getLatestEventCounts(status, filter);
    req.subscribe(resp => {
      if (resp.Message === 'Sucess') {
        this.spinner.hide()
        let resultData;
        // if (this.userRole === 'BMX' || this.userRole === 'Nissan' || this.userRole === 'ASTRAZENECA') {
        //   const messageResult = resp['result'];
        //   // this.customersData = resp.result.customers;
        //   console.log("messageResult", messageResult)
        //   resultData = messageResult;
        // }

        const messageResult = resp['result'].data;
        this.customersData = resp.result.customers;
        // console.log("messageResult", messageResult)
        resultData = messageResult;
        resultData.sort((a, b) => (b.eventGroupId > a.eventGroupId) ? -1 : 1);
        for (let data of resultData) {
          data.CheckpointDateTime = data.CheckpointDateTime.split(".")[0];
          data.CheckpointDateTime = data.CheckpointDateTime.replace("T", " ")
          if (this.userRole === 'BMX' || this.userRole === 'Nissan' || this.userRole === 'ASTRAZENECA' || this.userRole === 'BMS' || this.userRole === 'Cargotec' || this.userRole === 'CARGOTEC_FRT' || this.userRole === 'Total' || this.userRole === 'GSK' || this.userRole === 'IBM'
            || this.userRole === 'Maxion' || this.userRole === 'Pfizer' || this.userRole === 'Meritor' || this.userRole === 'Caterpillar' || this.userRole === 'SLB') {
            if (this.selectedItems[0].item_text === 'Express') {
              if (data.lspOrgName === 'DHL EXPRESS') {
                this.RowData.push(data);
              }
            }
            else if (this.selectedItems[0].item_text === 'Freight') {
              if (data.lspOrgName === 'DHL FREIGHT') {
                this.RowData.push(data);
              }
            }

            else if (this.selectedItems[0].item_text === 'Forwarding') {
              // console.log("Forwarding", data.lspOrgName)
              if (data.lspOrgName === 'DHL GLOBAL FORWRDING' || data.lspOrgName === 'DHL GLOBAL FORWARDING (FRANCE) SAS' || data.lspOrgName === 'DHL GLOBAL FORWARDING') {
                // console.log("iddddd")
                this.RowData.push(data);
              }
            }
            else if (this.selectedItems[0].item_text === 'ALL') {
              if (data.lspOrgName === 'DHL EXPRESS') {
                this.RowExpressData.push(data);
              }
              if (data.lspOrgName === 'DHL GLOBAL FORWRDING' || data.lspOrgName === 'DHL GLOBAL FORWARDING (FRANCE) SAS' || data.lspOrgName === 'DHL GLOBAL FORWARDING') {
                // console.log("iddddd")
                this.RowDHLData.push(data);
              }
            }
          }
          else if (this.userRole !== 'BMX') {
            this.RowData.push(data);
          }

        }
        // for (let data of customers) {
        //   this.RowData.push(data);

        // }
        this.DropdownRows(this.RowData);
      }
    });
  }



  DropdownRows(rows) {
    // console.log('##: ', this.customersData);
    const dis = this;
    dis.codearrayCustomer = [];
    // dis.CodearrayDataCustomer = null;
    for (let i = 0; i < this.customersData.length; i++) {
      const raws = this.customersData[i];
      dis.codearrayCustomer[i] = { codeCustomer: raws['customerOrgId'], codeCustomerName: raws['customerOrgName'] };
    }
    let arrayduplicateremoveOrigin = this.removeDuplicates(dis.codearrayCustomer, "codeCustomer");
    dis.CodearrayDataCustomer = arrayduplicateremoveOrigin;
  }

  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};
    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  clear() {
    this.CustomerselectedItems = null;
    this.tableCustomerDropdownValues = null;
    this.HAWBSearchField = null;
    this.loadData();
  }



  Details(checkpointcode, lspOrgId, lspOrgName, checkpointDescription, customerOrgId, customerOrgName) {
    // console.log("lspOrgId", lspOrgId);
    this.messageService.checkpointCodeMessage(checkpointcode, lspOrgId, lspOrgName, checkpointDescription, customerOrgId, customerOrgName, this.HAWBSearchField, '', '', '','');
    // console.log("intransit");
    this.router.navigate(['/inTransit'])
  }

}
