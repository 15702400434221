import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './layout/footer/footer.component';
import { LoginComponent } from './layout/login/login.component';
import { HeaderComponent } from './layout/header/header.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AngularTreeTableModule } from 'angular-tree-table';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AuthService } from './services/auth.service';
import { UserloginServiceService } from './services/userlogin-service.service';
import { ShipmentBookingComponent } from './components/shipment-booking/shipment-booking.component';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { DashboardComponent } from './layout/dashboard/dashboard.component';
import { InTransitComponent } from './components/in-transit/in-transit.component';
import { DeliveredComponent } from './components/delivered/delivered.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ShipmentpreparationComponent } from './components/shipmentpreparation/shipmentpreparation.component';
import { CheckpointsIntransitComponent } from './components/checkpoints-intransit/checkpoints-intransit.component';
import { TrackTemperatureComponent } from './components/track-temperature/track-temperature.component';
import { AgmCoreModule } from '@agm/core';
// import { ReusableMapComponent } from './components/reusable-map/reusable-map.component';
import { DeliveredDashboardComponent } from './components/delivered-dashboard/delivered-dashboard.component';
import { TransportRequestDashboardComponent } from './components/transport-request-dashboard/transport-request-dashboard.component';
import { TransportRequestComponent } from './components/transport-request/transport-request.component';
import { ShipmentinstructionComponent } from './components/shipmentinstruction/shipmentinstruction.component';
import { WarehouseInfrastructureComponent } from './components/warehouse-infrastructure/warehouse-infrastructure.component';
import { ShipmentConfirmationComponent } from './components/shipment-confirmation/shipment-confirmation.component';
import { ConsumerOrdersExceptionsComponent } from './components/consumer-orders-exceptions/consumer-orders-exceptions.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { ResizableModule } from 'angular-resizable-element';
import { SearchComponent } from './components/search/search.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NewIntransitComponent } from './components/new-intransit/new-intransit.component';
import { FusionTrackTempComponent } from './components/fusion-track-temp/fusion-track-temp.component';
import * as FusionCharts from "fusioncharts";
import * as charts from "fusioncharts/fusioncharts.charts";
import * as FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { FusionChartsModule } from "angular-fusioncharts";
import * as TimeSeries from "fusioncharts/fusioncharts.timeseries";
import { InTransitEUComponent } from './components/in-transit-eu/in-transit-eu.component';
import { DeliveredEUComponent } from './components/delivered-eu/delivered-eu.component';
import { PNPSearchComponent } from './components/pnpsearch/pnpsearch.component';
FusionCharts.options['license']({
  key: 'wkB3gzpI2J3A4A6B5A3A3F4H4B3G1A7C8wfhC4D1aieynD6D3B-8kdG2C9A4D6C5G5F4G2C3F3B4B5B5B1A2B4snnB14B1D8fB4A4H4H-8I-7iA7A3A3wqh1H2C1C5B-22moD2D5F1G3D2A6B5A4C7A3B4h1jjB8C4QB5jnB-8H3F1B5tgB2HA1E2A-7H-7nA2D6E1A3D3B2E2C2A5B3A5F3B2B3t==',
  creditLabel: false
});
FusionChartsModule.fcRoot(FusionCharts, charts, FusionTheme, TimeSeries);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    FooterComponent,
    ShipmentBookingComponent,
    DashboardComponent,
    InTransitComponent,
    DeliveredComponent,
    ShipmentpreparationComponent,
    CheckpointsIntransitComponent,
    TrackTemperatureComponent,
    // ReusableMapComponent,
    DeliveredDashboardComponent,
    TransportRequestDashboardComponent,
    TransportRequestComponent,
    ShipmentinstructionComponent,
    WarehouseInfrastructureComponent,
    ShipmentConfirmationComponent,
    ConsumerOrdersExceptionsComponent,
    SearchComponent,
    NewIntransitComponent,
    FusionTrackTempComponent,
    InTransitEUComponent,
    DeliveredEUComponent,
    PNPSearchComponent,

  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    HttpClientModule,
    ResizableModule,
    AngularTreeTableModule,
    FusionChartsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDhdinMYR_DnkHHUDjyxcDqe4PmT0C7w1Q',
      libraries: ['places', 'drawing', 'geometry'],
    }),
    // AgGridModule.withComponents([DataAgGridComponent]),
    AgGridModule.withComponents([]),
    AgChartsAngularModule,
    AngularMultiSelectModule,
  ],
  providers: [AuthService, UserloginServiceService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
