<div class="header shadow-sm">
  <div class="container">
    <div class="header-full d-flex flex-column flex-md-row align-items-center p-3 mb-3 pl-md-0 pr-md-0 ">
      <button class="navbar-toggler navbar-toggle d-sm-none d-xs-inline-block" type="button" data-toggle="collapse"
        data-target="#navbarMain" aria-controls="navbarMain" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <h5 class="my-0 font-weight-normal p-3 p-md-0">

        <div [ngStyle]="logoStyles()">
          <a target="" routerLink=""> <img [ngStyle]="logoStyles()" src="{{logo_url}}" /> </a>
        </div>
      </h5>
      <nav class="navbar navbar-expand-sm my-0 mb-0 p-0 w-100" *ngIf="loggedIn">
        <div class="collapse navbar-collapse justify-content-between" id="navbarMain">
          <ul class="navbar-nav ml-md-5 mt-0 mb-0 w-sm-100">
            <ng-container *ngFor="let tab of tabDetails; let i=index">
              <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <div class="nav-link tabColor fontSize cursorDefault" [routerLink]="tab.routerLink"
                  [ngClass]="activateTab(tab, i) " (click)="tabinfo(tab)">{{tab.title}}
                  <span *ngIf="i>0"><i class="fa fa-times-circle cursorPointer" (click)="close(i)"></i></span>
                </div>

              </li>
            </ng-container>

          </ul>
          <ul class="navbar-nav ml-md-5 mt-0 mb-0 w-sm-100">
            <!-- <li>
                <a (click)="clear()" title="Clear" class="grid-view btn btn-link"><i class="fa fa-refresh"></i></a>
            </li> -->
            <li class="nav-item">
              <a class="nav-link text-dark" routerLink="">{{username}}</a>
            </li>
            <li class="nav-item">
              <a style="cursor: pointer;" class="nav-link text-dark" (click)="logout()">Logout</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>