<div class="test-container">
    <div class="test-header">
        <div class="example-section">
            <div class="row">
                <div class="col-3 ml-3">
                    <div class="view-icons span float-left">
                        {{widgetStatus}}
                    </div>
                </div>
                
                <div class="ml-4">
                    <div class="view-icons span float-left">
                        {{periodLabel}}
                    </div>
                </div>
                <!-- <div class="col">
                    <ng-multiselect-dropdown [placeholder]="'Customer'" [data]="CodearrayDataCustomer"
                        [(ngModel)]="CustomerselectedItems" [settings]="CustomerdropdownSettings"
                        (onSelect)="onCustomerItemSelect($event)" (onDeSelect)="onCustomerDeSelect($event)">
                    </ng-multiselect-dropdown>
                </div> -->
                <!-- <div class="col">
                    <ng-multiselect-dropdown [placeholder]="'Courier/ forwarder'"
                        [data]="CodearrayDataCourier_forwarder" [(ngModel)]="Courier_forwarderselectedItems"
                        [settings]="Courier_forwarderdropdownSettings" [disabled]="disabled"
                        (onSelect)="onCourier_forwarderItemSelect($event)"
                        (onDeSelect)="onCourier_forwarderDeSelect($event)">
                    </ng-multiselect-dropdown>
                </div> -->
                <!-- <div class="col-2">
                    <ng-multiselect-dropdown [placeholder]="'Account'" [data]="CodearrayDataAccount"
                        [(ngModel)]="AccountselectedItems" [settings]="AccountdropdownSettings"
                        (onSelect)="AccountItemSelect($event)" (onDeSelect)="onAccountDeSelect($event)">
                    </ng-multiselect-dropdown>

                </div>
                <div class="col-1">
                    <a class="btn btn-secondary mb-3" style="float: right;" (click)="Go()"> GO </a>
                </div> -->
                <div class="col-auto float-right ml-auto">

                    <a class="btn btn-secondary mb-3" style="float: right;" (click)="resetState()"> Original Layout </a>
                    <div class="view-icons">
                        <a routerLink="/TransportRequestDashboard" title="Back" class="grid-view btn btn-link active"><i
                                class="fa fa-arrow-circle-left"></i></a>
                    </div>
                    <div class="view-icons">
                        <a (click)="clear()" title="Clear" class="grid-view btn btn-link"><i
                                class="fa fa-refresh"></i></a>
                    </div>

                </div>

            </div>


        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <ng-template [ngIf]="widgetStatus!=='New Orders'" [ngIfElse]="activeOrders">
            <div class="col-sm-3 graphHeight border">
                <ag-charts-angular [options]="options"></ag-charts-angular>
            </div>
            <div class="col-sm-9">
                <ag-grid-angular id='agGridTable' class="ag-theme-balham" [columnDefs]="columnDefs"
                    [domLayout]="domLayout" [rowData]="RowData" [debug]="true" [masterDetail]="true" [sideBar]="sideBar"
                    [enableRangeSelection]="true" [animateRows]="true" [pagination]="true"
                    [defaultColDef]="defaultColDef" [paginationPageSize]="25" [floatingFilter]="false"
                    [enableCharts]="true" (gridReady)="BindData($event)" (columnMoved)="onColumnMoved($event)">
                </ag-grid-angular>
            </div>
        </ng-template>
        <ng-template #activeOrders>
            <div class="col-sm-12">
                <ag-grid-angular id='agGridTable' class="ag-theme-balham" [columnDefs]="columnDefs"
                    [domLayout]="domLayout" [rowData]="RowData" [debug]="true" [masterDetail]="true" [sideBar]="sideBar"
                    [enableRangeSelection]="true" [animateRows]="true" [pagination]="true"
                    [defaultColDef]="defaultColDef" [paginationPageSize]="25" [floatingFilter]="false"
                    [enableCharts]="true" (gridReady)="BindData($event)" (columnMoved)="onColumnMoved($event)">
                </ag-grid-angular>
            </div>
        </ng-template>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>