<ng-container *ngIf="userRole === 'Express' || userRole === 'DHL'">
    <div class="test-container">
        <div class="test-header">
            <div class="example-section">

                <div class="row">

                    <div class="col">

                    </div>
                    <div class="col-2">
                        <ng-multiselect-dropdown [placeholder]="'Customer'" [data]="CodearrayDataCustomer"
                            [(ngModel)]="CustomerselectedItems" [settings]="CustomerdropdownSettings"
                            (onSelect)="onCustomerItemSelect($event)" (onDeSelect)="onCustomerDeSelect($event)">
                        </ng-multiselect-dropdown>
                    </div>
                    <ng-container *ngIf="userRole !== 'Nissan'">
                        <div class="col-2">
                            <input placeholder="Enter HAWB" class="form-control" type="text" name="HAWB"
                                [(ngModel)]="HAWBSearchField" (keyup.enter)="HAWBSearch()">
                        </div>
                    </ng-container>

                    <div class="col-auto float-right ml-auto">
                        <div class="view-icons">
                            <a (click)="clear()" title="Clear" class="grid-view btn btn-link"><i
                                    class="fa fa-refresh"></i></a>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-4 marginClass" *ngFor="let lst of RowData;let i = index">
            <div class="profile-widget h-100"
                [ngClass]="(lst.eventCode === 'OH' || lst.eventCode === 'FD' || lst.eventCode === 'ND' || lst.eventCode === 'CA' || lst.eventCode === 'MS' || lst.eventCode === 'BA') ? 'redborderClass' :(lst.description === 'EXCEPTIONS' || lst.description === 'CANCELLED'|| lst.description=== 'CANCEL')?'redborderClass': (lst.eventCode === 'TP') ? 'amberborderColor': ''"
                (click)="Details(lst.eventCode,lst.lspOrgId,lst.lspOrgName,lst.description,lst.customerOrgId,lst.customerOrgName)">
                <div class="profile-img">
                    <a class="avatar">
                        <span>{{lst.Count}}</span>
                    </a>
                </div>
                <div class="row"></div>
                <h4 class="mt-4 description"><a>{{lst.description}}</a>
                </h4>
                <div class="small text-muted">Last Update : {{lst.CheckpointDateTime}}</div>
            </div>
        </div>
    </div>
</ng-container>



<ng-container *ngIf="userRole === 'BMX'|| userRole === 'Nissan'|| userRole==='ASTRAZENECA' || userRole==='BMS'|| userRole==='Cargotec' || userRole==='CARGOTEC_FRT'||userRole==='Total'||userRole==='GSK' ||userRole==='IBM'
    || userRole==='Maxion'||userRole==='Pfizer'||userRole==='Meritor'||userRole==='Caterpillar'||userRole==='SLB'">
    <div class="test-container">
        <div class="test-header">
            <div class="example-section">

                <div class="row">

                    <div class="col">

                    </div>
                    <div class="col-2">
                        <ng-multiselect-dropdown [placeholder]="'custom placeholder'" [settings]="dropdownSettings"
                            [data]="dropdownList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                    <ng-container *ngIf="userRole !== 'Nissan'">
                        <div class="col-2">
                            <input placeholder="Enter HAWB" class="form-control" type="text" name="HAWB"
                                [(ngModel)]="HAWBSearchField" (keyup.enter)="HAWBSearch()">
                        </div>
                    </ng-container>
                    <div class="col-auto float-right ml-auto">
                        <div class="view-icons">
                            <a (click)="clear()" title="Clear" class="grid-view btn btn-link"><i
                                    class="fa fa-refresh"></i></a>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
    <ng-container *ngIf="selectedlogisticsValue === 'ALL'">
        <div class="wrap mt-5">
            <div class="floatleft">
                <div class="row wrapheader">Forwarding</div>
                <div class="row p-4 pr-5">
                    <div class="col-6" *ngFor="let lst of RowDHLData;let i = index">
                        <div class="profile-widget"
                            [ngClass]="(lst.eventCode === 'OH' || lst.eventCode === 'FD' || lst.eventCode === 'ND' || lst.eventCode === 'CA' || lst.eventCode === 'MS' || lst.eventCode === 'BA') ? 'redborderClass' :(lst.description === 'EXCEPTIONS' || lst.description === 'CANCELLED'|| lst.description=== 'CANCEL')?'redborderClass': (lst.eventCode === 'TP') ? 'amberborderColor': ''"
                            (click)="Details(lst.eventCode,lst.lspOrgId,lst.lspOrgName,lst.description,lst.customerOrgId,lst.customerOrgName)">
                            <div class="profile-img">
                                <a class="avatar">
                                    <span>{{lst.Count}}</span>
                                </a>
                            </div>
                            <div class="row"></div>
                            <h4 class="mt-4 description"><a>{{lst.description}}</a>
                            </h4>
                            <div class="small text-muted">Last Update : {{lst.CheckpointDateTime}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="floatright">
                <div class="row wrapheader">Express</div>
                <div class="row p-4 pl-5">
                    <div class="col-6" *ngFor="let lst of RowExpressData;let i = index">
                        <div class="profile-widget"
                            [ngClass]="(lst.eventCode === 'OH' || lst.eventCode === 'FD' || lst.eventCode === 'ND' || lst.eventCode === 'CA' || lst.eventCode === 'MS' || lst.eventCode === 'BA') ? 'redborderClass' :(lst.description === 'EXCEPTIONS' || lst.description === 'CANCELLED'|| lst.description=== 'CANCEL')?'redborderClass': (lst.eventCode === 'TP') ? 'amberborderColor': ''"
                            (click)="Details(lst.eventCode,lst.lspOrgId,lst.lspOrgName,lst.description,lst.customerOrgId,lst.customerOrgName)">
                            <div class="profile-img">
                                <a class="avatar">
                                    <span>{{lst.Count}}</span>
                                </a>
                            </div>
                            <div class="row"></div>
                            <h4 class="mt-4 description"><a>{{lst.description}}</a>
                            </h4>
                            <div class="small text-muted">Last Update : {{lst.CheckpointDateTime}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="clear: both;"> </div>
        </div>
    </ng-container>
    <ng-container *ngIf="selectedlogisticsValue !== 'ALL'">
        <div class="row mt-4">
            <div class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-4 marginClass"
                *ngFor="let lst of RowData;let i = index">
                <div class="profile-widget h-100"
                    [ngClass]="(lst.eventCode === 'OH' || lst.eventCode === 'FD' || lst.eventCode === 'ND' || lst.eventCode === 'CA' || lst.eventCode === 'MS' || lst.eventCode === 'BA') ? 'redborderClass' :(lst.description === 'EXCEPTIONS' || lst.description === 'CANCELLED'|| lst.description=== 'CANCEL')?'redborderClass': (lst.eventCode === 'TP') ? 'amberborderColor': ''"
                    (click)="Details(lst.eventCode,lst.lspOrgId,lst.lspOrgName,lst.description,lst.customerOrgId,lst.customerOrgName)">
                    <div class="profile-img">
                        <a class="avatar">
                            <span>{{lst.Count}}</span>
                        </a>
                    </div>
                    <div class="row"></div>
                    <h4 class="mt-4 description"><a>{{lst.description}}</a>
                    </h4>
                    <div class="small text-muted">Last Update : {{lst.CheckpointDateTime}}</div>
                </div>
            </div>
            <div class="wrap mt-5" *ngIf="RowData.length === 0">
                <div class="" style="width:100%;border: 1px solid gainsboro;">
                    <div class="row wrapheader">No Records</div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>