<div class="d-flex login-wrapper">
    <div *ngIf="userType !== 'Cargotec' && userType !== 'IBM'&& userType !== 'Maxion'&& userType !== 'Pfizer'&&userType !== 'DELL'&&userType !== 'HOLOGIC'&&userType !== 'SALMAR'&&userType !== 'ROCHE'&&userType !== 'APPLE'&&userType !== 'FORD'&&userType !== 'STELLANTIS'&&userType !== 'ASML'&&userType !== 'BAKERHUGHES'&&userType !== 'DSM'&&userType !== 'MICHELIN'&&userType !== 'SANOFI'&&userType !== 'BAYER'"
        class="mr-auto d-none d-sm-flex justify-content-right align-self-start part1">
        <div class="form-signin">
            <div>
                <h4>WELCOME</h4>
            </div>
            <p *ngIf="userType === 'DHL' || userType === 'Express'">Our experts understand the complex logistics
                requirements of your industry and can offer you tailor-made
                solutions to meet them.</p>
        </div>
    </div>
    <div class="justify-content-left align-self-start part2">
        <form class="form-signin shadow-lg" #form="ngForm" (ngSubmit)="onSubmit(form)">
            <h1 class="h5 mb-3 font-weight-normal">Login</h1>
            <div class="form-group">
                <label class="label-sm" for="inputEmail">Email Address</label>
                <input type="text" id="inputEmail" name="username" #username="ngModel" [(ngModel)]="model.username"
                    class="form-control form-control-sm" placeholder="Email Address" required autofocus />
            </div>
            <div class="form-group">
                <label class="label-sm" for="inputPassword">Password</label>
                <input type="password" id="inputPassword" name="password" #password="ngModel"
                    [(ngModel)]="model.password" class="form-control form-control-sm" placeholder="Password" required />
            </div>
            <button class="btn btn-secondary btn-block" type="submit">Login</button>
        </form>
    </div>
</div>