import { Component, OnInit } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { Chart } from 'chart.js';
import { environment } from '../../../environments/environment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Router } from '@angular/router';
import { MoreDetailsService } from 'src/app/services/more-details.service';
import { AuthService } from 'src/app/services/auth.service';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ViewChekingService } from 'src/app/services/view-cheking.service';

@Component({
  selector: 'app-delivered-dashboard',
  templateUrl: './delivered-dashboard.component.html',
  styleUrls: ['./delivered-dashboard.component.scss']
})
export class DeliveredDashboardComponent implements OnInit {
  userRole: any;
  codearrayCustomer = [];
  CodearrayDataCustomer = [];
  CustomerdropdownSettings = {};
  tableCustomerDropdownValues: any;
  CustomerselectedItems: null;
  ForwardingChart: any;

  model = {
    CustomerselectedItems: null
  };

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  selectedlogisticsValue: any;
  customersData: any;
  HAWBSearchField: any;
  ShipmentReferencesSearchField: any;
  dropdownListOrigin = [];
  dropdownListDestination = [];
  selectedItemsOrigin = [];
  selectedItemsDestination = [];
  dropdownSettingsDestination = {};
  dropdownSettingsOrigin = {};
  disableDestination: boolean = true;
  itemoriginCode: any;
  itemDestinatioCode: any;
  ExpressChart: any;
  ARAMEX: any;
  SCHENKER: any;
  DSV: any;
  CRANE: any;
  EXPEDITORS: any;
  GEODIS: any;
  KN: any;
  dropdownListMOT = [];
  selectedItemsMOT = [];
  dropdownSettingsMOT = {};
  disableMOT: boolean = true;
  itemMOTCode: any;

  view_ver1_ui: any;

  constructor(
    private requestService: RequestService,
    private router: Router,
    private messageService: MoreDetailsService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private http: HttpClient,
    private viewChecking: ViewChekingService,

  ) { }

  ngOnInit(): void {
    this.view_ver1_ui = this.viewChecking.viewCheking(); // checking view access based on user list.
    this.userRole = environment.userType;
    if (this.userRole != 'DHL' || this.userRole != 'Express') {
      this.loadData();
      this.logisticsSelectDropdown();
      // this.originDataCall();
    }
    else {
      this.DataLoad();
      this.customer_dropDown();
    }
  }

  HAWBSearch() {
    this.loadData();
  }

  ShipmentReferencesSearch() {
    this.loadData();
  }

  requiredChartInitialization: any;
  chartsSeparation: any = [
    { id: 0, logisticsValue: 'Forwarding', divId: 'ForwardingChart' },
    { id: 1, logisticsValue: 'Express', divId: 'ExpressChart' },
    { id: 2, logisticsValue: undefined, divId: 'ExpressChart' },
    { id: 3, logisticsValue: 'ARAMEX', divId: 'ARAMEXChart' },
    { id: 4, logisticsValue: 'SCHENKER', divId: 'SCHENKERChart' },
    { id: 5, logisticsValue: 'DSV', divId: 'DSVChart' },
    { id: 6, logisticsValue: 'CRANE', divId: 'CRANEChart' },
    { id: 7, logisticsValue: 'KN', divId: 'KNChart' },
    { id: 8, logisticsValue: 'GEODIS', divId: 'GEODISChart' },
    { id: 9, logisticsValue: 'EXPEDITORS', divId: 'EXPEDITORSChart' },
  ];

  forwarderFilterUserList: any = [
    'NISSAN', 'BMX', 'ASTRAZENECA', 'BMS', 'TOTAL', 'GSK', 'IBM', 'MAXION', 'PFIZER', 'MERITOR', 'CATERPILLAR', 'SLB', 'DELL', 'HOLOGIC', 'BOSCH', 'CISCO', 'HM', 'METSO', 'NOVONORDISK', 'SALMAR', 'ROCHE', 'APPLE', 'ARAMCO', 'SHELL', 'ASML', 'COMMSCOPE', 'FORD', 'ORGANON', 'STELLANTIS', 'BAKERHUGHES', "DSM", "MICHELIN", "SANOFI"
  ];

  // only CARGOTEC is extra in this array, comparing to above array.
  expressFilterUserList: any = [
    'NISSAN', 'BMX', 'ASTRAZENECA', 'BMS', 'TOTAL', 'GSK', 'IBM', 'CARGOTEC', 'MAXION', 'PFIZER', 'MERITOR', 'CATERPILLAR', 'SLB', 'DELL', 'HOLOGIC', 'BOSCH', 'CISCO', 'HM', 'METSO', 'NOVONORDISK', 'SALMAR', 'ROCHE', 'APPLE', 'ARAMCO', 'SHELL', 'ASML', 'COMMSCOPE', 'FORD', 'ORGANON', 'STELLANTIS', 'BAKERHUGHES', "DSM", "MICHELIN", "SANOFI"
  ];

  // All the filter checking like ARAMEX, DSV, SCHENKER, CRANE, GEODIS, EXPEDITORS having same users list 
  common_filterUsersList: any = [
    'NISSAN', 'BMX', 'ASTRAZENECA', 'BMS', 'TOTAL', 'GSK', 'IBM', 'MAXION', 'PFIZER', 'MERITOR', 'CATERPILLAR', 'SLB', 'DELL', 'HOLOGIC', 'BOSCH', 'CISCO', 'HM', 'METSO', 'NOVONORDISK', 'SALMAR', 'ROCHE', 'APPLE', 'ARAMCO', 'SHELL', 'ASML', 'COMMSCOPE', 'FORD', 'ORGANON', 'STELLANTIS', 'BAKERHUGHES', "DSM", "MICHELIN", "SANOFI"
  ];

  // It invoked by Oninit method.
  loadData() {

    // Finding the logistic value to Initializing the required graph.
    this.requiredChartInitialization = this.chartsSeparation.find(x => x.logisticsValue == this.selectedlogisticsValue);

    // this.originDataCall();
    const dis = this;
    let req = null;
    let status = '';
    if (sessionStorage.getItem('access-token') != null) {
      this.spinner.show();
    }

    let localstorageUserOrg = this.authService.getLoggedUser();
    let forwarderFilterUser = this.forwarderFilterUserList.includes(localstorageUserOrg.userOrg); // If user exists having TRUE other wise it is FALSE.
    let expressFilterUser = this.expressFilterUserList.includes(localstorageUserOrg.userOrg); // If user exists having TRUE other wise it is FALSE.
    let common_filterUsers = this.common_filterUsersList.includes(localstorageUserOrg.userOrg); // If user exists having TRUE other wise it is FALSE.

    //*** FORWARDER FILTER STARTING ***//
    let filter: any = { "lspOrg": ["DGF"] };
    if (localstorageUserOrg.userOrg !== 'NISSAN') {
      filter['emailId'] = [localstorageUserOrg.emailId];
      filter['eventGroupId'] = [70];
    }
    if (localstorageUserOrg.userOrg === 'NISSAN') {
      status = 'DELIVERED';
    }

    if (forwarderFilterUser) { // Checking on customerOrgList with local host customer org, if customer finds in that it will TRUE.
      filter['customerOrg'] = [localstorageUserOrg.userOrg];
    }
    if (this.HAWBSearchField !== null && this.HAWBSearchField !== undefined && this.HAWBSearchField !== '') {
      filter['houseBillNumber'] = [this.HAWBSearchField];
    }

    if (this.ShipmentReferencesSearchField !== null && this.ShipmentReferencesSearchField !== undefined && this.ShipmentReferencesSearchField !== '') {
      filter['shipperAccountNumber'] = [this.ShipmentReferencesSearchField];
    }

    if (this.itemoriginCode !== null && this.itemoriginCode !== undefined && this.itemoriginCode !== '') {
      filter['originCode'] = [this.itemoriginCode];
    }

    if (this.itemDestinatioCode !== null && this.itemDestinatioCode !== undefined && this.itemDestinatioCode !== '') {
      filter['destinationCode'] = [this.itemDestinatioCode];
    }

    if (this.itemMOTCode !== null && this.itemMOTCode !== undefined && this.itemMOTCode !== '') {
      filter['MOT'] = [this.itemMOTCode];
    }
    console.log("DHLE", this.selectedlogisticsValue)
    if (localstorageUserOrg.userOrg === 'HM'){
      filter['lspOrg'] = ['DHLE']
  }
    if (this.view_ver1_ui) {
      if (this.selectedlogisticsValue === 'Forwarding' || this.selectedlogisticsValue === undefined) {
        req = dis.requestService.getLatestEventCountsByCheckpointDates(status, filter);
        req.subscribe(resp => {
          this.spinner.hide();
          const messageResult = resp['result'];
          let resultData = messageResult;
          this.loadChart(resultData, 'DGF');
        });
      }
    }
    else {
      if (this.selectedlogisticsValue === 'Forwarding') {
        req = dis.requestService.getLatestEventCountsByCheckpointDates(status, filter);
        req.subscribe(resp => {
          this.spinner.hide();
          const messageResult = resp['result'];
          let resultData = messageResult;
          this.loadChart(resultData, 'DGF');
        });
      }
    }


    //*** FORWARDER FILTER END ***//

    //*** EXPRESS AND UNDEFINED FILTER STARTING ***//

    let filters: any;
    if (localstorageUserOrg.userOrg === 'CARGOTEC') {
      filters = { "lspOrg": ["EXPRESS"] };
    }
    else if (localstorageUserOrg.userOrg !== 'CARGOTEC') {
      filters = { "lspOrg": ["DHLE"] };
    }

    if (expressFilterUser) { // Checking customerList with customerOrgList2, if user finds it become TRUE.
      filters['customerOrg'] = [localstorageUserOrg.userOrg];
    }
    if (localstorageUserOrg.userOrg !== 'NISSAN') {
      filters['emailId'] = [localstorageUserOrg.emailId];
      filters['eventGroupId'] = [70];
    }

    if (this.ShipmentReferencesSearchField !== null && this.ShipmentReferencesSearchField !== undefined && this.ShipmentReferencesSearchField !== '') {
      filters['shipperAccountNumber'] = [this.ShipmentReferencesSearchField];
    }

    if (this.itemoriginCode !== null && this.itemoriginCode !== undefined && this.itemoriginCode !== '') {
      filters['originCode'] = [this.itemoriginCode];
    }

    if (this.itemDestinatioCode !== null && this.itemDestinatioCode !== undefined && this.itemDestinatioCode !== '') {
      filters['destinationCode'] = [this.itemDestinatioCode];
    }

    if (this.view_ver1_ui) {
      if (this.selectedlogisticsValue === 'Express') {
        if (sessionStorage.getItem('access-token') != null) {
          this.spinner.show();
        }
        req = dis.requestService.getLatestEventCountsByCheckpointDates(status, filters);
        req.subscribe(resp => {
          if (resp['Message'] === 'Sucess') {
            this.spinner.hide();
            const messageResult = resp['result'];
            let resultData = messageResult;
            this.loadChart(resultData, 'DHLE'); // Calling the chart loading method with required chart data.
          }
        });
      }
    }
    else {
      if (this.selectedlogisticsValue === 'Express' || this.selectedlogisticsValue === undefined) {
        if (sessionStorage.getItem('access-token') != null) {
          this.spinner.show();
        }
        req = dis.requestService.getLatestEventCountsByCheckpointDates(status, filters);
        req.subscribe(resp => {
          if (resp['Message'] === 'Sucess') {
            this.spinner.hide();
            const messageResult = resp['result'];
            let resultData = messageResult;
            this.loadChart(resultData, 'DHLE'); // Calling the chart loading method with required chart data.
          }
        });
      }
    }



    //*** EXPRESS AND UNDEFINED FILTER STARTING ***//

    //*** ARAMEX, DSV, SCHENKER, CRANE, GEODIS, EXPEDITORS FILTER STARTING ***//

    // Checking the selected logistic value, if the logistic value is not having below 3, then only the if condition is TRUE.
    if (this.selectedlogisticsValue != 'Express' && this.selectedlogisticsValue != undefined && this.selectedlogisticsValue != 'Forwarding') {

      let commonFilter: any = { "lspOrg": [this.selectedlogisticsValue,] };
      commonFilter['emailId'] = [localstorageUserOrg.emailId];

      if (common_filterUsers) { // Checking ARAMEX users with localhost userOrg, if finds it gets TRUE.
        commonFilter['customerOrg'] = [localstorageUserOrg.userOrg];
      }

      if (this.HAWBSearchField !== null && this.HAWBSearchField !== undefined && this.HAWBSearchField !== '') {
        commonFilter['houseBillNumber'] = [this.HAWBSearchField];
      }

      if (this.selectedlogisticsValue === this.selectedlogisticsValue) {
        if (sessionStorage.getItem('access-token') != null) {
          this.spinner.show();
        }
        req = dis.requestService.getLatestEventCountsByCheckpointDates(status, commonFilter);
        req.subscribe(resp => {
          this.spinner.hide();
          const messageResult = resp['result'];
          let resultData = messageResult;
          this.loadChart(resultData, this.selectedlogisticsValue); // Calling the chart loading method with required chart data.
        });
      }
    }
    //*** ARAMEX, DSV, SCHENKER, CRANE, GEODIS, EXPEDITORS FILTER END ***//

  }

  // It invoked by Oninit method.
  DataLoad() {
    const dis = this;
    let req = null;
    if (sessionStorage.getItem('access-token') != null) {
      this.spinner.show();
    }
    let filter: any = {};
    let localstorageUserOrg = this.authService.getLoggedUser();

    let customerdropdownValue = dis.tableCustomerDropdownValues;
    if (customerdropdownValue !== null && customerdropdownValue !== undefined && Object.keys(customerdropdownValue).length !== 0) {
      filter['customerOrg'] = [customerdropdownValue];
    }
    if (this.userRole === 'DHL') {
      filter = { "lspOrg": ["DGF"], 'emailId': [localstorageUserOrg.emailId], 'eventGroupId': [70] };
      if (customerdropdownValue !== null && customerdropdownValue !== undefined && Object.keys(customerdropdownValue).length !== 0) {
        filter['customerOrg'] = [customerdropdownValue];
      }
    }
    else if (this.userRole === 'Express') {
      filter = { "lspOrg": ["DHLE"] };
      if (customerdropdownValue !== null && customerdropdownValue !== undefined && Object.keys(customerdropdownValue).length !== 0) {
        filter['customerOrg'] = [customerdropdownValue];
      }
    }
    let status = 'DELIVERED';
    req = dis.requestService.getLatestEventCountsByCheckpointDates(status, filter);
    req.subscribe(resp => {
      this.spinner.hide();
      const messageResult = resp['result'];
      let resultData = messageResult;
      // this.ForwardingChartdata(resultData);
      this.loadChart(resultData, 'DGF'); // Calling the chart loading method with required chart data.
    });

    req = dis.requestService.getLatestEventCounts('DELIVERED', filter);
    req.subscribe(resp => {
      // this.spinner.hide();
      const messageResult = resp['result'].data;
      this.customersData = resp.result.customers;
      this.DropdownRows(messageResult);
    });
  }

  // It generates the code based on the data it received from the function arguments. This method was invoked at different places.
  // This is the common method to generate chart for different logistics values which selected by the user.
  loadChart(chartInput: any, labelTitle: any) {
    let $this = this;
    let key: any, value: any;
    let label = [];
    let dataset = [];
    for ([key, value] of Object.entries(chartInput)) {
      label.push(key);
      dataset.push(value);
    }
    if (this.ForwardingChart) {
      this.ForwardingChart.destroy();
    }
    let ctx = document.getElementById(this.requiredChartInitialization.divId); // The div id inserted based on the user selected logistic value.

    this.ForwardingChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: label,
        datasets: [{
          label: 'Delivered',
          data: dataset,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              precision: 0
            },
          }],
        },
        legend: {
          display: false,
        },
        responsive: true,

        onClick: function (e) {
          let activePointLabel = this.getElementsAtEvent(e)[0]._model.label;
          $this.Showtest(activePointLabel, labelTitle);
        }
      }
    });
  }

  Showtest(lableName, lspOrg) {
    if (this.userRole === 'DHL') {
      lspOrg = 'DGF';
    }
    else if (this.userRole === 'Express') {
      lspOrg = 'DHLE';
    }
    else {
      lspOrg = lspOrg;
    }
    const currentDate = new Date();

    let timeFrames = {
      "Last 24 Hrs": [new Date((new Date()).setDate(currentDate.getDate() - 1)), currentDate],
      "Last 7 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7))), new Date((new Date()).setDate(currentDate.getDate() - 1))],
      "Last 15 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 7)))],
      "Last 30 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15 + 30))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15)))],
      "Last 90 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15 + 30 + 90))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15 + 30)))],
      "More than 90 Days": [new Date("01/01/1990"), new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15 + 30 + 90 + 1)))]
    }
    let fromDate: any;
    let toDate: any

    if (lableName === 'Last 30 Days') {
      fromDate = new Date(timeFrames["Last 30 Days"][0]).toISOString();
      toDate = new Date(timeFrames["Last 30 Days"][1]).toISOString();
    }
    if (lableName === 'More than 90 Days') {
      fromDate = new Date(timeFrames["More than 90 Days"][0]).toISOString();
      toDate = new Date(timeFrames["More than 90 Days"][1]).toISOString();
    }
    if (lableName === 'Last 90 Days') {
      fromDate = new Date(timeFrames["Last 90 Days"][0]).toISOString();
      toDate = new Date(timeFrames["Last 90 Days"][1]).toISOString();
    }
    if (lableName === 'Last 15 Days') {
      fromDate = new Date(timeFrames["Last 15 Days"][0]).toISOString();
      toDate = new Date(timeFrames["Last 15 Days"][1]).toISOString();
    }
    if (lableName === 'Last 7 Days') {
      fromDate = new Date(timeFrames["Last 7 Days"][0]).toISOString();
      toDate = new Date(timeFrames["Last 7 Days"][1]).toISOString();
    }
    if (lableName === 'Last 24 Hrs') {
      fromDate = new Date(timeFrames["Last 24 Hrs"][0]).toISOString();
      toDate = new Date(timeFrames["Last 24 Hrs"][1]).toISOString();
    }

    this.messageService.DeliveredDetailsMessage(fromDate, toDate, lableName, lspOrg, this.tableCustomerDropdownValues, this.model.CustomerselectedItems, this.HAWBSearchField, this.itemoriginCode, this.itemDestinatioCode, this.ShipmentReferencesSearchField);

    this.router.navigate(['/delivered']);
  }

  DropdownRows(rows) {
    const dis = this;
    dis.codearrayCustomer = [];
    for (let i = 0; i < this.customersData.length; i++) {
      const raws = this.customersData[i];
      dis.codearrayCustomer[i] = { codeCustomer: raws['customerOrgId'], codeCustomerName: raws['customerOrgName'] };
    }
    let arrayduplicateremoveOrigin = this.removeDuplicates(dis.codearrayCustomer, "codeCustomer");
    dis.CodearrayDataCustomer = arrayduplicateremoveOrigin;
  }

  removeDuplicates(originalArray, prop) {
    let newArray = [];
    let lookupObject = {};
    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  clear() {
    this.CustomerselectedItems = null;
    this.tableCustomerDropdownValues = null;
    this.HAWBSearchField = null;
    this.ShipmentReferencesSearchField = null;
    this.selectedItemsOrigin = null;
    this.itemoriginCode = null;
    this.selectedItemsDestination = null;
    this.itemDestinatioCode = null;
    this.loadData();
  }

  // It invoked by Oninit method.
  customer_dropDown() {
    this.CustomerdropdownSettings = {
      singleSelection: true,
      idField: 'codeCustomer',
      textField: 'codeCustomerName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  // ng multi select drop down method.
  onCustomerItemSelect(item) {
    this.model.CustomerselectedItems = item.codeCustomerName;
    this.tableCustomerDropdownValues = item.codeCustomer;
    this.DataLoad();
  }

  // ng multi select drop down method.
  onCustomerDeSelect(item) {
    this.tableCustomerDropdownValues = null;
    this.DataLoad();
  }

  // It sets the select drop down data list. It invoked by Oninit method.
  logisticsSelectDropdown() {
    if (this.userRole === 'SLB') {
      this.dropdownList = [
        { item_id: 1, item_text: 'Express' },
        { item_id: 2, item_text: 'Forwarding' },
        { item_id: 3, item_text: 'ARAMEX' },
        { item_id: 4, item_text: 'SCHENKER' },
        { item_id: 5, item_text: 'DSV' },
        { item_id: 7, item_text: 'GEODIS' },
        { item_id: 8, item_text: 'EXPEDITORS' },
        { item_id: 9, item_text: 'KN' },
        { item_id: 10, item_text: 'CRANE' }
      ];
    } else {
      this.dropdownList = [
        { item_id: 1, item_text: 'Express' },
        { item_id: 2, item_text: 'Forwarding' },
      ];
    }
    if (this.view_ver1_ui) {
      this.selectedItems = [
        { item_id: 1, item_text: 'Express' },
      ];
    }
    else {
      this.selectedItems = [
        { item_id: 2, item_text: 'Forwarding' },
      ];
    }

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  // ng multi select drop down method.
  onItemSelect(item: any) {
    this.selectedlogisticsValue = item.item_text;
    this.loadData();
  }

  // ng multi select drop down method.
  onSelectAll(items: any) {
  }

  // origin_dropDown() {
  //   this.dropdownSettingsOrigin = {
  //     singleSelection: true,
  //     idField: 'origin_code',
  //     textField: 'origin_code',
  //     selectAllText: 'Select All',
  //     unSelectAllText: 'UnSelect All',
  //     itemsShowLimit: 3,
  //     allowSearchFilter: true,
  //     closeDropDownOnSelection: true
  //   };
  // }

  // originDataCall() {
  //   const dis = this;
  //   let req = null;
  //   let localstorageUserOrg = this.authService.getLoggedUser();
  //   if (this.selectedlogisticsValue === "Forwarding" || this.selectedlogisticsValue === 'Express') {
  //     this.tableCustomerDropdownValues = localstorageUserOrg.userOrgs;
  //     if (this.selectedlogisticsValue === "Forwarding") {
  //       this.selectedlogisticsValue = 'DGF';
  //     }
  //     else if (this.selectedlogisticsValue === 'Express') {
  //       this.selectedlogisticsValue = 'DHLE';
  //     }
  //   }
  //   else {
  //     this.tableCustomerDropdownValues = localstorageUserOrg.userOrg;
  //     this.selectedlogisticsValue = this.selectedlogisticsValue;
  //   }
  //   let params = {
  //     email: localstorageUserOrg.emailId,
  //     custOrgId: localstorageUserOrg.userOrg,
  //     lspOrg: this.selectedlogisticsValue
  //   }
  //   req = dis.requestService.originDropdownData(params.email, params.custOrgId, params.lspOrg);
  //   req.subscribe(resp => {
  //     if (resp['Message'] === 'Sucess') {
  //       if (resp['result'].length !== 0) {
  //         this.origin_dropDown();
  //         let originList = [];
  //         let x = resp['result'][0].origin_array[0].origin_code.sort();
  //         x.map((y: any) => {
  //           let obj = {
  //             "origin_code": y
  //           }
  //           originList.push(obj);
  //         })
  //         this.dropdownListOrigin = originList;
  //       }
  //       else {
  //         this.dropdownListOrigin = [];
  //       }
  //     }
  //   });
  //   this.loadData();
  // }

  // onItemSelectOrigin(item: any) {
  //   this.itemoriginCode = item.origin_code;
  //   if (this.selectedItemsDestination.length !== 0) {
  //     this.selectedItemsDestination = [];
  //   }
  //   this.DestinationDataCall(item.origin_code);
  //   this.loadData();
  // }

  // destination_dropDown() {
  //   this.dropdownSettingsDestination = {
  //     singleSelection: true,
  //     idField: 'destination_code',
  //     textField: 'destination_code',
  //     selectAllText: 'Select All',
  //     unSelectAllText: 'UnSelect All',
  //     itemsShowLimit: 3,
  //     allowSearchFilter: true,
  //     closeDropDownOnSelection: true
  //   };
  // }

  // MOTData() {
  //   this.dropdownSettingsMOT = {
  //     singleSelection: true,
  //     idField: 'item_id',
  //     textField: 'item_text',
  //     selectAllText: 'Select All',
  //     unSelectAllText: 'UnSelect All',
  //     itemsShowLimit: 3,
  //     allowSearchFilter: true,
  //     closeDropDownOnSelection: true
  //   };
  //   this.dropdownListMOT = [
  //     { item_id: 1, item_text: 'AIR' },
  //     { item_id: 2, item_text: 'ROAD' },
  //     { item_id: 3, item_text: 'SEA' },
  //     { item_id: 4, item_text: 'EXP' },
  //   ];
  //   this.disableMOT = false;
  // }

  // DestinationDataCall(origin_code) {
  //   const dis = this;
  //   let req = null;
  //   let localstorageUserOrg = this.authService.getLoggedUser();
  //   if (this.userRole === 'DHL' || this.userRole === 'Express') {
  //     this.tableCustomerDropdownValues = this.tableCustomerDropdownValues;
  //     if (this.tableCustomerDropdownValues === 'CARGOTEC_FRT') {
  //       this.selectedlogisticsValue = 'FREIGHT';
  //     }
  //     else {
  //       if (this.userRole === 'DHL') {
  //         this.selectedlogisticsValue = 'DGF';
  //       }
  //       else if (this.userRole === 'Express') {
  //         this.selectedlogisticsValue = 'DHLE';
  //       }
  //     }
  //   }
  //   else {
  //     this.tableCustomerDropdownValues = localstorageUserOrg.userOrg;
  //     this.selectedlogisticsValue = this.selectedlogisticsValue;
  //   }
  //   let params = {
  //     email: localstorageUserOrg.emailId,
  //     custOrgId: this.tableCustomerDropdownValues,
  //     originCode: origin_code,
  //     lspOrg: this.selectedlogisticsValue
  //   }
  //   req = dis.requestService.DestinationDropdownData(params.email, params.custOrgId, params.originCode, params.lspOrg);
  //   req.subscribe(resp => {
  //     if (resp['Message'] === 'Sucess') {
  //       if (resp.result.length > 0) {
  //         this.disableDestination = false;
  //         this.destination_dropDown();
  //         let destinList = [];
  //         let x = resp['result'][0].orig_dest_array.destination_code.sort();
  //         x.map((y: any) => {
  //           let obj = {
  //             destination_code: y
  //           }
  //           destinList.push(obj);
  //         })
  //         this.dropdownListDestination = destinList;
  //       }
  //       else {
  //         this.dropdownListDestination = [];
  //       }
  //     }
  //   });
  // }

  // onItemSelectDestination(item: any) {
  //   this.itemDestinatioCode = item.destination_code;
  //   this.MOTData();
  //   this.loadData();
  // }

  // onItemSelectMOT(item: any) {
  //   this.itemMOTCode = item['item_text'];
  //   this.loadData();
  // }

}
