import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { RequestService } from 'src/app/services/request.service';

@Component({
  selector: 'app-shipment-confirmation',
  templateUrl: './shipment-confirmation.component.html',
  styleUrls: ['./shipment-confirmation.component.scss']
})
export class ShipmentConfirmationComponent implements OnInit {
  columnDefs: any;
  RowData: any;
  IsColumnsToFit: boolean;
  AgLoad: boolean;

  gridApi: any;
  gridColumnApi: any;
  eventparsing: any = [];
  OE_ED: any = [];
  SideOverlay = false;
  dateRange: string;
  graphData: any;
  options: any;
  showNoRows: boolean;
  periodLabel: any = 'Last 24 Hrs';


  constructor(private requestService: RequestService, private spinner: NgxSpinnerService) { }
  domLayout = 'autoHeight';
  chartData() {
    let key;
    let value;
    let cData: any = [];
    for ([key, value] of Object.entries(this.graphData)) {
      if (value !== 0) {
        cData.push(
          {
            duration: key,
            Orders: value
          }
        )
      }
    }

    console.log('CData:', cData);
    this.options = {
      // title: { text: "New Orders" },
      // subtitle: { text: 'in billion U.S. dollars' },

      autoSize: true, // default: true
      // width: 200,
      // height: 200,
      data: cData,
      series: [
        {
          type: 'column',
          xKey: 'duration',            
          yKeys: ['Orders'],

          listeners: {
            nodeClick: (event) => {
              // console.log('clickedonchart:', datum);
              this.basedOnChart(event)
              // this.basedOnChart.bind(this)
              // this.gridOptions = 
              // this.gridOptions = {
              //   rowData: this.GetTableData(),
              //   columnDefs: this.GetAgColumns(),
              // }
            },
          },
          tooltip: {
            renderer: function (params) {
              return {
                title: params.xValue,
                content: params.yValue.toFixed(0),
              };
            },
          },
        },
      ],
      legend: {
        position: 'top',
      },
      axes: [
        {
          type: 'category',
          position: 'bottom',

          label: {
            // padding: 30,
            rotation: 90
          },
          // tick: { count: 20 },
        },
        {
          type: 'number',
          position: 'left'
        }
      ]
    };
  }

  BindData(params) {
    this.gridApi = params.api;
    this.gridApi.showLoadingOverlay();
    if (this.showNoRows) {
      this.gridApi.showNoRowsOverlay();
    }
    params.columnApi.autoSizeAllColumns();
    this.gridColumnApi = params.columnApi;
    // this.gridApi.getToolPanelInstance('filters').expandFilters();
    var columnState = JSON.parse(localStorage.getItem('myBookingColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }


  onColumnMoved(params) {
    console.log("params:::")
    var columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem('myBookingColumnState', columnState);
  }

  onGridReady(params) {
    var columnState = JSON.parse(localStorage.getItem('myBookingColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }

  resetState() {
    this.gridColumnApi.resetColumnState();
    console.log('column state reset');
  }

  GetAgColumns() {
    this.columnDefs = [
      { headerName: 'Status', field: 'pc_status', sortable: true, suppressFiltersToolPanel: true, },
      // {
      //   headerName: 'Reply Date', field: 'pc_reply_date', sortable: true,
      //   cellRenderer: function (params) {
      //     let data = params.value
      //     data = data.split('T')[0];
      //     return data
      //   }
      // },
      {
        headerName: 'Order Date', field: 'pc_order_date', sortable: true, suppressFiltersToolPanel: true, valueFormatter: onlydateFormatter
        // cellRenderer: function (params) {
        //   let data = params.value
        //   data = data.split('T')[0];
        //   return data
        // }
      },
      { headerName: 'Dealer No', field: 'pc_dealer_no', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Dealer Name', field: 'pc_order_by', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Dealer Order No', field: 'pc_order_number', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Invoice No', field: 'pc_invoice_no', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Item No', field: 'pc_item_no', sortable: true, suppressFiltersToolPanel: true, },
      { headerName: 'Buyer', field: 'pc_buyer', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Part Number', field: 'pc_part_number', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Part Name', field: 'pc_part_name', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Confirmed Order Qty', field: 'pc_confirmed_order_qty', sortable: true, suppressFiltersToolPanel: true },
      // { headerName: 'Shipped Pno', field: 'pc_shipped_pno', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Manufacturer', field: 'pc_manufacturer', sortable: true, suppressFiltersToolPanel: true },
      {
        headerName: 'ETD', field: 'pc_etd', sortable: true, suppressFiltersToolPanel: true, valueFormatter: onlydateFormatter
        // cellRenderer: function (params) {
        //   let data = params.value
        //   data = data.split('T')[0];
        //   return data
        // }
      },
      { headerName: 'Shipper Comment', field: 'pc_shipper_comment', sortable: true, suppressFiltersToolPanel: true },

    ];
  }

  clear() {
    window.location.reload();
  }

  defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
  };

  sideBar = {
    hiddenByDefault: false,
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          allowDragFromColumnsToolPanel: true
        },
      },
      // {
      //   id: 'filters',
      //   labelDefault: 'Shipper Account',
      //   labelKey: 'filters',
      //   iconKey: 'filter',
      //   toolPanel: 'agFiltersToolPanel',
      // }
    ]
  }

  gridOptions = {
    rowData: this.GetTableData(),
    columnDefs: this.GetAgColumns(),
  }

  GetTableData() {
    // this.AgLoad = true;
    const dis = this;
    let req = null;
    let filter: any = {};
    this.RowData = []
    // this.spinner.show();
    req = dis.requestService.shipmentConfirmation(this.dateRange);
    req.subscribe(resp => {
      const messageResult = resp.data;
      console.log("messageResult", messageResult)
      this.showNoRows = false;
      if (messageResult.length === 0) {
        this.showNoRows = true;
      }
      // this.spinner.hide();
      this.RowData = messageResult;
      this.graphData = resp.graphData;
      this.chartData();
      this.spinner.hide();
    });
  }

  ngOnInit(): void {
    this.spinner.show();
    this.chartData();
    this.spinner.hide();
  }

  basedOnChart(e) {
    this.spinner.show();
    console.log('clickedOnChart:', e);
    const currentDate = new Date();

    let timeFrames = {
      "Last 24 Hrs": [new Date((new Date()).setDate(currentDate.getDate() - 1)), currentDate],
      "Last 7 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7))), new Date((new Date()).setDate(currentDate.getDate() - 1))],
      "Last 15 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 7)))],
      "Last 30 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15 + 30))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15)))],
      "Last 90 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15 + 30 + 90))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15 + 30)))],
    }
    let fromDate: any;
    let toDate: any

    if (e.datum.duration === 'Last 30 Days') {
      fromDate = new Date(timeFrames["Last 30 Days"][0]).toISOString();
      toDate = new Date(timeFrames["Last 30 Days"][1]).toISOString()
    }
    if (e.datum.duration === 'Last 90 Days') {
      fromDate = new Date(timeFrames["Last 90 Days"][0]).toISOString();
      toDate = new Date(timeFrames["Last 90 Days"][1]).toISOString()
    }
    if (e.datum.duration === 'Last 15 Days') {
      fromDate = new Date(timeFrames["Last 15 Days"][0]).toISOString();
      toDate = new Date(timeFrames["Last 15 Days"][1]).toISOString()
    }
    if (e.datum.duration === 'Last 7 Days') {
      fromDate = new Date(timeFrames["Last 7 Days"][0]).toISOString();
      toDate = new Date(timeFrames["Last 7 Days"][1]).toISOString()
    }
    if (e.datum.duration === 'Last 24 Hrs') {
      fromDate = new Date(timeFrames["Last 24 Hrs"][0]).toISOString();
      toDate = new Date(timeFrames["Last 24 Hrs"][1]).toISOString()
    }
    this.periodLabel = e.datum.duration;
    fromDate = moment(fromDate).format('YYYY-MM-DD HH:mm:ss');
    toDate = moment(toDate).format('YYYY-MM-DD HH:mm:ss');
    console.log(fromDate, toDate);
    this.dateRange = fromDate + ', ' + toDate;
    this.GetTableData();
    this.GetAgColumns();

  }


}

function dateFormatter(params): any {
  if (params.value !== null) {
    return moment(params.value).format('YYYY-MM-DD HH:mm')
  }
}

function onlydateFormatter(params): any {
  if (params.value !== null) {
    return moment(params.value).format('YYYY-MM-DD')
  }
}

