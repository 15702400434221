import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EnvSelectionService } from './services/env-selection.service';

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  subscription: Subscription;
  title = 'BMX';
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  environmentName: any = environment.userType;
  domain = "client";
  endPoint_base_url: any;
  env_selected_analytics: any;
  env_selected_carbe: any;
  env_selected_vndord: any;
  env_selected_invaud: any;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private getHost,
    private envSelection: EnvSelectionService
  ) {
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
  }

  ngOnInit(): void {
    this.favIcon.href = environment.favIconUrl; // TO SET THE FAV ICON.

    // Finding the current domain name we serve.
    let hostName = this.getHost.location.host; // It's fetching the current host name in the url bar.
    //hostName = ""; //removed hardcoded hostname value
    hostName = hostName.toString();

    // Conditions for selecting the end point to pass in to the service files.

    if (hostName.includes('beta-blessllp')) {
      this.endPoint_base_url = environment.blessllp_beta_url;
      this.env_selected_analytics = environment.blessllp_beta_Analytics;
      this.env_selected_carbe = environment.blessllp_beta_Carbe;
      this.env_selected_vndord = environment.blessllp_beta_Vndord;
      this.env_selected_invaud = environment.blessllp_beta_Invaud;
      // localStorage.setItem("base_url", this.endPoint_base_url);
    }

    else if (hostName.includes('uat-blessllp')) {
      this.endPoint_base_url = environment.blessllp_uat_url;
      this.env_selected_analytics = environment.blessllp_uat_Analytics;
      this.env_selected_carbe = environment.blessllp_uat_Carbe;
      this.env_selected_vndord = environment.blessllp_uat_Vndord;
      this.env_selected_invaud = environment.blessllp_uat_Invaud;
      // localStorage.setItem("base_url", this.endPoint_base_url);
    }

    else if (hostName.includes('blessllp')) {
      this.endPoint_base_url = environment.blessllp_prod_url;
      this.env_selected_analytics = environment.blessllp_prod_Analytics;
      this.env_selected_carbe = environment.blessllp_prod_Carbe;
      this.env_selected_vndord = environment.blessllp_prod_Vndord;
      this.env_selected_invaud = environment.blessllp_prod_Invaud;
      // localStorage.setItem("base_url", this.endPoint_base_url);
    }

    else if (hostName.includes('beta')) {
      this.endPoint_base_url = environment.beta_url;
      this.env_selected_analytics = environment.beta_Analytics;
      this.env_selected_carbe = environment.beta_Carbe;
      this.env_selected_vndord = environment.beta_Vndord;
      this.env_selected_invaud = environment.beta_Invaud;
      // localStorage.setItem("base_url", this.endPoint_base_url);
    }

    else if (hostName.includes('uat')) {
      this.endPoint_base_url = environment.uat_url;
      this.env_selected_analytics = environment.uat_Analytics;
      this.env_selected_carbe = environment.uat_Carbe;
      this.env_selected_vndord = environment.uat_Vndord;
      this.env_selected_invaud = environment.uat_Invaud;
      // localStorage.setItem("base_url", this.endPoint_base_url);
    }
    else {
      this.endPoint_base_url = environment.prod_url;
      this.env_selected_analytics = environment.prod_Analytics;
      this.env_selected_carbe = environment.prod_Carbe;
      this.env_selected_vndord = environment.prod_Vndord;
      this.env_selected_invaud = environment.prod_Invaud;
      // localStorage.setItem("base_url", this.endPoint_base_url);
    }

    this.envSelection.endPointSelection(this.endPoint_base_url);
    this.envSelection.redirectUrlSelection(this.env_selected_analytics, this.env_selected_carbe, this.env_selected_vndord, this.env_selected_invaud);
    // console.log('base url===', localStorage.getItem('base_url'));
    // console.log('location==', this.document2.location);
    // console.log('host word==', hostName);

    if (environment.userType === 'DHL' || environment.userType === 'Express' || environment.userType === 'LLP') {
      document.getElementById('config').innerHTML = "Skutrace" + ' - ' + this.environmentName;
    }
    else if (environment.userType === 'BLESSLLP') {
      document.getElementById('config').innerHTML = "Skutrace" + ' - ' + this.environmentName;
    }
    else {
      document.getElementById('config').innerHTML = "Blesstrace" + ' - ' + this.environmentName;
    }
  }

}
