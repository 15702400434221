
<!-- <div class="form-row font ">
    <div class="form-group col">
        <label>Bill to Party</label>
        <ng-multiselect-dropdown [placeholder]="'Bill to Party'" [data]="codearrayDataBillTOParty"
            [(ngModel)]="BillTOPartyselectedItems" [settings]="BillTOPartydropdownSettings"
            (onSelect)="onItemBillTOPartySelect($event)" (onSelectAll)="onBillTOPartySelectAll($event)">
        </ng-multiselect-dropdown>
    </div>
    <div class="form-group col">
        <label>Shipper</label>
        <ng-multiselect-dropdown [placeholder]="'Shipper'" [data]="codearrayDataShipper"
            [(ngModel)]="ShipperselectedItems" [settings]="ShipperdropdownSettings" [disabled]="Shipperdisabled"
            (onSelect)="onItemShipperSelect($event)" (onSelectAll)="onSelectAll($event)">
        </ng-multiselect-dropdown>
    </div>
    <div class="form-group col">
        <label>Branch</label>
        <ng-multiselect-dropdown [placeholder]="'Branch'" [data]="codearrayDataBranch"
            [(ngModel)]="BranchselectedItems" [settings]="BranchdropdownSettings"
            (onSelect)="onItemBranchSelect($event)" [disabled]="Branchdisabled" (onSelectAll)="onSelectAll($event)">
        </ng-multiselect-dropdown>
    </div>
    <div class="form-group col">
        <label>From Date </label>
        <input type="date" max={{ToMaxDate}} style="font-size: 12px;" class="form-control" [ngModel]="Fromdt"
            #Fromdt="ngModel" (change)="FromDate(Fromdt)">
    </div>
    <div class="form-group col">
        <label>To Date </label>
        <input type="date" min={{ToMinDate}} style="font-size: 12px;" class="form-control" [disabled]="Todisabled"
            [ngModel]="Todt" #Todt="ngModel" (change)="ToDate(Todt)">
    </div>
    <button class="form-control-col btn btn-sm btn-secondary mt-4" data-widget="collapse" type="button"
        (click)="clear()">
        <i></i><span>Clear</span>
    </button>

</div> -->

<angular-tree-table [tableData]="tableData"></angular-tree-table>


<div class="modal" class="modal custom-modal fade" role="dialog" id="newModal2" data-backdrop="static"
    data-keyboard="false" >
    <div class="modal-dialog modal-dialog-scrollable modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5><strong>HAWB#: Q776307</strong></h5>
                <h5><strong style="margin-left: 500px;">Forwarder: DHL GLOBAL FORWARDING (THAILAND) LIMITED</strong>
                </h5>
                <button type="button" class="close" data-dismiss="modal"><span>&times;</span></button>
            </div>
            <div class="modal-body">
                <!-- <h5><strong><u class="pl-2">Proforma Details</u></strong></h5> -->
                <div class="additionalDetails">
                    <div class="row" style="border-top: 1px solid #dee2e6;border-bottom: 1px solid #dee2e6;">
                        <div class="col-6" style="border-right: 1px solid #dee2e6;">
                            <h5 class="mt-2"><strong><u class="pl-2 mt-2">Pickup Address</u></strong></h5>
                            <div class="col mt-2">
                                <div class="row">
                                    <div class="col">Address Type</div>:<div class="col mr-5">
                                        Consignor Pickup Delivery Address
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">Address1</div>:<div class="col-6 mr-4">
                                        376 CHEMIN DE L ORME
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">Address2</div>:<div class="col-6 mr-4">
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">Address Override</div>:<div class="col mr-5">
                                        false
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">Address Short Code</div>:<div class="col mr-5">
                                        376 CHEMIN DE L ORME
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">City</div>:<div class="col mr-5">
                                        MARCY L ETOILE
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">Company Name</div>:<div class="col mr-5">
                                        BIOMERIEUX SA EXPORT / HORS DOM TOM
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">Country Code</div>:
                                    <div class="col-6 mr-4">
                                        FR
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">Country Name</div>:
                                    <div class="col-6 mr-4">
                                        France
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6" style="border-right: 1px solid #dee2e6;">
                            <h5 class="mt-2"><strong><u class="pl-2 mt-2">Delivery Address</u></strong></h5>
                            <div class="col">
                                <div class="row mt-2">
                                    <div class="col">Address Type</div>:<div class="col mr-5">
                                        Consignee Pickup Delivery Address
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">Address1</div>:<div class="col mr-5">3195/9 FLR4 VIBUL THANI BLDG
                                        RAMA 4 ROAD
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">Address2</div>:<div class="col mr-5">
                                        KWAENG KLONGTON KHET KLONGTOEY
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">Address Override</div>:<div class="col mr-5">
                                        false
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">Address Short Code</div>:<div class="col mr-5">3195/9 FLR4 VIBUL
                                        THANI B
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">City</div>:<div class="col mr-5">
                                        BANGKOK
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">Company Name</div>:<div class="col mr-5">
                                        BIOMERIEUX (THAILAND) LTD
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">Country Code</div>:<div class="col mr-5">
                                        TH
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">Country Name</div>:<div class="col mr-5">
                                        Thailand
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="additionalDetails mt-2">
                    <div class="row" style="border-top: 1px solid #dee2e6;border-bottom: 1px solid #dee2e6;">
                        <div class="col-6" style="border-right: 1px solid #dee2e6;">
                            <h5 class="mt-2"><strong><u class="pl-2 mt-2">Port of Loading</u></strong></h5>
                            <div class="col mt-2">
                                <div class="row">
                                    <div class="col">Port Of Loading Code</div>:<div class="col mr-5">
                                        FRLYS
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">Port Of Loading Name</div>:<div class="col-6 mr-4">
                                        Saint-Exupery Apt/Lyon
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6" style="border-right: 1px solid #dee2e6;">
                            <h5 class="mt-2"><strong><u class="pl-2 mt-2">Port of Discharge</u></strong></h5>
                            <div class="col mt-2">
                                <div class="row">
                                    <div class="col">Port Of Discharge Code</div>:<div class="col mr-5">
                                        THBKK
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">Port Of Discharge Name</div>:<div class="col-6 mr-4">
                                        Bangkok
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="additionalDetails mt-2">
                    <div class="row" style="border-top: 1px solid #dee2e6;border-bottom: 1px solid #dee2e6;">
                        <div class="col-6" style="border-right: 1px solid #dee2e6;">
                            <h5 class="mt-2"><strong><u class="pl-2 mt-2">Origin</u></strong></h5>
                            <div class="col mt-2">
                                <div class="row">
                                    <div class="col">Origin Code</div>:<div class="col mr-5">
                                        LYS
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6" style="border-right: 1px solid #dee2e6;">
                            <h5 class="mt-2"><strong><u class="pl-2 mt-2">Destination</u></strong></h5>
                            <div class="col mt-2">
                                <div class="row">
                                    <div class="col">Destination Code</div>:<div class="col mr-5">
                                        BKK
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>







                <div class="additionalDetails mt-2">
                    <div class="row">
                        <div class="col">
                            <div class="col d-flex justify-content-center mt-2">
                                <h5 class="col">
                                    <strong><u>Milestone Collection</u></strong></h5>
                            </div>
                            <angular-tree-table [tableData]="MilestoneCollectionTableData"></angular-tree-table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>