import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { EnvSelectionService } from './env-selection.service';

@Injectable({
  providedIn: 'root'
})

export class RequestService {
  private customHttpClient: HttpClient;
  private endPoint: any;
  constructor(private http: HttpClient, backend: HttpBackend, private envSelection: EnvSelectionService) {
    this.customHttpClient = new HttpClient(backend);
    this.endPoint = this.envSelection.getEndPoint(); // It returns the current running env related base end point url.
  }

  getLatestEventCounts(status: string, filter: any) {
    return this.http.get(this.endPoint + environment.port + '/shipment/getLatestEventCounts', { params: { status: status, filter: JSON.stringify(filter) } })
  }

  getLatestEventCountsByCheckpointDates(status: string, filter: any) {
    return this.http.get(this.endPoint + environment.port + '/shipment/getLatestEventCountsByCheckpointDates', { params: { status: status, filter: JSON.stringify(filter) } })
  }

  List(status: string, filter: any) {
    return this.http.get(this.endPoint + environment.port + '/shipment/getShipmentByStatus', { params: { status: status, filter: JSON.stringify(filter) } });
  }
  popup(obj: { dealerId?: any; dealerOrder?: any; houseAwb?: any; hawb?: any; }) {
    return this.http.get(this.endPoint + environment.port + '/hawblines/gethawbLinesByAwb', { params: obj });
  }
  airWayBillDetails(houseAwb: any, hawb: any, fullDetails: boolean) {
    return this.http.get(this.endPoint + environment.port + '/hawblines/gethawbLinesByAwb', { params: { houseAwb: houseAwb, hawb: hawb, fullDetails } });
  }
  eventChainDetails(uniqueId: number) {
    return this.http.get(this.endPoint + environment.port + '/shipment/getEventchain', { params: { id: uniqueId } });
  }
  tracktempList() {
    return this.http.get(this.endPoint + environment.port + '/tracking/getTrackTemperatures');
  }
  mapData(uniqueId: any) {
    return this.http.get(this.endPoint + environment.port + '/tracking/getTrackTemperatures', { params: { id: uniqueId } });
  }
  mapMarkersData(id: any, lltd: any, lgtd: any) {
    return this.http.get(this.endPoint + environment.port + '/tracking/getTrackTemperatures', { params: { id: id, lltd: lltd, lgtd: lgtd } });
  }
  purchaseOrdersDashboardCount() {
    return this.http.get(this.endPoint + '/co/orders/getDashboardCount');
  }
  purchaseOrders(region: any, status: any, dateRange: any) {
    return this.http.get(this.endPoint + '/co/orders/getOrderList', { params: { region: region, status, dateRange } });
  }
  shipmentConfirmation(dateRange: string) {
    return this.http.get(this.endPoint + '/co/orders/getConfirmedList', { params: { dateRange } });
  }
  purchaseOrderExceptions(obj: { dateRange: any; pe_status: any[]; }) {
    return this.http.get(this.endPoint + '/co/orders/getExceptionList', { params: obj });
  }
  WarehouseInfrastructure(dateRange: string) {
    return this.http.get(this.endPoint + '/tr/warehouse/getOrderList', { params: { dateRange } });
  }
  ShipmentInstruction(obj: { dateRange: string; }) {
    return this.http.get(this.endPoint + '/tr/shipment/getShipmentList', { params: obj });
  }
  // trMoreDetailsCall(si_houseway_waybill_no: any) {
  //   return this.http.get(this.endPoint + '/tr/shipment/getHAWBDetails', { params: { si_houseway_waybill_no: si_houseway_waybill_no } });
  // }
  trMoreDetailsCall(obj: any) {
    return this.http.get(this.endPoint + '/tr/shipment/getInvoiceDetails', { params: obj });
  }
  purchaseOrderPartial_Full(region: any) {
    return this.http.get(this.endPoint + '/co/orders/getExceptionList', { params: { region: region } });
  }
  warehouseMoreDetails(obj: { wi_invoice_no?: any; ext_order_number?: any; }) {
    return this.http.get(this.endPoint + '/tr/warehouse/getInvoiceDetails', { params: obj });
  }
  referencesMoreDetails(uniqueId: any) {
    return this.http.get(this.endPoint + environment.port + '/shipment/getShipmentReferences', { params: { id: uniqueId } });
  }
  moreDetailsMapData(masterId: any) {
    return this.http.get(this.endPoint + environment.port + '/shipment/getMasterLegsLatLong', { params: { masterId: masterId } });
  }
  customerDropdownData() {
    return this.http.get(this.endPoint + environment.port + '/config/customers');
  }
  originDropdownData(email: any, custOrgId: any, lspOrgId: any) {
    return this.http.get(this.endPoint + environment.port + '/config/origins', { params: { email: email, custOrgId: custOrgId, lspOrgId: lspOrgId } });
  }
  DestinationDropdownData(email: any, custOrgId: any, originCode: any, lspOrgId: any) {
    return this.http.get(this.endPoint + environment.port + '/config/destinations', { params: { email: email, custOrgId: custOrgId, originCode: originCode, lspOrgId: lspOrgId } });
  }
  eta_etd_serviceCode_history(shipId: any) {
    return this.http.get(this.endPoint + environment.port + '/shipment/eta-etd-history', { params: { shipId: shipId } });
  }
  tracktemps(parentid: any) {
    return this.http.get(this.endPoint + environment.port + '/tracking/track-temps', { params: { parentId: parentid } });
  }

  refreshToken(token: string) {
    return this.http.post(this.endPoint + environment.port + '/shipment/auth/refresh-tokens', {
      refreshToken: token
    });
  }
  DocumentsAPI(parentid: any) {
    return this.http.get(this.endPoint + environment.port + '/documents', { params: { parentId: parentid } });
  }

  getFsuData(HouseAirwayBillNumber: any) {
    return this.http.get(this.endPoint + environment.port + '/shipment/getFSU_details', { params: { HouseAirwayBillNumber: HouseAirwayBillNumber } });
  }
  originsByLsp(filter: any) {
    return this.http.get(this.endPoint + environment.port + '/config/origins-by-lsp', { params: { filter: JSON.stringify(filter) } });
  }
  destinationsByOriginLsp(filter: any) {
    return this.http.get(this.endPoint + environment.port + '/config/destinations-by-origin-lsp', { params: { filter: JSON.stringify(filter) } });
  }
  // DocumentBlessAPICall(downloadUrl: any) {
  //   const auth_token = 'NDZjMmJiNmQtNTY2Yy00NTJhLWFiM2MtYjBlODhiYzFiZjg0.MEQCIAVA9QOzABesf7j4vlkOLUPMZdT1bHAvPjKyLqbm1Wt3AiBxxzoWKoLd4dHPy7oh2OwJu/izRE6bNUOV3GfFa/Q8mA=='
  //   const kid = '9945d359e1ede3143e871258eb977e05408785306037639790f5ca2de41514ab'
  //   let headers = new HttpHeaders()
  //     .set('Authorization', auth_token)
  //     .set('kid', kid)
  //     .set('redirect', 'follow')
  //     .set('rejectUnauthorized', 'false');
  //   return this.customHttpClient.get('http://13.70.192.104/bless/api/v3/messages/attachment/' + downloadUrl, { headers: headers })
  // 9D30164
  // }

  // DocumentBlessAPICall(downloadUrl: any): Observable<HttpResponse<Blob>> {
  //   return this.http.get<Blob>(this.endPoint + environment.port + '/documents/download', { observe: 'response', responseType: 'blob' as 'json', params: { documentId: "14cb9d5a7fa8cbd87558bfe6d49662d00a47ef865a4845ec1ca3300792ea5ffd" } })
  // }
  // DocumentBlessAPICall() {
  //   return this.http.get(this.endPoint + '/bless/api/v3/messages/attachment/' + "14cb9d5a7fa8cbd87558bfe6d49662d00a47ef865a4845ec1ca3300792ea5ffd")
  // }

  PNPShipment(status: any, filter: any) {
    return this.http.get(this.endPoint + '/pnp/shipment' + '/ship-data/shipment', { params: { status: status, filter: JSON.stringify(filter) } });
  }

  PNPShipmentPagination(obj: any) {
    return this.http.post(this.endPoint + '/pnp/shipment' + '/ship-data/shipmentPagination', obj);
  }
  PNPgetEventchain(id: any) {
    return this.http.get(this.endPoint + '/pnp/shipment' + '/ship-data/getEventchain', { params: { id: id } });
  }
  PNPgethawbLinesByAwb(hawb: any) {
    return this.http.get(this.endPoint + '/pnp/shipment' + '/ship-data/gethawbLinesByAwb', { params: { hawb: hawb } });
  }

  PNPgetAddressMap(address_id: any) {
    return this.http.get(this.endPoint + '/pnp/shipment' + '/ship-data/getAddressMap', { params: { address_id: address_id } });
  }

  EUMap(city: any, country: any, postalcode?: any) {
    return this.http.get('https://nominatim.openstreetmap.org/search.php', { params: { country: country, city: city, postalcode: postalcode, format: 'jsonv2' } })
  }

  getOriginsPNP() {
    return this.http.get(this.endPoint + '/pnp/shipment' + '/ship-data/getOriginsPNP');
  }

  getOriginsByDestinationPNP(originCode: any) {
    return this.http.get(this.endPoint + '/pnp/shipment' + '/ship-data/getOriginsByDestinationPNP', { params: { originCode: originCode } });
  }

  carbEgetShipmentRoutesAndEmissionData(shipment_id: any) {
    return this.http.get(this.endPoint + environment.port1 + '/CarbonEmissionStats/getShipmentRoutesAndEmissionData', { params: { shipment_id: shipment_id } });
  }

  getGroupByCounts(filter: any) {
    return this.http.get(this.endPoint + environment.port + '/shipment/getGroupByCounts', { params: { filter: JSON.stringify(filter) } });
  }

  getShipmentWithPagination(filter: any, status: any, order: any, search: any, colFilter: any, request: any) {
    return this.http.post(this.endPoint + environment.port + '/shipment/getShipmentByStatusV2', {
      filter: filter,
      status: status,
      order: order,
      search: search,
      colFilter: colFilter,
      request
    });
  }

  eventChainDetailsV2(uniqueId: number) {
    return this.http.get(this.endPoint + environment.port + '/shipmentV2/getEventchain', { params: { id: uniqueId } });
  }

}
