import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgForm } from '@angular/forms';
import { UserloginServiceService } from 'src/app/services/userlogin-service.service';
import { BaseService } from 'src/app/services/base.service';
import { Md5 } from 'ts-md5/dist/md5';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  model = { username: null, password: null };
  userType = null;
  constructor(private router: Router, private authService: AuthService,
    private userLoginService: UserloginServiceService,
    private baseService: BaseService) { }

  ngOnInit() {
    const dis = this;
    dis.userType = environment.userType;
  }


  // onSubmit(form: NgForm) {
  //   const dis = this;
  //   if (dis.model.username === 'dhl@dhl.com' && dis.model.password === 'bl3s5$2020') {
  //     dis.authService.login('', 'dhl', '', '');
  //     dis.router.navigate(['']);
  //   }
  //   else {
  //     this.baseService.alert('Wrong Credintials');
  //   }

  // }


  onSubmit(form: NgForm) {
    const dis = this;
    let hash = Md5.hashStr(dis.model.password);
    dis.userLoginService.checkUserLogin(dis.model.username, hash).subscribe(resp => {
      if (resp['status'] === 'SUCCESS') {
        const loginDetails = resp['data'].user;
        const userRole = loginDetails.userRole;
        const userId = loginDetails.userId;
        const userOrg = loginDetails.userOrg;
        const orgType = loginDetails.orgType
        dis.authService.login(dis.model.username, userRole, userId, userOrg, orgType);
        if (resp['data'].accessToken != null) {
          // store the token in the sessionStorage 
          sessionStorage.setItem("access-token", resp['data'].accessToken)
        }
        dis.router.navigate(['']);
      } else {
        this.baseService.alert(resp['status']);
      }

    });

  }

}

