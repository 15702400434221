import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MoreDetailsService } from 'src/app/services/more-details.service';
import { TabService } from 'src/app/services/tab-service.service';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
import { logos } from 'src/app/constants/logo';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  title: any;
  path: any;
  tabDetails: any = [];
  Tab: any = [];
  clickedTab: any;
  role!: string;
  loggedIn = false;
  username = '';
  userType = null;
  userId = null;
  loggedUser: any = null;
  userName: any;
  logo_url: any = null; // It stores the logo src URL.
  logo_obj: any; // It stores logo constant object data from constants.
  logo_width: any; // It is storing logo size from constants.

  constructor(private router: Router, private authService: AuthService, private tablist: TabService,
    private moreDetails: MoreDetailsService) { }

  ngOnInit() {
    this.tablist.CurrentTab.subscribe((resp: any) => {
      this.tabDetails = this.tablist.getTabs();
    });
    const dis = this;
    this.userName = environment.userType;
    this.loggedUser = this.authService.getLoggedUser();
    this.logo_obj = this.getLogo(dis.userName); // To get logo object.
    if (this.logo_obj.initial_header) {
      this.logo_url = this.logo_obj.logo_src; // Assigning logo url.
      this.logo_width = this.logo_obj.width; // Asigning logo width.
      console.log('logo user name', this.userName);
    }
    else {
      this.userType = this.loggedUser.userOrg;
      if (this.userType) {
        this.logo_obj = this.getLogo(dis.userType); // To get logo object.
        this.logo_url = this.logo_obj.logo_src; // Assigning logo url.
        this.logo_width = this.logo_obj.width; // Asigning logo width.
        console.log('logo user type', this.userType);
      }
    }

    this.userId = this.loggedUser.userId;
    this.authService.loginStateChanged$.subscribe(resp => {
      dis.loggedIn = dis.authService.isUserLoggedIn();

      const loggedUser1 = dis.authService.getLoggedUser();
      this.userType = loggedUser1.userOrg;
      this.userId = loggedUser1.userId;
      if (dis.authService.isUserLoggedIn()) {
        dis.username = loggedUser1.emailId;
        if (!this.logo_obj.initial_header) {
          this.logo_obj = this.getLogo(dis.userType); // To get logo object.
          this.logo_url = this.logo_obj.logo_src; // Assigning logo url.
          this.logo_width = this.logo_obj.width; // Asigning logo width.
          console.log('logo user type auth', this.userType);
        }
      } else {
        dis.username = '';
      }
      dis.updateHtmlClass();
    });
    dis.loggedIn = this.authService.isUserLoggedIn();
    const loggedUser = this.authService.getLoggedUser();
    if (this.authService.isUserLoggedIn()) {
      this.username = loggedUser.emailId;
    } else {
      this.username = '';
    }
    dis.updateHtmlClass();
  }

  getLogo(user: any) {
    let logo_url = logos.logo; // Fetching full logo objects constant.
    let single_logo_obj = logo_url.find(x => x.user == user); // Finding the respective logo object from constant.
    return single_logo_obj; // Returning logo object.
  }

  // Applying styles to logo block. 
  logoStyles() {
    let styles = { 'height': 'auto', width: this.logo_width };
    return styles;
  }

  updateHtmlClass() {
    if (this.authService.isUserLoggedIn()) {
      console.log("login-bg");
      $('html').removeClass('login-bg');
    } else {
      $('html').addClass('login-bg');
    }
  }

  logout() {
    Swal.fire({
      title: 'Are you sure you want to exit ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    }).then((result) => {
      if (result.isConfirmed) {
        let winobj = this.moreDetails.getReference();
        if (winobj !== undefined) {
          winobj.close();
        }
        this.logo_url = null; // Removing logo.
        this.authService.logout();
        this.router.navigate(['login']);
        sessionStorage.clear();
      }
    })
  }

  close(index: any) {
    this.tablist.closeTab(index);
  }

  tabinfo(tab: any) {
    this.clickedTab = tab.title;
    let clickedTab = tab.routerLink;
  }

  activateTab(tab: any, index: any) {
    if (this.tabDetails.length - 1 === index) {
      return 'active';
    } else if (tab.title === this.clickedTab) {
      return 'active';
    } else {
      return '';
    }
  }
}

