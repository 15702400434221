<div>
    <ag-grid-angular class="ag-theme-balham" [columnDefs]="columnDefs" [domLayout]="domLayout"
    [debug]="true"  
    [animateRows]="true"
    [rowModelType]="rowModelType" 
    [serverSideStoreType]="serverSideStoreType"
    [paginationPageSize]="paginationPageSize" 
    [cacheBlockSize]="cacheBlockSize"
    [defaultColDef]="defaultColDef" 
    [pagination]="true" 
    (gridReady)="BindData($event)">
</ag-grid-angular>
</div>


<!-- <div>
    <ag-grid-angular class="ag-theme-balham" [columnDefs]="columnDefs" [domLayout]="domLayout" [rowData]="RowData"
        [debug]="true" [masterDetail]="true" [sideBar]="false" [enableRangeSelection]="true" [animateRows]="true"
        [pagination]="true" [defaultColDef]="defaultColDef" [paginationPageSize]="25" [enableCharts]="true"
        (gridReady)="BindData($event)">
    </ag-grid-angular>
</div> -->



<ng-container *ngIf="SideOverlay === true">
    <div id="myNav" class="overlay" *ngIf="onRowClickedValue!=undefined">
        <div class="container">
            <div class="row" style="border-bottom: 1px solid #dee2e6;">
                <div class="col"></div>
                <div class="col" style="text-align:center;">
                    <h5 class="mt-4 font-weight-bold">HAWB#: <span class="font-weight-bold"
                            style="font-size: 1.25rem;">{{onRowClickedValue.ship_tracking_num}}</span>
                    </h5>
                </div>
                <div class="col mt-4">
                    <div class="row">
                        <div class="">
                            <img src="https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ffcd36">
                            <h6>{{onRowClickedValue['origin_code']}}</h6>
                        </div>

                        <div class="mt-2 ml-2">
                            ---->
                        </div>

                        <div class="ml-2">
                            <img src="https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ea4335">
                            <h6> {{onRowClickedValue['destination_code']}} </h6>
                        </div>
                    </div>
                </div>
                <div class="col-1">
                    <a href="javascript:void(0)" class="" style="text-align:right;" (click)="closemodal()">&times;</a>
                </div>
            </div>
        </div>


        <div class="overlay-content">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">

                        <div class="scrollmenu">

                            <div class="mt-2">
                                <div class="mmm">
                                    <h5 class="d-flex"><b><u>Events</u></b></h5>

                                    <span class="timeline ml-3 d-flex">
                                        <ul class="p-0" id="reverseCount">
                                            <li *ngFor="let eventparsing of eventparsing;let i=index">
                                                <div>
                                                    <div class="circle"></div>
                                                    <div style="font-size: 10px;">
                                                        <div class="lable">
                                                            <span class="label label-success"
                                                                *ngIf="eventparsing.location !== null">
                                                                {{eventparsing.location}} /
                                                                {{eventparsing.description}}
                                                            </span>
                                                            <span class="label label-success"
                                                                *ngIf="eventparsing.location === null">
                                                                {{eventparsing.description}}
                                                            </span>

                                                        </div>
                                                        <div class="title">{{eventparsing.actualDate |
                                                            date:'dd-MMM-YYYY HH:mm'}}
                                                        </div>
                                                    </div>
                                                </div>

                                            </li>
                                        </ul>
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-sm-12 col-md-9 col-lg-9 col-xl-9">
                        <!--Accordion wrapper-->
                        <div class="accordion md-accordion mt-4 mb-5" id="accordionEx" role="tablist"
                            aria-multiselectable="true">


                            <!-- Map Accordion card -->
                            <ng-container>
                                <div class="card">

                                    <!-- Card header -->
                                    <div class="card-header" role="tab" id="headingOne1">
                                        <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne1"
                                            aria-expanded="true" aria-controls="collapseOne1">
                                            <h5 class="mb-0" style="color: black;">
                                                Map <i class="fa fa-angle-down rotate-icon"></i>
                                            </h5>
                                        </a>
                                    </div>

                                    <!-- Card body -->
                                    <div id="collapseOne1" class="collapse " role="tabpanel"
                                        aria-labelledby="headingOne1" data-parent="#accordionEx">
                                        <div class="card-body">
                                            <ng-container *ngIf="latlng.length !== 0">
                                                <agm-map [fullscreenControl]='false' [mapTypeControl]='false'
                                                    [fitBounds]="true" [minZoom]=2>
                                                    <agm-marker *ngFor="let coordinate of start_end_mark; let i=index"
                                                        [latitude]="coordinate[0]" [longitude]="coordinate[1]"
                                                        [agmFitBounds]="true" [iconUrl]="coordinate[2]">
                                                    </agm-marker>
                                                    <agm-polyline [visible]="true" [strokeWeight]="3"
                                                        [strokeColor]="'#07b57a'">
                                                        <agm-polyline-point
                                                            *ngFor="let coordinate of latlng; let i=index"
                                                            [latitude]="coordinate[0]" [longitude]="coordinate[1]">
                                                        </agm-polyline-point>
                                                        <agm-icon-sequence [strokeColor]="'black'" [repeat]="'10px'"
                                                            [offset]="'0'" [strokeWeight]="3" [scale]="2"
                                                            [strokeOpacity]="100" [path]="'M 0,-1 0,1'">
                                                        </agm-icon-sequence>
                                                    </agm-polyline>
                                                </agm-map>

                                            </ng-container>
                                            <ng-container *ngIf="latlng.length === 0">
                                                <p style="text-align: center;font-size: 15px;">No Data Found</p>
                                            </ng-container>
                                        </div>
                                    </div>

                                </div>
                            </ng-container>
                            <!-- Map Accordion card -->


                            <!--Details Accordion card -->
                            <div class="card">

                                <!-- Card header -->
                                <div class="card-header" role="tab" id="headingTwo2">
                                    <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx"
                                        href="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">
                                        <h5 class="mb-0" style="color: black;">
                                            Details <i class="fa fa-angle-down rotate-icon"></i>
                                        </h5>
                                    </a>
                                </div>

                                <!-- Card body -->
                                <div id="collapseTwo2" class="collapse show" role="tabpanel"
                                    aria-labelledby="headingTwo2" data-parent="#accordionEx">
                                    <div class="card-body">
                                        <div class="leftContent pt-1">
                                            <div class="ng-tns-c4-0">
                                                <div class="qntyDiv">
                                                    <div class="cont">
                                                        <div class="title">Ship Id : </div>
                                                        <div class="ng-tns-c4-0">{{onRowClickedValue.ship_reference_id}}
                                                        </div>
                                                    </div>
                                                    <div class="cont">
                                                        <div class="title">Shipment Reference : </div>
                                                        <div class="qntyUnitDesc">
                                                            {{onRowClickedValue.shipper_reference}} </div>
                                                    </div>
                                                    <div class="cont">
                                                        <div class="title">Delivery : </div>
                                                        <div class="qntyUnitDesc">
                                                            <ng-container
                                                                *ngIf="onRowClickedValue.delivery_house_no != undefined">
                                                                <b>House No</b> -
                                                                {{onRowClickedValue.delivery_house_no}} ,
                                                                <b>Street Code</b> -
                                                                {{onRowClickedValue.delivery_street_code}} ,
                                                                <b>Zip Code</b> -
                                                                {{onRowClickedValue.delivery_zip_code}}
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="onRowClickedValue.delivery_house_no === undefined">
                                                                <b>Refer DHL Facility</b>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <ng-container>
                                                <div class="leftContent">
                                                    <div class="ng-tns-c4-0">
                                                        <div class="qntyDiv">

                                                            <div class="cont">
                                                                <div class="title">Posting Date :
                                                                </div>
                                                                <div class="qntyUnitDesc">
                                                                    <div class="row">
                                                                        <div class="col">
                                                                            <ng-container
                                                                                *ngIf="onRowClickedValue.etd!== null">
                                                                                {{onRowClickedValue.etd.split('T')[0] |
                                                                                date:'dd-MMM-YYYY'}}
                                                                            </ng-container>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="cont">
                                                                <div class="title">Delivery Date :
                                                                </div>
                                                                <div class="qntyUnitDesc">
                                                                    <ng-container *ngIf="onRowClickedValue.eta!== null">
                                                                        {{onRowClickedValue.eta.split('T')[0] |
                                                                        date:'dd-MMM-YYYY'}}
                                                                    </ng-container>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>

                                        </div>
                                        <div class="bottomContent">
                                            <div class="statusDiv">
                                                <table class="ng-tns-c4-0" width="100%">
                                                    <tr class="locationHeader">
                                                        <td class="ng-tns-c4-0" width="10%"> Origin </td>
                                                        <td class="ng-tns-c4-0" width="20%"
                                                            style="text-transform: capitalize;">
                                                            DHL Facility (Retail Outlet / PackStation)</td>
                                                        <td class="ng-tns-c4-0" width="20%"
                                                            style="text-transform: capitalize;">
                                                            Delivery Address</td>
                                                        <td class="ng-tns-c4-0" width="10%"> Destination </td>



                                                    </tr>
                                                    <tr class="imageCont font-wt">
                                                        <td class="ng-tns-c4-0">
                                                            <div class="inTransit bottimestatusdiv">
                                                                <div class="title font-wt"
                                                                    style="text-transform: uppercase">
                                                                    {{onRowClickedValue.origin_name}} ,
                                                                    {{onRowClickedValue.origin_country}}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="ng-tns-c4-0">
                                                            <div class="booking bottimestatusdiv">
                                                                <div class="title font-wt"
                                                                    style="text-transform: uppercase">
                                                                    {{onRowClickedValue.name_pickup}},{{onRowClickedValue.line1_pickup}},{{onRowClickedValue.city_pickup}},{{onRowClickedValue.state_pickup}},{{onRowClickedValue.country_name_pickup}}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="ng-tns-c4-0">

                                                            <div class="booking bottimestatusdiv">
                                                                <div class="title font-wt"
                                                                    style="text-transform: uppercase">
                                                                    <ng-container
                                                                        *ngIf="onRowClickedValue.name_delivery === '0000000000000'">
                                                                        {{onRowClickedValue.country_name_delivery}}
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="onRowClickedValue.name_delivery !== '0000000000000'">
                                                                        {{onRowClickedValue.name_delivery}} ,
                                                                    {{onRowClickedValue.country_name_delivery}}
                                                                    </ng-container>
                                                                    
                                                                </div>
                                                            </div>

                                                        </td>
                                                        <td class="ng-tns-c4-0">
                                                            <div class="inTransit bottimestatusdiv">
                                                                <div class="title font-wt"
                                                                    style="text-transform: uppercase">

                                                                    {{onRowClickedValue.destination_name}}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!--Details Accordion card -->
                        </div>
                        <!-- Accordion wrapper -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>



<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>