import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { EnvSelectionService } from './env-selection.service';

interface SearchResults {
  Message: string;
  result: any;
}


@Injectable({
  providedIn: 'root'
})
export class UserloginServiceService {
  private endPoint: any;

  constructor(private http: HttpClient, private envSelection: EnvSelectionService) {
    this.endPoint = this.envSelection.getEndPoint();
  }
  checkUserLogin(email, appkey) {
    const params = {
      email,
      appkey
    };
    const url = this.endPoint + environment.port + '/auth/users';
    console.log(url,'url');
    return this.http.post<SearchResults>(url, params);
  }
}
