import { Component, OnInit } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { MoreDetailsService } from 'src/app/services/more-details.service';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
import { AuthService } from 'src/app/services/auth.service';
import * as FusionCharts from "fusioncharts";
import { IDetailCellRendererParams, ValueGetterParams, ColumnApi, GridOptions, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-community';
import {
  ColDef,
  FirstDataRenderedEvent,
  GridReadyEvent,
  ServerSideStoreType,
} from 'ag-grid-community';
import * as fileSaver from 'file-saver';
import { ResizeEvent } from 'angular-resizable-element';

import { Chart } from 'chart.js';
import { ViewChekingService } from 'src/app/services/view-cheking.service';
import { EnvSelectionService } from 'src/app/services/env-selection.service';
import 'chartjs-plugin-colorschemes';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-in-transit',
  templateUrl: './in-transit.component.html',
  styleUrls: ['./in-transit.component.scss']
})
export class InTransitComponent implements OnInit {
  start_end_mark = [];

  latlng = [];
  codearrayCustomer = [];
  CodearrayDataCustomer = [];
  CustomerdropdownSettings = {};
  tableCustomerDropdownValues: any


  codearrayCourier_forwarder = [];
  CodearrayDataCourier_forwarder = [];
  Courier_forwarderdropdownSettings = {};
  tableCourier_forwarderDropdownValues: any
  disabled = true;


  codearrayAccount = [];
  CodearrayDataAccount = [];
  AccountdropdownSettings = {};

  dealerData = [];
  selectedDealer = [];
  dealerDropdownSettings = {};

  orderDate: any;

  tableAccountDropdownValues: any
  Accountarray: any = [];
  AccountDisable = true

  Courier_forwarderselectedItems: null;
  AccountselectedItems = [];
  CustomerselectedItems: null;


  widthLatestEvent: any = 370;
  heightLatestEvent: any = 500;
  typeLatestEvent = "doughnut2d";
  dataFormatLatestEvent = "json";
  dataSourceLatestEvent: any = [];

  model = {
    Courier_forwarderselectedItems: null, AccountselectedItems: null, CustomerselectedItems: null
  };

  onRowClickedValue = null;
  columnDefs: any;
  DocumentscolumnDefs: any
  RowData: any;
  DocumentsRowData: any
  startClockValue :any;
  stopClockValue:any
  signature:any
  public detailCellRendererParams: any = {

    detailGridOptions: {
      columnDefs: [
        // { field: 'attachmentHash', headerName: 'Attachment Hash' },
        { field: 'evidenceId', headerName: 'Document Name' },
        {
          field: 'downloadUrl', headerName: 'Download Url',
          onCellClicked: this.downloadUrl.bind(this),
          cellRenderer: function (params) {
            let keyData = params.value;
            let newLink = `<a href="javascript:void(0)" style="
            padding: 0px;
            font-size: 12px;
            color: #007bff;
        ">${keyData}</a>`;
            return newLink;
          }
        },

      ],
      defaultColDef: {
        flex: 1,
      },
    },
    getDetailRowData: function (params) {
      params.successCallback(params.data.attachments);
    },
  } as IDetailCellRendererParams;



  IsColumnsToFit: boolean;
  AgLoad: boolean;

  gridApi: any;
  gridColumnApi: any;
  eventparsing: any = [];
  referencesData: any = [];
  flightLegSequenceData: any = [];
  OE_ED: any = [];
  SideOverlay = false;
  SideOverlay2 = false;
  houseWayBilldetails: any;
  shipmentDetails: any;
  partDetails: any;
  packageDetails: any = [];
  uniqueIDD: any;
  dealerArrayData: any[];
  dealerIdd: any;
  clickedDealerStatus: boolean;
  showNextbutton: boolean = false;
  HAWBresult: any;
  l: number;
  showBackbutton: boolean;
  openReferencesBody: boolean = false;
  openSegmentDetailsBody: boolean = false;
  openDocumentsBody: boolean = false;
  openMapBody: boolean = false;
  masterID: any;
  mwb: any;
  legsArray = [];
  historyData = [];
  userOrg: any;
  transportMode: any;
  icon = {
    url: 'http://developerdrive.developerdrive.netdna-cdn.com/wp-content/uploads/2013/08/ddrive.png',
    scaledSize: { height: 40, width: 40 }
  }
  options: any;
  graphData: any;

  dropdownListAsserId = [];
  selectedItemsAsserId = [];
  dropdownSettingsAsserId = {};

  dropdownListDateFilter = [];
  selectedItemsDateFilter = [];
  dropdownSettingsDateFilter = {};

  codearrayAssertID = [];
  codearrayDateFilter = [];
  CodearrayDataAssertID = [];
  CodearrayDataDateFilter = [];
  tooltipShowDelay = 0;
  MapMarkerClickedValue: string;
  previous = null;

  tempidentifier: any;
  dataSource: any;
  type: string;
  width: string;
  height: string;
  fusionChartsEventData: any;
  DocumentsDataList: any;

  columnApi: ColumnApi = new ColumnApi();

  gridOptionsDocuments: GridOptions = {
    defaultColDef: {
      filter: true,
      sortable: true,
      resizable: true,
    },
  };
  MasterAirwayBillNumber: any;
  groupedFlightEventsDLV: any = []
  groupedFlightEvents: any = [];


  fsuEvent = [];
  fsuResponse: any = [];
  fsu_details: Object;

  routesAndEmissionResp: any;
  mainFreightAirwaybillData: any = [];
  flightData: any = [];

  exceptionsChartOptions;
  exceptionsGraphData;
  showExceptionsGraph: boolean = false;
  last2checkpoints: any = [];
  eventchainv2Resp: any = [];
  deliveredPacakageSign: boolean;

  onResizeEnd(event: ResizeEvent): void {
    console.log(event);
    this.style = {
      width: `${event.rectangle.width}px`,
      height: `${event.rectangle.height}px`
    };
  };

  style: {};
  ForwardingChart: any;

  eventCode: any;
  eventCodeLable: any = 'ALL-shipments'

  rowModelType = 'serverSide';
  paginationPageSize = 20;
  cacheBlockSize = 20;
  serverSideStoreType: ServerSideStoreType = 'partial';
  endPoint: any;


  constructor(
    private requestService: RequestService,
    private messageService: MoreDetailsService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private viewChecking: ViewChekingService,
    private envSelection: EnvSelectionService
  ) {
    this.endPoint = this.envSelection.getEndPoint(); // It returns the current running env related base end point url.

    this.type = "timeseries";
    this.width = "100%";
    this.height = "500";
    this.fsuResponse = [];
    // This is the dataSource of the chart
    this.dataSource = {
      chart: {},
      caption: {
        text: "Temperature Reading"
      },
      subcaption: {
        text: "in °C , <strong>Threshold - 15°C - 20°C</strong> "
      },
      yaxis: [
        {
          plot: [
            {
              value: "Temperature",
              connectnulldata: true,
              style: {
                "plot.null": {
                  "stroke-dasharray": "none",
                  stroke: "#d6d6d6"
                }
              }
            }
          ],
          format: {
            suffix: '°C',
          },
          title: "Temperature"
        }
      ],
      datamarker: [],
      xaxis: {
        plot: "Time",
        timemarker: []
      }
    };

  }

  domLayout = 'autoHeight';
  userRole = environment.userType;
  MDlspOrgId = this.messageService.lspOrgId;
  MD_lspOrgName = this.messageService.lspOrgName;
  MD_CheckpointDescription = this.messageService.checkpointDescription;
  MD_customerOrgName = this.messageService.customerOrgName;

  getRandomColor() {
    var colors = [];
    for (var i = 0; i < 50; i++) {
      var letters = '0123456789ABCDEF'.split('');
      var color = '#';
      for (var x = 0; x < 6; x++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      colors.push(color);
    }
    return colors;
  }


  latestEventChart() {
    let key, value;
    let label: any[] = [];
    let dataset: any[] = [];
    console.log("exceptionsGraphData", this.exceptionsGraphData)
    dataset = Object.entries(this.exceptionsGraphData).map(([label, value]) => ({ label, value }));

    // for ([key, value] of Object.entries(this.exceptionsGraphData)) {
    //   label.push(key);
    //   dataset.push(value);
    // };
    // let finaldata = {
    //   label: label,
    //   value: dataset
    // }
    console.log("fusion", dataset)
    const data = {
      chart: {
        caption: "Latest Tracking Event",
        decimals: "0",
        theme: "fusion",
        "showLegend": "1",
        "interactiveLegend": "1",
        "showpercentvalues": "1",
        "plotHighlightEffect": "fadeout",
        "legendposition": "bottom",
        // "legendWidth": "170",
        // "legendItemFontSize": "9",
        "showLabels": "0",
        "showvalues": "0",
        "showTooltip": "1",
        "enablemultislicing": "0",
        "enableSlicing": "0",
        "palettecolors": "#008ee4",
        // "legendNumRows": "2",
        // "legendNumColumns": "3"
      },
      data: dataset
    };
    console.log("fusionchart", dataset);
    this.dataSourceLatestEvent = data;
  }

  excptionsChartData() {

    let $this = this;
    var ctx = document.getElementById('ForwardingChart');
    let key, value;
    let label: any[] = [];
    let dataset: any[] = [];
    for ([key, value] of Object.entries(this.exceptionsGraphData)) {
      label.push(key);
      dataset.push(value);
    };
    // console.log('graphData:', label, dataset);
    if (this.ForwardingChart) {
      this.ForwardingChart.destroy()
    }
    this.ForwardingChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: label,
        datasets: [{
          data: dataset,
          borderWidth: 1
        }]
      },
      options: {
        layout: {
          padding: 30
        },
        plugins: {
          colorschemes: {
            scheme: 'tableau.Blue20',
          }
        },
        maintainAspectRatio: false,
        responsive: true,
        aspectRatio: 1,
        cutoutPercentage: 60,
        legend: {
          display: true,
          position: 'bottom',
          align: "start",
          fullSize: false,
          labels: {
            font: {
              size: 9
            },
            padding: 6
          },
        },
        title: {
          display: true,
          text: 'Latest Tracking Event'
        },
        onClick: function (e) {
          var activePointLabel = this.getElementsAtEvent(e)[0]._model.label;
          $this.Showtest(activePointLabel);
        }
      }
    });
  }


  GetValueFormatted(value: any) {
    console.log("GetValueFormatted")
  }

  Showtest(clicked: any) {
    console.log("graphHeight border", clicked.split('/')[0]);
    this.eventCode = clicked;
    let text: any = 'All Shipments'
    if (this.messageService.specificDetails !== null && this.messageService.specificDetails !== undefined && this.messageService.specificDetails !== '') {
      if (this.messageService.specificDetails === 'activeOrders') {
        text = "Shipments with update in the last 2 hours"
      }
      else if (this.messageService.specificDetails === 'moreThanADayOrders') {
        text = "Shipments with no update for more than 48 hours"
      }
      else {
        text = "All Shipments"
      }
    }
    else {
      text = "All Shipments"
    }
    this.eventCodeLable = text + ' - ' + clicked
    this.GetTableData();
  }




  basedOnChart(e) {

  }



  fetchData() {

    var dataFetch = this.fusionData();
    setTimeout(() => {
      console.log("dataFetch", dataFetch)
      var schemaFetch = this.fusionSchema();

      console.log("schemaFetch", schemaFetch)
      // console.log("dataFetch", dataFetch)
      Promise.all([dataFetch, schemaFetch]).then(res => {
        const [data, schema] = res;
        // const schema = res;
        // First we are creating a DataStore
        const fusionDataStore = new FusionCharts.DataStore();
        // After that we are creating a DataTable by passing our data and schema as arguments
        const fusionTable = fusionDataStore.createDataTable(data, schema);
        // Afet that we simply mutated our timeseries datasource by attaching the above
        // DataTable into its data property.
        this.xAxisData();
        this.dataSource.data = fusionTable;
        console.log("this.dataSoure", this.dataSource.data)
      });
    }, 1000);
  }


  fusionSchema() {
    let obj = [
      {
        column: "Time",
        format: "%d-%b-%Y %I:%M:%S %p",
        index: 0,
        name: "Time",
        outputAs: undefined,
        type: "date",
        anchorBorderColor: "#0372AB",
        anchorBgColor: "#E1f5ff"
      },
      {
        column: "Temperature",
        index: 1,
        name: "Temperature",
        type: "number",
        anchorBorderColor: "#0372AB",
        anchorBgColor: "#E1f5ff"
      },
      {
        name: "_row_id",
        type: "string"
      }
    ]
    return obj;
  }

  fusionData() {
    this.dataSource.datamarker = [];
    console.log("11")
    let id = 811;
    let data: any = [];
    this.requestService.tracktemps(this.uniqueIDD).subscribe((resp: any) => {
      if (resp.Message === 'Sucess') {
        console.log("if")
        resp.result.sort((a, b) => (b.latest_reading_date > a.latest_reading_date) ? -1 : 1);
        let identifierColor: any
        for (let [i, element] of resp.result.entries()) {
          if (element.latest_reading_temp >= 20) {
            this.tempidentifier = 'H'
            identifierColor = '#ff0000'
          }
          else if (element.latest_reading_temp <= 15) {
            this.tempidentifier = 'L',
              identifierColor = '#ff0000'
          }
          else {
            this.tempidentifier = '',
              identifierColor = '#008000'
          }
          let dataArray: any = [];
          dataArray = [
            moment(element.latest_reading_date.split('.')[0]).format('DD-MMM-YYYY HH:mm:ss A'),
            element.latest_reading_temp,
            i
          ]
          data.push(dataArray);
          let identifierArray = {
            value: "Temperature",
            time: moment(element.latest_reading_date.split('.')[0]).format('DD-MMM-YYYY HH:mm:ss A'),
            type: "pin",
            identifier: '',
            timeformat: "%d-%b-%Y %I:%M:%S %p",
            style: {
              text: {
                "fill": identifierColor,
              },
              marker: {
                "fill": identifierColor,
                "font-weight": "bold",
                "stroke": identifierColor
              }
            }
          }
          this.dataSource.datamarker.push(identifierArray)
        }
        this.graphData = data
        console.log("ifLOOP", data)
        return data;
      }
    })
    return data;

  }

  xAxisData() {
    let data: any = [];
    this.dataSource.xaxis.timemarker = [];
    let id = 811;
    let resp1 = this.fusionChartsEventData;
    // this.requestService.eventChainDetails(this.uniqueIDD).subscribe((resp1: any) => {
    resp1.result.sort((a, b) => (b.sortDate > a.sortDate) ? -1 : 1);
    for (let element1 of resp1.result) {

      let obj = {
        start: moment(element1.sortDate.split('.')[0]).format('DD-MMM-YYYY HH:mm:ss A'),
        label: element1.eventCode + ' - ' + element1.description,
        timeformat: "%d-%b-%Y %I:%M:%S %p"
      }
      data.push(obj);
    }
    this.dataSource.xaxis.timemarker = this.removeDuplicates(data, "label");
    console.log("xaxis", this.dataSource.xaxis.timemarker)
  }


  onFirstDataRendered(params: FirstDataRenderedEvent) {
    // arbitrarily expand a row for presentational purposes
    setTimeout(function () {
      params.api.getDisplayedRowAtIndex(1)!.setExpanded(false);
    }, 0);
  }



  onGridReadyDocuments(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.columnApi.autoSizeAllColumns();
    this.gridApi.sizeColumnsToFit();
  }

  BindData(params) {
    this.gridApi = params.api;
    // var allColumnIds = [];
    // this.gridColumnApi.getAllColumns().forEach(function (column) {
    //   allColumnIds.push(column.colId);
    // });
    // this.gridColumnApi.autoSizeColumns(allColumnIds, false);
    params.columnApi.autoSizeAllColumns();
    this.gridColumnApi = params.columnApi;
    // this.gridApi.getToolPanelInstance('filters').expandFilters();
    var columnState = JSON.parse(localStorage.getItem('myIntransitColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }


  BindDataPagination(params) {
    this.gridApi = params.api;
    params.columnApi.autoSizeAllColumns();
    this.gridColumnApi = params.columnApi;
    let localstorageUserOrg = this.authService.getLoggedUser();
    // this.AgLoad = true;;
    const dis = this;
    let req = null;
    let status = '';
    // console.log("this.messageService.lspOrgId", this.messageService.lspOrgId);
    let filter: any = {};
    let customerdropdownValue = dis.tableCustomerDropdownValues;
    let Courier_forwarderdropdownValue = dis.tableCourier_forwarderDropdownValues;
    // console.log("tableAccountDropdownValues", dis.tableAccountDropdownValues)
    let AccountdropdownValue = dis.tableAccountDropdownValues;
    let dealerDropdownValue = dis.dealerIdd;
    if (localstorageUserOrg.userOrg !== 'NISSAN') {
      filter['emailId'] = [localstorageUserOrg.emailId];
    }
    if (this.messageService.lspOrgId !== undefined) {
      // console.log("this.messageService.lspOrgId", this.messageService.lspOrgId);
      filter['lspOrg'] = this.messageService.lspOrgId;
    }
    if (this.messageService.customerOrgId !== undefined) {
      filter['customerOrg'] = [this.messageService.customerOrgId];
    }
    if (customerdropdownValue !== null && customerdropdownValue !== undefined && Object.keys(customerdropdownValue).length !== 0) {
      // console.log("if")
      filter['customerOrg'] = [customerdropdownValue];
    }
    // console.log("filter", filter)
    if (Courier_forwarderdropdownValue !== null && Courier_forwarderdropdownValue !== undefined && Object.keys(customerdropdownValue).length !== 0) {
      filter['lspOrg'] = [Courier_forwarderdropdownValue];
    }
    if (AccountdropdownValue !== null && AccountdropdownValue !== undefined && AccountdropdownValue.length !== 0) {
      filter['accountCode'] = AccountdropdownValue;
    }
    if (dealerDropdownValue !== null && dealerDropdownValue !== undefined) {
      filter['dealerId'] = [dealerDropdownValue];
    }
    if (this.orderDate !== null && this.orderDate !== undefined && this.orderDate !== '') {
      filter['DATEBETWEEN-orderDate'] = [this.orderDate, this.orderDate];
    }
    if (this.messageService.checkpointDescription !== undefined) {
      if (localstorageUserOrg.userOrg === 'NISSAN') {
        status = this.messageService.checkpointcode
        filter['eventDesc'] = [this.messageService.checkpointDescription];
      }
      else {
        filter['eventGroupId'] = [this.messageService.checkpointcode];

      }
    }
    if (this.messageService.HAWBSearchField !== undefined && this.messageService.HAWBSearchField !== null) {
      filter['houseBillNumber'] = [this.messageService.HAWBSearchField];
    }
    if (this.messageService.originCode !== null && this.messageService.originCode !== undefined && this.messageService.originCode !== '') {
      filter['origin'] = [this.messageService.originCode];
    }

    if (this.messageService.destinationCode !== null && this.messageService.destinationCode !== undefined && this.messageService.destinationCode !== '') {
      filter['destination'] = [this.messageService.destinationCode];
    }

    if (this.messageService.ShipmentReferencesSearchField !== null && this.messageService.ShipmentReferencesSearchField !== undefined && this.messageService.ShipmentReferencesSearchField !== '') {
      filter['LIKE-awbIssuePlace'] = [this.messageService.ShipmentReferencesSearchField];
    }

    if (this.messageService.MOT !== null && this.messageService.MOT !== undefined && this.messageService.MOT !== '') {
      filter['transportMode'] = [this.messageService.MOT];
    }

    if (localstorageUserOrg.userOrg === 'CARGOTEC_FRT') {
      filter['lspOrg'] = ['FREIGHT'];
      status = ''
    }
    if (localstorageUserOrg.userOrg === 'CARGOTEC') {
      filter['lspOrg'] = ['DGF', 'EXPRESS', 'FREIGHT'];
      status = ''
    }

    if (this.eventCode !== null && this.eventCode !== undefined && this.eventCode !== '') {
      filter['eventCode'] = [this.eventCode];
    }
    const currentDate = new Date();
    let fromDate;
    let toDate;
    console.log("this.messageService.specificDetails", this.messageService.specificDetails)
    if (this.messageService.specificDetails !== null && this.messageService.specificDetails !== undefined && this.messageService.specificDetails !== '') {
      if (this.messageService.specificDetails === 'activeOrders') {
        let format = new Date((new Date()).setDate(currentDate.getDate())).setHours(new Date().getHours() - 2)
        fromDate = new Date(format).toISOString()
        let toFormat = new Date((new Date()).setDate(currentDate.getDate()))
        toDate = new Date(toFormat).toISOString();
      }
      else if (this.messageService.specificDetails === 'moreThanADayOrders') {
        fromDate = 1970;
        let format = new Date((new Date()).setDate(currentDate.getDate() - 2))
        toDate = new Date(format).toISOString();

      }
      filter['DATEBETWEEN-checkpointDateTime'] = [fromDate, toDate];
    }
    let datasource: IServerSideDatasource = GetTableDataPagination(status, filter, this.spinner, this);
    // register the datasource with the grid
    this.gridApi.setServerSideDatasource(datasource);
    this.chartAPICallforHM();
  }





  onColumnMoved(params) {
    // console.log("params:::")
    var columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem('myIntransitColumnState', columnState);
  }

  onGridReady(params) {
    var columnState = JSON.parse(localStorage.getItem('myIntransitColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }

  resetState() {
    this.gridColumnApi.resetColumnState();
    // console.log('column state reset');
  }

  getDocumentsTableHeaders() {
    this.DocumentscolumnDefs = [
      { field: 'document_type', headerName: 'Document Type', cellRenderer: 'agGroupCellRenderer' },
      { field: 'document_id', headerName: 'Document ID' },
      { field: 'event_code', headerName: 'Event Code' },
      { field: 'file_name', headerName: 'File Name' },

    ]
  }

  GetAgColumns() {
    let localstorageUserOrg = this.authService.getLoggedUser();
    if (this.MD_customerOrgName === 'CARGOTEC_FRT' || localstorageUserOrg.userOrg === 'CARGOTEC_FRT') {
      this.columnDefs = [
        { headerName: 'Account No', field: 'accountCode', sortable: true },
        {
          headerName: 'Order Code', field: 'HAWB', sortable: true, suppressFiltersToolPanel: true,
          onCellClicked: this.onRowClicked.bind(this),
          // cellRenderer: 'agGroupCellRenderer'
          cellRenderer: function (params) {
            let keyData = params.value;
            let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
            return newLink;
          }

        },
        {
          headerName: 'Customer Reference', field: 'awbIssuePlace', sortable: true, suppressFiltersToolPanel: true,
          onCellClicked: this.onRowClicked.bind(this),
          // cellRenderer: 'agGroupCellRenderer'
          cellRenderer: function (params) {
            let keyData = params.value;
            if (params.data.lspOrgId === 'DGF') {
              keyData = params.data.forwardingShipment
            }
            if (keyData === null) {
              keyData = 'Not Assigned'
            }
            let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
            return newLink;
          }
        },
        { headerName: 'Shipper', field: 'companyName', sortable: true },
        { headerName: 'Customer', field: 'customerOrgName', sortable: true, suppressFiltersToolPanel: true },

        { headerName: 'MAWB', field: 'mawb', sortable: true, suppressFiltersToolPanel: true, hide: true },
        { headerName: 'Checkpoint Date', field: 'latestTrackingTimeStamp', valueFormatter: this.checkPointDateFormate, sortable: true, suppressFiltersToolPanel: true, hide: true },
        // { headerName: 'Invoice Number', field: '', sortable: true, suppressFiltersToolPanel: true },       
        { headerName: 'Origin', field: 'originCode', sortable: true, suppressFiltersToolPanel: true },
        { headerName: 'Destination', field: 'airportOfDestinationCode', sortable: true, suppressFiltersToolPanel: true },
        { headerName: 'Service Type', field: 'incoDescription', sortable: true, suppressFiltersToolPanel: true },
        { headerName: 'Est Delivery', field: 'shipment_eta', sortable: true },
        { headerName: 'Number of Pieces', field: 'quantity', sortable: true, suppressFiltersToolPanel: true },
        { headerName: 'Weight', field: 'totalWeight', sortable: true, valueFormatter: this.DecimalFormate, suppressFiltersToolPanel: true },
        { headerName: 'Volume', field: 'totalVolume', sortable: true, valueFormatter: this.DecimalFormate, suppressFiltersToolPanel: true },
      ];
    }
    else if (this.MD_customerOrgName === 'CARGOTEC' || localstorageUserOrg.userOrg === 'CARGOTEC') {
      this.columnDefs = [
        { headerName: 'Account Code', field: 'accountCode', sortable: true },
        {
          headerName: 'Transport Mode', field: 'transportMode', sortable: true,
        },
        {
          headerName: 'Hawb /Tracking No/Order Code', field: 'HAWB', sortable: true,
          tooltipValueGetter: (params) => params.data.latestTrackingEvent,
          onCellClicked: this.onRowClicked.bind(this),
          // cellRenderer: 'agGroupCellRenderer'
          cellRenderer: function (params) {
            let keyData = params.value;
            let newLink = `<a href="javascript:void(0)" >${keyData}</a>`;
            return newLink;
          }
        },
        {
          headerName: 'Customer Reference', field: 'awbIssuePlace', sortable: true, suppressFiltersToolPanel: true,
          onCellClicked: this.onRowClicked.bind(this),
          // cellRenderer: 'agGroupCellRenderer'
          cellRenderer: function (params) {
            let keyData = params.value;
            if (params.data.lspOrgId === 'DGF') {
              keyData = params.data.forwardingShipment
            }
            if (keyData === null) {
              keyData = 'Not Assigned'
            }
            let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
            return newLink;
          }
        },
        { headerName: 'Shipper', field: 'companyName', sortable: true },
        { headerName: 'Customer/Consignee', field: 'customerOrgName', sortable: true, suppressFiltersToolPanel: true },
        { headerName: 'Origin', field: 'originCode', sortable: true },
        { headerName: 'Destination', field: 'airportOfDestinationCode', sortable: true },
        // { headerName: 'Service Type', field: 'incoDescription', sortable: true },
        // { headerName: 'Inco Term', field: 'incoTerm', sortable: true },
        { headerName: 'Est Delivery', field: 'shipment_eta', sortable: true },
        // { headerName: 'Awb Number', field: 'awbNumber', sortable: true },
        { headerName: 'Number of Pieces/Quantity', field: 'quantity', sortable: true },
        { headerName: 'Weight', field: 'totalWeight', sortable: true, valueFormatter: this.DecimalFormate },
        { headerName: 'Volume', field: 'totalVolume', sortable: true, valueFormatter: this.DecimalFormate },
        { headerName: 'Latest Tracking Event', field: 'latestTrackingEvent', sortable: true },
        // { headerName: 'Latest Tracking Time Stamp', field: 'latestTrackingTimeStamp', valueFormatter: this.checkPointDateFormate, sortable: true },
        // { headerName: 'Event Code', field: 'eventCode', sortable: true },

        // { headerName: 'Company Name', field: 'companyName', sortable: true },
        // { headerName: 'Customer OrgId', field: 'customerOrgId', sortable: true },
        // { headerName: 'Customer OrgName', field: 'customerOrgName', sortable: true },
        // { headerName: 'lspOrg Id', field: 'lspOrgId', sortable: true },
        // { headerName: 'lspOrg Name', field: 'lspOrgName', sortable: true },
        // { headerName: 'Pickup Company Name', field: 'pickupCompanyName', sortable: true },
        // { headerName: 'Delivery Company Name', field: 'deliveryCompanyName', sortable: true },

      ]
    }

    else {
      if (this.userRole === 'Nissan') {

        if (this.messageService.lspOrgId === 'DHLE') {
          this.columnDefs = [
            { headerName: 'Account No', field: 'accountCode', sortable: true },
            { headerName: 'Account Description', field: 'companyName', sortable: true },
            { headerName: 'MAWB', field: 'mawb', sortable: true, suppressFiltersToolPanel: true, hide: true },
            // { headerName: 'Shipment ID', field: 'forwardingShipment', sortable: true, suppressFiltersToolPanel: true },
            // {
            //   headerName: 'Courier/Forwarder', field: 'lspOrgId', sortable: true, suppressFiltersToolPanel: true,
            // cellRenderer: function (params) {
            //   let keyData = params.value;
            //   if (keyData === 'EXPRESS') {
            //     keyData = "DXP"
            //   }
            //   else {
            //     keyData = "DGF"
            //   }
            //   return keyData;
            // }
            // },
            // { headerName: 'Checkpoint Code', field: 'eventCode', sortable: true, suppressFiltersToolPanel: true },
            // { headerName: 'Latest Checkpoint', field: 'latestTrackingEvent', sortable: true, suppressFiltersToolPanel: true },
            { headerName: 'Checkpoint Date', field: 'latestTrackingTimeStamp', valueFormatter: this.checkPointDateFormate, sortable: true, suppressFiltersToolPanel: true, hide: true },
            { headerName: 'Consignee', field: 'customerOrgName', sortable: true, suppressFiltersToolPanel: true },
            { headerName: 'Dealer Id', field: 'ext_dealerId', sortable: true, suppressFiltersToolPanel: true },
            { headerName: 'Dealer Name', field: 'ext_dealerName', sortable: true, suppressFiltersToolPanel: true },
            {
              headerName: 'Dealer Order No', field: 'ext_dealerOrder', sortable: true, suppressFiltersToolPanel: true,
              onCellClicked: this.onRowClickedDealer.bind(this),
              // cellRenderer: 'agGroupCellRenderer'
              cellRenderer: function (params) {
                let keyData = params.value;
                // let newLink = keyData !== null ? `<a href="javascript:void(0)">${keyData}</a>` : '';
                if (keyData !== null) {
                  let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
                  return newLink;
                } else {
                  return '';
                }

              }
            },
            { headerName: 'Indent', field: 'ext_indent', sortable: true, suppressFiltersToolPanel: true },
            { headerName: 'Allocated Parts', field: 'ext_allocated_parts', sortable: true, suppressFiltersToolPanel: true },
            { headerName: 'Part Description', field: 'ext_item_description', sortable: true, suppressFiltersToolPanel: true },
            { headerName: 'Origin', field: 'originCode', sortable: true, suppressFiltersToolPanel: true },
            { headerName: 'Destination', field: 'airportOfDestinationCode', sortable: true, suppressFiltersToolPanel: true },
            {
              headerName: 'HAWB/Tracking No', field: 'HAWB', sortable: true, suppressFiltersToolPanel: true,
              onCellClicked: this.onRowClicked.bind(this),
              // cellRenderer: 'agGroupCellRenderer'
              cellRenderer: function (params) {
                let keyData = params.value;
                let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
                return newLink;
              }

            },
            { headerName: 'Number of Pieces', field: 'quantity', sortable: true, suppressFiltersToolPanel: true },
            { headerName: 'Order Date', field: 'ext_orderDate', sortable: true, suppressFiltersToolPanel: true, valueFormatter: onlydateFormatter, hide: true },
            { headerName: 'Pickup Date', field: 'ext_pickup_date', sortable: true, suppressFiltersToolPanel: true, valueFormatter: onlydateFormatter },
            { headerName: 'Line #', field: 'ext_line_no', sortable: true, suppressFiltersToolPanel: true, hide: true },
            // { headerName: 'Flight number', field: 'flightNumber', sortable: true, suppressFiltersToolPanel: true },
          ];
        }
        else {
          this.columnDefs = [
            { headerName: 'Account No', field: 'accountCode', sortable: true },
            { headerName: 'Account Description', field: 'companyName', sortable: true },
            { headerName: 'MAWB', field: 'mawb', sortable: true, suppressFiltersToolPanel: true, hide: true },
            // { headerName: 'Shipment ID', field: 'forwardingShipment', sortable: true, suppressFiltersToolPanel: true },
            // {
            //   headerName: 'Courier/Forwarder', field: 'lspOrgId', sortable: true, suppressFiltersToolPanel: true,
            // cellRenderer: function (params) {
            //   let keyData = params.value;
            //   if (keyData === 'EXPRESS') {
            //     keyData = "DXP"
            //   }
            //   else {
            //     keyData = "DGF"
            //   }
            //   return keyData;
            // }
            // },
            // { headerName: 'Checkpoint Code', field: 'eventCode', sortable: true, suppressFiltersToolPanel: true },
            // { headerName: 'Latest Checkpoint', field: 'latestTrackingEvent', sortable: true, suppressFiltersToolPanel: true },
            { headerName: 'Checkpoint Date', field: 'latestTrackingTimeStamp', valueFormatter: this.checkPointDateFormate, sortable: true, suppressFiltersToolPanel: true, hide: true },
            { headerName: 'Consignee', field: 'customerOrgName', sortable: true, suppressFiltersToolPanel: true },
            { headerName: 'Origin', field: 'originCode', sortable: true, suppressFiltersToolPanel: true },
            { headerName: 'Destination', field: 'airportOfDestinationCode', sortable: true, suppressFiltersToolPanel: true },
            {
              headerName: 'HAWB/Tracking No', field: 'HAWB', sortable: true, suppressFiltersToolPanel: true,
              onCellClicked: this.onRowClicked.bind(this),
              // cellRenderer: 'agGroupCellRenderer'
              cellRenderer: function (params) {
                let keyData = params.value;
                let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
                return newLink;
              }

            },
            { headerName: 'Number of Pieces', field: 'quantity', sortable: true, suppressFiltersToolPanel: true },
            { headerName: 'Order Date', field: 'ext_orderDate', sortable: true, suppressFiltersToolPanel: true, valueFormatter: onlydateFormatter, hide: true },
            { headerName: 'Pickup Date', field: 'ext_pickup_date', sortable: true, suppressFiltersToolPanel: true, valueFormatter: onlydateFormatter },
            // { headerName: 'Inco Term', field: 'incoTerm', sortable: true, suppressFiltersToolPanel: true },
            // { headerName: 'Service Type', field: 'incoDescription', sortable: true, suppressFiltersToolPanel: true },
            // { headerName: 'Customer Reference No', field: '', sortable: true, suppressFiltersToolPanel: true },
            { headerName: 'Line #', field: 'ext_line_no', sortable: true, suppressFiltersToolPanel: true, hide: true },
            // { headerName: 'Flight number', field: 'flightNumber', sortable: true, suppressFiltersToolPanel: true },
          ];
        }
      }
      if (this.userRole === 'SLB') {
        if (this.messageService.lspOrgId[0] === "ARAMEX" || this.messageService.lspOrgId[0] === 'DGF') {
          this.columnDefs = [
            { headerName: 'Shipment References', field: 'awbIssuePlace', sortable: true },
            { headerName: 'MOT', field: 'transportMode', sortable: true },
            {
              headerName: 'HAWB/Tracking No', field: 'HAWB', sortable: true, suppressFiltersToolPanel: true,
              onCellClicked: this.onRowClicked.bind(this),
              // cellRenderer: 'agGroupCellRenderer'
              cellRenderer: function (params) {
                let keyData = params.value;
                let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
                return newLink;
              }

            },
            { headerName: 'Shipper', field: 'companyName', sortable: true },
            { headerName: 'Consignee', field: 'customerOrgName', sortable: true },
            {
              headerName: 'Origin', colId: 'pickupCountryCode', valueGetter: this.OriginValueGetter.bind(this), sortable: true, suppressFiltersToolPanel: true,
            },
            {
              headerName: 'Destination', colId: 'deliveryCountryCode', valueGetter: this.DestinationValueGetter.bind(this), sortable: true, suppressFiltersToolPanel: true,
            },
            {
              headerName: 'Latest Tracking Event', colId: 'latestTrackingEvent', valueGetter: this.latestTrackingEventValueGetter.bind(this), sortable: true,
            },
            { headerName: 'Checkpoint Date', field: 'latestTrackingTimeStamp', valueFormatter: this.checkPointDateFormate, sortable: true, suppressFiltersToolPanel: true, hide: true },
            { headerName: 'Forwarder', field: 'lspOrgName', sortable: true },
            { headerName: 'Inco Term', field: 'incoTerm', sortable: true, suppressFiltersToolPanel: true },
            { headerName: 'Number of Pieces', field: 'quantity', sortable: true, suppressFiltersToolPanel: true },
            {
              headerName: 'Volume', colId: 'totalVolume', field: 'Volume', sortable: true, filter: false,
              cellRenderer: function (params) {
                let keyData = params.data;
                if (keyData.volumeUnit !== null) {
                  let Volume = Number(keyData.totalVolume).toFixed(3) + ' ' + keyData.volumeUnit
                  return Volume;
                }
                else {
                  let Volume = Number(keyData.totalVolume).toFixed(3)
                  return Volume
                }

              }
            },
            {
              headerName: 'Weight', colId: 'totalWeight', field: 'Weight', sortable: true, filter: false,
              cellRenderer: function (params) {
                let keyData = params.data;
                if (keyData.WeightUnit !== null) {
                  let Weight = Number(keyData.totalWeight).toFixed(3) + ' ' + keyData.WeightUnit
                  return Weight;
                }
                else {
                  let Weight = Number(keyData.totalWeight).toFixed(3)
                  return Weight;
                }

              }
            },
            { headerName: 'Account No', field: 'accountCode', sortable: true },
            { headerName: 'Account Description', field: 'companyName', sortable: true },
            { headerName: 'Carbe', field: 'eventDepartment', sortable: true }
          ];
        }
        else {
          this.columnDefs = [
            { headerName: 'Shipment References', field: 'awbIssuePlace', sortable: true },
            { headerName: 'MOT', field: 'transportMode', sortable: true },
            {
              headerName: 'HAWB/Tracking No', field: 'HAWB', sortable: true, suppressFiltersToolPanel: true,
              onCellClicked: this.onRowClicked.bind(this),
              // cellRenderer: 'agGroupCellRenderer'
              cellRenderer: function (params) {
                let keyData = params.value;
                let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
                return newLink;
              }

            },
            { headerName: 'Shipper', field: 'companyName', sortable: true },
            { headerName: 'Consignee', field: 'customerOrgName', sortable: true },
            {
              headerName: 'Origin', colId: 'pickupCountryCode', valueGetter: this.OriginValueGetter.bind(this), sortable: true, suppressFiltersToolPanel: true,
            },
            {
              headerName: 'Destination', colId: 'deliveryCountryCode', valueGetter: this.DestinationValueGetter.bind(this), sortable: true, suppressFiltersToolPanel: true,
            },
            {
              headerName: 'Latest Tracking Event', colId: 'latestTrackingEvent', valueGetter: this.latestTrackingEventValueGetter.bind(this), sortable: true,
            },
            { headerName: 'Checkpoint Date', field: 'latestTrackingTimeStamp', valueFormatter: this.checkPointDateFormate, sortable: true, suppressFiltersToolPanel: true, hide: true },
            { headerName: 'Forwarder', field: 'lspOrgName', sortable: true },
            { headerName: 'Inco Term', field: 'incoTerm', sortable: true, suppressFiltersToolPanel: true },
            { headerName: 'Number of Pieces', field: 'quantity', sortable: true, suppressFiltersToolPanel: true },
            {
              headerName: 'Volume', field: 'Volume', sortable: true, filter: false,
              cellRenderer: function (params) {
                let keyData = params.data;
                if (keyData.volumeUnit !== null) {
                  let Volume = Number(keyData.totalVolume).toFixed(3) + ' ' + keyData.volumeUnit
                  return Volume;
                }
                else {
                  let Volume = Number(keyData.totalVolume).toFixed(3)
                  return Volume
                }

              }
            },
            {
              headerName: 'Weight', field: 'Weight', sortable: true, filter: false,
              cellRenderer: function (params) {
                let keyData = params.data;
                if (keyData.WeightUnit !== null) {
                  let Weight = Number(keyData.totalWeight).toFixed(3) + ' ' + keyData.WeightUnit
                  return Weight;
                }
                else {
                  let Weight = Number(keyData.totalWeight).toFixed(3)
                  return Weight;
                }

              }
            },
            { headerName: 'Account No', field: 'accountCode', sortable: true },
            { headerName: 'Account Description', field: 'companyName', sortable: true, filter: false },
          ];
        }
      }
      else if (this.messageService.lspOrgId === "DHLE" || this.messageService.lspOrgId === 'DGF') {
        this.columnDefs = [
          { headerName: 'Shipment References', field: 'awbIssuePlace', sortable: true },
          { headerName: 'MOT', field: 'transportMode', sortable: true },
          {
            headerName: 'HAWB/Tracking No', field: 'HAWB', sortable: true, suppressFiltersToolPanel: true,
            onCellClicked: this.onRowClicked.bind(this),
            // cellRenderer: 'agGroupCellRenderer'
            cellRenderer: function (params) {
              let keyData = params.value;
              let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
              return newLink;
            }

          },
          { headerName: 'Shipper', field: 'companyName', sortable: true },
          { headerName: 'Consignee', field: 'customerOrgName', sortable: true },
          {
            headerName: 'Origin', colId: 'pickupCountryCode', valueGetter: this.OriginValueGetter.bind(this), sortable: true, suppressFiltersToolPanel: true,
          },
          {
            headerName: 'Destination', colId: 'deliveryCountryCode', valueGetter: this.DestinationValueGetter.bind(this), sortable: true, suppressFiltersToolPanel: true,
          },
          {
            headerName: 'Latest Tracking Event', colId: 'latestTrackingEvent', valueGetter: this.latestTrackingEventValueGetter.bind(this), sortable: true,
          },
          { headerName: 'ETA', colId: 'shipment_eta', valueGetter: this.shipmentetavalueGetter.bind(this), sortable: true },
          { headerName: 'Checkpoint Date', field: 'latestTrackingTimeStamp', valueFormatter: this.checkPointDateFormate, sortable: true, suppressFiltersToolPanel: true, hide: true },
          { headerName: 'Forwarder', field: 'lspOrgName', sortable: true },
          { headerName: 'Inco Term', field: 'incoTerm', sortable: true, suppressFiltersToolPanel: true },
          { headerName: 'Number of Pieces', field: 'quantity', sortable: true, suppressFiltersToolPanel: true },
          {
            headerName: 'Volume', colId: 'totalVolume', field: 'Volume', sortable: true, filter: false,
            cellRenderer: function (params) {
              let keyData = params.data;
              if (keyData.volumeUnit !== null) {
                let Volume = Number(keyData.totalVolume).toFixed(3) + ' ' + keyData.volumeUnit
                return Volume;
              }
              else {
                let Volume = Number(keyData.totalVolume).toFixed(3)
                return Volume
              }

            }
          },
          {
            headerName: 'Weight', colId: 'totalWeight', field: 'Weight', sortable: true, filter: false,
            cellRenderer: function (params) {
              let keyData = params.data;
              if (keyData.WeightUnit !== null) {
                let Weight = Number(keyData.totalWeight).toFixed(3) + ' ' + keyData.WeightUnit
                return Weight;
              }
              else {
                let Weight = Number(keyData.totalWeight).toFixed(3)
                return Weight;
              }

            }
          },
          { headerName: 'Account No', field: 'accountCode', sortable: true },
          { headerName: 'Account Description', field: 'companyName', sortable: false, filter: false },
        ];
      }
      else {
        this.columnDefs = [
          { headerName: 'Shipment References', field: 'awbIssuePlace', sortable: true },
          { headerName: 'MOT', field: 'transportMode', sortable: true },
          {
            headerName: 'HAWB/Tracking No', field: 'HAWB', sortable: true, suppressFiltersToolPanel: true,
            onCellClicked: this.onRowClicked.bind(this),
            // cellRenderer: 'agGroupCellRenderer'
            cellRenderer: function (params) {
              let keyData = params.value;
              let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
              return newLink;
            }

          },
          { headerName: 'Shipper', field: 'companyName', sortable: true },
          { headerName: 'Consignee', field: 'customerOrgName', sortable: true },
          {
            headerName: 'Origin', colId: 'pickupCountryCode', valueGetter: this.OriginValueGetter.bind(this), sortable: true, suppressFiltersToolPanel: true,
          },
          {
            headerName: 'Destination', colId: 'deliveryCountryCode', valueGetter: this.DestinationValueGetter.bind(this), sortable: true, suppressFiltersToolPanel: true,
          },
          {
            headerName: 'Latest Tracking Event', colId: 'latestTrackingEvent', valueGetter: this.latestTrackingEventValueGetter.bind(this), sortable: true,
          },
          { headerName: 'ETA', field: 'shipment_eta', valueGetter: this.shipmentetavalueGetter.bind(this), sortable: true },
          { headerName: 'Checkpoint Date', field: 'latestTrackingTimeStamp', valueFormatter: this.checkPointDateFormate, sortable: true, suppressFiltersToolPanel: true, hide: true },
          { headerName: 'Forwarder', field: 'lspOrgId', sortable: true },
          { headerName: 'Inco Term', field: 'incoTerm', sortable: true, suppressFiltersToolPanel: true },
          { headerName: 'Number of Pieces', field: 'quantity', sortable: true, suppressFiltersToolPanel: true },
          {
            headerName: 'Volume', colId: 'totalVolume', field: 'Volume', sortable: true, filter: false,
            cellRenderer: function (params) {
              let keyData = params.data;
              if (keyData.volumeUnit !== null) {
                let Volume = Number(keyData.totalVolume).toFixed(3) + ' ' + keyData.volumeUnit
                return Volume;
              }
              else {
                let Volume = Number(keyData.totalVolume).toFixed(3)
                return Volume
              }

            }
          },
          {
            headerName: 'Weight', colId: 'totalWeight', field: 'Weight', sortable: true, filter: false,
            cellRenderer: function (params) {
              let keyData = params.data;
              if (keyData.WeightUnit !== null) {
                let Weight = Number(keyData.totalWeight).toFixed(3) + ' ' + keyData.WeightUnit
                return Weight;
              }
              else {
                let Weight = Number(keyData.totalWeight).toFixed(3)
                return Weight;
              }

            }
          },
          { headerName: 'Startclock Date', field: 'awbIssueDate', valueGetter: this.startClockValueGetter.bind(this) ,sortable: true, filter: false},
        { headerName: 'Stopclock Timestamp', colId:'latestTrackingTimeStamp', valueGetter: this.stopClockvalueGetter.bind(this), sortable: true, filter: false}
        ];
      }
    }


  }

  latestTrackingEventValueGetter(params: ValueGetterParams) {
    return params.data.latestTrackingEvent.substring(params.data.latestTrackingEvent.indexOf('/') + 1);
  }

  shipmentetavalueGetter(params: ValueGetterParams) {

    if (params.data.shipment_eta !== null && params.data.shipment_eta !== undefined && params.data.shipment_eta !== ' ') {
      let keydata = params.data.shipment_eta.split(' ')[0];
      return keydata;
    }

  }

  stopClockvalueGetter(params:ValueGetterParams){
    let keyData
    if(params.data.eventCode === "OK"){
      keyData = moment.utc(params.data.latestTrackingTimeStamp).format('YYYY-MM-DD HH:mm')      
        return keyData;;
    }
    else
    return keyData; 
  }

  startClockValueGetter(params:ValueGetterParams){
    let keyData=params.data;
    keyData = moment.utc(params.data.awbIssueDate).format('YYYY-MM-DD HH:mm');
    return keyData
  }

  OriginValueGetter(params: ValueGetterParams) {
    let keyData = params.data;
    if (keyData.pickupCity !== null) {
      let originCode = keyData.pickupCity + ',' + keyData.pickupCountryCode
      return originCode;
    }
    else {
      let originCode = keyData.pickupCountryCode;
      return originCode
    }
  }
  DestinationValueGetter(params: ValueGetterParams) {
    let keyData = params.data;
    if (keyData.deliveryCity !== null) {
      let airportOfDestinationCode = keyData.deliveryCity + ',' + keyData.deliveryCountryCode
      return airportOfDestinationCode;
    }
    else {
      let airportOfDestinationCode = keyData.deliveryCountryCode;
      return airportOfDestinationCode
    }
  }


  onRowClickedDealer(event: any) {
    // console.log('dealer popup');
    this.clickedDealerStatus = true;
    this.popupData(event.data.houseawbid, event.data.HAWB, event.data.ushipmentid, event.data.ext_dealerId, event.data.ext_dealerOrder);
  }


  customer_dropDown() {
    // console.log("customer_dropDown")
    this.CustomerdropdownSettings = {
      singleSelection: true,
      idField: 'codeCustomer',
      textField: 'codeCustomerName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  onCustomerItemSelect(item) {
    // console.log("item.codeCustomer", item.codeCustomer)
    this.model.CustomerselectedItems = item.codeCustomer
    this.tableCustomerDropdownValues = this.model.CustomerselectedItems;
    this.GetTableData()
    // this.initializeTableData();
    // this.DropdownData();
    // this.disabled = false;
    this.AccountDisable = false;
  }

  onCustomerDeSelect(item) {
    this.tableCustomerDropdownValues = null;
    this.Courier_forwarderselectedItems = null;
    this.AccountselectedItems = null;
    this.tableAccountDropdownValues = null;
    this.tableCourier_forwarderDropdownValues = null
    this.GetTableData();
  }

  onCourier_forwarder_dropDown() {
    this.Courier_forwarderdropdownSettings = {
      singleSelection: true,
      idField: 'codeCourier_forwarder',
      textField: 'codeCourier_forwarderName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }


  onCourier_forwarderItemSelect(item) {
    this.model.Courier_forwarderselectedItems = item.codeCourier_forwarder
    this.tableCourier_forwarderDropdownValues = this.model.Courier_forwarderselectedItems;
    this.GetTableData();
    this.AccountDisable = false;
  }

  onCourier_forwarderDeSelect(item) {
    this.tableCourier_forwarderDropdownValues = null;
    this.GetTableData();
  }

  // -------------------------- Account Dropdown ----------------------- 

  onAccount_dropDown() {
    this.AccountdropdownSettings = {
      singleSelection: false,
      idField: 'codeAccount',
      textField: 'codeAccount',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  AccountItemSelect(item: any) {
    const codeAcc = item.codeAccount.split('-')[0].trim();
    // console.log('codeAcc:', codeAcc);
    this.Accountarray.push(codeAcc);
    // console.log("push", this.Accountarray)
    this.Go();
    // this.model.AccountselectedItems = item.codeAccount
    // this.tableAccountDropdownValues = this.model.AccountselectedItems;
    // this.GetTableData();
  }

  // ----------------------- Dealer Dropdown ------------------------
  dealer_dropown() {
    this.dealerDropdownSettings = {
      singleSelection: true,
      idField: 'dealerId',
      textField: 'dealerId',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  dealerSelect(item: any) {
    this.dealerIdd = item.dealerId.split('-')[0].trim();

  }
  onDealerDeSelect(item: any) {
    this.dealerIdd = null
  }

  Go() {
    // console.log("GOOOOO", this.Accountarray, this.tableAccountDropdownValues);
    if (this.Accountarray.length === 0) {
      // console.log("go:::IF", this.Accountarray)
      this.tableAccountDropdownValues = null;

    }

    else {
      if (this.tableAccountDropdownValues !== null && this.tableAccountDropdownValues !== undefined) {
        // console.log("go:::IF", this.Accountarray)
        this.tableAccountDropdownValues.length === 0;
      }
      else {
        this.tableAccountDropdownValues = this.Accountarray;
      }
    }
    this.spinner.show();
    this.GetTableData();
  }

  dateEvent(e) {
    // console.log('# ', e.target.value);
    // this.orderDate = e.target.value;
  }
  onAccountDeSelect(item) {
    this.Accountarray = [];
    // console.log('item', item)
    // console.log('deleted Item: ', this.AccountselectedItems);
    this.AccountselectedItems.forEach((element: any) => {
      const data = element.codeAccount.split('-')[0].trim();
      this.Accountarray.push(data);
    });
    // this.AccountselectedItems.splice(this.AccountselectedItems.indexOf(item['codeAccount']), 1);
    // console.log("newArray", this.Accountarray);
    this.Go();
  }


  GetTableData() {
    let localstorageUserOrg = this.authService.getLoggedUser();
    // this.AgLoad = true;;
    const dis = this;
    let req = null;
    let status = '';
    // console.log("this.messageService.lspOrgId", this.messageService.lspOrgId);
    let filter: any = {};
    let customerdropdownValue = dis.tableCustomerDropdownValues;
    let Courier_forwarderdropdownValue = dis.tableCourier_forwarderDropdownValues;
    // console.log("tableAccountDropdownValues", dis.tableAccountDropdownValues)
    let AccountdropdownValue = dis.tableAccountDropdownValues;
    let dealerDropdownValue = dis.dealerIdd;
    if (localstorageUserOrg.userOrg !== 'NISSAN') {
      filter['emailId'] = [localstorageUserOrg.emailId];
    }
    if (this.messageService.lspOrgId !== undefined) {
      // console.log("this.messageService.lspOrgId", this.messageService.lspOrgId);
      filter['lspOrg'] = this.messageService.lspOrgId;
    }
    if (this.messageService.customerOrgId !== undefined) {
      filter['customerOrg'] = [this.messageService.customerOrgId];
    }
    if (customerdropdownValue !== null && customerdropdownValue !== undefined && Object.keys(customerdropdownValue).length !== 0) {
      // console.log("if")
      filter['customerOrg'] = [customerdropdownValue];
    }
    // console.log("filter", filter)
    if (Courier_forwarderdropdownValue !== null && Courier_forwarderdropdownValue !== undefined && Object.keys(customerdropdownValue).length !== 0) {
      filter['lspOrg'] = [Courier_forwarderdropdownValue];
    }
    if (AccountdropdownValue !== null && AccountdropdownValue !== undefined && AccountdropdownValue.length !== 0) {
      filter['accountCode'] = AccountdropdownValue;
    }
    if (dealerDropdownValue !== null && dealerDropdownValue !== undefined) {
      filter['dealerId'] = [dealerDropdownValue];
    }
    if (this.orderDate !== null && this.orderDate !== undefined && this.orderDate !== '') {
      filter['DATEBETWEEN-orderDate'] = [this.orderDate, this.orderDate];
    }
    if (this.messageService.checkpointDescription !== undefined) {
      if (localstorageUserOrg.userOrg === 'NISSAN') {
        status = this.messageService.checkpointcode
        filter['eventDesc'] = [this.messageService.checkpointDescription];
      }
      else {
        filter['eventGroupId'] = [this.messageService.checkpointcode];

      }
    }
    if (this.messageService.HAWBSearchField !== undefined && this.messageService.HAWBSearchField !== null) {
      filter['houseBillNumber'] = [this.messageService.HAWBSearchField];
    }
    if (this.messageService.originCode !== null && this.messageService.originCode !== undefined && this.messageService.originCode !== '') {
      filter['origin'] = [this.messageService.originCode];
    }

    if (this.messageService.destinationCode !== null && this.messageService.destinationCode !== undefined && this.messageService.destinationCode !== '') {
      filter['destination'] = [this.messageService.destinationCode];
    }

    if (this.messageService.ShipmentReferencesSearchField !== null && this.messageService.ShipmentReferencesSearchField !== undefined && this.messageService.ShipmentReferencesSearchField !== '') {
      filter['LIKE-awbIssuePlace'] = [this.messageService.ShipmentReferencesSearchField];
    }

    if (this.messageService.MOT !== null && this.messageService.MOT !== undefined && this.messageService.MOT !== '') {
      filter['transportMode'] = [this.messageService.MOT];
    }

    if (localstorageUserOrg.userOrg === 'CARGOTEC_FRT') {
      filter['lspOrg'] = ['FREIGHT'];
      status = ''
    }
    if (localstorageUserOrg.userOrg === 'CARGOTEC') {
      filter['lspOrg'] = ['DGF', 'EXPRESS', 'FREIGHT'];
      status = ''
    }

    if (this.eventCode !== null && this.eventCode !== undefined && this.eventCode !== '') {
      filter['eventDescription'] = [this.eventCode];
    }
    const currentDate = new Date();
    let fromDate;
    let toDate;
    console.log("this.messageService.specificDetails", this.messageService.specificDetails)
    if (this.messageService.specificDetails !== null && this.messageService.specificDetails !== undefined && this.messageService.specificDetails !== '') {
      if (this.messageService.specificDetails === 'activeOrders') {
        let format = new Date((new Date()).setDate(currentDate.getDate())).setHours(new Date().getHours() - 2)
        fromDate = new Date(format).toISOString()
        let toFormat = new Date((new Date()).setDate(currentDate.getDate()))
        toDate = new Date(toFormat).toISOString();
      }
      else if (this.messageService.specificDetails === 'moreThanADayOrders') {
        fromDate = 1970;
        let format = new Date((new Date()).setDate(currentDate.getDate() - 2))
        toDate = new Date(format).toISOString();
      }
      filter['DATEBETWEEN-checkpointDateTime'] = [fromDate, toDate];
    }

    console.log("code-intransit", status, this.messageService.checkpointcode)
    if (!this.view_ver1_ui) {
      req = dis.requestService.List(status, filter);
      req.subscribe(resp => {
        if (resp.Message === 'Sucess') {
          const messageResult = resp['result'];
          // console.log("messageResult", messageResult)
          messageResult.sort((a, b) => (a.latestTrackingTimeStamp > b.latestTrackingTimeStamp) ? -1 : 1);
          this.RowData = messageResult;
          let rows = [];
          for (let parsedMessageResult of messageResult) {
            rows.push(parsedMessageResult);
          }
          let Data: any = [];
          Data.push(...rows);
          this.TableDropdownRows(Data);
          this.spinner.hide();
        } else if (resp.status.code === 'FAILURE') {
          this.spinner.hide();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: resp.status.message,
            // footer: '<a href="">Why do I have this issue?</a>'
          });
        }
      });
    }
    // if (this.userRole === 'HM' || this.userRole === 'APPLE') {
    if (this.view_ver1_ui) { // checking view access, based on enum users list.
      let datasource: any = GetTableDataPagination(status, filter, this.spinner, this);
      // register the datasource with the grid
      this.gridApi!.setServerSideDatasource(datasource);
    }
    // if (this.userRole === 'HM' || this.userRole === 'APPLE') {
    if (this.view_ver1_ui) { // checking view access, based on enum users list.
      this.chartAPICallforHM();
      // }
    }
  }

  chartAPICallforHM() {
    this.spinner.show();
    let filter: any = {};
    let localstorageUserOrg = this.authService.getLoggedUser();
    // this.AgLoad = true;;
    const dis = this;
    let req = null;
    let status = '';
    let customerdropdownValue = dis.tableCustomerDropdownValues;
    let AccountdropdownValue = dis.tableAccountDropdownValues;
    // if (this.messageService.checkpointcode === 80 ||  this.messageService.checkpointcode  === 100) {
    if (localstorageUserOrg.userOrg !== 'NISSAN') {
      filter['emailId'] = [localstorageUserOrg.emailId];
    }
    if (this.messageService.lspOrgId !== undefined) {
      // console.log("this.messageService.lspOrgId", this.messageService.lspOrgId);
      filter['lspOrg'] = this.messageService.lspOrgId;
    }
    if (this.messageService.customerOrgId !== undefined) {
      filter['customerOrg'] = [this.messageService.customerOrgId];
    }
    if (AccountdropdownValue !== null && AccountdropdownValue !== undefined && AccountdropdownValue.length !== 0) {
      filter['accountCode'] = AccountdropdownValue;
    }
    if (customerdropdownValue !== null && customerdropdownValue !== undefined && Object.keys(customerdropdownValue).length !== 0) {
      // console.log("if")
      filter['customerOrg'] = [customerdropdownValue];
    }
    if (this.messageService.originCode !== null && this.messageService.originCode !== undefined && this.messageService.originCode !== '') {
      filter['origin'] = [this.messageService.originCode];
    }

    if (this.messageService.destinationCode !== null && this.messageService.destinationCode !== undefined && this.messageService.destinationCode !== '') {
      filter['destination'] = [this.messageService.destinationCode];
    }

    filter['eventGroupId'] = [this.messageService.checkpointcode];
    req = dis.requestService.getGroupByCounts(filter);
    req.subscribe(resp => {
      if (resp['Message'] === 'Sucess') {
        this.spinner.hide();
        this.exceptionsGraphData = resp.result;
        // this.latestEventChart();
        this.excptionsChartData()
      }
    })
  }






  TableDropdownRows(rows) {
    const dis = this;
    dis.codearrayCustomer = [];
    dis.codearrayCourier_forwarder = [];
    dis.codearrayAccount = [];
    dis.dealerArrayData = [];
    // dis.CodearrayDataCustomer = null;
    for (let i = 0; i < rows.length; i++) {
      const raws = rows[i];
      dis.codearrayCustomer[i] = { codeCustomer: raws['customerOrgId'], codeCustomerName: raws['customerOrgName'] };
      dis.codearrayCourier_forwarder[i] = { codeCourier_forwarder: raws['lspOrgId'], codeCourier_forwarderName: raws['lspOrgName'] };
      dis.codearrayAccount[i] = { codeAccount: raws['accountCode'] + ' - ' + raws['companyName'] };
      if (raws['ext_dealerId'] !== null) {
        dis.dealerArrayData[i] = { dealerId: raws['ext_dealerId'] + ' - ' + raws['ext_dealerName'] };
      }
    }
    let arrayduplicateremoveOrigin = this.removeDuplicates(dis.codearrayCustomer, "codeCustomer");
    dis.CodearrayDataCustomer = arrayduplicateremoveOrigin;
    let arrayduplicateremoveDestination = this.removeDuplicates(dis.codearrayCourier_forwarder, "codeCourier_forwarder");
    dis.CodearrayDataCourier_forwarder = arrayduplicateremoveDestination;
    let arrayduplicateremoveAccount = this.removeDuplicates(dis.codearrayAccount, "codeAccount");
    dis.CodearrayDataAccount = arrayduplicateremoveAccount;
    let arrayduplicateremoveDealer = this.removeDuplicates(dis.dealerArrayData, "dealerId");
    dis.dealerData = arrayduplicateremoveDealer;
  }

  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};
    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    console.log("meee", newArray)
    return newArray;
  }

  base64ToArrayBuffer(base64: any): ArrayBuffer {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  // downloadUrl(event: any) {
  //   console.log("subClicked", typeof event.value);
  //   this.requestService.DocumentBlessAPICall(event.value)
  //     .subscribe((resp: HttpResponse<Blob>) => {
  //       console.log(resp.headers.get('content-disposition'));
  //       // return
  //       let data = resp;
  //       console.log("resp", resp.body)
  //       // let respHeaders = resp.headers.get('content-disposition');
  //       // console.log("respHeaders", resp.headers, resp.body, respHeaders)
  //       // let response = this.base64ToArrayBuffer(data);
  //       let response: any = data;
  //       let file = new Blob([response], { type: 'image/jpg' });
  //       var fileURL = URL.createObjectURL(file);
  //       window.open(fileURL);
  //       // fileSaver.saveAs(fileURL);
  //     });
  // }

  downloadUrl(event: any) {
    // this.requestService.DocumentBlessAPICall()
    //   .subscribe((resp: any) => {
    fileSaver.saveAs(this.endPoint + '/bless/api/v3/messages/attachment/' + event.value)
    // });
  }


  onRowClicked(event: any) {    
    this.CodearrayDataDateFilter = [];
    this.CodearrayDataAssertID = [];
    this.codearrayDateFilter = [];
    this.codearrayAssertID = [];
    this.clickedDealerStatus = false;
    this.startClockValue = moment.utc(event.data.awbIssueDate).format("YYYY-MM-DD HH:mm");
    if(event.data.eventCode ==="OK"){
      this.stopClockValue = moment.utc(event.data.latestTrackingTimeStamp).format("YYYY-MM-DD HH:mm");
    }
    
    // this.onRowClickedValue = event.value;
    // console.log("this.onRowClickedValue", event.data.houseawbid)
    // let def = 'customStats';
    // event.api.openToolPanel(def);
    // this.moreDetails.changeMessage(this.onRowClickedValue);
    this.mwb = event.data.mawb
    let uniqueId = event.data.ushipmentid;
    if (event.data.masterId !== null) {
      this.masterID = event.data.masterId
    }
    else if (event.data.masterId === null) {
      this.masterID = '101' + event.data.ushipmentid + '101'
    }
    this.transportMode = event.data.transportMode;
    console.log("transportMode", this.transportMode)
    this.popupData(event.data.houseawbid, event.data.HAWB, uniqueId, event.data.ext_dealerId, event.data.ext_dealerOrder)
    // $("#newModal2").modal("show");

  }

  popupData(houseAwb, hawb, uniqueId, dealerId, dealerOrder) {
    this.CodearrayDataDateFilter = [];
    this.CodearrayDataAssertID = [];
    const dis = this;
    let req = null;
    if (this.clickedDealerStatus) {
      let req1 = {
        dealerId, dealerOrder
      }
      this.spinner.show();
      req = dis.requestService.popup(req1);
      req.subscribe(resp => {
        this.HAWBresult = resp.result;
        // console.log("result1::::::", this.HAWBresult)
        for (let i = 0; i < this.HAWBresult.length; i++) {
          if (this.HAWBresult.length === 1) {
            // console.log('# ', this.showNextbutton);
            this.onRowClickedValue = this.HAWBresult[0];
            this.uniqueIDD = this.HAWBresult[0].ushipmentid
            uniqueId = this.uniqueIDD;
          } else if (this.HAWBresult.length > 1) {
            this.showNextbutton = true;
            this.showBackbutton = false;
            this.l = 1
            // console.log('## ', this.showNextbutton);
            this.onRowClickedValue = this.HAWBresult[0];
            this.uniqueIDD = this.HAWBresult[0].ushipmentid
            uniqueId = this.uniqueIDD;
          }
        }
        // console.log("messageResult", this.onRowClickedValue)
        dis.eventparsing = [];
        this.eventchainloop(uniqueId);
        if (this.userOrg !== 'NISSAN') {
          this.referencesEvent(uniqueId);
          this.mapData();
        }
        this.SideOverlay = true;
        if (this.userRole !== 'Cargotec' && this.userOrg !== 'NISSAN') {
          this.fetchData();
        }
        // this.spinner.hide();
      });
    } else {
      this.uniqueIDD = uniqueId;
      let req2 = {
        houseAwb, hawb
      }
      this.spinner.show();
      req = dis.requestService.popup(req2);
      req.subscribe(resp => {
        let result = resp.result;
        // console.log("result::::::", result)
        for (let i = 0; i < result.length; i++) {
          if (i === 0) {
            this.onRowClickedValue = result[0];
          }
        }
        // console.log("messageResult", this.onRowClickedValue)
        dis.eventparsing = [];
        this.eventchainloop(uniqueId);
        if (this.userOrg !== 'NISSAN') {
          this.referencesEvent(uniqueId)
          this.mapData();
        }
        if (this.userRole !== 'Cargotec' && this.userOrg !== 'NISSAN') {
          this.fetchData();
        }
        this.SideOverlay = true;
        // this.spinner.hide();
      });
    }

  }

  referencesEvent(uniqueId) {
    const dis = this;
    let req = null;
    req = this.requestService.referencesMoreDetails(uniqueId);
    req.subscribe(resp => {
      dis.referencesData = resp.result;
    });
  }

  referencesClicked() {
    this.openReferencesBody = !this.openReferencesBody;
  }

  SegmentDetailsClicked() {
    this.openSegmentDetailsBody = !this.openSegmentDetailsBody;
  }

  mapClicked() {
    this.latlng = [];
    this.openMapBody = !this.openMapBody;
    this.mapData()
  }


  asserIDDropdownMethod() {
    this.dropdownSettingsAsserId = {
      singleSelection: true,
      text: "Select Asset/Container",
      enableSearchFilter: true,
    };
  }

  onItemSelectAssertID(item: any) {
    // console.log(item.id);
    // this.selectedItemsAsserId = item.id;
    this.trackTempDetails()
  }
  OnItemDeSelectAssertID(item: any) {
    // console.log(item);
    this.selectedItemsAsserId = [];
    this.trackTempDetails();
  }

  DateFilterDropdownMethod() {
    this.dropdownSettingsDateFilter = {
      singleSelection: true,
      text: "Select Date",
      enableSearchFilter: true,
    };
  }

  onItemSelectDateFilter(item: any) {
    // console.log(item.id);
    // this.selectedItemsDateFilter = item.itemName;
    this.trackTempDetails();
  }
  OnItemDeSelectDateFilter(item: any) {
    // console.log(item);
    this.selectedItemsDateFilter = [];
    this.trackTempDetails();
  }


  trackTempClicked() {
    this.fetchData();
    // this.selectedItemsAsserId = [];
    // this.selectedItemsDateFilter = [];
  }

  trackTempDetails() {
    // console.log('this.graphData1111::::::', typeof this.graphData)

    const dis = this;
    this.requestService.tracktemps(this.uniqueIDD).subscribe((resp: any) => {
      if (resp.Message === 'Sucess') {
        // console.log("tracktemp:::", this.selectedItemsDateFilter, this.selectedItemsDateFilter.length);
        if (this.selectedItemsDateFilter.length !== 0) {
          // console.log("if");
          let dateFilter: any = [];
          for (let data of resp['result']) {
            // console.log("data:::", data['latest_reading_date'])
            if (data['latest_reading_date'].includes(this.selectedItemsDateFilter[0]['itemName'])) {
              // console.log("if1:::");
              dateFilter.push(data);
              // console.log("dateFilter", dateFilter)
            }
          }
          this.graphData = dateFilter;
        }
        else if (this.selectedItemsAsserId.length !== 0) {
          let assertFilter: any = [];
          for (let data of resp['result']) {
            if (data.ship_asset_id.includes(this.selectedItemsAsserId[0]['itemName'])) {
              assertFilter.push(data);
            }
          }
          this.graphData = assertFilter
        }
        else {
          this.CodearrayDataDateFilter = [];
          this.CodearrayDataAssertID = [];
          this.codearrayDateFilter = [];
          this.codearrayAssertID = [];
          this.graphData = resp['result'];
          this.graphData.sort((a, b) => (a.latest_reading_date > b.latest_reading_date) ? -1 : 1);
          for (let i = 0; i < this.graphData.length; i++) {
            const raws = this.graphData[i];
            dis.codearrayAssertID[i] = { id: raws['ship_asset_id'], itemName: raws['ship_asset_id'] };
            dis.codearrayDateFilter[i] = { id: raws['latest_reading_date'], itemName: raws['latest_reading_date'].split('T')[0] };
          }
          let arrayduplicateremoveAsserID = this.removeDuplicates(dis.codearrayAssertID, "id");
          dis.CodearrayDataAssertID = arrayduplicateremoveAsserID;
          let arrayduplicateremoveDateFilter = this.removeDuplicates(dis.codearrayDateFilter, "itemName");
          dis.CodearrayDataDateFilter = arrayduplicateremoveDateFilter;
          // console.log("CodearrayDataAssertID,CodearrayDataDateFilter", dis.CodearrayDataAssertID, dis.CodearrayDataDateFilter)
        }
        // console.log('this.graphData::::::', typeof this.graphData)
        this.chartData();

      }
    })
  }




  chartData() {
    this.graphData.sort((a, b) => (b.latest_reading_date > a.latest_reading_date) ? -1 : 1);
    for (let data of this.graphData) {
      data.latest_reading_temp = parseFloat(data.latest_reading_temp);
      if (this.selectedItemsDateFilter.length === 0) {
        data.latest_reading_date = data.latest_reading_date.split('T')[0]
        let date = new Date(data.latest_reading_date);
        let format = moment(date).format("YY-MM-DD");
        data.latest_reading_date = format;
      }
      else if (this.selectedItemsDateFilter.length !== 0) {
        let formatDate = data.latest_reading_date.split('T')[1];
        data.latest_reading_date = formatDate.split('.')[0];
      }
    }
    this.options = {
      title: {
        text: 'Temperature Reading in °C',
        fontWeight: 'bold'
      },
      autoSize: true,
      data: this.graphData,

      series: [
        {
          xKey: 'latest_reading_date',
          yKey: 'latest_reading_temp',
        },
      ],
      legend: {
        enabled: false
      },
      axes: [
        {
          type: 'category',
          position: 'bottom',
          title: {
            text: 'Event - Timestamp',
            enabled: true
          },
          label: {
            rotation: 90
          },
        },
        {
          type: 'number',
          position: 'left',
          title: {
            text: 'in Celsius',
            enabled: true,
          },

        }
      ]
    };
    // console.log("chartDate", this.options)

  }

  mapData() {
    this.latlng = [];
    this.start_end_mark = [];
    this.legsArray = [];
    this.previous = null;
    this.spinner.show();
    this.requestService.moreDetailsMapData(this.masterID).subscribe((resp: any) => {
      if (resp.Message === 'Sucess') {
        this.spinner.hide();
        // console.log("mapData:::", resp['result']);
        this.flightLegSequenceData = resp['result']
        if (resp['result'].length !== 0) {
          let lastCode = resp['result'][resp['result'].length - 1]['destination_code']
          for (let loop of resp['result']) {
            this.latlng.push([+loop.orig_latitude, +loop.orig_longitude])
            this.latlng.push([+loop.dest_latitude, +loop.dest_longitude])
            if (resp['result'].length === 1) {
              // console.log("if:::::1")
              this.legsArray.push(loop.origin_code, loop.destination_code)
              // console.log("if:::::Result", this.legsArray)
            } else if (resp['result'].length > 1) {
              // console.log("ELSE if:::::2")
              this.legsArray.push(loop.origin_code);
              if (resp['result'].length - 1 === resp['result'].indexOf(loop)) {
                this.legsArray.push(lastCode)
              }
              // console.log("ELSE if:::::Result", this.legsArray)

            }
          }
          // console.log('array', this.latlng);
          for (let i = 0; i < this.latlng.length; i++) {
            if (i === 0) {
              let icon = {
                url: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ffcd36',
                scaledSize: {
                  width: 30,
                  height: 45
                }
              }
              this.latlng[i].push(icon)

            }
          }
          this.start_end_mark.push(this.latlng[0]);
          this.start_end_mark.push(this.latlng[this.latlng.length - 1]);
          // console.log("this.start_end_mark", this.start_end_mark)
        }
        else {
          this.latlng = [];
        }
      }

    })

  }

  nextHAWB() {

    if (this.HAWBresult.length > this.l) {
      this.l++
      // console.log('# ', this.l);
      this.onRowClickedValue = this.HAWBresult[this.l - 1];
      this.uniqueIDD = this.onRowClickedValue.ushipmentid
      this.eventparsing = [];
      this.eventchainloop(this.uniqueIDD);
    }
    if (this.l === this.HAWBresult.length) {
      this.showNextbutton = false;
      this.showBackbutton = true;
    }
  }

  backHAWB() {
    if (this.l > 1) {
      this.l--
      console.log('# ', this.l);
      this.onRowClickedValue = this.HAWBresult[this.l - 1];
      this.uniqueIDD = this.onRowClickedValue.ushipmentid
      this.eventparsing = [];
      this.eventchainloop(this.uniqueIDD);
    }
    if (this.l == 1) {
      this.showNextbutton = true;
      this.showBackbutton = false;
    }
  }

  openHWABdetails() {
    this.packageDetails = [];
    this.spinner.show();
    this.requestService.airWayBillDetails(this.onRowClickedValue.houseawbid, this.onRowClickedValue.HAWB, true).subscribe((resp: any) => {
      // console.log('hawbill details:', resp);
      this.spinner.hide();
      if (resp.Message === 'Sucess') {
        this.houseWayBilldetails = resp.result.houseBills[0];
        if (this.houseWayBilldetails.pickupAddressOverride !== null) {
          let timeHrs = this.houseWayBilldetails.pickupAddressOverride.split(':')[0];
          let timeMin = this.houseWayBilldetails.pickupAddressOverride.split(':')[1];
          let fullTime = timeHrs + ':' + timeMin;
          this.houseWayBilldetails.pickupAddressOverride = fullTime.replace('T', ' ');
        }
        console.log("this.messageService.lspOrgId", this.messageService.lspOrgId)
        if (this.messageService.lspOrgId[0] === "DHLE" || this.messageService.lspOrgId[0] === 'DGF') {
          this.houseWayBilldetails.pickupAccountCode = this.houseWayBilldetails.pickupAccountCode
          this.houseWayBilldetails.deliveryAccountCode = this.houseWayBilldetails.deliveryAccountCode
        }
        else {
          this.houseWayBilldetails.pickupAccountCode = ''
          this.houseWayBilldetails.deliveryAccountCode = ''
        }
        this.shipmentDetails = resp.result.shipment[0];
        this.shipmentDetails.weight = Number(this.shipmentDetails.weight).toFixed(3);
        this.shipmentDetails.volume = Number(this.shipmentDetails.volume).toFixed(3);
        for (let data of resp.result.packages) {
          if (data.commodity !== null || data.container_number !== null || data.package_count !== null) {
            this.packageDetails.push(data);
            console.log(this.packageDetails, this.packageDetails.length)
          }
          else {
            this.packageDetails = [];
          }
        }

        this.partDetails = resp.result.partDetails;
      }
      else {
        alert(resp.Message);
      }
    });

    this.SideOverlay = false;
    this.SideOverlay2 = true;
  }

  closeHAWBdetails() {
    this.SideOverlay = true;
    this.eventparsing = [];
    this.eventchainloop(this.uniqueIDD);
    this.SideOverlay2 = false;
  }


  clear() {
    this.tableAccountDropdownValues = [];
    this.CustomerselectedItems = null;
    this.tableCourier_forwarderDropdownValues = null;
    this.Courier_forwarderselectedItems = null;
    this.tableCustomerDropdownValues = null;
    this.AccountselectedItems = null;
    this.tableAccountDropdownValues = []
    // this.Accountarray = null;
    this.dealerIdd = null;
    this.orderDate = ''
    // this.dealerData = null;
    this.selectedDealer = null;
    this.disabled = true;
    this.AccountDisable = true;
    this.spinner.show();
    this.eventCode = null;
    this.eventCodeLable = 'ALL'
    this.GetTableData();
  }


  routesAndEmissionClicked() {

    this.requestService.carbEgetShipmentRoutesAndEmissionData(this.uniqueIDD).subscribe((resp: any) => {
      let data = resp['result'][0]['routes_emissions'];
      this.routesAndEmissionResp = data;
      this.mainFreightAirwaybillData = data['mainFreight']['AirwayFlightDetails'];
      this.flightData = data['mainFreight']['FlightDetails']
    });
  }


  eventchainloopV2(uniqueId) {

    this.eventchainv2Resp = [];
    const dis = this;
    let req = null;
    req = this.requestService.eventChainDetailsV2(uniqueId);
    req.subscribe((resp: any) => {
      console.log("resp", resp);
      if(resp.result['LAST-MILE DELIVERY']){
        this.deliveredPacakageSign = true
        let events = resp.result['LAST-MILE DELIVERY'].events;
        events.forEach((ele)=>{
          if(ele.eventCode == "OK" ){
            this.signature = ele.signature
          }
        })
      }
      const objArray = [];
      Object.keys(resp['result']).forEach(key => objArray.push({
        name: key,
        result: resp['result'][key]
      }));
      console.log("objArray", objArray);
      for (let data of objArray) {
        for (let data1 of data['result']['events']) {
          let date = new DatePipe('en-US').transform(data1.sortDate, 'dd-MMM-YYYY HH:mm', 'UTC');
          data1['sortDate'] = date
        }
      }
      this.eventchainv2Resp = objArray;
      
      console.log("eventchainv2Resp", this.eventchainv2Resp);
      document.getElementById('reverseCountV2').style.setProperty('--ulLength', dis.eventchainv2Resp.length + 1);
      this.spinner.hide();
      // let ul = document.querySelector('li')
      // ul.style.setProperty('--ulLength', '10')
    })
  }





  async eventchainloop(uniqueId) {
    const dis = this;
    let raws;
    let req = null;
    dis.eventparsing = [];
    dis.OE_ED = [];
    dis.fsuEvent = [];
    //if (this.userRole === 'HOLOGIC' || this.userRole === 'Cargotec') {
      //await this.getFsuDataOnRowClicked();
    //}
    req = this.requestService.eventChainDetails(uniqueId);
    req.subscribe(resp => {
      this.fusionChartsEventData = resp
      let messageResult = resp['result'];         
      messageResult = [...messageResult, ...dis.fsuEvent]
      console.log('messageResult', messageResult);
      // messageResult.forEach((data)=>{
      //   if(data.eventCode === "OK"){
      //     this.signature = data.signature;
      //   }
      // })
      for (let i = 0; i < messageResult.length; i++) {
        raws = messageResult[i];

        if (raws.sortDate !== null && raws.sortDate.includes("T")) {
          let date = raws.sortDate.split('T')[0];
          let time = raws.sortDate.split('T')[1];
          let hrs = time.split(':')[0];
          let min = time.split(':')[1];
          let finalTime = hrs + ':' + min
          raws.sortDate = date + ' ' + finalTime
        }
        if (raws.estimatedDate !== null && raws.estimatedDate.includes("T")) {

          let hrs = raws.estimatedDate.split(':')[0];
          let min = raws.estimatedDate.split(':')[1];
          raws.estimatedDate = hrs + ':' + min;
        }
        if (raws.estimatedDate === null) {
          raws.estimatedDate = ""
        }

        if (raws.sortDate !== null) {
          dis.eventparsing.push(raws);
        }
        else if (raws.sortDate === null && raws.estimatedDate !== null) {
          if (raws.description === 'Departed') {
            raws.description = 'Departure'
          }
          else if (raws.description === 'Arrived') {
            raws.description = 'Arrival'
          }
          dis.OE_ED.push(raws);
        }
        console.log("length", dis.eventparsing.length)
        this.last2checkpoints = []
        if (dis.eventparsing.length > 1) {
          console.log("IF:::length", dis.eventparsing.length)
          this.last2checkpoints.push(dis.eventparsing[0])
          this.last2checkpoints.push(dis.eventparsing[1])
        }
        else if (dis.eventparsing.length === 1) {
          console.log("else:::length", dis.eventparsing.length)
          this.last2checkpoints.push(dis.eventparsing[0])
        }

      }
      // console.log('-----------------------------::::', dis.eventparsing.length);
      // document.getElementById('reverseCount').style.setProperty('--ulLength', dis.eventparsing.length + 1);
      // let ul = document.querySelector('li')
      // ul.style.setProperty('--ul-length', '10')
      if (this.view_ver1_ui) {
        this.eventchainloopV2(uniqueId)
      }
    });
  }




  closemodal() {
    // console.log("close")
    this.SideOverlay = false;
    this.selectedItemsDateFilter = [];
    this.selectedItemsAsserId = []
  }

  saveState() {
    // this.gridColumnApi.getColumnState();
    var columnState = JSON.stringify(this.gridColumnApi.getColumnState());
    // localStorage.setItem('myColumnState', columnState);
    sessionStorage.setItem('savedState', columnState);
    // console.log('column state saved');
  }


  eta_etd_serviceCode_history(shipId) {
    this.historyData = []
    // console.log("eta_etd_serviceCode_history")
    const dis = this;
    let req = null;
    req = dis.requestService.eta_etd_serviceCode_history(shipId);
    req.subscribe(resp => {
      for (let data of resp['result']) {
        if (data.eta !== null) {
          let DateTime = data.eta.split('.')[0];
          DateTime = DateTime.split(':')[0];
          DateTime = DateTime + ':' + data.eta.split(':')[1];
          data.eta = DateTime.replace('T', ' ');
        }
        if (data.etd !== null) {
          let DateTime = data.etd.split('.')[0];
          DateTime = DateTime.split(':')[0];
          DateTime = DateTime + ':' + data.etd.split(':')[1];
          data.etd = DateTime.replace('T', ' ');
        }
      }
      this.historyData = resp['result']
    });
  }

  defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    // filter: true,
    searchable: true,
    floatingFilter: true,
    resizable: true,
    autoHeight: true
  };

  sideBar = {
    hiddenByDefault: false,
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          allowDragFromColumnsToolPanel: true
        },
      },
      // {
      //   id: 'filters',
      //   labelDefault: 'Shipper Account',
      //   labelKey: 'filters',
      //   iconKey: 'filter',
      //   toolPanel: 'agFiltersToolPanel',
      // }
    ]
  }

  gridOptions: any


  clickedMarker(i, infowindow) {
    console.log("iiii", i);
    if (i === 0) {
      this.MapMarkerClickedValue = 'Origin'
    }
    if (i === 1) {
      this.MapMarkerClickedValue = 'Destination'
    }

    if (this.previous === infowindow) {
      console.log("1111", infowindow)
      return;
    }

    if (this.previous !== null) {
      console.log("2222", infowindow)
      this.previous.close();
    }
    this.previous = infowindow;
  }

  filter_ver1_ui: any;
  view_ver1_ui: any;
  ngOnInit(): void {
    this.filter_ver1_ui = this.viewChecking.filterChecking(); // checking filter access based on user list.
    this.view_ver1_ui = this.viewChecking.viewCheking(); // checking view access based on user list.

    this.fsuEvent = [];
    this.customer_dropDown();
    this.onCourier_forwarder_dropDown();
    this.onAccount_dropDown();
    this.dealer_dropown();
    // $('#date').datepicker();
    let localstorageUserOrg = this.authService.getLoggedUser();
    this.userOrg = localstorageUserOrg.userOrg
    // if (this.userRole === 'HM' || this.userRole === 'APPLE') {
    if (this.view_ver1_ui) { // checking view access, based on enum users list.
      this.showExceptionsGraph = true
    }
    // if (this.userRole === 'HM' || this.userRole === 'APPLE') {
    if (this.view_ver1_ui) { // checking view access, based on enum users list.
      this.gridOptions = {
        columnDefs: this.GetAgColumns(),
      }
    }
    else {
      this.gridOptions = {
        rowData: this.GetTableData(),
        columnDefs: this.GetAgColumns(),
      }
    }

    console.log("eventCodeLable", this.eventCodeLable)
    if (this.messageService.specificDetails !== null && this.messageService.specificDetails !== undefined && this.messageService.specificDetails !== '') {
      if (this.messageService.specificDetails === 'activeOrders') {
        this.eventCodeLable = "Shipments with update in the last 2 hours"
      }
      else if (this.messageService.specificDetails === 'moreThanADayOrders') {
        this.eventCodeLable = "Shipments with no update for more than 48 hours"
      }
      else {
        this.eventCodeLable = "All Shipments"
      }
    }
    else {
      this.eventCodeLable = "All Shipments"
    }

  }



  getFsuDataOnRowClicked() {
    let temp = this.requestService.getFsuData(this.onRowClickedValue.HAWB);
    temp.subscribe((resp) => {
      if (resp["result"].length != 0) {
        this.fsuResponse = resp["result"];
        console.log(resp);
        this.MasterAirwayBillNumber = resp["result"].MasterAirwayBillNumber;
        this.groupedFlightEventsDLV = resp["result"].groupedFlightEventsDLV;
        this.groupedFlightEvents = resp["result"].groupedFlightEvents;
        this.groupedFlightEventsDLV[0].events.forEach((el) => {
          this.fsuEvent.push({
            actualDate: null,
            description: el.checkpointCode,
            estimatedDate: null,
            eventCode: null,
            eventGroup: null,
            eventGroupOderId: null,
            id: null,
            location: null,
            sequence: null,
            sortDate: el.checkpointDatetime,
          });
        });
        for (let data of this.groupedFlightEvents) {
          for (let val of data.events) {
            val["checkpointDatetime"] = moment(val["checkpointDatetime"]).format(
              "DD MMM / hh:mm"
            );
            if (val.checkpointCode == "BKD") {
              val["imageSRC"] = "./assets/images/fsu_images/booking.png";
            } else if (val.checkpointCode == "RCS") {
              val["imageSRC"] = "./assets/images/fsu_images/received.png";
            } else if (val.checkpointCode == "DEP") {
              val["imageSRC"] = "./assets/images/fsu_images/departure.png";
            } else if (val.checkpointCode == "ARR") {
              val["imageSRC"] = "./assets/images/fsu_images/arrival.png";
            } else if (val.checkpointCode == "NFD") {
              val["imageSRC"] = "./assets/images/fsu_images/notified.png";
            } else if (val.checkpointCode == "DLV") {
              val["imageSRC"] = "./assets/images/fsu_images/delivered.png";
            } else {
              val["imageSRC"] = "./assets/images/fsu_images/default.png";
            }
          }
        }
      }
    });
  }


  dateFormate(value: any): any {
    if (value !== null) {
      return moment(value).format('YYYY-MM-DD HH:mm');
    }
  }

  checkPointDateFormate(value: any): any {
    if (value !== null) {
      return moment.utc(value.value).format('YYYY-MM-DD HH:mm');
    }
  }

  DecimalFormate(value: any): any {
    if (value !== null) {
      var num = new Number(value.value);
      return (Math.round(value.value * 100) / 100).toFixed(3);;
    }
  }

  DocumentsClicked() {
    // this.DocumentscolumnDefs = [
    //   { field: 'document_type', headerName: 'Document Type' },
    // ]
    this.openDocumentsBody = !this.openDocumentsBody;
    this.requestService.DocumentsAPI(this.uniqueIDD).subscribe((resp: any) => {
      this.DocumentsRowData = resp['result'];
      this.getDocumentsTableHeaders();
    })
  }

  documentsClicked(data: any) {
    console.log("documentData....", data)
  }

  bindFSUData(value: any) {
    console.log("Inside FSU bind Function");
  }

  // datePickerOPen() {
  // //   focus: =>(){
  // //     datepicker.show();
  // // }
  //   $('#date').datepicker('show');
  // }

}



function dateFormatter(params: any): any {
  if (params.value !== null) {
    return moment(params.value).format('YYYY-MM-DD HH:mm')
  }
}

function onlydateFormatter(params): any {
  if (params.value !== null) {
    return moment(params.value).format('YYYY-MM-DD')
  }
}

function GetTableDataPagination(status: string, filter: any, spinner: NgxSpinnerService, dis: any) {
  return {
    getRows: (params: IServerSideGetRowsParams) => {
      spinner.show();
      let colFilter = {};
      console.log('Grid params:', params.request);
      let req: any = dis.requestService.getShipmentWithPagination(filter, status, null, null, colFilter, params.request);
      // adding delay to simulate real server call
      setTimeout(function () {
        req.subscribe(resp => {
          console.log("resp.Message", resp.Message);
          //console.log("resp:", JSON.stringify(resp));
          if (resp.Message === 'Sucess') {
            console.log("resp", resp);
            const messageResult = resp.result["rows"];
            console.log("lastRow", resp.lastRow);
            messageResult.sort((a, b) => (a.latestTrackingTimeStamp > b.latestTrackingTimeStamp) ? -1 : 1);

            // call the success callback
            params.success({
              rowData: messageResult,
              rowCount: resp.result['count'],
            });

            let rows = [];
            for (let parsedMessageResult of messageResult) {
              rows.push(parsedMessageResult);
            }
            let Data: any = [];
            Data.push(...rows);
            dis.TableDropdownRows(Data);
          } else if (resp.status.code === 'FAILURE') {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: resp.status.message,
              // footer: '<a href="">Why do I have this issue?</a>'
            });
            params.fail();
          }
          spinner.hide();
        }, 200);
      });
    },
  };
}



// $('#date').click(function () {
//   $('#date').datepicker('show');
// });