import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth.service';
import { MoreDetailsService } from 'src/app/services/more-details.service';
import { RequestService } from 'src/app/services/request.service';
import 'ag-grid-enterprise'
import {
  AgGridEvent,
  ColDef,
  ExcelExportParams,
  GridApi,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  ServerSideStoreType,
  ValueGetterParams
} from 'ag-grid-community';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-pnpsearch',
  templateUrl: './pnpsearch.component.html',
  styleUrls: ['./pnpsearch.component.scss']
})
export class PNPSearchComponent implements OnInit {
  userType = null;

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  domLayout = 'autoHeight';
  RowData: any;
  SideOverlay = false;

  onRowClickedValue = null;
  eventparsing: any = [];

  // rowModelType = 'serverSide';
  // paginationPageSize = 20;
  // cacheBlockSize = 20;
  // serverSideStoreType: ServerSideStoreType = 'partial';
  latlng: any = [];
  start_end_mark: any = [];

  icon = {
    url: 'http://developerdrive.developerdrive.netdna-cdn.com/wp-content/uploads/2013/08/ddrive.png',
    scaledSize: { height: 40, width: 40 }
  }

  HAWBSearchField: any = null;



  dropdownListCustomerOrg = [];
  dropdownListOrigin = [];
  dropdownListDestination = [];
  dropdownListLsp = [];
  selectedItemsCustomerOrg = [];
  selectedItemsOrigin = [];
  selectedItemsDestination = [];
  selectedItemsLsp = [];
  dropdownSettingsDestination = {};
  dropdownSettingsOrigin = {};
  customerOrgdropdownSettings = {};
  LspdropdownSettings = {};
  itemcustomerOrgId: any;
  itemoriginCode: any;
  itemDestinatioCode: any;
  itemLsP: any;
  disableOrigin: boolean = true;
  disableDestination: boolean = true;


  searchOriginID: any;
  searchDestinationID: any;

  constructor(private requestService: RequestService, private messageService: MoreDetailsService, private spinner: NgxSpinnerService,
    private authService: AuthService) { }

  defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    // filter: true,
    searchable: true,
    floatingFilter: true,
    resizable: true,
  };
  gridOptions = {
    rowData: this.GetTableData(),
    columnDefs: this.GetAgColumns(),
  }


  ngOnInit(): void {
    this.userType = environment.userType;
    this.searchOriginID = this.messageService.PNPsearchOriginID;
    this.searchDestinationID = this.messageService.PNPsearchDestinationID;
    this.lspData();
  }



  BindData(params) {
    this.gridApi = params.api;
    params.columnApi.autoSizeAllColumns();
  }



  // BindData(params) {
  //   this.gridApi = params.api;
  //   params.columnApi.autoSizeAllColumns();
  //   this.gridColumnApi = params.columnApi;
  //   var datasource: IServerSideDatasource = {
  //     getRows: (params: IServerSideGetRowsParams) => {
  //       // console.log("getRows params --- ", params)
  //       this.GetTableData(params)
  //     }
  //   }
  //   this.gridApi.setServerSideDatasource(datasource);
  // }




  GetAgColumns() {

    this.columnDefs = [
      { headerName: 'Shipment References', field: 'shipper_reference', sortable: true },
      { headerName: 'Account No', field: 'address_id_shipper', sortable: true },
      { headerName: 'Account Description', field: 'company_name_shipper', sortable: true },
      { headerName: 'MOT', field: 'mode_of_transport', sortable: true },
      {
        headerName: 'HAWB/Tracking No', field: 'ship_tracking_num', sortable: true, suppressFiltersToolPanel: true,
        onCellClicked: this.onRowClicked.bind(this),
        // cellRenderer: 'agGroupCellRenderer'
        cellRenderer: function (params) {
          let keyData = params.value;
          let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
          return newLink;
        }

      },
      // { headerName: 'Consignee', field: 'customerOrgName', sortable: true },
      {
        headerName: 'Origin', valueGetter: this.OriginValueGetter.bind(this), sortable: true, suppressFiltersToolPanel: true,
      },
      {
        headerName: 'Destination', valueGetter: this.DestinationValueGetter.bind(this), sortable: true, suppressFiltersToolPanel: true,
      },
      {
        headerName: 'Latest Tracking Event', valueGetter: this.latestTrackingEventValueGetter.bind(this), sortable: true,
      },
      {
        headerName: 'Delivery Date', field: 'eta', sortable: true,
        // valueFormatter: this.dateFormate.bind(this),
        cellRenderer: function (params) {
          let keyData = params.value;
          if (keyData !== null) {
            let newData = keyData.split('T')[0];
            let format = moment(newData).format('DD-MMM-YYYY')
            return format;
          }
          else {
            return '';
          }
        }
      },
      {
        headerName: 'Posting Date', field: 'etd', sortable: true,
        // valueFormatter: this.dateFormate.bind(this),

        cellRenderer: function (params) {
          let keyData = params.value;
          if (keyData !== null) {
            let newData = keyData.split('T')[0];
            let format = moment(newData).format('DD-MMM-YYYY')
            return format;
          }
          else {
            return '';
          }
        }
      },

    ]

  }

  dateFormate(rowData: any): any {
    console.log("value", rowData.value)
    if (rowData.value !== null) {
      return moment(rowData.value).format('YYYY-MM-DD');
    }
  }

  latestTrackingEventValueGetter(params: ValueGetterParams) {
    return params.data.event_code + '/' + params.data.event_desc
    // return params.data.latestTrackingEvent.substring(params.data.latestTrackingEvent.indexOf('/') + 1);
  }

  OriginValueGetter(params: ValueGetterParams) {
    let keyData = params.data;
    if (keyData.origin_code !== null) {
      let originCode = keyData.origin_code + ',' + keyData.origin_country
      return originCode;
    }
    else {
      let originCode = keyData.origin_country;
      return originCode
    }
  }
  DestinationValueGetter(params: ValueGetterParams) {
    let keyData = params.data;
    if (keyData.destination_code !== null) {
      let airportOfDestinationCode = keyData.destination_code;
      return airportOfDestinationCode;
    }
    else {
      let airportOfDestinationCode = keyData.destination_country;
      return airportOfDestinationCode
    }
  }


  GetTableData() {
    this.spinner.show();
    const dis = this;
    let req = null;
    let filter: any = {};
    if (this.messageService.PNPsearchOriginID !== null && this.messageService.PNPsearchOriginID !== undefined && this.messageService.PNPsearchOriginID !== '') {
      filter['originCode'] = [this.messageService.PNPsearchOriginID];
    }
    if (this.messageService.PNPsearchDestinationID !== null && this.messageService.PNPsearchDestinationID !== undefined && this.messageService.PNPsearchDestinationID !== '') {
      filter['destinationCode'] = [this.messageService.PNPsearchDestinationID];
    }
    this.requestService.PNPShipment('', filter).subscribe((resp: any) => {
      this.spinner.hide();
      if (resp.Message === 'Sucess') {
        this.RowData = resp['result'];
      }
    })

  }

  GetTableDataPagination(params: any) {
    this.RowData = []
    let obj = {
      "dataparams": params.request,
      "status": 'INTRANSIT'
    }

    this.spinner.show()
    this.requestService.PNPShipmentPagination(obj).subscribe((resp: any) => {
      // console.log(resp, "resp")
      if (resp.Message === 'Done') {
        params.successCallback(resp.messageResult, resp.totalRowsCount);
      }
      else {
        params.failCallback();
        Swal.fire({
          icon: 'error',
          text: resp.Message,
        })
      }
      this.spinner.hide()
    }
      , (err: any) => {
        this.spinner.hide()
        Swal.fire({
          title: err.status,
          text: err.statusText,
          icon: 'error'
        })
      })
  }


  onRowClicked(event: any) {
    console.log(event.data)
    this.eventChain(event.data.id);
    this.details(event.data.ship_tracking_num);
    this.SideOverlay = true;
  }

  eventChain(id) {
    this.spinner.show();
    const dis = this;
    let raws;
    let req = null;
    dis.eventparsing = [];
    console.log(id + 18)
    this.requestService.PNPgetEventchain(id + 18).subscribe((resp: any) => {
      if (resp.Message === 'Sucess') {
        let messageResult = resp['result'];
        for (let i = 0; i < messageResult.length; i++) {
          raws = messageResult[i];

          if (raws.sortDate !== null && raws.sortDate.includes("T")) {
            let date = raws.sortDate.split('T')[0];
            let time = raws.sortDate.split('T')[1];
            let hrs = time.split(':')[0];
            let min = time.split(':')[1];
            let finalTime = hrs + ':' + min
            raws.sortDate = date + ' ' + finalTime
          }
          if (raws.estimatedDate !== null && raws.estimatedDate.includes("T")) {

            let hrs = raws.estimatedDate.split(':')[0];
            let min = raws.estimatedDate.split(':')[1];
            raws.estimatedDate = hrs + ':' + min;
          }
          if (raws.estimatedDate === null) {
            raws.estimatedDate = ""
          }
          else if (raws.sortDate === null && raws.estimatedDate !== null) {
            if (raws.description === 'Departed') {
              raws.description = 'Departure'
            }
            else if (raws.description === 'Arrived') {
              raws.description = 'Arrival'
            }
          }
          if (raws.sortDate !== null) {
            dis.eventparsing.push(raws);
          }

        }
        // console.log('-----------------------------::::', dis.eventparsing.length);
        document.getElementById('reverseCount').style.setProperty('--ulLength', dis.eventparsing.length + 1);
        // let ul = document.querySelector('li')
        // ul.style.setProperty('--ul-length', '10')
        this.spinner.hide();
      }
    });
  }

  details(hawb) {
    this.spinner.show();
    const dis = this;
    let req = null;
    this.requestService.PNPgethawbLinesByAwb(hawb).subscribe((resp: any) => {
      this.spinner.hide();
      if (resp.Message === 'Sucess') {
        this.onRowClickedValue = resp['result'][0];
        let check = parseInt(this.onRowClickedValue['address_id_delivery'])
        if (check != 0) {
          console.log("000000000", parseInt(this.onRowClickedValue['address_id_delivery']))
          this.onRowClickedValue['delivery_house_no'] = this.onRowClickedValue['address_id_delivery'].substring(8, 11)
          this.onRowClickedValue['delivery_street_code'] = this.onRowClickedValue['address_id_delivery'].substring(5, 8)
          this.onRowClickedValue['delivery_zip_code'] = this.onRowClickedValue['address_id_delivery'].substring(0, 5)
          console.log("111111111", this.onRowClickedValue)
        }
      }
      this.mapDataEU()
    });
  }

  closemodal() {
    this.SideOverlay = false;
  }

  mapDataEU() {
    this.latlng = [];
    this.start_end_mark = []
    let mapObj = {
      "originlatitude": null,
      "originlongitude": null,
      "destinationlatitude": null,
      "destinationlongitude": null,
    }
    console.log("this.onRowClickedValue['address_id_shipper']", this.onRowClickedValue)
    this.requestService.PNPgetAddressMap(this.onRowClickedValue['address_id_shipper']).subscribe((resp1: any) => {
      console.log("resp['result'][0]", resp1['result'][0])
      mapObj['originlatitude'] = parseFloat(resp1['result'][0]['latitude']),
        mapObj['originlongitude'] = parseFloat(resp1['result'][0]['longitude'])
      console.log("1", mapObj.originlatitude)

      let check = parseInt(this.onRowClickedValue['address_id_delivery'])
      if (check === 0) {
        this.requestService.EUMap(this.onRowClickedValue['city_pickup'], '', this.onRowClickedValue['zip_pickup']).subscribe((resp: any) => {
          mapObj['destinationlatitude'] = parseFloat(resp[0]['lat']);
          mapObj['destinationlongitude'] = parseFloat(resp[0]['lon']);
          console.log("mapObj1111", mapObj);
          if (mapObj['originlatitude'] !== null && mapObj['originlongitude'] !== null) {
            this.latlng.push([mapObj['originlatitude'], mapObj['originlongitude']]);
          }
          if (mapObj['destinationlatitude'] !== null && mapObj['destinationlongitude'] !== null) {
            this.latlng.push([mapObj['destinationlatitude'], mapObj['destinationlongitude']])
          }
          this.latlong();
        })
      }
      else {
        let checkPostalCode = parseInt(this.onRowClickedValue['zip_delivery']);
        if (checkPostalCode != 0) {
          this.requestService.EUMap('', this.onRowClickedValue['country_name_delivery'], this.onRowClickedValue['zip_delivery']).subscribe((resp: any) => {
            console.log("222", resp)
            mapObj['destinationlatitude'] = parseFloat(resp[0]['lat']);
            mapObj['destinationlongitude'] = parseFloat(resp[0]['lon']);
            console.log("mapObj1111", mapObj);
            if (mapObj['originlatitude'] !== null && mapObj['originlongitude'] !== null) {
              this.latlng.push([mapObj['originlatitude'], mapObj['originlongitude']]);
            }
            if (mapObj['destinationlatitude'] !== null && mapObj['destinationlongitude'] !== null) {
              this.latlng.push([mapObj['destinationlatitude'], mapObj['destinationlongitude']])
            }
            this.latlong();
          })
        }
        else {
          this.requestService.EUMap('', this.onRowClickedValue['country_name_delivery'], '').subscribe((resp: any) => {
            mapObj['destinationlatitude'] = parseFloat(resp[0]['lat']);
            mapObj['destinationlongitude'] = parseFloat(resp[0]['lon']);
            console.log("mapObj2222", mapObj);
            if (mapObj['originlatitude'] !== null && mapObj['originlongitude'] !== null) {
              this.latlng.push([mapObj['originlatitude'], mapObj['originlongitude']]);
            }
            if (mapObj['destinationlatitude'] !== null && mapObj['destinationlongitude'] !== null) {
              this.latlng.push([mapObj['destinationlatitude'], mapObj['destinationlongitude']])
            }
            this.latlong();
          })
        }

      }

    });

  }

  latlong() {
    console.log("this.latlng", this.latlng)
    if (this.latlng.length === 2) {
      for (let i = 0; i < this.latlng.length; i++) {
        if (i === 0) {
          let icon = {
            url: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ffcd36',
            scaledSize: {
              width: 30,
              height: 45
            }
          }
          this.latlng[i].push(icon)

        }
      }
      this.start_end_mark.push(this.latlng[0]);
      this.start_end_mark.push(this.latlng[this.latlng.length - 1]);
    }
    else {
      this.latlng = [];
      this.start_end_mark = []
    }
  }



  lspData() {
    this.LspdropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    if (this.userType === 'SLB') {
      this.dropdownListLsp = [
        { item_id: 1, item_text: 'Express' },
        { item_id: 2, item_text: 'Forwarding' },
        { item_id: 3, item_text: 'FREIGHT' },
        { item_id: 4, item_text: 'ARAMEX' },
        { item_id: 5, item_text: 'SCHENKER' },
        { item_id: 6, item_text: 'DSV' },
        { item_id: 7, item_text: 'Geodis' },
        { item_id: 8, item_text: 'Expeditors' },
        { item_id: 9, item_text: 'KN' },
        { item_id: 10, item_text: 'CRANE' }

      ];
    }
    else if (this.userType === 'HM') {
      this.dropdownListLsp = [
        { item_id: 3, item_text: 'PNP' },
      ];
    }
    else {
      this.dropdownListLsp = [
        { item_id: 1, item_text: 'DGF' },
        { item_id: 2, item_text: 'DHLE' },
        { item_id: 3, item_text: 'FREIGHT' },
      ];
    }
  }

  onItemSelectLsp(item: any) {
    console.log("item.", item.item_text);
    this.itemLsP = item.item_text;
    if (this.selectedItemsOrigin.length !== 0) {
      this.selectedItemsOrigin = [];
    }
    this.PNPoriginDataCall()
  }

  origin_dropDown() {
    console.log("11111")
    this.dropdownSettingsOrigin = {
      singleSelection: true,
      idField: 'origin_code',
      textField: 'origin_code',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }


  PNPoriginDataCall() {
    const dis = this;
    let req = null;
    req = dis.requestService.getOriginsPNP();
    req.subscribe(resp => {
      if (resp['Message'] === 'Sucess') {
        if (resp['result'].length !== 0) {
          this.disableOrigin = false
          this.origin_dropDown();
          let originList = []
          let x = resp['result'].sort();
          x.map((y: any) => {
            let obj = {
              "origin_code": y
            }
            originList.push(obj)
          })
          this.dropdownListOrigin = originList;
          console.log(this.dropdownListOrigin)
        }
        else {
          this.dropdownListOrigin = []
        }
      }
    })
  }


  onItemSelectOrigin(item: any) {
    console.log(item.origin_code);
    this.itemoriginCode = item.origin_code;
    if (this.selectedItemsDestination.length !== 0) {
      this.selectedItemsDestination = [];
    }
    this.PNPDestinationDataCall(item.origin_code)

  }

  destination_dropDown() {
    this.dropdownSettingsDestination = {
      singleSelection: true,
      idField: 'destination_code',
      textField: 'destination_code',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }


  PNPDestinationDataCall(origin_code) {
    const dis = this;
    let req = null;
    req = dis.requestService.getOriginsByDestinationPNP(origin_code);
    req.subscribe(resp => {
      if (resp['Message'] === 'Sucess') {
        if (resp.result.length > 0) {
          this.disableDestination = false
          this.destination_dropDown();
          // this.dropdownListDestination = resp['result'][0].orig_dest_array.destination_code.sort();
          let destinList = []
          let x = resp['result'].sort();
          x.map((y: any) => {
            let obj = {
              destination_code: y
            }
            destinList.push(obj)
          })
          this.dropdownListDestination = destinList
        }
        else {
          this.dropdownListDestination = []
        }
      }
    });
  }


  onItemSelectDestination(item: any) {
    console.log(item.destination_code);
    this.messageService.PNPSearchData(this.itemoriginCode, this.itemDestinatioCode)
  }


  HAWBSearch() {

  }


}
