import { Component, OnInit } from '@angular/core';
import { TabService, Tab } from 'src/app/services/tab-service.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { RequestService } from 'src/app/services/request.service';
import { AuthService } from 'src/app/services/auth.service';
import { MoreDetailsService } from 'src/app/services/more-details.service';
import { EnvSelectionService } from '../../services/env-selection.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  userType = null;
  orgType: any
  loggedUser = null;
  title: any;
  TabDetails: any;
  Tab: any = [];
  cardpartitions: boolean = true;
  ConsumerCardPartitions: any = true;
  ShipperCardPartitions: any = true;
  Forwarder_courierCardPartitions: any = true;
  InvoiceAuditpartitions: any = true;
  dropdownListCustomerOrg = [];
  dropdownListOrigin = [];
  dropdownListDestination = [];
  dropdownListLsp = [];
  selectedItemsCustomerOrg = [];
  selectedItemsOrigin = [];
  selectedItemsDestination = [];
  selectedItemsLsp = [];
  dropdownSettingsDestination = {};
  dropdownSettingsOrigin = {};
  customerOrgdropdownSettings = {};
  LspdropdownSettings = {};
  itemcustomerOrgId: any;
  itemoriginCode: any;
  itemDestinatioCode: any;
  itemLsP: any;
  disableOrigin: boolean = true;
  disableDestination: boolean = true;
  ShowShipmentSearch: boolean = false;
  searchTitle: any;
  HAWBSearchField: any = null;
  ShipmentReferencesSearchField: any = null;
  windowObjectReference: any;
  windowObjectReferenceBilling = null;
  windowObjectReferenceVendorOrder = null;
  windowObjectReferenceCarbE = null;
  redirectingUrlObj: any;
  removeTiles: boolean;
  // conditions = "this.userType === 'DHL' || this.userType === 'Express' || this.userType === 'LLP' || this.userType === 'ASTRAZENECA' || this.userType === 'BMS' || this.userType === 'Cargotec' || this.userType === 'GSK'|| this.userType === 'IBM' || this.userType === 'Total' || this.userType === 'Meritor'|| this.userType === 'Maxion' || this.userType === 'Pfizer' || this.userType === 'Caterpillar'|| this.userType === 'SLB'"
  constructor(private tabService: TabService, private router: Router,
    private requestService: RequestService,
    private authService: AuthService,
    private messageService: MoreDetailsService,
    private envSelection: EnvSelectionService) { }

  ngOnInit() {
    this.userType = environment.userType;
    if(this.userType ==="HM"){
      this.removeTiles = false
    }
    this.removeTiles = true
    this.cardpartitions = false
    this.ConsumerCardPartitions = false
    this.ShipperCardPartitions = false
    this.Forwarder_courierCardPartitions = false;
    this.InvoiceAuditpartitions = false;
    let localstorageorgType = this.authService.getLoggedUser();
    this.orgType = localstorageorgType.orgType;
    var windowLocation = window.location
    this.redirectingUrlObj = this.envSelection.getRedirectingUrl(); // It returns the current running env related base redirect url.
    console.log("this.redirectingUrlObj", this.redirectingUrlObj)
    
  }


  WidgetCarbEClicked() {
    if (this.windowObjectReferenceCarbE == null || this.windowObjectReferenceCarbE.closed) {
      this.windowObjectReferenceCarbE = window.open(this.redirectingUrlObj.carbe);
      this.messageService.setReference(this.windowObjectReferenceCarbE);
    }
    else {
      this.windowObjectReferenceCarbE.focus();
    };
  }

  WidgetAnalyticsClicked() {
    let status = environment.analyticsWidget
    if (status) {
      console.log("WidgetAnalyticsClicked")
      const token = sessionStorage.getItem('access-token');
      if (this.windowObjectReference == null || this.windowObjectReference.closed || this.windowObjectReference == undefined) {
        this.windowObjectReference = window.open(this.redirectingUrlObj.analytics);
        this.messageService.setReference(this.windowObjectReference);
      }
      else {
        this.windowObjectReference.focus();
      };
    }
  }



  WidgetClicked(WidgetStatus: any) {
    const dis = this;
    if (WidgetStatus === 'shipmentBooking') {
      dis.title = 'Shipment Booking';

      this.ConsumerCardPartitions = false;
      this.ShipperCardPartitions = false;
      this.Forwarder_courierCardPartitions = false;
      this.InvoiceAuditpartitions = false;
      this.cardpartitions = environment.shipment;
    }
    else if (WidgetStatus === 'CONSUMER') {
      this.ConsumerCardPartitions = environment.consumerOrders;
      this.ShipperCardPartitions = false;
      this.Forwarder_courierCardPartitions = false;
      this.InvoiceAuditpartitions = false;
      this.cardpartitions = false;
    }
    else if (WidgetStatus === 'ShipperActions') {
      this.ConsumerCardPartitions = false;
      this.ShipperCardPartitions = environment.transportRequest;
      this.Forwarder_courierCardPartitions = false;
      this.InvoiceAuditpartitions = false;
      this.cardpartitions = false;
    }
    else if (WidgetStatus === 'Forwarder_Courier') {
      this.ConsumerCardPartitions = false;
      this.ShipperCardPartitions = false;
      this.Forwarder_courierCardPartitions = environment.forwarderCourier;
      this.InvoiceAuditpartitions = false;
      this.cardpartitions = false;
    }
    else if (WidgetStatus === 'Invoice&Audit') {
      this.ConsumerCardPartitions = false;
      this.ShipperCardPartitions = false;
      this.Forwarder_courierCardPartitions = false;
      this.InvoiceAuditpartitions = environment.invoiceAndAudit;
      this.cardpartitions = false;
    }
    else if (WidgetStatus === 'shipmentRequestClicked') {
      dis.title = 'Tracking';
      this.tabService.openTab(new Tab(dis.title, 'tracking'));
    }
    else if (WidgetStatus === 'ShipmentPlanning') {

      dis.title = 'Shipment Preparation';
      this.tabService.openTab(new Tab(dis.title, 'ShipmentPreparation'));

    }


    else if (WidgetStatus === 'InTransit') {
      console.log('Intransit', this.userType)
      dis.title = 'In-Transit';
      if (this.userType === 'Cargotec') {
        this.tabService.openTab(new Tab(dis.title, 'inTransit'));
      }

      else if (this.userType === 'Nissan') {
        this.tabService.openTab(new Tab(dis.title, 'checkpoint_Intransit'));
      }
      else {
        this.tabService.openTab(new Tab(dis.title, 'new_Checkpoint_Intransit'));
      }
    }

    else if (WidgetStatus === 'InTransitEU') {
      if (this.userType === 'HM') {
        dis.title = 'In-Transit EU';
        this.tabService.openTab(new Tab(dis.title, 'inTransitEU'));
      }
    }

    else if (WidgetStatus === 'DeliveredEU') {
      if (this.userType === 'HM') {
        dis.title = 'Delivered EU';
        this.tabService.openTab(new Tab(dis.title, 'DeliveredEU'));
      }
    }


    else if (WidgetStatus === 'Delivered') {
      dis.title = 'Delivered';
      this.tabService.openTab(new Tab(dis.title, 'DeliveredDashboard'));
    }
    else if (WidgetStatus === 'TrackTemperature') {
      dis.title = 'Track Temperature';
      this.tabService.openTab(new Tab(dis.title, 'TrackTemperature'));
    }
    else if (WidgetStatus === 'PurchaseOrder') {
      dis.title = 'Purchase Order';
      this.tabService.openTab(new Tab(dis.title, 'TransportRequestDashboard'));
    }
    else if (WidgetStatus === 'ShippingInstruction') {
      if (this.userType === 'Nissan') {
        dis.title = 'Shipping Instruction';
        this.tabService.openTab(new Tab(dis.title, 'ShipingInstruction'));
      }
      else {

      }
    }
    else if (WidgetStatus === 'warehouseInfrastructure') {
      if (this.userType === 'Nissan') {
        dis.title = 'Warehouse Infrastructure';
        this.tabService.openTab(new Tab(dis.title, 'warehouseInfrastructure'));
      }
      else {

      }
    }
    else if (WidgetStatus === 'ShipperConfirmation') {
      if (this.userType === 'Nissan') {
        dis.title = 'Shipment Full Confirmation';
        this.tabService.openTab(new Tab(dis.title, 'ShipingConfirmation'));
      }
      else {

      }
    }
    else if (WidgetStatus === 'Exceptions') {
      dis.title = 'Exceptions';
      this.tabService.openTab(new Tab(dis.title, 'Exceptions'));
    }
    else if (WidgetStatus === 'VendorOrder') {
      if (this.windowObjectReferenceVendorOrder == null || this.windowObjectReferenceVendorOrder.closed) {
        const token = sessionStorage.getItem('access-token');
        this.windowObjectReferenceVendorOrder = window.open(this.redirectingUrlObj.vndord);
        this.messageService.setReference(this.windowObjectReferenceVendorOrder);
      }
      else {
        this.windowObjectReferenceVendorOrder.focus();
      };
    }
    else if (WidgetStatus === 'Billing') {
      if (this.windowObjectReferenceBilling == null || this.windowObjectReferenceBilling.closed) {
        const token = sessionStorage.getItem('access-token');
        console.log(this.redirectingUrlObj,'this.redirectingUrlObj');
        this.windowObjectReferenceBilling = window.open(this.redirectingUrlObj.invaud + "?accessToken=" + token);
        this.messageService.setReference(this.windowObjectReferenceBilling);
      }
      else {
        this.windowObjectReferenceBilling.focus();
      };
    }
  }

  clear() {
    this.cardpartitions = false
    this.ConsumerCardPartitions = false
    this.ShipperCardPartitions = false
    this.Forwarder_courierCardPartitions = false;
    this.InvoiceAuditpartitions = false;
    this.ShowShipmentSearch = false;
  }

  shipmentSearchClick(key: any) {
    if (this.userType === 'DHL' || this.userType === 'Express') {
      this.customerData();
    }
    else {
      this.lspData()
    }
    this.ShowShipmentSearch = !this.ShowShipmentSearch
    if (key === 'shipmentBooking') {
      this.searchTitle = 'Shipment'
    }
  }



  lspData() {
    this.LspdropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    if (this.userType === 'SLB') {
      this.dropdownListLsp = [
        { item_id: 1, item_text: 'Express' },
        { item_id: 2, item_text: 'Forwarding' },
        { item_id: 3, item_text: 'FREIGHT' },
        { item_id: 4, item_text: 'ARAMEX' },
        { item_id: 5, item_text: 'SCHENKER' },
        { item_id: 6, item_text: 'DSV' },
        { item_id: 7, item_text: 'Geodis' },
        { item_id: 8, item_text: 'Expeditors' },
        { item_id: 9, item_text: 'KN' },
        { item_id: 10, item_text: 'CRANE' }

      ];
    }
    else if (this.userType === 'HM') {
      this.dropdownListLsp = [
        { item_id: 1, item_text: 'DGF' },
        { item_id: 2, item_text: 'DHLE' },
        { item_id: 3, item_text: 'PNP' },
      ];
    }
    else {
      this.dropdownListLsp = [
        { item_id: 1, item_text: 'DGF' },
        { item_id: 2, item_text: 'DHLE' },
        { item_id: 3, item_text: 'FREIGHT' },
      ];
    }
  }

  onItemSelectLsp(item: any) {
    console.log("item.", item.item_text);
    this.itemLsP = item.item_text;
    if (this.itemLsP === 'PNP') {
      if (this.selectedItemsOrigin.length !== 0) {
        this.selectedItemsOrigin = [];
      }
      this.PNPoriginDataCall()
    } else {
      if (this.selectedItemsOrigin.length !== 0) {
        this.selectedItemsOrigin = [];
      }
      this.originDataCall()
    }
  }



  customer_dropDown() {
    this.customerOrgdropdownSettings = {
      singleSelection: true,
      idField: 'customerOrgId',
      textField: 'customerOrgId',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  customerData() {
    const dis = this;
    let req = null;
    req = dis.requestService.customerDropdownData();
    req.subscribe(resp => {
      console.log("customerORG::::", resp)
      if (resp['Message'] === 'Sucess') {
        this.customer_dropDown();
        this.dropdownListCustomerOrg = resp['result']
      }

    });
  }

  onItemSelectCustomerOrg(item: any) {
    console.log(item.customerOrgId);
    if (this.selectedItemsOrigin.length !== 0) {
      this.selectedItemsOrigin = [];
    }
    this.itemcustomerOrgId = item.customerOrgId
    this.originDataCall()
  }

  origin_dropDown() {
    console.log("11111")
    this.dropdownSettingsOrigin = {
      singleSelection: true,
      idField: 'origin_code',
      textField: 'origin_code',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }


  PNPoriginDataCall() {
    const dis = this;
    let req = null;
    req = dis.requestService.getOriginsPNP();
    req.subscribe(resp => {
      if (resp['Message'] === 'Sucess') {
        if (resp['result'].length !== 0) {
          this.disableOrigin = false
          this.origin_dropDown();
          let originList = []
          let x = resp['result'].sort();
          x.map((y: any) => {
            let obj = {
              "origin_code": y
            }
            originList.push(obj)
          })
          this.dropdownListOrigin = originList;
          console.log(this.dropdownListOrigin)
        }
        else {
          this.dropdownListOrigin = []
        }
      }
    })
  }

  originDataCall() {
    const dis = this;
    let req = null;
    let localstorageUserOrg = this.authService.getLoggedUser();
    if (this.userType === 'DHL' || this.userType === 'Express') {
      this.itemcustomerOrgId = this.itemcustomerOrgId;
      if (this.userType === 'DHL') {
        this.itemLsP = 'DGF'
      }
      else if (this.userType === 'Express') {
        this.itemLsP = 'DHLE'
      }
    }
    else {
      this.itemcustomerOrgId = localstorageUserOrg.userOrg
      this.itemLsP = this.itemLsP
    }
    let params = {
      email: localstorageUserOrg.emailId,
      custOrgId: this.itemcustomerOrgId,
      lspOrg: this.itemLsP
    }
    let filter: any = {};
    filter['lspOrg'] = [this.itemLsP];
    filter['customerOrg'] = [this.itemcustomerOrgId]
    filter['emailId'] = [localstorageUserOrg.emailId]
    req = dis.requestService.originsByLsp(filter);
    req.subscribe(resp => {
      if (resp['Message'] === 'Sucess') {
        console.log("originDropdownData::::", resp)
        if (resp['result'].length !== 0) {
          this.disableOrigin = false
          this.origin_dropDown();
          let originList = []
          let x = resp['result'].sort();
          x.map((y: any) => {
            let obj = {
              "origin_code": y
            }
            originList.push(obj)
          })
          this.dropdownListOrigin = originList
        }
        else {
          this.dropdownListOrigin = []
        }
      }
    });
  }
  onItemSelectOrigin(item: any) {
    console.log(item.origin_code);
    this.itemoriginCode = item.origin_code;
    if (this.itemLsP === 'PNP') {
      if (this.selectedItemsDestination.length !== 0) {
        this.selectedItemsDestination = [];
      }
      this.PNPDestinationDataCall(item.origin_code)
    } else {
      if (this.selectedItemsDestination.length !== 0) {
        this.selectedItemsDestination = [];
      }
      this.DestinationDataCall(item.origin_code)
    }
  }

  destination_dropDown() {
    this.dropdownSettingsDestination = {
      singleSelection: true,
      idField: 'destination_code',
      textField: 'destination_code',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }


  PNPDestinationDataCall(origin_code) {
    const dis = this;
    let req = null;
    req = dis.requestService.getOriginsByDestinationPNP(origin_code);
    req.subscribe(resp => {
      if (resp['Message'] === 'Sucess') {
        if (resp.result.length > 0) {
          this.disableDestination = false
          this.destination_dropDown();
          // this.dropdownListDestination = resp['result'][0].orig_dest_array.destination_code.sort();
          let destinList = []
          let x = resp['result'].sort();
          x.map((y: any) => {
            let obj = {
              destination_code: y
            }
            destinList.push(obj)
          })
          this.dropdownListDestination = destinList
        }
        else {
          this.dropdownListDestination = []
        }
      }
    });
  }


  DestinationDataCall(origin_code) {
    const dis = this;
    let req = null;
    let localstorageUserOrg = this.authService.getLoggedUser();
    if (this.userType === 'DHL' || this.userType === 'Express') {
      this.itemcustomerOrgId = this.itemcustomerOrgId;
      if (this.itemcustomerOrgId === 'CARGOTEC_FRT') {
        this.itemLsP = 'FREIGHT'
      }
      else {
        if (this.userType === 'DHL') {
          this.itemLsP = 'DGF'
        }
        else if (this.userType === 'Express') {
          this.itemLsP = 'DHLE'
        }
      }

    }
    else {
      this.itemcustomerOrgId = localstorageUserOrg.userOrg
      this.itemLsP = this.itemLsP
    }
    let params = {
      email: localstorageUserOrg.emailId,
      custOrgId: this.itemcustomerOrgId,
      originCode: origin_code,
      lspOrg: this.itemLsP
    }
    let filter: any = {};
    filter['lspOrg'] = [this.itemLsP];
    filter['customerOrg'] = [this.itemcustomerOrgId]
    filter['emailId'] = [localstorageUserOrg.emailId]
    filter['origin'] = [origin_code]
    req = dis.requestService.destinationsByOriginLsp(filter);
    req.subscribe(resp => {
      if (resp['Message'] === 'Sucess') {
        if (resp.result.length > 0) {
          this.disableDestination = false
          this.destination_dropDown();
          // this.dropdownListDestination = resp['result'][0].orig_dest_array.destination_code.sort();
          let destinList = []
          let x = resp['result'].sort();
          x.map((y: any) => {
            let obj = {
              destination_code: y
            }
            destinList.push(obj)
          })
          this.dropdownListDestination = destinList
        }
        else {
          this.dropdownListDestination = []
        }
      }
    });
  }

  onItemSelectDestination(item: any) {
    console.log(item.destination_code);
    this.title = this.searchTitle;
    console.log("searchTitle", this.searchTitle)
    this.itemDestinatioCode = item.destination_code;
    if (this.itemLsP === 'PNP') {
      this.tabService.openTab(new Tab('EU Search', 'PNPSearch'));
      this.messageService.PNPSearchData(this.itemoriginCode, this.itemDestinatioCode)
    }
    else {
      this.tabService.openTab(new Tab(this.title, 'Search'));
      this.messageService.SearchData(this.itemcustomerOrgId, this.itemoriginCode, this.itemDestinatioCode, this.itemLsP, null)
    }
  }

  HAWBSearch() {
    console.log("HAWBEntered", this.HAWBSearchField)
    this.title = this.searchTitle;
    this.tabService.openTab(new Tab(this.title, 'Search'));
    this.messageService.SearchData(this.itemcustomerOrgId, this.itemoriginCode, this.itemDestinatioCode, this.itemLsP, this.HAWBSearchField);
  }

  public ShipmentReferencesSearch(): void {
    console.log("ShipmentReferencesSearchField", this.ShipmentReferencesSearchField)
    this.title = this.searchTitle;
    this.tabService.openTab(new Tab(this.title, 'Search'));
    this.messageService.SearchData(this.itemcustomerOrgId, this.itemoriginCode, this.itemDestinatioCode, this.itemLsP, this.HAWBSearchField, this.ShipmentReferencesSearchField);
  }

}
