import { Component, OnInit } from '@angular/core';
import { observable } from 'rxjs';
import { RequestService } from 'src/app/services/request.service';
import * as FusionCharts from "fusioncharts";
import * as moment from 'moment';
import { Color } from 'ag-grid-community';

@Component({
  selector: 'app-fusion-track-temp',
  templateUrl: './fusion-track-temp.component.html',
  styleUrls: ['./fusion-track-temp.component.scss']
})
export class FusionTrackTempComponent implements OnInit {


  tempidentifier: any;
  dataSource: any;
  type: string;
  width: string;
  height: string;
  constructor(private requestService: RequestService) {
    this.type = "timeseries";
    this.width = "1000";
    this.height = "500";

    // This is the dataSource of the chart
    this.dataSource = {
      chart: {},
      caption: {
        text: "Temperature Reading"
      },
      subcaption: {
        text: "in °C , <strong>Threshold - 15°C - 20°C</strong> "
      },
      yaxis: [
        {
          plot: [
            {
              value: "Temperature",
              connectnulldata: true,
              style: {
                "plot.null": {
                  "stroke-dasharray": "none",
                  stroke: "#d6d6d6"
                }
              }
            }
          ],
          format: {
            suffix: '°C',
          },
          title: "Temperature",
          //   referenceLine: [
          //     {
          //     label: 'Lower Limit',
          //     value: 15,
          //     style: {
          //       marker: {
          //         strokeDasharray: 4.3
          //       }
          //     }
          //   }, {
          //     label: 'Upper Limit',
          //     value: 20,
          //     style: {
          //       marker: {
          //         strokeDasharray: 4.3
          //       }
          //     }
          //   }
          // ]
        }
      ],
      datamarker: [],
      xaxis: {
        plot: "Time",
        timemarker: [

        ],
        outputtimeformat: {

        }
      }
    };

    this.fetchData();
  }

  // In this method we will create our DataStore and using that we will create a custom DataTable which takes two
  // parameters, one is data another is schema.
  fetchData() {

    var dataFetch = this.fusionData();
    setTimeout(() => {
      console.log("dataFetch", dataFetch)
      var schemaFetch = this.fusionSchema();

      console.log("schemaFetch", schemaFetch)
      // console.log("dataFetch", dataFetch)
      Promise.all([dataFetch, schemaFetch]).then(res => {
        const [data, schema] = res;
        // const schema = res;
        // First we are creating a DataStore
        const fusionDataStore = new FusionCharts.DataStore();
        // After that we are creating a DataTable by passing our data and schema as arguments
        const fusionTable = fusionDataStore.createDataTable(data, schema);
        // Afet that we simply mutated our timeseries datasource by attaching the above
        // DataTable into its data property.
        this.xAxisData();
        this.dataSource.data = fusionTable;
        console.log("this.dataSoure", this.dataSource.data)
      });
    }, 1000);
  }

  fusionSchema() {
    let obj = [
      {
        column: "Time",
        format: "%d-%b-%Y %I:%M:%S %p",
        index: 0,
        name: "Time",
        outputAs: undefined,
        type: "date",
        anchorBorderColor: "#0372AB",
        anchorBgColor: "#E1f5ff"
      },
      {
        column: "Temperature",
        index: 1,
        name: "Temperature",
        type: "number",
        anchorBorderColor: "#0372AB",
        anchorBgColor: "#E1f5ff"
      },
      {
        name: "_row_id",
        type: "string"
      }
    ]
    return obj;
  }

  fusionData() {
    console.log("11")
    let id = 811;
    let data: any = [];
    this.requestService.tracktemps(id).subscribe((resp: any) => {
      if (resp.Message === 'Sucess') {
        console.log("if")
        resp.result.sort((a, b) => (b.latest_reading_date > a.latest_reading_date) ? -1 : 1);
        let identifierColor: any
        for (let [i, element] of resp.result.entries()) {

          if (element.latest_reading_temp >= 20) {
            this.tempidentifier = 'H'
            identifierColor = '#ff0000'
          }
          else if (element.latest_reading_temp <= 15) {
            this.tempidentifier = 'L',
              identifierColor = '#ff0000'
          }
          else {
            this.tempidentifier = '',
              identifierColor = '#008000'
          }
          let dataArray = [
            moment(element.latest_reading_date.split('.')[0]).format('DD-MMM-YYYY HH:mm:ss A'),
            element.latest_reading_temp,
            i
          ]
          data.push(dataArray);
          let identifierArray = {
            value: "Temperature",
            time: moment(element.latest_reading_date.split('.')[0]).format('DD-MMM-YYYY HH:mm:ss A'),
            type: "pin",
            identifier: '',
            timeformat: "%d-%b-%Y %I:%M:%S %p",
            style: {
              text: {
                "fill": identifierColor,
              },
              marker: {
                "fill": identifierColor,
                "font-weight": "bold",
                "stroke": identifierColor
              }
            }
          }
          this.dataSource.datamarker.push(identifierArray)
        }
        console.log("ifLOOP", data)
        return data;
      }
    })
    return data;

  }

  xAxisData() {
    let id = 811;
    this.requestService.eventChainDetails(id).subscribe((resp1: any) => {
      resp1.result.sort((a, b) => (b.sortDate > a.sortDate) ? -1 : 1);
      for (let element1 of resp1.result) {

        let obj = {
          start: moment(element1.sortDate.split('.')[0]).format('DD-MMM-YYYY HH:mm:ss A'),
          label: element1.eventCode + ' - ' + element1.description,
          timeformat: "%d-%b-%Y %I:%M:%S %p"
        }
        this.dataSource.xaxis.timemarker.push(obj);

      }
      console.log("xaxis", this.dataSource.xaxis.timemarker)
    });
  }


  ngOnInit() {

  }
}


