import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loginStateChange = new Subject();
  loginStateChanged$ = this.loginStateChange.asObservable();

  constructor(private router: Router) { }
  login(emailId: string, userRole: string, userId: string, userOrg: string, orgType: string) {
    console.log("LOgin")
    sessionStorage.setItem('user', JSON.stringify({ emailId, userRole, userId, userOrg, orgType }));
    this.loginStateChanged();
  }

  logout() {
    // sessionStorage.removeItem('user');
    // sessionStorage.removeItem('myColumnState');
    // sessionStorage.removeItem('Tab');
    window.sessionStorage.clear();
    this.router.navigate(['']);
    window.location.reload();
    this.loginStateChanged();
  }

  isUserLoggedIn() {
    return sessionStorage.getItem('user') !== null;
  }
  getLoggedUser() {
    if (!this.isUserLoggedIn()) {
      return {};
    }
    return JSON.parse(sessionStorage.getItem('user') || '{}');
  }
  loginStateChanged() {
    this.loginStateChange.next();
  }

  saveAccessToken(token: string): void {
    window.sessionStorage.removeItem('access-token');
    window.sessionStorage.setItem('access-token', token);
  }
  getAccessToken(): string | null {
    return window.sessionStorage.getItem('access-token');
  }
  saveRefreshToken(token: string): void {
    window.sessionStorage.removeItem('refresh-token');
    window.sessionStorage.setItem('refresh-token', token);
  }
  getRefreshToken(): string | null {
    return window.sessionStorage.getItem('refresh-token');
  }

}
