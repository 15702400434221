import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router) {
    // This is constructor
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (sessionStorage.getItem('access-token') != null) {
      const token = sessionStorage.getItem('access-token');
      // if the token is  stored in sessionstorage add it to http header
      const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
      //clone http to the custom AuthRequest and send it to the server 
      const AuthRequest = request.clone({ headers: headers });
      // console.log("AuthRequest", AuthRequest)
      return next.handle(AuthRequest).pipe(
        tap((event: HttpEvent<any>) => { },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 403 || err.status === 401) {
                this.authService.logout();
                this.router.navigate(['login']);
                Swal.fire({
                  icon: 'error',
                  heightAuto: false,
                  html: 'Your Session has Expired,' + ' <br><br> Click <b>OK</b> to Login',
                })
              }
            }
          }
        ));
    } else {
      if (this.router.url !== '/login') {
        Swal.fire({
          icon: 'error',
          heightAuto: false,
          html: 'Your Session has Timed out,' + ' <br><br> Click <b>OK</b> to Login',
        }).then(() => {
          this.authService.logout();
          this.router.navigate(['login']);
        });
        
        
      }

      return next.handle(request);
    }
  }
}