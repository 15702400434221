<div class="row mt-3">
    <ng-container *ngIf="ShowShipmentSearch === true">
        <div class="col-3" *ngIf="userType === 'DHL' || userType === 'Express'">
            <ng-multiselect-dropdown [placeholder]="'Customer Org'" [settings]="customerOrgdropdownSettings"
                [data]="dropdownListCustomerOrg" [(ngModel)]="selectedItemsCustomerOrg"
                (onSelect)="onItemSelectCustomerOrg($event)">
            </ng-multiselect-dropdown>
        </div>
        <div class="col-3" *ngIf="userType !== 'DHL' && userType !== 'Express'">
            <ng-multiselect-dropdown [placeholder]="'LSP'" [settings]="LspdropdownSettings" [data]="dropdownListLsp"
                [(ngModel)]="selectedItemsLsp" (onSelect)="onItemSelectLsp($event)">
            </ng-multiselect-dropdown>
        </div>
        <div class="col-2">
            <ng-multiselect-dropdown [placeholder]="'Origin'" [settings]="dropdownSettingsOrigin"
                [data]="dropdownListOrigin" [(ngModel)]="selectedItemsOrigin" (onSelect)="onItemSelectOrigin($event)"
                [disabled]="disableOrigin">
            </ng-multiselect-dropdown>

        </div>
        <div class="col-2">
            <ng-multiselect-dropdown [placeholder]="'Destination'" [settings]="dropdownSettingsDestination"
                [data]="dropdownListDestination" [(ngModel)]="selectedItemsDestination"
                (onSelect)="onItemSelectDestination($event)" [disabled]="disableDestination">
            </ng-multiselect-dropdown>
        </div>
        <div class="col-2">
            <input placeholder="Enter HAWB" class="form-control" type="text" name="HAWB" [(ngModel)]="HAWBSearchField"
                (keyup.enter)="HAWBSearch()">
        </div>
        <div class="col-2">
            <input placeholder="Enter Shipment References" class="form-control" type="text" name="ShipmentReferences"
                [(ngModel)]="ShipmentReferencesSearchField" (keyup.enter)="ShipmentReferencesSearch()">
        </div>
    </ng-container>
    <div class="col-auto float-right ml-auto">
        <!-- <div class="view-icons"> -->
        <a (click)="clear()" title="Clear" class="grid-view btn btn-link"><i class="fa fa-refresh"></i></a>
        <!-- </div> -->
    </div>
</div>
<div class="row justify-content-center" style="margin-top:25px">
    <div class="col-sm-12 col-md-3 col-lg ConsumerOrder" *ngIf="removeTiles">
        <ng-container *ngIf="!ConsumerCardPartitions">
            <a class="card-link ConsumerOrders" (click)="WidgetClicked('CONSUMER')">
                <div class="card align-items-center">
                    <div class="card-body">
                        <div class="imageconsumer"></div>

                        <span>
                            Consumer Orders
                        </span>

                    </div>
                </div>
            </a>
        </ng-container>
        <ng-container *ngIf="ConsumerCardPartitions">
            <div class="card align-items-center" style="height: 207px;">
                <div class="card-body" style="margin-top: 80px;">
                    <div class="row  mb-4" style="border-bottom: 1px solid;">
                        <div class="col" style="margin-top: -32px;">
                            <a class="card-link purchaseOrderCursorPermissions"
                                (click)="WidgetClicked('PurchaseOrder')">
                                <span>
                                    Purchase Order
                                </span>
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col" style="border-right: 1px solid;">
                            <a class="card-link shipmentConfirmationCursorPermissions"
                                (click)="WidgetClicked('ShipperConfirmation')">
                                <span style="white-space: nowrap;">
                                    Shipment Full Confirmation (ETD)
                                </span>
                            </a>
                        </div>
                        <div class="col">
                            <a class="card-link exceptionCursorPermissions" (click)="WidgetClicked('Exceptions')">
                                <span>
                                    Exceptions
                                </span>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </ng-container>
    </div>

    <div class="col-sm-12 col-md-3 col-lg TransportRequest" *ngIf="removeTiles">
        <ng-template #enableTransportRequest>
            <a class="card-link" style="cursor: not-allowed;" (click)="WidgetClicked('ShipperActions')">
                <div class="card align-items-center">
                    <div class="card-body">
                        <div class="imageshipper1"></div>

                        <span>
                            Transport Request
                        </span>

                    </div>
                </div>
            </a>
        </ng-template>
        <ng-template [ngIf]="userType!=='LLP' || orgType !== 'USER'" [ngIfElse]="enableTransportRequest">
            <ng-container *ngIf="!ShipperCardPartitions">
                <a class="card-link TransportRequest" (click)="WidgetClicked('ShipperActions')">
                    <div class="card align-items-center">
                        <div class="card-body">
                            <div class="imageshipper"></div>

                            <span>
                                Transport Request
                            </span>

                        </div>
                    </div>
                </a>
            </ng-container>
            <ng-container *ngIf="ShipperCardPartitions">
                <div class="card align-items-center" style="height: 207px;">
                    <div class="card-body" style="margin-top: 80px;">
                        <div class="row  mb-4" style="border-bottom: 1px solid;">
                            <div class="col" style="margin-top: -32px;">
                                <a class="card-link WarehouseInfrastructure"
                                    (click)="WidgetClicked('warehouseInfrastructure')">
                                    <span>
                                        Warehouse Infrastructure
                                    </span>
                                </a>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col" style="border-right: 1px solid;">
                                <a class="card-link VendorOrder" (click)="WidgetClicked('VendorOrder')">
                                    <span>
                                        Vendor Order
                                    </span>
                                </a>
                            </div>
                            <div class="col">
                                <a class="card-link ShippingInstruction" (click)="WidgetClicked('ShippingInstruction')">
                                    <span>
                                        Shipping Instruction
                                    </span>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </ng-container>
        </ng-template>
    </div>

    <div class="col-sm-12 col-md-3 col-lg ForwarderCourier" *ngIf="removeTiles">
        <ng-container *ngIf="!Forwarder_courierCardPartitions">
            <a class="card-link ForwarderCourier" (click)="WidgetClicked(' Forwarder_Courier')">
                <div class="card align-items-center">
                    <div class="card-body">
                        <div class="imageForwarder_Courier"></div>

                        <span>
                            Forwarder / Courier
                        </span>

                    </div>
                </div>
            </a>
        </ng-container>
        <ng-container *ngIf="Forwarder_courierCardPartitions">
            <div class="card align-items-center" style="height: 207px;">
                <div class="card-body" style="margin-top: 80px;">
                    <div class="row  mb-4" style="border-bottom: 1px solid;">
                        <div class="col" style="margin-top: -32px;">
                            <a class="card-link" (click)="WidgetClicked('BookingRequest')">
                                <span>
                                    Booking Request
                                </span>
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <a class="card-link" (click)="WidgetClicked('BookingConfirmation')">
                                <span>
                                    Booking Confirmation
                                </span>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </ng-container>
    </div>
    <div class="col-sm-12 col-md-3 col-lg">
        <ng-template #enableshipmentBooking>
            <div class="card align-items-center">
                <div class="card-body">
                    <span style="border: 1px solid #dfdddd; margin-left: 203px;cursor: pointer;padding: 4px;
                border-radius: 5px;color: darkgray;cursor:not-allowed">
                        Search
                    </span>
                    <div class="image1disable ml-5" (click)="WidgetClicked('shipmentBooking')"></div>
                    <a class="card-link" style="cursor: not-allowed;" (click)="WidgetClicked('shipmentBooking')">
                        <span>
                            Shipment
                        </span>
                    </a>
                </div>
            </div>
        </ng-template>
        <ng-template [ngIf]="userType!=='LLP' || orgType !== 'USER'" [ngIfElse]="enableshipmentBooking">
            <ng-container *ngIf="!cardpartitions">
                <div class="card align-items-center">

                    <div class="card-body">
                        <span style="border: 1px solid #dfdddd; margin-left: 203px;cursor: pointer;padding: 4px;
                    border-radius: 5px;color: darkgray;" (click)="shipmentSearchClick('shipmentBooking')">
                            Search
                        </span>
                        <div class="image1 ml-5" (click)="WidgetClicked('shipmentBooking')"></div>
                        <a class="card-link" (click)="WidgetClicked('shipmentBooking')">
                            <span>
                                Shipment
                            </span>
                        </a>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="cardpartitions">
                <div class="card align-items-center" style="height: 207px;">
                    <div class="card-body" style="margin-top: 50px;">
                        <div class="row  mb-4" style="border-bottom: 1px solid;">
                            <!-- <div class="col" style="margin-top: -32px;"> -->
                            <div class="col">
                                <a class="card-link ShipmentPlanning" (click)="WidgetClicked('ShipmentPlanning')">
                                    <span>
                                        Shipment Preparation
                                    </span>
                                </a>
                            </div>
                            <!-- <div class="col" style="margin-top: -32px;">
                            <a class="card-link" (click)="WidgetClicked('TrackTemperature')">
                                <span>
                                    Track Temp
                                </span>
                            </a>
                        </div> -->
                        </div>
                        <div class="row">
                            <div class="col" style="border-right: 1px solid;">
                                <a class="card-link intransitCursorPermissions" (click)="WidgetClicked('InTransit')">
                                    <span *ngIf="userType==='HM'">
                                        In-Transit-CrossBorder
                                    </span>
                                    <span *ngIf="userType!=='HM'">
                                        In-Transit
                                    </span>
                                </a>
                            </div>
                            <div class="col">
                                <a class="card-link deliveredCursorPermissions" (click)="WidgetClicked('Delivered')">
                                    <span style="white-space: nowrap;" *ngIf="userType==='HM'">
                                        Delivered-CrossBorder
                                    </span>
                                    <span *ngIf="userType!=='HM'">
                                        Delivered
                                    </span>
                                </a>
                            </div>
                        </div>
                        <ng-container *ngIf="userType==='HM'">
                            <div class="row  mt-4" style="border-top: 1px solid;">
                                <div class="col mb-4 mt-4" style="border-right: 1px solid;">
                                    <a class="card-link" (click)="WidgetClicked('InTransitEU')">
                                        <span>
                                            In-Transit EU
                                        </span>
                                    </a>
                                </div>
                                <div class="col mt-4" style="white-space: nowrap;">
                                    <a class="card-link" (click)="WidgetClicked('DeliveredEU')">
                                        <span>
                                            Delivered EU
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </ng-container>


                    </div>
                </div>
            </ng-container>
        </ng-template>
    </div>
</div>


<div class="row justify-content-center" style="margin-top:40px">
    <ng-container *ngIf="userType!=='SLB'&&userType!=='DELL'&&userType!=='HM'">
        <div class="col-sm-12 col-md-3 col-lg Rating" style="cursor: not-allowed;">
            <div class="card align-items-center">
                <div class="card-body">
                    <div class="image3"></div>
                    <a class="card-link ratingCursorPermissions">
                        <span>
                            Rating
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="userType==='SLB'||userType==='DELL'||userType==='HM'">
        <div class="col-sm-12 col-md-3 col-lg CarbE" (click)="WidgetCarbEClicked()" *ngIf="removeTiles">
            <div class="card align-items-center">
                <div class="card-body">
                    <div class="image3"></div>
                    <a class="card-link" (click)="WidgetCarbEClicked()">
                        <span>
                            Carb-E
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </ng-container>

    <div class="col-sm-12 col-md-3 col-lg InvoiceAudit">
        <ng-container *ngIf="!InvoiceAuditpartitions">
            <a class="card-link InvoiceAudit" (click)="WidgetClicked('Invoice&Audit')">
                <div class="card align-items-center">
                    <div class="card-body">
                        <div class="image4"></div>

                        <span>
                            Invoice & Audit
                        </span>

                    </div>
                </div>
            </a>
        </ng-container>
        <ng-container *ngIf="InvoiceAuditpartitions">
            <div class="card align-items-center" style="height: 207px;">
                <div class="card-body" style="margin-top: 80px;">
                    <div class="row  mb-4" style="border-bottom: 1px solid;">
                        <div class="col" style="margin-top: -32px;">
                            <a class="card-link invoiceCursorPermissions" (click)="WidgetClicked('Invoice')">
                                <span style="white-space: nowrap;">
                                    Invoice
                                </span>
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col" style="border-right: 1px solid;">
                            <a class="card-link reconciliationCursorPermissions"
                                (click)="WidgetClicked('Reconcilation')">
                                <span style="white-space: nowrap;">
                                    Reconciliation
                                </span>
                            </a>
                        </div>
                        <div class="col">
                            <a class="card-link billingCursorPermissions" (click)="WidgetClicked('Billing')">
                                <span>
                                    Billing
                                </span>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </ng-container>
    </div>
    <div class="col-sm-12 col-md-3 col-lg Settlement" *ngIf="removeTiles">
        <div class="card align-items-center">
            <div class="card-body">
                <div class="imageSettlement"></div>
                <a class="card-link settlementCursorPermissions">
                    <span>
                        Settlement
                    </span>
                </a>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-3 col-lg analytics">
        <a class="card-link analytics" (click)="WidgetAnalyticsClicked()">
            <div class="card align-items-center">
                <div class="card-body">
                    <div class="imageAnalytics"></div>

                    <span>
                        Analytics
                    </span>

                </div>
            </div>
        </a>
    </div>

</div>