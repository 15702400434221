import { Injectable } from '@angular/core';
import { filter_ver1_ui, view_ver1_ui } from '../enums/roles.enum';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ViewChekingService {

  constructor() { }

  filter_ver1_ui: any;
  view_ver1_ui: any;
  userRole: any;
  viewCheking() {
    this.userRole = environment.userType;
    this.view_ver1_ui = Object.values(view_ver1_ui).filter((v) => isNaN(Number(v)));
    this.view_ver1_ui = this.view_ver1_ui.includes(this.userRole);
    return this.view_ver1_ui;
  }
  filterChecking() {
    this.userRole = environment.userType;
    this.filter_ver1_ui = Object.values(filter_ver1_ui).filter((v) => isNaN(Number(v)));
    this.filter_ver1_ui = this.filter_ver1_ui.includes(this.userRole);
    return this.filter_ver1_ui;
  }
}
