import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,
    private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // const path = next.url.join('/');
    // console.log('loginState: ', this.authService.loginStateChanged())
      console.log('userLoggedIN:', this.authService.isUserLoggedIn());
    if (!this.authService.isUserLoggedIn()) {
      // if (path !== 'login') {
      this.router.navigate(['login']);
      // return false;
      // }
      return false;
    }
    const currentRole = this.authService.getLoggedUser().userRole;
    let authorizedRoles = [];
    if (typeof next.data['roles'] === 'undefined') {
      authorizedRoles = [];
    } else if (next.data['roles'] === null) {
      authorizedRoles = [];
    } else {
      authorizedRoles = next.data['roles'];
    }
    if (authorizedRoles.indexOf(currentRole) <= -1) {
      return false;
    }
    return true;
  }
}
