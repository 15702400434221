import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { RequestService } from 'src/app/services/request.service';

@Component({
  selector: 'app-warehouse-infrastructure',
  templateUrl: './warehouse-infrastructure.component.html',
  styleUrls: ['./warehouse-infrastructure.component.scss']
})
export class WarehouseInfrastructureComponent implements OnInit {
  columnDefs: any;
  RowData: any;
  IsColumnsToFit: boolean;
  AgLoad: boolean;

  gridApi: any;
  gridColumnApi: any;
  eventparsing: any = [];
  OE_ED: any = [];
  SideOverlay = false;
  wiInvoiceNo: any;
  onRowClickedValue: any;
  dealerOrderNumber: any;
  clickedOnDealer: boolean;
  clickedOnINV: boolean;
  graphData: any;
  dateRange: string;
  periodLabel: any = 'Last 24 Hrs';


  constructor(private requestService: RequestService, private spinner: NgxSpinnerService) { }
  domLayout = 'autoHeight';
  options: any;

  chartData() {
    let key;
    let value;
    let cData: any = [];
    for ([key, value] of Object.entries(this.graphData)) {
      if (value !== 0) {
        cData.push(
          {
            duration: key,
            'Planned Pickup Date': value
          }
        )
      }
    }

    console.log('CData:', cData);
    this.options = {
      // title: { text: "New Orders" },
      // subtitle: { text: 'in billion U.S. dollars' },

      data: cData,
      series: [
        {
          type: 'column',
          xKey: 'duration',
          yKeys: ['Planned Pickup Date'],
          listeners: {
            nodeClick: (event) => {
              // console.log('clickedonchart:', datum);
              this.basedOnChart(event)
              // this.basedOnChart.bind(this)
              // this.gridOptions = 
              // this.gridOptions = {
              //   rowData: this.GetTableData(),
              //   columnDefs: this.GetAgColumns(),
              // }
            },
          },
          tooltip: {
            renderer: function (params) {
              return {
                title: params.xValue,
                content: params.yValue.toFixed(0),
              };
            },
          },
        },
      ],
      legend: {
        position: 'top',
      },
      axes: [
        {
          type: 'category',
          position: 'bottom',

          label: {
            // padding: 30,
            rotation: 90
          },
          // tick: { count: 20 },
        },
        {
          type: 'number',
          position: 'left'
        }
      ]
    };
  }

  BindData(params) {
    this.gridApi = params.api;
    this.gridApi.showLoadingOverlay();
    // params.columnApi.autoSizeAllColumns();
    // this.gridColumnApi = params.columnApi;
    // this.gridApi.getToolPanelInstance('filters').expandFilters();
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, false);
    var columnState = JSON.parse(localStorage.getItem('myBookingColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }


  onColumnMoved(params) {
    console.log("params:::")
    var columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem('myBookingColumnState', columnState);
  }

  onGridReady(params) {
    var columnState = JSON.parse(localStorage.getItem('myBookingColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }

  resetState() {
    this.gridColumnApi.resetColumnState();
    console.log('column state reset');
  }

  GetAgColumns() {
    this.columnDefs = [


      // { headerName: 'Item No', field: 'wi_item_no', sortable: true },
      // { headerName: 'HAWB No', field: 'si_houseway_waybill_no', sortable: true },

      { headerName: 'Order Date', field: 'ext_order_date', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Dealer No', field: 'ext_dealer_no', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Dealer Name', field: 'ext_order_by', sortable: true, suppressFiltersToolPanel: true },
      {
        headerName: 'Dearler Order No', field: 'ext_order_number', sortable: true, suppressFiltersToolPanel: true,
        onCellClicked: this.onRowClickedDealer.bind(this),
        cellRenderer: function (params) {
          let keyData = params.value;
          if (keyData !== null) {
            let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
            return newLink;
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'Invoice No', field: 'wi_invoice_no', sortable: true, suppressFiltersToolPanel: true,
        onCellClicked: this.onRowClicked.bind(this),
        cellRenderer: function (params) {
          let keyData = params.value;
          let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
          return newLink;
        }
      },
      {
        headerName: 'Planned PickUp date', field: 'wi_planned_pickup_date', sortable: true, suppressFiltersToolPanel: true,
        cellRenderer: function (params) {
          let data = params.value
          data = data.split('T')[0];
          return data
        }
      },
      { headerName: 'MOT', field: 'wi_mot', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Serial No', field: 'wi_serialno', sortable: true, suppressFiltersToolPanel: true },
      // { headerName: 'Allocated Part', field: 'wi_allocated_part', sortable: true, suppressFiltersToolPanel: true },
      // { headerName: 'Requested Part', field: 'wi_requested_part', sortable: true, suppressFiltersToolPanel: true },
      // { headerName: 'Part Description', field: 'wi_part_description', sortable: true, suppressFiltersToolPanel: true },
      // { headerName: 'Delivery Instruction', field: 'wi_delivery_instruction', sortable: true, suppressFiltersToolPanel: true },
      // { headerName: 'Vol Length', field: 'wi_vol_length', sortable: true, suppressFiltersToolPanel: true },
      // { headerName: 'Vol Width', field: 'wi_vol_width', sortable: true, suppressFiltersToolPanel: true },
      // { headerName: 'Vol Height', field: 'wi_vol_height', sortable: true, suppressFiltersToolPanel: true },
      // { headerName: 'NetWT', field: 'wi_netwt', sortable: true, suppressFiltersToolPanel: true },
      // { headerName: 'Loc inCharge', field: 'wi_loc_incharge', sortable: true, suppressFiltersToolPanel: true },
      // { headerName: 'Loc Code', field: 'wi_loc_code', sortable: true, suppressFiltersToolPanel: true },
      // { headerName: 'Package Unit', field: 'wi_package_unit', sortable: true, suppressFiltersToolPanel: true },


      // { headerName: 'Package Unit', field: 'wi_package_unit', sortable: true, suppressFiltersToolPanel: true },
      // { headerName: 'Allocation No', field: 'wi_allocation_no', sortable: true, suppressFiltersToolPanel: true },

      // { headerName: 'Currency', field: 'wi_currency', sortable: true, suppressFiltersToolPanel: true },
      // { headerName: 'Conversion Rate', field: 'wi_conversion_rate', sortable: true, suppressFiltersToolPanel: true },
      // { headerName: 'Unit Price', field: 'wi_unit_price', sortable: true, suppressFiltersToolPanel: true },
    ];

  }

  clear() {
    window.location.reload();
  }

  defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
  };

  sideBar = {
    hiddenByDefault: false,
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          allowDragFromColumnsToolPanel: true
        },
      },
      // {
      //   id: 'filters',
      //   labelDefault: 'Shipper Account',
      //   labelKey: 'filters',
      //   iconKey: 'filter',
      //   toolPanel: 'agFiltersToolPanel',
      // }
    ]
  }

  gridOptions = {
    rowData: this.GetTableData(),
    columnDefs: this.GetAgColumns(),
  }

  GetTableData() {
    // this.AgLoad = true;;
    const dis = this;
    let req = null;
    let filter: any = {};
    this.RowData = []
    req = dis.requestService.WarehouseInfrastructure(this.dateRange);
    req.subscribe(resp => {
      const messageResult = resp.data;
      this.RowData = messageResult;
      this.graphData = resp.graphData;
      this.chartData();
      this.spinner.hide();
    });
  }

  onRowClicked(event) {
    this.clickedOnINV = true;
    this.clickedOnDealer = false;
    const dis = this;
    let req: any;
    this.wiInvoiceNo = event.data.wi_invoice_no;
    // console.log('ccccccccccccc:', this.wiInvoiceNo);
    this.spinner.show();
    let req1 = {
      wi_invoice_no: this.wiInvoiceNo
    }
    req = dis.requestService.warehouseMoreDetails(req1);
    req.subscribe(resp => {
      const messageResult = resp;
      // console.log("messsss", messageResult);
      this.onRowClickedValue = messageResult;
      this.SideOverlay = true;
      this.spinner.hide();
    });
  }

  onRowClickedDealer(event) {
    this.clickedOnDealer = true;
    this.clickedOnINV = false;
    const dis = this;
    let req: any;
    this.dealerOrderNumber = event.data.ext_order_number;
    console.log('ccccccccccccc:', this.wiInvoiceNo);
    let req1 = {
      ext_order_number: this.dealerOrderNumber
    }
    this.spinner.show();
    req = dis.requestService.warehouseMoreDetails(req1);
    req.subscribe(resp => {
      const messageResult = resp;
      console.log("messsss", messageResult);
      this.onRowClickedValue = messageResult;
      this.SideOverlay = true;
      this.spinner.hide();
    });
  }



  closemodal() {
    this.SideOverlay = false;
  }

  ngOnInit(): void {
  }

  basedOnChart(e) {
    this.spinner.show();
    console.log('clickedOnChart:', e);
    const currentDate = new Date();

    // let timeFrames = {
    //   "Last 24 Hrs": [new Date((new Date()).setDate(currentDate.getDate() - 1)), currentDate],
    //   "Last 7 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7))), new Date((new Date()).setDate(currentDate.getDate() - 1))],
    //   "Last 15 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 7)))],
    //   "Last 30 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15 + 30))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15)))],
    //   "Last 90 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15 + 30 + 90))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15 + 30)))],
    // }
    let timeFrames: any = {

      "Last 24 Hrs": [new Date((new Date()).setDate(currentDate.getDate() - 1)), currentDate],

      "Last 48 Hrs": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 2))), new Date((new Date()).setDate(currentDate.getDate() - 1))],

      "Last 72 Hrs": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 2 + 3))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 2)))],

      "Last 1 Week": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 2 + 3 + 7))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 2 + 3)))],

      "Above 1 Week": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 2 + 3 + 7 + 365))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 2 + 3 + 7)))],

    }
    let fromDate: any;
    let toDate: any

    // if (e.datum.duration === 'Last 30 Days') {
    //   fromDate = new Date(timeFrames["Last 30 Days"][0]).toISOString();
    //   toDate = new Date(timeFrames["Last 30 Days"][1]).toISOString()
    // }
    // if (e.datum.duration === 'Last 90 Days') {
    //   fromDate = new Date(timeFrames["Last 90 Days"][0]).toISOString();
    //   toDate = new Date(timeFrames["Last 90 Days"][1]).toISOString()
    // }
    // if (e.datum.duration === 'Last 15 Days') {
    //   fromDate = new Date(timeFrames["Last 15 Days"][0]).toISOString();
    //   toDate = new Date(timeFrames["Last 15 Days"][1]).toISOString()
    // }
    // if (e.datum.duration === 'Last 7 Days') {
    //   fromDate = new Date(timeFrames["Last 7 Days"][0]).toISOString();
    //   toDate = new Date(timeFrames["Last 7 Days"][1]).toISOString()
    // }
    // if (e.datum.duration === 'Last 24 Hrs') {
    //   fromDate = new Date(timeFrames["Last 24 Hrs"][0]).toISOString();
    //   toDate = new Date(timeFrames["Last 24 Hrs"][1]).toISOString()
    // }

    if (e.datum.duration === 'Above 1 Week') {
      fromDate = new Date(timeFrames["Above 1 Week"][0]).toISOString();
      toDate = new Date(timeFrames["Above 1 Week"][1]).toISOString()
    }
    if (e.datum.duration === 'Last 1 Week') {
      fromDate = new Date(timeFrames["Last 1 Week"][0]).toISOString();
      toDate = new Date(timeFrames["Last 1 Week"][1]).toISOString()
    }
    if (e.datum.duration === 'Last 72 Hrs') {
      fromDate = new Date(timeFrames["Last 72 Hrs"][0]).toISOString();
      toDate = new Date(timeFrames["Last 72 Hrs"][1]).toISOString()
    }
    if (e.datum.duration === 'Last 48 Hrs') {
      fromDate = new Date(timeFrames["Last 48 Hrs"][0]).toISOString();
      toDate = new Date(timeFrames["Last 48 Hrs"][1]).toISOString()
    }
    if (e.datum.duration === 'Last 24 Hrs') {
      fromDate = new Date(timeFrames["Last 24 Hrs"][0]).toISOString();
      toDate = new Date(timeFrames["Last 24 Hrs"][1]).toISOString()
    }
    this.periodLabel = e.datum.duration;
    fromDate = moment(fromDate).format('YYYY-MM-DD HH:mm:ss');
    toDate = moment(toDate).format('YYYY-MM-DD HH:mm:ss');
    console.log(fromDate, toDate);
    this.dateRange = fromDate + ', ' + toDate;
    this.GetTableData();
    this.GetAgColumns();

  }

}
