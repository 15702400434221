import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { RequestService } from 'src/app/services/request.service';

@Component({
  selector: 'app-consumer-orders-exceptions',
  templateUrl: './consumer-orders-exceptions.component.html',
  styleUrls: ['./consumer-orders-exceptions.component.scss']
})
export class ConsumerOrdersExceptionsComponent implements OnInit {
  columnDefs: any;
  RowData: any;
  IsColumnsToFit: boolean;
  AgLoad: boolean;

  gridApi: any;
  gridColumnApi: any;
  eventparsing: any = [];
  OE_ED: any = [];
  SideOverlay = false;
  options: any;
  dateRange: any;
  graphData: any;
  showNoRows: boolean = false;

  statusData = [];
  selectedStatus = [];
  statusDropdownSettings = {};
  statusDataFromTable: any;
  statusID = [];

  orderData: any = [

    {
      duration: '24 hours',
      B: 23,
      c: 44
    },
    {
      duration: 'Last 7 days',
      Total_records: 40,
      B: 4,
      c: 10,
      c1: 20
    },
    //   {
    //     duration: 'Last 15 days',
    //     Total_records: 60
    //   },
    //   {
    //     duration: 'Last 30 days',
    //     Total_records: 100,
    //   },
    //   {
    //     duration: 'Last 90 days',
    //     Total_records: 200
    //   },
  ]
  periodLabel: any = 'Last 24 Hrs';
  constructor(private requestService: RequestService, private spinner: NgxSpinnerService) { }
  domLayout = 'autoHeight';

  status_dropown() {
    const statuss = [];
    for (let i = 0; i < this.statusDataFromTable.length; i++) {
      statuss.push(this.statusDataFromTable[i].DISTINCT);
    }
    this.statusData = statuss;
    this.statusDropdownSettings = {
      singleSelection: false,
      idField: 'statusId',
      textField: 'statusId',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  statusSelect(items: any) {
    console.log('# ', this.selectedStatus);
    this.statusID = this.selectedStatus;
    this.spinner.show();
    this.GetTableData();
  }
  statusSelectAll(items: any) {
    console.log('#selectAll: ', items);
    this.statusID = items;
    this.spinner.show();
    this.GetTableData();
  }
  onStatusDeSelect(item: any) {
    this.statusID = this.selectedStatus;
    this.spinner.show();
    this.GetTableData();
  }

  onStatusDeselectAll(items: any) {
    this.statusID = null;
    this.spinner.show();
    this.GetTableData();
  }

  chartData() {
    let key;
    let value;
    let indexOfData = [];
    let cData: any = [];
    // console.log('graphDATAAAAAAAAA:', this.graphData);
    if (this.graphData !== undefined) {
      console.log('graphDATAAAAAAAAA:', this.graphData);
      cData = this.graphData.filter(data => Object.keys(data).length > 1)
        
        // let count = Object.keys(data).length;
        // if (count > 1) {
        //   console.log('#count1111graphData:', data);
        //   indexOfData.push(data);
        // }  
      
      
      console.log('#splicedgraphData:', this.graphData);
      
      console.log('CData:', cData);

      this.options = {
        // title: { text: "New Orders" },
        // subtitle: { text: 'in billion U.S. dollars' },

        data: cData,
        // data: this.orderData,
        series: [
          {
            type: 'column',
            xKey: 'duration',
            yKeys: ['B', 'C1', 'C2', 'C3', 'C4', 'C5'],
            yNames: ['B', 'C1', 'C2', 'C3', 'C4', 'C5'],
            grouped: true,
            listeners: {
              nodeClick: (event) => {
                // console.log('clickedonchart:', datum);
                this.basedOnChart(event)
                // this.basedOnChart.bind(this)
                // this.gridOptions = 
                // this.gridOptions = {
                //   rowData: this.GetTableData(),
                //   columnDefs: this.GetAgColumns(),
                // }
              },
            },
            tooltip: {
              renderer: function (params) {
                return {
                  title: params.yName,
                  content: params.xValue + ': ' + params.yValue.toFixed(0),
                };
              },
            },
          },
        ],
        legend: {
          position: 'top',
        },
        axes: [
          {
            type: 'category',
            position: 'bottom',

            label: {
              // padding: 30,
              rotation: 90
            },
            // tick: { count: 20 },
          },
          {
            type: 'number',
            position: 'left'
          }
        ]
      };
    }
  }



  BindData(params) {
    this.gridApi = params.api;
    this.gridApi.showLoadingOverlay();
    if (this.showNoRows) {
      this.gridApi.showNoRowsOverlay();
    }
    params.columnApi.autoSizeAllColumns();
    this.gridColumnApi = params.columnApi;
    // this.gridApi.getToolPanelInstance('filters').expandFilters();
    var columnState = JSON.parse(localStorage.getItem('myBookingColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }


  onColumnMoved(params) {
    console.log("params:::")
    var columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem('myBookingColumnState', columnState);
  }

  onGridReady(params) {
    var columnState = JSON.parse(localStorage.getItem('myBookingColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }

  resetState() {
    this.gridColumnApi.resetColumnState();
    console.log('column state reset');
  }

  GetAgColumns() {
    this.columnDefs = [
      { headerName: 'Status', field: 'pe_status', sortable: true, suppressFiltersToolPanel: true },
      {
        headerName: 'Order Date', field: 'pe_order_date', sortable: true, suppressFiltersToolPanel: true, valueFormatter: onlydateFormatter, filter: 'agTextColumnFilter',
        // cellRenderer: function (params) {
        //   let data = params.value
        //   data = data.split('T')[0];
        //   return data
        // }
      },
      { headerName: 'Dealer No', field: 'pe_dealer_no', sortable: true, suppressFiltersToolPanel: true, filter: 'agTextColumnFilter' },
      { headerName: 'Dealer Name', field: 'pe_order_by', sortable: true, suppressFiltersToolPanel: true, filter: 'agTextColumnFilter', },
      { headerName: 'Dealer Order No', field: 'pe_order_number', sortable: true, suppressFiltersToolPanel: true, filter: 'agTextColumnFilter', },
      { headerName: 'Buyer', field: 'pe_buyer', sortable: true, suppressFiltersToolPanel: true, filter: 'agTextColumnFilter', },
      { headerName: 'Invoice No', field: 'pe_invoice_no', sortable: true, suppressFiltersToolPanel: true, filter: 'agTextColumnFilter', },
      { headerName: 'Item No', field: 'pe_item_no', sortable: true, suppressFiltersToolPanel: true, filter: 'agTextColumnFilter', },
      { headerName: 'Part Number', field: 'pe_part_number', sortable: true, suppressFiltersToolPanel: true, filter: 'agTextColumnFilter', },
      { headerName: 'Part Name', field: 'pe_part_name', sortable: true, suppressFiltersToolPanel: true, filter: 'agTextColumnFilter', },
      { headerName: 'Confirmed Order Qty', field: 'pe_confirmed_order_qty', sortable: true, suppressFiltersToolPanel: true, filter: 'agTextColumnFilter' },
      {
        headerName: 'Reply Date', field: 'pe_reply_date', sortable: true, valueFormatter: onlydateFormatter, filter: 'agDateColumnFilter',
        // cellRenderer: function (params) {
        //   let data = params.value
        //   data = data.split('T')[0];
        //   return data
        // }
      },
      { headerName: 'Shipped Pno', field: 'pe_shipped_pno', sortable: true, suppressFiltersToolPanel: true, filter: 'agTextColumnFilter' },
      { headerName: 'Manufacturer', field: 'pe_manufacturer', sortable: true, suppressFiltersToolPanel: true, filter: 'agTextColumnFilter' },
      { headerName: 'TBA', field: 'pe_tba', sortable: true, suppressFiltersToolPanel: true, valueFormatter: onlydateFormatter, filter: 'agTextColumnFilter' },
      {
        headerName: 'ETD', field: 'pe_etd', sortable: true, suppressFiltersToolPanel: true, hide: true, filter: 'agTextColumnFilter',
        cellRenderer: function (params) {
          let data = params.value
          data = data.split('T')[0];
          return data
        }
      },
      { headerName: 'Shipper Comment', field: 'pe_shipper_comment', sortable: true, suppressFiltersToolPanel: true, filter: 'agTextColumnFilter' },
    ];

  }

  clear() {
    window.location.reload();
  }

  defaultColDef = {
    sortable: true,
    // filter: 'agTextColumnFilter',
    filter: true,
    searchable: true,
    floatingFilter: true,
    resizable: true,
  };

  sideBar = {
    hiddenByDefault: false,
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          allowDragFromColumnsToolPanel: true
        },
      },
      // {
      //   id: 'filters',
      //   labelDefault: 'Shipper Account',
      //   labelKey: 'filters',
      //   iconKey: 'filter',
      //   toolPanel: 'agFiltersToolPanel',
      // }
    ]
  }

  gridOptions = {
    rowData: this.GetTableData(),
    columnDefs: this.GetAgColumns(),
  }

  GetTableData() {
    // this.AgLoad = true;;
    const dis = this;
    let req = null;
    let filter: any = {};
    this.RowData = [];
    let req1 = {
      dateRange: this.dateRange,
      pe_status: this.statusID
    }
    req = dis.requestService.purchaseOrderExceptions(req1);
    req.subscribe(resp => {
      const messageResult = resp.data;
      this.showNoRows = false;
      if (messageResult.length === 0) {
        this.showNoRows = true;
      }
      console.log("messageResult", messageResult)
      this.RowData = messageResult;
      this.statusDataFromTable = resp.status;
      this.graphData = resp.graphData;
      this.status_dropown();
      console.log('getTableDAtaGraphData:', this.graphData);
      this.chartData();
      this.spinner.hide();
    });
  }

  ngOnInit(): void {
    this.spinner.show();
    this.chartData();
    this.spinner.hide();
  }
  basedOnChart(e) {
    this.spinner.show();
    console.log('clickedOnChart:', e);
    const currentDate = new Date();

    let timeFrames = {
      "Last 24 Hrs": [new Date((new Date()).setDate(currentDate.getDate() - 1)), currentDate],
      "Last 7 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7))), new Date((new Date()).setDate(currentDate.getDate() - 1))],
      "Last 15 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 7)))],
      "Last 30 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15 + 30))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15)))],
      "Last 90 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15 + 30 + 90))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15 + 30)))],
    }
    let fromDate: any;
    let toDate: any

    if (e.datum.duration === 'Last 30 Days') {
      fromDate = new Date(timeFrames["Last 30 Days"][0]).toISOString();
      toDate = new Date(timeFrames["Last 30 Days"][1]).toISOString()
    }
    if (e.datum.duration === 'Last 90 Days') {
      fromDate = new Date(timeFrames["Last 90 Days"][0]).toISOString();
      toDate = new Date(timeFrames["Last 90 Days"][1]).toISOString()
    }
    if (e.datum.duration === 'Last 15 Days') {
      fromDate = new Date(timeFrames["Last 15 Days"][0]).toISOString();
      toDate = new Date(timeFrames["Last 15 Days"][1]).toISOString()
    }
    if (e.datum.duration === 'Last 7 Days') {
      fromDate = new Date(timeFrames["Last 7 Days"][0]).toISOString();
      toDate = new Date(timeFrames["Last 7 Days"][1]).toISOString()
    }
    if (e.datum.duration === 'Last 24 Hrs') {
      fromDate = new Date(timeFrames["Last 24 Hrs"][0]).toISOString();
      toDate = new Date(timeFrames["Last 24 Hrs"][1]).toISOString()
    }
    this.periodLabel = e.datum.duration;
    fromDate = moment(fromDate).format('YYYY-MM-DD HH:mm:ss');
    toDate = moment(toDate).format('YYYY-MM-DD HH:mm:ss');
    console.log(fromDate, toDate);
    this.dateRange = fromDate + ', ' + toDate;
    this.statusID = [e.yKey];
    this.GetTableData();
    this.GetAgColumns();

  }

}
function dateFormatter(params) {
  return moment(params.value).format('YYYY-MM-DD HH:mm')
}

function onlydateFormatter(params): any {
  if (params.value !== null) {
    return moment(params.value).format('YYYY-MM-DD')
  }
}
