import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvSelectionService {

  constructor() { }
  endPoint: any;
  redirectingUrlObj: any;

  endPointSelection(inputEndPoint: any) {
    this.endPoint = inputEndPoint;
  }

  getEndPoint() {
    return this.endPoint;
  }

  redirectUrlSelection(analytics: any, carbe: any, vndord: any, invaud: any) {
    this.redirectingUrlObj = {
      'analytics': analytics,
      'carbe': carbe,
      'vndord': vndord,
      'invaud': invaud
    }
  }

  getRedirectingUrl() {
    return this.redirectingUrlObj;
  }

}
