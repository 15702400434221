export const environment = {
    production: false,

    uat_url: "https://shipping-api.klivolks.com",
    prod_url: "https://shipping-api.klivolks.com",
    beta_url: "https://shipping-api.klivolks.com",
    // Bless related end points UAT/PROD/BETA.
    blessllp_uat_url: "https://shipping-api.klivolks.com",
    blessllp_prod_url: "https://shipping-api.klivolks.com",
    blessllp_beta_url: "https://shipping-api.klivolks.com",



    port: '/hm/shipment',
    port1: '/message',
    userType: 'HM',



    uat_Analytics: 'https://shipping-api.klivolks.com/hm/analytics1/#',
    uat_Vndord: '',
    uat_Invaud: '',
    uat_Carbe: 'https://shipping-api.klivolks.com/hm/carbe/#',
    // PRODUCTION redirection URL's.
    prod_Analytics: '',
    prod_Carbe: '',
    prod_Vndord: '',
    prod_Invaud: '',
    // BETA redirection URL's.
    beta_Analytics: 'https://beta-transilp.blesstrace.com/hm/analytics/#',
    beta_Carbe: 'https://beta-transilp.blesstrace.com/hm/carbe/#',
    beta_Vndord: '',
    beta_Invaud: '',
    // Bless UAT redirection URL's.
    blessllp_uat_Analytics: '',
    blessllp_uat_Carbe: '',
    blessllp_uat_Vndord: '',
    blessllp_uat_Invaud: '',
    // Bless BETA redirection URL's.
    blessllp_beta_Analytics: '',
    blessllp_beta_Carbe: '',
    blessllp_beta_Vndord: '',
    blessllp_beta_Invaud: '',
    // Bless PRODUCTION redirection URL's.
    blessllp_prod_Analytics: '',
    blessllp_prod_Carbe: '',
    blessllp_prod_Vndord: '',
    blessllp_prod_Invaud: '',




    consumerOrders: false,
    transportRequest: false,
    forwarderCourier: false,
    shipment: true,
    Rating: '',
    invoiceAndAudit: false,
    Settlement: '',
    analyticsWidget: false,
    favIconUrl: './assets/images/H&M/fav-icon.png'
};
