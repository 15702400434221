<div class="test-container">
    <div class="test-header">
        <div class="example-section">
            <div class="row mt-3">
                <div class="col-3 ml-3">
                    <div class="view-icons span mt-4 float-left">
                        Exceptions of Transactions
                    </div>
                </div>
                <div class="ml-3">
                    <div class="view-icons span mt-4 float-left">
                        {{periodLabel}}
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <label for="">Status</label>
                        <ng-multiselect-dropdown [data]="statusData" [(ngModel)]="selectedStatus"
                            [settings]="statusDropdownSettings" (onSelect)="statusSelect($event)" (onSelectAll)="statusSelectAll($event)"
                            (onDeSelect)="onStatusDeSelect($event)" (onDeSelectAll)="onStatusDeselectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="col">

                </div>
                <div class="col-auto float-right ml-auto">
                    <a class="btn btn-secondary mb-3" style="float: right;" (click)="resetState()"> Original Layout </a>
                    <div class="view-icons">
                        <a (click)="clear()" title="Clear" class="grid-view btn btn-link"><i
                                class="fa fa-refresh"></i></a>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-sm-3 graphHeight border" *ngIf="graphData !== undefined">
            <ag-charts-angular [options]="options"></ag-charts-angular>
        </div>
        <div class="col-sm-9">
            <ag-grid-angular class="ag-theme-balham" [columnDefs]="columnDefs" [domLayout]="domLayout"
                [rowData]="RowData" [debug]="true" [masterDetail]="true" [sideBar]="false"
                [enableRangeSelection]="true" [animateRows]="true" [pagination]="true" [defaultColDef]="defaultColDef"
                [paginationPageSize]="25" [floatingFilter]="false" [enableCharts]="true" (gridReady)="BindData($event)"
                (columnMoved)="onColumnMoved($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>