import { Injectable } from '@angular/core';

declare var $: any;

declare var bootbox: any;


@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor() { }

  // showLoading() {
  //   this.loadingService.show();
  // }

  // hideLoading() {
  //   this.loadingService.hide();
  // }

  showErrorMessage(msg: string) {
    $.notify({ message: msg, title: 'Error' }, { autoHideDelay: 10000, className: 'error' });
  }

  showSuccessMessage(msg: string) {
    $.notify({ message: msg, title: 'Success' }, { autoHideDelay: 10000, className: 'success' });
  }

  showWarningMessage(msg: string) {
    $.notify({ message: msg, title: 'Warning' }, { autoHideDelay: 10000, className: 'warn' });
  }
  public alert(msg: any) {

    var successBox = bootbox.alert({
      size: "medium",
      title: "Status",
      message: "<strong>" + msg + "</strong>",
      className: "successPopup",
      callback: function () { /* your callback code */ }
    })
    successBox.init(function () {
      $(".successPopup").find(".modal-header").css({ "background-color": "rgb(247, 247, 247)", "color": "black" });
      $(".bootbox.modal").find(".modal-footer").find('button-default').css({ "background-color": "#fdcc30", "color": "white" });
      $(".bootbox.modal").find(".modal-content").css({ "width": "635px" });

    });
  }
  // public alertFailure(msg) {

  //   var FailureBox = bootbox.alert({
  //     size: "medium",
  //     title: "Failure",
  //     message: "<strong>" + msg + "</strong>",
  //     className: "FailureBoxPopup",
  //     callback: function () { /* your callback code */ }
  //   })
  //   FailureBox.init(function () {
  //     $(".FailureBoxPopup").find(".modal-header").css({ "background-color": "#fdcc30", "color": "white" });
  //     $(".bootbox.modal").find(".modal-footer").find('button-default').css({ "background-color": "#fdcc30", "color": "white" });
  //     $(".bootbox.modal").find(".modal-content").css({ "width": "635px" });

  //   });
  // }
}
