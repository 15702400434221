<div class="test-container">
    <div class="test-header">
        <div class="example-section">
            <!-- <button (click)="saveState()">Save State</button>
        <button (click)="restoreState()">Restore State</button> -->
            <div class="row mt-3 pl-3">
                <div class="view-icons span marginTopStyle" *ngIf="userRole === 'BMX'">
                    <span *ngIf="MD_lspOrgName === 'DGF'">
                        DHL GLOBAL FORWRDING
                    </span>
                    <span *ngIf="MD_lspOrgName === 'DHLE'">
                        DHL EXPRESS
                    </span>
                    ,
                    {{MD_LabelName}}
                </div>
                <div class="view-icons span marginTopStyle" *ngIf="userRole !== 'BMX'">
                    {{MD_LabelName}}
                    <span *ngIf="DeliveredcustomerOrgName !== null">
                        , {{DeliveredcustomerOrgName}}
                    </span>
                </div>
                <!-- <div class="col">
                    <ng-multiselect-dropdown [placeholder]="'Customer'" [data]="CodearrayDataCustomer"
                        [(ngModel)]="CustomerselectedItems" [settings]="CustomerdropdownSettings"
                        (onSelect)="onCustomerItemSelect($event)" (onDeSelect)="onCustomerDeSelect($event)">
                    </ng-multiselect-dropdown>
                </div> -->
                <!-- <div class="col">
                    <ng-multiselect-dropdown [placeholder]="'Courier/ forwarder'"
                        [data]="CodearrayDataCourier_forwarder" [(ngModel)]="Courier_forwarderselectedItems"
                        [settings]="Courier_forwarderdropdownSettings" [disabled]="disabled"
                        (onSelect)="onCourier_forwarderItemSelect($event)"
                        (onDeSelect)="onCourier_forwarderDeSelect($event)">
                    </ng-multiselect-dropdown>
                </div> -->
                <ng-container *ngIf="MDlspOrgId == 'DHLE' || MDlspOrgId== 'DGF'">
                    <div class="col-sm-12 col-md-6 col-lg-3">
                        <div class="form-group">
                            <label for="">Account</label>
                            <ng-multiselect-dropdown [data]="CodearrayDataAccount" [(ngModel)]="AccountselectedItems"
                                [settings]="AccountdropdownSettings" (onSelect)="AccountItemSelect($event)"
                                (onDeSelect)="onAccountDeSelect($event)">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg" *ngIf="userRole === 'Nissan'">
                        <div class="form-group">
                            <label for="">Dealer</label>
                            <ng-multiselect-dropdown [data]="dealerData" [(ngModel)]="selectedDealer"
                                [settings]="dealerDropdownSettings" (onSelect)="dealerSelect($event)"
                                (onDeSelect)="onDealerDeSelect($event)">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>

                </ng-container>
                <div class="col ml-auto marginTopStyle">
                    <a class="btn btn-secondary mb-3" style="float: right;" (click)="resetState()"> Original Layout </a>
                    <div class="view-icons">
                        <a routerLink="/DeliveredDashboard" title="Back" class="grid-view btn btn-link active"><i
                                class="fa fa-arrow-circle-left"></i></a>
                    </div>
                    <div class="view-icons">
                        <a (click)="clear()" title="Clear" class="grid-view btn btn-link"><i
                                class="fa fa-refresh"></i></a>
                    </div>

                </div>
            </div>



        </div>
    </div>
</div>

<div>
    <!-- <button class="btn" type="button" (click)="autoSizeColoumns()">autoSizeAll</button> -->
    <!-- <table id="dynamic-table" class="table table-stripedtable-hover"> -->
    <ng-container *ngIf="!view_ver1_ui">
        <ag-grid-angular class="ag-theme-balham" [columnDefs]="columnDefs" [domLayout]="domLayout" [rowData]="RowData"
            [debug]="true" [masterDetail]="true" [sideBar]="false" [enableRangeSelection]="true" [animateRows]="true"
            [pagination]="true" [defaultColDef]="defaultColDef" [paginationPageSize]="25" [enableCharts]="true"
            (gridReady)="BindData($event)" (columnMoved)="onColumnMoved($event)">
        </ag-grid-angular>
    </ng-container>

    <ng-container *ngIf="view_ver1_ui">
        <ag-grid-angular class="ag-theme-balham" [columnDefs]="columnDefs" [domLayout]="domLayout" [debug]="true"
            [animateRows]="true" [rowModelType]="rowModelType" [serverSideStoreType]="serverSideStoreType"
            [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize" [defaultColDef]="defaultColDef"
            [pagination]="true" (gridReady)="BindDataPagination($event)">
        </ag-grid-angular>
    </ng-container>

    <!-- </table> -->
</div>


<ng-container *ngIf="SideOverlay === true">
    <div id="myNav" class="overlay" *ngIf="onRowClickedValue!=undefined" mwlResizable [enableGhostResize]="true"
        [resizeEdges]="{bottom: true, right: true, top: true, left: true}" (resizeEnd)="onResizeEnd($event)"
        [ngStyle]="style2">
        <div class="container">
            <div class="row" style="border-bottom: 1px solid #dee2e6;">
                <div class="col"></div>
                <div class="col">
                    <h5 class="mt-4 font-weight-bold">HAWB#: <span class="btn btn-link font-weight-bold"
                            style="font-size: 1.25rem;" (click)="openHWABdetails()">{{onRowClickedValue.HAWB}}</span>
                    </h5>
                    <h5 class="font-weight-bold" style="margin-top: -12px;">
                        MAWB#: <span class="font-weight-bold" style="font-size: 1.25rem;">{{mwb}}</span>
                    </h5>
                </div>
                <div class="col mt-4" *ngIf="legsArray.length !== 0">
                    <div class="row">
                        <div class="">
                            <img src="https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ffcd36">
                            <h6>{{legsArray[0]}}</h6>
                        </div>

                        <div class="mt-2 ml-2">
                            <span *ngFor="let legsData of legsArray | slice:1:legsArray.length - 1">
                                ----> {{legsData}}
                            </span>
                            ---->
                        </div>
                        <div class="ml-2">
                            <img src="https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ea4335">
                            <h6> {{legsArray[legsArray.length-1]}}</h6>
                        </div>
                    </div>
                </div>
                <div class="col-1 mt-4" *ngIf="showBackbutton">
                    <div class="view-icons mt-4">
                        <a href="javascript:void(0)" (click)="backHAWB()" title="Back"
                            class="grid-view btn btn-link active"><i class="fa fa-arrow-circle-left"></i></a>
                    </div>
                </div>

                <div class="col-1 mt-4" *ngIf="showNextbutton">
                    <div class="view-icons mt-4">
                        <a href="javascript:void(0)" (click)="nextHAWB()" title="Next"
                            class="grid-view btn btn-link active"><i class="fa fa-arrow-circle-right"></i></a>
                    </div>
                </div>
                <div class="col-1">
                    <a href="javascript:void(0)" class="" style="text-align:right;" (click)="closemodal()">&times;</a>
                </div>
            </div>
        </div>


        <div class="overlay-content">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <!-- <div class="container"> -->
                        <!-- <div class="row"> -->
                        <!-- <div class="col-sm-12 col-md-12 col-lg-12 EC justify-content-center"> -->
                        <div class="scrollmenu">
                            <!-- <h5 class="card-title">
                                        <span>
                                            <b><u class="pl-2">Events</u></b>
                                        </span>
                                    </h5> -->
                            <!-- <div class="row"> -->
                            <!-- <div>
                                            <h5 class="card-title">
                                                <span>
                                                    <b><u class="pl-2">Events</u></b>
                                                </span>
                                            </h5>
                                        </div> -->
                            <!-- <div class="col mt-2"> -->
                            <div class="mt-2">
                                <div class="mmm">
                                    <ng-container *ngIf="!view_ver1_ui">
                                        <h5 class="d-flex"><b><u>Events</u></b></h5>

                                        <span class="timeline ml-3 d-flex">
                                            <ul class="p-0" id="reverseCount">
                                                <li *ngFor="let eventparsing of eventparsing;let i=index">
                                                    <div>
                                                        <div class="circle"></div>
                                                        <div style="font-size: 10px;">
                                                            <div class="lable">
                                                                <span class="label label-success"
                                                                    *ngIf="eventparsing.location !== null">
                                                                    {{eventparsing.location}} /
                                                                    {{eventparsing.description}}
                                                                </span>
                                                                <span class="label label-success"
                                                                    *ngIf="eventparsing.location === null">
                                                                    {{eventparsing.description}}
                                                                </span>

                                                            </div>
                                                            <div class="title">{{eventparsing.sortDate |
                                                                date:'dd-MMM-YYYY HH:mm'}} / UTC Time
                                                            </div>
                                                        </div>
                                                    </div>

                                                </li>
                                            </ul>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="view_ver1_ui">

                                        <h5 class="d-flex"><b><u>Events in UTC Time</u></b></h5>
                                        <div class="accordion md-accordion mt-4 mb-5" id="accordionEx1" role="tablist"
                                            aria-multiselectable="true">
                                            <span class="d-flex" *ngIf="eventchainv2Resp.length !==0">
                                                <ul class="p-0">
                                                    <div class="card"
                                                        *ngFor="let eventchainv2Resp of eventchainv2Resp;index as i">
                                                        <!-- Card header -->
                                                        <div class="card-header" role="tab" [id]="'headingTwo6'+i">
                                                            <a class="collapsed" data-toggle="collapse"
                                                                data-parent="#accordionEx1" [href]="'#collapseTwo6'+i"
                                                                aria-expanded="false"
                                                                [attr.aria-controls]="'collapseTwo6'+i">
                                                                <h5 class="mb-0" style="color: black;">
                                                                    {{eventchainv2Resp['result']['eventGroup']}}

                                                                    <!-- <i class="fa fa-file-text-o ml-4" title="Documents"
                                                                        style="color: grey;cursor:auto"
                                                                        aria-disabled="true"></i> -->
                                                                </h5>
                                                                <h6 class="card-subtitle  mt-3 mb-3 ml-3 text-muted">
                                                                    {{eventchainv2Resp['result']['latestEventLocation']}}
                                                                    /
                                                                    {{eventchainv2Resp['result']['latestEventDesc']}}
                                                                </h6>
                                                                <h6 class="card-subtitle mb-2 ml-3 text-muted">
                                                                    {{eventchainv2Resp['result']['latestEventDate']
                                                                    |
                                                                    date:'dd-MMM-YYYY HH:mm':'UTC'}}
                                                                </h6>
                                                            </a>
                                                        </div>
                                                        <!-- Card body -->
                                                        <div [id]="'collapseTwo6'+i" class="collapse" role="tabpanel"
                                                            data-parent="#accordionEx1"
                                                            [attr.aria-labelledby]="'headingTwo5'+i">
                                                            <div class="card-body">
                                                                <span class="timeline ml-3 d-flex">
                                                                    <ul class="p-0" id="reverseCountV2">
                                                                        <li
                                                                            *ngFor="let eventparsing of eventchainv2Resp['result']['events'];let i=index">
                                                                            <div>
                                                                                <div class="circle"></div>
                                                                                <div>
                                                                                    <div class="lable">
                                                                                        <span
                                                                                            class="label label-success"
                                                                                            *ngIf="eventparsing.location !== null">
                                                                                            {{eventparsing.location}} /
                                                                                            {{eventparsing.description}}
                                                                                        </span>
                                                                                        <span
                                                                                            class="label label-success"
                                                                                            *ngIf="eventparsing.location === null">
                                                                                            {{eventparsing.description}}
                                                                                        </span>

                                                                                    </div>
                                                                                    <div class="title"
                                                                                        style="font-size:13px;">
                                                                                        {{eventparsing.sortDate}}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </li>
                                                                    </ul>
                                                                </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </ul>
                                            </span>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>

                        </div>



                        <!-- </div> -->
                        <!-- </div> -->
                        <!-- </div> -->
                    </div>

                    <div class="col-sm-12 col-md-9 col-lg-9 col-xl-9" style="margin-bottom: 5%;">
                        <!--Accordion wrapper-->
                        <div class="accordion md-accordion mt-4 mb-5" id="accordionEx" role="tablist"
                            aria-multiselectable="true">

                            <!-- Accordion card -->
                            <ng-container *ngIf="userRole !== 'Nissan'">
                                <div class="card">

                                    <!-- Card header -->
                                    <div class="card-header" role="tab" id="headingOne1">
                                        <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne1"
                                            aria-expanded="true" aria-controls="collapseOne1">
                                            <h5 class="mb-0" style="color: black;">
                                                Map <i class="fa fa-angle-down rotate-icon"></i>
                                            </h5>
                                        </a>
                                    </div>

                                    <!-- Card body -->
                                    <div id="collapseOne1" class="collapse show" role="tabpanel"
                                        aria-labelledby="headingOne1" data-parent="#accordionEx">
                                        <div class="card-body">
                                            <ng-container *ngIf="latlng.length !== 0">
                                                <div class="">
                                                    <agm-map [fullscreenControl]='false' [mapTypeControl]='true'
                                                        [fitBounds]="true" [minZoom]=2>
                                                        <agm-marker
                                                            *ngFor="let coordinate of start_end_mark; let i=index"
                                                            [latitude]="coordinate[0]" [longitude]="coordinate[1]"
                                                            [agmFitBounds]="true" [iconUrl]="coordinate[2]"
                                                            (markerClick)="clickedMarker(i,infowindow)">
                                                            <agm-info-window [disableAutoPan]="true" #infowindow>
                                                                <strong *ngIf="MapMarkerClickedValue==='Origin'">Origin
                                                                    ETD
                                                                    :
                                                                    <ng-container
                                                                        *ngIf="onRowClickedValue.shipment_etd !== null">
                                                                        {{onRowClickedValue.shipment_etd.split(' ')[0]}}
                                                                    </ng-container>
                                                                </strong>
                                                                <strong
                                                                    *ngIf="MapMarkerClickedValue==='Destination'">Destination
                                                                    ETA :
                                                                    <ng-container
                                                                        *ngIf="onRowClickedValue.shipment_eta !== null">
                                                                        {{onRowClickedValue.shipment_eta.split(' ')[0]}}
                                                                    </ng-container>
                                                                </strong>
                                                            </agm-info-window>
                                                        </agm-marker>
                                                        <agm-polyline [visible]="true" [strokeWeight]="3"
                                                            [strokeColor]="'#07b57a'">
                                                            <agm-polyline-point
                                                                *ngFor="let coordinate of latlng; let i=index"
                                                                [latitude]="coordinate[0]" [longitude]="coordinate[1]">
                                                            </agm-polyline-point>
                                                            <agm-icon-sequence [strokeColor]="'black'" [repeat]="'10px'"
                                                                [offset]="'0'" [strokeWeight]="3" [scale]="2"
                                                                [strokeOpacity]="100" [path]="'M 0,-1 0,1'">
                                                            </agm-icon-sequence>
                                                        </agm-polyline>
                                                    </agm-map>

                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="latlng.length === 0">
                                                <p style="text-align: center;font-size: 15px;">No Data Found</p>
                                            </ng-container>
                                        </div>
                                    </div>

                                </div>
                            </ng-container>
                            <!-- Accordion card -->

                            <!-- Accordion card -->
                            <div class="card">

                                <!-- Card header -->
                                <div class="card-header" role="tab" id="headingTwo2">
                                    <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx"
                                        href="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">
                                        <h5 class="mb-0" style="color: black;">
                                            Details <i class="fa fa-angle-down rotate-icon"></i>
                                        </h5>
                                    </a>
                                </div>

                                <!-- Card body -->
                                <div id="collapseTwo2" class="collapse" role="tabpanel" aria-labelledby="headingTwo2"
                                    data-parent="#accordionEx">
                                    <div class="card-body">
                                        <div class="leftContent pt-1">
                                            <div class="ng-tns-c4-0">
                                                <div class="qntyDiv">
                                                    <div class="cont">
                                                        <div class="title">Pickup : </div>
                                                        <div class="ng-tns-c4-0">{{onRowClickedValue.pickupCompanyName}}
                                                        </div>
                                                    </div>
                                                    <div class="cont">
                                                        <div class="title">Forwarder : </div>
                                                        <div class="qntyUnitDesc" title="Pallet">
                                                            {{onRowClickedValue.forwarder}} </div>
                                                    </div>
                                                    <div class="cont">
                                                        <div class="title">Delivery : </div>
                                                        <div class="qntyUnitDesc" title="Pallet">
                                                            {{onRowClickedValue.deliveryCompanyName}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="userRole !== 'Nissan'">
                                                <div class="leftContent">
                                                    <div class="ng-tns-c4-0">
                                                        <div class="qntyDiv">
                                                            <div class="cont">
                                                                <div class="title">Inco Term : </div>
                                                                <div class="qntyUnitDesc" title="Pallet">
                                                                    {{onRowClickedValue.incoTerm}}
                                                                </div>
                                                            </div>
                                                            <div class="cont">
                                                                <div class="title">Customs Release Date : </div>
                                                                <div class="qntyUnitDesc">
                                                                    {{onRowClickedValue.import_clearance_date}}
                                                                </div>
                                                            </div>
                                                            <div class="cont">
                                                                <div class="title">Service Type :
                                                                    <div class=""
                                                                        style="padding-left: 82px;margin-top: -9px;"
                                                                        *ngIf="onRowClickedValue.service_code_change_bool !== 0">
                                                                        <sup class="OE">
                                                                            <a id="foo"
                                                                                (mouseenter)="eta_etd_serviceCode_history(onRowClickedValue.ushipmentid)">*</a>
                                                                            <table>
                                                                                <tr>
                                                                                    <th>Service Type History</th>
                                                                                </tr>
                                                                                <tr *ngFor="let data of historyData">
                                                                                    <ng-container
                                                                                        *ngIf="data.service_code!== null">
                                                                                        <td>{{data.service_code}}</td>
                                                                                    </ng-container>
                                                                                </tr>
                                                                            </table>
                                                                        </sup>
                                                                    </div>
                                                                </div>
                                                                <div class="qntyUnitDesc">
                                                                    {{onRowClickedValue.incoDescription}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="leftContent">
                                                    <div class="ng-tns-c4-0">
                                                        <div class="qntyDiv">
                                                            <div class="cont">
                                                                <div class="title">ETD :
                                                                    <div class=""
                                                                        style="padding-left: 82px;margin-top: -9px;"
                                                                        *ngIf="onRowClickedValue.etd_change_bool !== 0">
                                                                        <sup class="OE">
                                                                            <a id="foo"
                                                                                (mouseenter)="eta_etd_serviceCode_history(onRowClickedValue.ushipmentid)">*</a>
                                                                            <table>
                                                                                <tr>
                                                                                    <th>ETD History</th>
                                                                                </tr>
                                                                                <tr *ngFor="let data of historyData">
                                                                                    <ng-container
                                                                                        *ngIf="data.etd!== null">
                                                                                        <td>{{data.etd}}</td>
                                                                                    </ng-container>
                                                                                </tr>
                                                                            </table>
                                                                        </sup>
                                                                    </div>
                                                                </div>
                                                                <div class="qntyUnitDesc">
                                                                    <div class="row">
                                                                        <div class="col">
                                                                            <ng-container
                                                                                *ngIf="onRowClickedValue.shipment_etd!== null">
                                                                                {{onRowClickedValue.shipment_etd.split('
                                                                                ')[0]}}
                                                                            </ng-container>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="cont">
                                                                <div class="title">ETA :
                                                                    <div class=""
                                                                        style="padding-left: 82px;margin-top: -9px;"
                                                                        *ngIf="onRowClickedValue.eta_change_bool !== 0">
                                                                        <sup class="OE">
                                                                            <a id="foo"
                                                                                (mouseenter)="eta_etd_serviceCode_history(onRowClickedValue.ushipmentid)">*</a>
                                                                            <table>
                                                                                <tr>
                                                                                    <th>ETA History</th>
                                                                                </tr>
                                                                                <tr *ngFor="let data of historyData">
                                                                                    <ng-container
                                                                                        *ngIf="data.eta!== null">
                                                                                        <td>{{data.eta}}</td>
                                                                                    </ng-container>
                                                                                </tr>
                                                                            </table>
                                                                        </sup>
                                                                    </div>
                                                                </div>
                                                                <div class="qntyUnitDesc">
                                                                    <ng-container
                                                                        *ngIf="onRowClickedValue.shipment_eta!== null">
                                                                        {{onRowClickedValue.shipment_eta.split(' ')[0]}}
                                                                    </ng-container>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="bottomContent">
                                            <div class="statusDiv">
                                                <table class="ng-tns-c4-0" width="100%">
                                                    <tr class="locationHeader">
                                                        <td class="ng-tns-c4-0" width="10%"> Origin </td>
                                                        <td class="ng-tns-c4-0" width="20%"
                                                            style="text-transform: capitalize;">
                                                            Pickup Address</td>
                                                        <td class="ng-tns-c4-0" width="15%"> Port of Loading </td>
                                                        <td class="ng-tns-c4-0" width="15%"> Port of Discharge </td>
                                                        <td class="ng-tns-c4-0" width="20%"
                                                            style="text-transform: capitalize;">
                                                            Delivery Address</td>
                                                        <td class="ng-tns-c4-0" width="10%"> Destination </td>



                                                    </tr>
                                                    <tr class="imageCont font-wt">
                                                        <td class="ng-tns-c4-0">
                                                            <div class="inTransit bottimestatusdiv">
                                                                <div class="title font-wt"
                                                                    style="text-transform: uppercase">
                                                                    {{onRowClickedValue.pickupCity}} ,
                                                                    {{onRowClickedValue.pickupCountryCode}}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="ng-tns-c4-0">
                                                            <div class="booking bottimestatusdiv">
                                                                <div class="title font-wt"
                                                                    style="text-transform: uppercase">
                                                                    {{onRowClickedValue.pickupAddress1}},{{onRowClickedValue.pickupAddress2}},{{onRowClickedValue.pickupCity}},{{onRowClickedValue.pickupCountryCode}}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="ng-tns-c4-0">
                                                            <div class="inTransit bottimestatusdiv"
                                                                style="text-transform: uppercase">
                                                                <div class="ng-tns-c4-0 ng-star-inserted">
                                                                    <div
                                                                        class="dates font-wt ng-tns-c4-0 ng-star-inserted">

                                                                        {{onRowClickedValue.portOfLoadingCode}} </div>

                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td class="ng-tns-c4-0">
                                                            <div class="inTransit bottimestatusdiv"
                                                                style="text-transform: uppercase">
                                                                <div class="ng-tns-c4-0 ng-star-inserted">
                                                                    <div
                                                                        class="dates font-wt ng-tns-c4-0 ng-star-inserted">

                                                                        {{onRowClickedValue.portOfDischargeCode}} </div>

                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td class="ng-tns-c4-0">

                                                            <div class="booking bottimestatusdiv">
                                                                <div class="title font-wt"
                                                                    style="text-transform: uppercase">
                                                                    {{onRowClickedValue.deliveryAddress1}},{{onRowClickedValue.deliveryAddress2}},{{onRowClickedValue.deliveryCity}},{{onRowClickedValue.deliveryCountryCode}}
                                                                </div>
                                                            </div>

                                                        </td>
                                                        <td class="ng-tns-c4-0">
                                                            <div class="inTransit bottimestatusdiv">
                                                                <div class="title font-wt"
                                                                    style="text-transform: uppercase">

                                                                    {{onRowClickedValue.deliveryCity}} ,
                                                                    {{onRowClickedValue.deliveryCountryCode}}
                                                                </div>
                                                            </div>
                                                        </td>







                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="userRole !== 'Nissan'">
                                            <!--References--->
                                            <div class="row mt-3">
                                                <div class="col-12">
                                                    <div class="accordion" id="accordionExample">
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne">
                                                                <h5 class="mb-0">
                                                                    <button (click)="referencesClicked()"
                                                                        class="btn btn-link" type="button"
                                                                        data-toggle="collapse"
                                                                        data-target="#collapseOne" aria-expanded="true"
                                                                        aria-controls="collapseOne">
                                                                        <strong style="color: black;font-size: larger;">
                                                                            References
                                                                        </strong>
                                                                    </button>
                                                                </h5>
                                                            </div>

                                                            <div *ngIf="openReferencesBody" id="collapseOne"
                                                                class="collapse hide" aria-labelledby="headingOne"
                                                                data-parent="#accordionExample">
                                                                <div class="card-body">
                                                                    <ng-container *ngIf="referencesData.length !== 0">
                                                                        <table
                                                                            class="table table-bordered table-hover table-sm">
                                                                            <thead class="thead-light">
                                                                                <tr>
                                                                                    <th>Qualifier</th>
                                                                                    <th>Reference Desc</th>
                                                                                    <th>Value</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr *ngFor="let data of referencesData">
                                                                                    <td>{{data.qualifier}}</td>
                                                                                    <td>{{data.reference_desc}}</td>
                                                                                    <td>{{data.reference_value}}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="referencesData.length === 0">
                                                                        <p style="text-align: center;font-size: 15px;">
                                                                            No
                                                                            Data Found</p>
                                                                    </ng-container>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6">

                                                </div>

                                            </div>
                                            <!----End Of References-->
                                            <!---SegmentDetails-->
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="accordion" id="accordionExampleSegmentDetails">
                                                        <div class="card">
                                                            <div class="card-header" id="headingOneSegmentDetails">
                                                                <h5 class="mb-0">
                                                                    <button (click)="SegmentDetailsClicked()"
                                                                        class="btn btn-link" type="button"
                                                                        data-toggle="collapse"
                                                                        data-target="#collapseOneSegmentDetails"
                                                                        aria-expanded="true"
                                                                        aria-controls="collapseOneSegmentDetails">
                                                                        <strong style="color: black;font-size: larger;">
                                                                            Segment Details
                                                                        </strong>
                                                                    </button>
                                                                </h5>
                                                            </div>

                                                            <div *ngIf="openSegmentDetailsBody"
                                                                id="collapseOneSegmentDetails" class="collapse hide"
                                                                aria-labelledby="headingOneSegmentDetails"
                                                                data-parent="#accordionExampleSegmentDetails">
                                                                <div class="card-body">
                                                                    <ng-container
                                                                        *ngIf="flightLegSequenceData.length !== 0">
                                                                        <table
                                                                            class="table table-bordered table-hover table-sm">
                                                                            <thead class="thead-light">
                                                                                <tr>
                                                                                    <th>Sequence</th>
                                                                                    <th>Origin</th>
                                                                                    <th>Destination</th>
                                                                                    <th>Mode of Transport</th>
                                                                                    <th>Carrier Name</th>
                                                                                    <th>Transport Number</th>
                                                                                    <th>Vessel Code</th>
                                                                                    <th>Voyage Number</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr style="white-space: nowrap;"
                                                                                    *ngFor="let data of flightLegSequenceData">
                                                                                    <td>{{data.sequence_no}}</td>
                                                                                    <td>{{data.origin_code}}</td>
                                                                                    <td>{{data.destination_code}}</td>
                                                                                    <td>{{data.orig_mode_of_transport}}
                                                                                    </td>
                                                                                    <td>{{data.orig_carrier_name}}</td>
                                                                                    <td>{{data.orig_transport_number}}
                                                                                    </td>
                                                                                    <td>{{data.orig_vessel_number}}</td>
                                                                                    <td>{{data.orig_voyage_number}}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </ng-container>
                                                                       <!--NEW FSU Screen Start -->
                                                                       <ng-container >
                                                                        <div *ngIf="groupedFlightEvents.length > 0">
                                                                            <div
                                                                                *ngFor="let data of groupedFlightEvents">
                                                                                <div class="timeline-container">
                                                                                    <div class="arrow">
                                                                                        <!-- <div class="left"></div>
                                                                        <div class="right"></div> -->
                                                                                        <span
                                                                                            class="flightText">{{data.flightDetails['flightNumber']}}</span>
                                                                                    </div>
                                                                                    <div
                                                                                        *ngFor="let val of data.events">
                                                                                        <div class="box">
                                                                                            <div class="title">
                                                                                                {{val.location}}</div>
                                                                                            <div class="image"> <img
                                                                                                    class="img-size"
                                                                                                    src="{{val.imageSRC}}" />
                                                                                            </div>
                                                                                            <div class="title">
                                                                                                {{val.checkpointCode}}
                                                                                            </div>
                                                                                            <div class="text">
                                                                                                {{val.checkpointDatetime}}
                                                                                                <br> {{val.pieces}} pcs
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                            <div class="row"
                                                                                style="margin-left:0px;margin-right:0px;margin-top: 10px;">
                                                                                <div class="col-12 "
                                                                                    style="background-color:#f1f1f1; margin-left: 25px;">
                                                                                    <h5 style="font-weight: bold;"> &gt;
                                                                                        Booking & Acceptance information
                                                                                    </h5>
                                                                                </div>
                                                                            </div>

                                                                            <div class="accordion"
                                                                                id="accordionSegmentDetails">

                                                                                <div class="card"
                                                                                    style="margin:25px 25px">

                                                                                    <!-- Card header -->
                                                                                    <div class="card-header" role="tab"
                                                                                        id="headingOne4">
                                                                                        <a data-toggle="collapse"
                                                                                            data-parent="#accordionSegmentDetails"
                                                                                            href="#collapseOne4"
                                                                                            aria-expanded="true"
                                                                                            aria-controls="collapseOne4">
                                                                                            <h5 class="mb-0"
                                                                                                style="color: black;font-weight: bold;">
                                                                                                eFreight details
                                                                                            </h5>
                                                                                        </a>
                                                                                    </div>

                                                                                    <!-- Card body -->
                                                                                    <div id="collapseOne4" class="show"
                                                                                        role="tabpanel"
                                                                                        aria-labelledby="headingOne4"
                                                                                        data-parent="#accordionSegmentDetails">
                                                                                        <div class="card-body">
                                                                                            <div class="row">
                                                                                                <!-- <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                                <h5> <b>PreCheck status </b> </h5>
                                                                                Failed &#10006; <br>
                                                                                18 Jun 22/ 03:05
                                                                                
                                                                            </div>
                    
                                                                            <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                                <h5> <b> Quick drop-off status </b></h5>
                                                                            </div>
                    
                                                                            <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                                <h5><b> eAWB  acknowledged(FMA) </b></h5>
                    
                                                                            </div>
                    
                                                                            <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                                <h5> <b>EAAWB  Special Handlinng Coddes </b></h5>
                                                                            </div> -->

                                                                                            </div>
                                                                                            <!-- <br> -->
                                                                                            <div class="row">
                                                                                                <div
                                                                                                    class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                                                    <h5
                                                                                                        style="text-align: center;">
                                                                                                        <b>eData status
                                                                                                        </b>
                                                                                                    </h5>
                                                                                                    <table
                                                                                                        class="table table-borderless lineHieght">
                                                                                                        <tr>
                                                                                                            <th>AWB</th>
                                                                                                            <th>Status
                                                                                                            </th>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td><strong>{{mwb}}</strong>
                                                                                                            </td>
                                                                                                            <td> &#10004;
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </table>
                                                                                                </div>

                                                                                                <!-- <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                                        <h5 style="text-align: center;"> <b>Message  history </b></h5>
                                                                                </div> -->
                                                                                            </div>

                                                                                            <div class="row">
                                                                                                <div class="col-11"
                                                                                                    style="background-color:#f1f1f1; margin-left: 15px;">
                                                                                                    <h5
                                                                                                        style="font-weight: bold;">
                                                                                                        Latest booking
                                                                                                        details</h5>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!-- <div class="row" style="margin-top:10px">
                                                                                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                                    <h6><b> LAT/ Latest Acceptance Time    </b></h6>
                                                                                    18 Jun 22 /7:00
                                                                                </div>
                    
                                                                                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                                    <h6> <b>TOA/ Time of Availability  </b>  </h6>
                                                                                    18 Jun 22 /7:00
                                                                                </div>
                    
                                                                                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                                    <h6> <b>RCS/ Shipment accepted    </b></h6>
                                                                                   18 Jun 22 /7:00
                                                                               </div>
                                                                          </div> -->
                                                                                            <div class="row"
                                                                                                style="margin-top:10px; margin-bottom:10px;">
                                                                                                <div class="col-sm-12">


                                                                                                    <ng-container>
                                                                                                        <table
                                                                                                            class="table table-bordered table-hover table-sm">
                                                                                                            <thead
                                                                                                                class="thead-light">
                                                                                                                <tr>
                                                                                                                    <th>Origin
                                                                                                                    </th>
                                                                                                                    <th>Destination
                                                                                                                    </th>
                                                                                                                    <th>Flight
                                                                                                                        no.
                                                                                                                    </th>
                                                                                                                    <th>Flight
                                                                                                                        Date
                                                                                                                    </th>
                                                                                                                    <th>Pieces
                                                                                                                    </th>
                                                                                                                    <th>Actual
                                                                                                                        weight
                                                                                                                    </th>
                                                                                                                    <th>Volume
                                                                                                                    </th>
                                                                                                                    <th>Status
                                                                                                                    </th>

                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                                                                                                <tr
                                                                                                                    *ngFor="let data of groupedFlightEvents">
                                                                                                                    <td>{{data.flightDetails['fltDepPortCode']}}
                                                                                                                    </td>
                                                                                                                    <td>{{data.flightDetails['fltArrPortCode']}}
                                                                                                                    </td>
                                                                                                                    <td><b>{{data.flightDetails['flightNumber']}}
                                                                                                                        </b>
                                                                                                                    </td>
                                                                                                                    <td>{{data.flightDetails['segmentOccurrenceDateTime']}}
                                                                                                                    </td>
                                                                                                                    <td>{{data.flightDetails.flightDetailsAdditionalAttribute.flightPieces}}
                                                                                                                    </td>
                                                                                                                    <td>{{data.flightDetails.flightDetailsAdditionalAttribute.flightWeight}}
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                    </td>

                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </ng-container>
                                                                                                </div>

                                                                                            </div>

                                                                                            <!-- <div class="row" style="margin-top:10px; margin-bottom:10px;">
                                                                               <div class="col-11" style ="background-color:#f1f1f1; margin-left: 15px;">
                                                                                   <h5 style ="font-weight: bold;"> Booking at Good acceptance.</h5>
                                                                                </div>
                                                                      </div> -->
                                                                                            <!-- <div class="row" style="margin-top:10px; margin-bottom:10px;">
                                                                                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                                    <h6><b> SCC / Product Codes  </b></h6>
                                                                                    HEA, PPL , MDK,PAS, SPX , PIL, UULD,EAP
                                                                                </div>
                    
                                                                                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                                    <h6><b> LAT/ Latest Acceptance Time    </b></h6>
                                                                                    18 Jun 22 /7:00
                                                                                </div>
                    
                                                                                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                                    <h6> <b>TOA/ Time of Availability  </b>  </h6>
                                                                                    18 Jun 22 /7:00
                                                                                </div>
                    
                                                                                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                                    <h6> <b>Latest booking update    </b></h6>
                                                                                    18 Jun 22 /7:00
                                                                                </div>
                                                                            </div> -->
                                                                                            <!-- <div class="row" style="margin-top:10px; margin-bottom:10px;">
                                                                                <div class="col-sm-12">
                                                                                    
                                                                                    <ng-container >
                                                                                        <table
                                                                                            class="table table-bordered table-hover table-sm">
                                                                                            <thead class="thead-light">
                                                                                                <tr>
                                                                                                    <th>Origin</th>
                                                                                                    <th>Destination</th>
                                                                                                    <th>Flight no.</th>
                                                                                                    <th>Flight Date</th>
                                                                                                    <th>Pieces</th>
                                                                                                    <th>Actual weight</th>
                                                                                                    <th>Volume</th>
                                                                                                    <th>Status</th>
                                                                                                    
                                                                                                </tr>
                                                                                            </thead>
                                                                                        <tbody>
                                                                                                <tr *ngFor="let data of groupedFlightEvents">
                                                                                                    <td>{{data.flightDetails['fltDepPortCode']}}</td>
                                                                                                    <td>{{data.flightDetails['fltArrPortCode']}}</td>
                                                                                                    <td>{{data.flightDetails['flightNumber']}}</td>
                                                                                                    <td>{{data.flightDetails['segmentOccurrenceDateTime']}}</td>
                                                                                                    <td>{{data.flightDetails.flightDetailsAdditionalAttribute.flightPieces}}</td>
                                                                                                    <td>{{data.flightDetails.flightDetailsAdditionalAttribute.flightWeight}}</td>
                                                                                                    <td></td>
                                                                                                    <td></td>
                                                                                                    
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </ng-container>
                                                                                </div>
                                                                            </div> -->
                                                                                            <div class="row"
                                                                                                style="margin-top:10px; margin-bottom:10px;">
                                                                                                <div class="col-11"
                                                                                                    style="background-color:#f1f1f1; margin-left: 15px;">
                                                                                                    <h5
                                                                                                        style="font-weight: bold;">
                                                                                                        &gt; Status
                                                                                                        history
                                                                                                    </h5>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="row"
                                                                                                style="margin-top:10px; margin-bottom:10px;">
                                                                                                <div class="col-sm-12">

                                                                                                    <ng-container>
                                                                                                        <table
                                                                                                            class="table table-bordered table-hover table-sm">
                                                                                                            <thead
                                                                                                                class="thead-light">
                                                                                                                <tr>
                                                                                                                    <th>
                                                                                                                        Event
                                                                                                                    </th>

                                                                                                                    <!-- <th>Description</th> -->
                                                                                                                    <th>
                                                                                                                        Station.
                                                                                                                    </th>
                                                                                                                    <th>
                                                                                                                        Flight
                                                                                                                        no.<br>(Origin/Destination)
                                                                                                                    </th>
                                                                                                                    <th>
                                                                                                                        Actual<br>
                                                                                                                        pieces/Weight
                                                                                                                    </th>
                                                                                                                    <th>
                                                                                                                        Planned
                                                                                                                        <br>
                                                                                                                        pieces/
                                                                                                                        weight
                                                                                                                    </th>
                                                                                                                    <th>
                                                                                                                        Actual
                                                                                                                        Time
                                                                                                                    </th>

                                                                                                                    <!-- <th>Planned Time</th> -->

                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody
                                                                                                                *ngFor="let grouping of groupedFlightEvents">
                                                                                                                <tr
                                                                                                                    *ngFor="let eventParing of grouping.events">

                                                                                                                    <td>
                                                                                                                        {{eventParing.checkpointCode}}
                                                                                                                    </td>

                                                                                                                    <!-- <td></td> -->
                                                                                                                    <td>
                                                                                                                        {{eventParing.location}}
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <b>{{eventParing.flightNumber}}</b>
                                                                                                                        ({{eventParing.segmentOrigin}}<i
                                                                                                                            style="color:gray"
                                                                                                                            class="fa fa-plane"></i>{{eventParing.segmentDestination}})
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        {{eventParing.pieces}}/{{eventParing.weight}}
                                                                                                                        kg
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        {{eventParing.pieces}}/{{eventParing.weight}}
                                                                                                                        kg
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        {{eventParing.createdDate}}
                                                                                                                    </td>

                                                                                                                    <!-- <td></td> -->


                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </ng-container>
                                                                                                </div>
                                                                                            </div>


                                                                                        </div>

                                                                                    </div>


                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    </ng-container>
                                                                    <!--NEW FSU Screen END -->

                                                                    <ng-container
                                                                        *ngIf="groupedFlightEvents.length === 0">
                                                                        <p style="text-align: center;font-size: 15px;">
                                                                            No
                                                                            Data Found</p>
                                                                    </ng-container>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!---End of SegmentDetails-->
                                            <!---Documents-->
                                            <ng-container *ngIf="userRole !== 'DHL'">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="accordion" id="accordionExampleDocuments">
                                                            <div class="card">
                                                                <div class="card-header" id="headingOneDocuments">
                                                                    <h5 class="mb-0">
                                                                        <button (click)="DocumentsClicked()"
                                                                            class="btn btn-link" type="button"
                                                                            data-toggle="collapse"
                                                                            data-target="#collapseOneDocuments"
                                                                            aria-expanded="true"
                                                                            aria-controls="collapseOneDocuments">
                                                                            <strong
                                                                                style="color: black;font-size: larger;">
                                                                                Documents
                                                                            </strong>
                                                                        </button>
                                                                    </h5>
                                                                </div>

                                                                <div *ngIf="openDocumentsBody" id="collapseOneDocuments"
                                                                    class="collapse hide"
                                                                    aria-labelledby="headingOneDocuments"
                                                                    data-parent="#accordionExampleDocuments">
                                                                    <div class="card-body">
                                                                        <ag-grid-angular class="ag-theme-balham"
                                                                            [columnDefs]="DocumentscolumnDefs"
                                                                            [domLayout]="domLayout"
                                                                            [detailRowAutoHeight]="true"
                                                                            [rowData]="DocumentsRowData"
                                                                            [defaultColDef]="defaultColDef"
                                                                            [masterDetail]="true"
                                                                            (firstDataRendered)="onFirstDataRendered($event)"
                                                                            [detailCellRendererParams]="detailCellRendererParams"
                                                                            [pagination]="true" [paginationPageSize]="5"
                                                                            [gridOptions]="gridOptionsDocuments"
                                                                            (gridReady)="onGridReadyDocuments($event)">
                                                                        </ag-grid-angular>
                                                                        <!-- <ng-container
                                                                    *ngIf="flightLegSequenceData.length === 0">
                                                                    <p style="text-align: center;font-size: 15px;">No
                                                                        Data Found</p>
                                                                </ng-container> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <!---End of Documents-->
                                        </ng-container>
                                    </div>
                                </div>

                            </div>
                            <!-- Accordion card -->

                            <!-- Accordion card -->
                            <div class="card" *ngIf="userRole === 'BMS' || userRole === 'GSK'||userRole === 'Pfizer'
                            ||userRole === 'ASTRAZENECA' ||userRole === 'EXPRESS' ||userRole === 'DHL'">

                                <!-- Card header -->
                                <div class="card-header" role="tab" id="headingTwo3">
                                    <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx"
                                        href="#collapseTwo3" aria-expanded="false" aria-controls="collapseTwo3">
                                        <h5 class="mb-0" style="color: black;" (click)="trackTempClicked()">
                                            Track Temperature <i class="fa fa-angle-down rotate-icon"></i>
                                        </h5>
                                    </a>
                                </div>

                                <!-- Card body -->
                                <div id="collapseTwo3" class="collapse" role="tabpanel" aria-labelledby="headingTwo3"
                                    data-parent="#accordionEx">
                                    <div class="card-body">
                                        <ng-container *ngIf="graphData.length !== 0">
                                            <div class="row" *ngIf="graphData !== undefined">
                                                <fusioncharts [type]="type" [width]="width" [height]="height"
                                                    [dataSource]="dataSource">
                                                </fusioncharts>

                                            </div>

                                        </ng-container>

                                        <ng-container *ngIf="graphData.length === 0">
                                            <p style="text-align: center;font-size: 15px;">No Data Found</p>
                                        </ng-container>
                                    </div>
                                </div>

                            </div>
                            <!-- Accordion card -->


                        </div>
                        <!-- Accordion wrapper -->
                    </div>
                </div>


            </div>
        </div>
    </div>
</ng-container>


<ng-container *ngIf="SideOverlay2 === true">
    <div id="myNav2" class="overlay" *ngIf="houseWayBilldetails !== undefined || shipmentDetails !== undefined">
        <div class="container">
            <div class="row justify-content-between mt-4">
                <h5 class="font-weight-bold mx-auto">Details</h5>
                <div class="view-icons">
                    <a href="javascript:void(0)" (click)="closeHAWBdetails()" title="Back"
                        class="grid-view btn btn-link active"><i class="fa fa-arrow-circle-left"></i></a>
                </div>
            </div>

            <hr>

            <!-- <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="card h-100">
                        <h5 class="card-header font-weight-bold">
                            Bill Details
                        </h5>
                        <div class="card-body">
                            <div class="card-text">
                                <table class="table table-borderless lineHieght">
                                    <tbody>
                                        <tr>
                                            <td class="font-weight-bold">House File Number</td>
                                            <td>:</td>
                                            <td>{{houseWayBilldetails.HAWB}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">User Id</td>
                                            <td>:</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">User Name</td>
                                            <td>:</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">FHL Issue Date</td>
                                            <td>:</td>
                                            <td>{{dateFormate(houseWayBilldetails.awbIssueDate)}}</td>
                                        </tr> -->
            <!-- <tr>
                                            <td class="font-weight-bold">EDD</td>
                                            <td>:</td>
                                            <td>{{dateFormate(shipmentDetails.etd)}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">EAD</td>
                                            <td>:</td>
                                            <td>{{dateFormate(shipmentDetails.eta)}}</td>
                                        </tr> -->
            <!-- </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div> -->

            <!-- <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="card h-100">
                        <h5 class="card-header font-weight-bold">
                            Package Details
                        </h5>
                        <div class="card-body">
                            <div class="card-text">
                                <table class="table table-borderless lineHieght">
                                    <tbody>
                                        <tr>
                                            <td class="font-weight-bold">Total Packages</td>
                                            <td>:</td>
                                            <td>{{shipmentDetails.number_of_pieces}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Total Pck Type</td>
                                            <td>:</td>
                                            <td>{{shipmentDetails.shipment_type_code}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Total SLAC</td>
                                            <td>:</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Total SLAC Type</td>
                                            <td>:</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Total Weight UOM</td>
                                            <td>:</td>
                                            <td>{{shipmentDetails.weight_uom}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Total Volume UOM</td>
                                            <td>:</td>
                                            <td>{{shipmentDetails.volume_uom}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Total Chargeable UOM</td>
                                            <td>:</td>
                                            <td>{{shipmentDetails.chargeable_weight_uom}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Waybill Chargeable UOM</td>
                                            <td>:</td>
                                            <td>{{shipmentDetails.chargeable_weight_uom}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Goods Description</td>
                                            <td>:</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Mode of Transport</td>
                                            <td>:</td>
                                            <td>{{shipmentDetails.mode_of_transport}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div> -->

            <!-- <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="card h-100">
                        <h5 class="card-header font-weight-bold">
                            Part Details
                        </h5>


                        <div class="card-body">
                            <div class="card-text">
                                <table class="table table-borderless lineHieght">
                                    <tbody>
                                        <tr>
                                            <td class="font-weight-bold">Pickup Date</td>
                                            <td>:</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Allocated Parts</td>
                                            <td>:</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Parts Description</td>
                                            <td>:</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Total Volume UOM</td>
                                            <td>:</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="row mt-3">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="card h-100">
                        <h5 class="card-header font-weight-bold">
                            Shipper
                        </h5>
                        <div class="card-body">
                            <div class="card-text">
                                <table class="table table-borderless lineHieght">
                                    <tbody>
                                        <tr>
                                            <td class="font-weight-bold">Account Number</td>
                                            <td>:</td>
                                            <td>{{houseWayBilldetails.pickupAccountCode}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Name</td>
                                            <td>:</td>
                                            <td>{{houseWayBilldetails.pickupCompanyName}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">City</td>
                                            <td>:</td>
                                            <td>{{houseWayBilldetails.pickupCity}}</td>
                                        </tr>
                                        <!-- <tr>
                                            <td class="font-weight-bold">State Province</td>
                                            <td>:</td>
                                            <td></td>
                                        </tr> -->
                                        <tr>
                                            <td class="font-weight-bold">Country Code</td>
                                            <td>:</td>
                                            <td>{{houseWayBilldetails.pickupCountryCode}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Postal Code</td>
                                            <td>:</td>
                                            <td>{{houseWayBilldetails.pickupZip}}</td>
                                        </tr>
                                        <!-- <tr>
                                            <td class="font-weight-bold">Tax ID</td>
                                            <td>:</td>
                                            <td></td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="card h-100">
                        <h5 class="card-header font-weight-bold">
                            Consignee
                        </h5>
                        <div class="card-body">
                            <div class="card-text">
                                <table class="table table-borderless lineHieght">
                                    <tbody>
                                        <tr>
                                            <td class="font-weight-bold">Account Number</td>
                                            <td>:</td>
                                            <td>{{houseWayBilldetails.deliveryAccountCode}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Name</td>
                                            <td>:</td>
                                            <td>{{houseWayBilldetails.deliveryCompanyName}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">City</td>
                                            <td>:</td>
                                            <td>{{houseWayBilldetails.deliveryCity}}</td>
                                        </tr>
                                        <!-- <tr>
                                            <td class="font-weight-bold">State Province</td>
                                            <td>:</td>
                                            <td></td>
                                        </tr> -->
                                        <tr>
                                            <td class="font-weight-bold">Country Code</td>
                                            <td>:</td>
                                            <td>{{houseWayBilldetails.deliveryCountryCode}}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Postal Code</td>
                                            <td>:</td>
                                            <td>{{houseWayBilldetails.deliveryZip}}</td>
                                        </tr>
                                        <!-- <tr>
                                            <td class="font-weight-bold">Tax Id</td>
                                            <td>:</td>
                                            <td></td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="row mt-3">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="card-text">
                                <div class="row">
                                    <div class="col-sm-2">
                                        <label for="" class="font-weight-bold">Buyer :</label>
                                    </div>
                                    <div class="col-sm-2">
                                        <label for="">{{houseWayBilldetails.customerOrgName}}</label>
                                    </div>
                                    <div class="col-sm-2">
                                        <label for="" class="font-weight-bold">Intent :</label>
                                    </div>
                                    <div class="col-sm-2">
                                        <label for=""> </label>
                                    </div>
                                    <div class="col-sm-2">
                                        <label for="" class="font-weight-bold">Account Number :</label>
                                    </div>
                                    <div class="col-sm-2">
                                        <label for="">{{houseWayBilldetails.deliveryAccountCode}}</label>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-2">
                                        <label for="" class="font-weight-bold">Invoice Number :</label>
                                    </div>
                                    <div class="col-sm-2">
                                        <label for=""> </label>
                                    </div>
                                    <div class="col-sm-2">
                                        <label for="" class="font-weight-bold">Pickup Date :</label>
                                    </div>
                                    <div class="col-sm-2">
                                        <label for=""> </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- <div class="row mt-3">
                <div class="col-sm-12">
                    <table class="table table-bordered">
                        <thead class="tableBackground">
                            <tr>
                                <th>Goods Description</th>
                                <th>Package Count</th>
                                <th>Package Type</th>
                                <th>Length</th>
                                <th>Width</th>
                                <th>Height</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let goods of packageDetails">
                                <td>{{goods.commodity}}</td>
                                <td>{{goods.package_count}}</td>
                                <td>{{goods.type}}</td>
                                <td>{{goods.length}}</td>
                                <td>{{goods.width}}</td>
                                <td>{{goods.height}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> -->

            <div class="row mt-3">
                <div class="col-sm-12">
                    <h3>Bill Details</h3>
                </div>
                <div class="col-sm-12">
                    <table class="table table-bordered">
                        <thead class="tableBackground">
                            <tr>
                                <th>House File Number</th>
                                <!-- <th>User Id</th>
                                <th>User Name</th> -->
                                <!-- <th>FHL Issue Date</th> -->
                                <!-- <th>Master Air Way Bill Number</th> -->
                                <th>Ship Ref</th>
                                <th>Pickup date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{houseWayBilldetails.HAWB}}</td>
                                <!-- <td></td>
                                <td>{{houseWayBilldetails.deliveryAccountName}}</td> -->
                                <!-- <td>{{houseWayBilldetails.awbIssueDate.split('T')[0]}}</td> -->
                                <!-- <td>{{houseWayBilldetails.awbIssueDate | date: 'dd-MM-YYYY'}}</td> -->
                                <!-- <td>{{houseWayBilldetails.HAWB}}</td> -->
                                <td>{{houseWayBilldetails.deliveryAddressOverride}}</td>
                                <td>{{houseWayBilldetails.pickupAddressOverride}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>



            <ng-container *ngIf="userRole === 'Nissan1'">
                <div class="row mt-3">
                    <div class="col-sm-12">
                        <h3>Part Details</h3>
                    </div>
                    <div class="col-sm-12">
                        <table class="table table-bordered">
                            <thead class="tableBackground">
                                <tr>
                                    <th>Pickup Date</th>
                                    <th>Allocated Parts</th>
                                    <th>Parts Description</th>
                                    <ng-container
                                        *ngIf="houseWayBilldetails.forwarder==='DHLE'||houseWayBilldetails.forwarder==='EXPRESS'">
                                        <th>Indent</th>
                                        <th>Line Number</th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let partDetailsMapping of partDetails">
                                    <td>{{partDetailsMapping.pickup_date.split('T')[0]}}</td>
                                    <td>{{partDetailsMapping.allocated_parts}}</td>
                                    <td>{{partDetailsMapping.item_description}}</td>
                                    <td>{{partDetailsMapping.indent}}</td>
                                    <td>{{partDetailsMapping.line_no}}</td>
                                </tr>
                                <!-- <tr *ngIf="partDetails.length === 0">No Records</tr> -->
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>
            <ng-container>
                <div class="row mt-3">
                    <div class="col-sm-12">
                        <h3>Package Details</h3>
                    </div>
                    <div class="col-sm-12">
                        <table class="table table-bordered">
                            <thead class="tableBackground">
                                <tr>
                                    <th>Package Name</th>
                                    <th>Package ID</th>
                                    <th>Package Count</th>
                                    <ng-container *ngIf="MDlspOrgId!=='DHLE'">
                                        <th>Package Type</th>
                                        <th>Length</th>
                                        <th>Width</th>
                                        <th>Height</th>
                                    </ng-container>

                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="packageDetails.length !==0">
                                    <tr *ngFor="let goods of packageDetails">
                                        <td>{{goods.commodity}}</td>
                                        <td>{{goods.container_number}}</td>
                                        <td>{{goods.package_count}}</td>
                                        <ng-container *ngIf="MDlspOrgId!=='DHLE'">
                                            <td>{{goods.type}}</td>
                                            <td>{{goods.length}}</td>
                                            <td>{{goods.width}}</td>
                                            <td>{{goods.height}}</td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                                <ng-container *ngIf="packageDetails.length === 0">
                                    <tr style="text-align:center">
                                        <ng-container *ngIf="MDlspOrgId!=='DHLE'">
                                            <td colspan="7"> No Record Found</td>
                                        </ng-container>
                                        <ng-container *ngIf="MDlspOrgId==='DHLE'">
                                            <td colspan="3"> No Record Found</td>
                                        </ng-container>
                                    </tr>
                                </ng-container>

                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>

            <div class="row mt-3 marginBottom">
                <div class="col-sm-12">
                    <table class="table table-bordered">
                        <thead class="tableBackground">
                            <tr>
                                <th>Shipment ID</th>
                                <th>Ship Desc</th>
                                <th>Char Wt UOm</th>
                                <th>Charg Wt</th>
                                <th>Charge Vol UOm</th>
                                <th>Charg Vol</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{shipmentDetails.ship_reference_id}}</td>
                                <td>{{shipmentDetails.commodity_code}} - {{shipmentDetails.commodity_text}}</td>
                                <td>{{shipmentDetails.weight_uom}}</td>
                                <td>{{shipmentDetails.weight}}</td>
                                <td>{{shipmentDetails.volume_uom}}</td>
                                <td>{{shipmentDetails.volume}}</td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</ng-container>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>