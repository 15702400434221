import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { RequestService } from 'src/app/services/request.service';

@Component({
  selector: 'app-shipmentpreparation',
  templateUrl: './shipmentpreparation.component.html',
  styleUrls: ['./shipmentpreparation.component.scss']
})
export class ShipmentpreparationComponent implements OnInit {
  codearrayCustomer = [];
  CodearrayDataCustomer = [];
  CustomerdropdownSettings = {};
  tableCustomerDropdownValues: any


  codearrayCourier_forwarder = [];
  CodearrayDataCourier_forwarder = [];
  Courier_forwarderdropdownSettings = {};
  tableCourier_forwarderDropdownValues: any
  disabled = true;


  codearrayAccount = [];
  CodearrayDataAccount = [];
  AccountdropdownSettings = {};
  tableAccountDropdownValues: any
  AccountDisable = true
  Courier_forwarderselectedItems: null;
  AccountselectedItems: null;
  CustomerselectedItems: null;

  model = {
    Courier_forwarderselectedItems: null, AccountselectedItems: null, CustomerselectedItems: null
  };
  onRowClickedValue = null;
  columnDefs: any;
  RowData: any;
  IsColumnsToFit: boolean;
  AgLoad: boolean;

  gridApi: any;
  gridColumnApi: any;
  eventparsing: any = [];
  OE_ED: any = [];
  SideOverlay = false;


  constructor(private requestService: RequestService, private authService: AuthService) { }
  domLayout = 'autoHeight';


  BindData(params) {
    this.gridApi = params.api;
    params.columnApi.autoSizeAllColumns();
    this.gridColumnApi = params.columnApi;
    this.gridApi.getToolPanelInstance('filters').expandFilters();
    var columnState = JSON.parse(localStorage.getItem('myBookingColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }


  onColumnMoved(params) {
    console.log("params:::")
    var columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem('myBookingColumnState', columnState);
  }

  onGridReady(params) {
    var columnState = JSON.parse(localStorage.getItem('myBookingColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }

  resetState() {
    this.gridColumnApi.resetColumnState();
    console.log('column state reset');
  }

  GetAgColumns() {
    this.columnDefs = [

      {
        headerName: 'HAWB/Tracking No', field: 'HAWB', sortable: true, suppressFiltersToolPanel: true,
        chartDataType: 'category',
        onCellClicked: this.onRowClicked.bind(this),
        // cellRenderer: 'agGroupCellRenderer'
        cellRenderer: function (params) {
          let keyData = params.value;
          let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
          return newLink;
        }

      },
      { headerName: 'Account No', field: 'accountCode', sortable: true },
      { headerName: 'MAWB', field: 'mawb', sortable: true, suppressFiltersToolPanel: true, hide: true },
      { headerName: 'Shipment ID', field: 'forwardingShipment', sortable: true, suppressFiltersToolPanel: true },
      {
        headerName: 'Courier/Forwarder', field: 'lspOrgId', sortable: true, suppressFiltersToolPanel: true,
        // cellRenderer: function (params) {
        //   let keyData = params.value;
        //   if (keyData === 'EXPRESS') {
        //     keyData = "DXP"
        //   }
        //   else {
        //     keyData = "DGF"
        //   }
        //   return keyData;
        // }
      },

      { headerName: 'Checkpoint Code', field: 'eventCode', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Latest Checkpoint', field: 'latestTrackingEvent', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Latest Checkpoint Date', field: 'latestTrackingTimeStamp', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Origin', field: 'originCode', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Destination', field: 'airportOfDestinationCode', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Flight number', field: 'flightNumber', sortable: true, suppressFiltersToolPanel: true },
    ];

  }

  customer_dropDown() {
    console.log("customer_dropDown")
    this.CustomerdropdownSettings = {
      singleSelection: true,
      idField: 'codeCustomer',
      textField: 'codeCustomerName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  onCustomerItemSelect(item) {
    console.log("item.codeCustomer", item.codeCustomer)
    this.model.CustomerselectedItems = item.codeCustomer
    this.tableCustomerDropdownValues = this.model.CustomerselectedItems;
    this.GetTableData()
    // this.initializeTableData();
    // this.DropdownData();
    this.disabled = false;
  }

  onCustomerDeSelect(item) {
    this.tableCustomerDropdownValues = null;
    this.Courier_forwarderselectedItems = null;
    this.AccountselectedItems = null;
    this.tableAccountDropdownValues = null;
    this.tableCourier_forwarderDropdownValues = null
    this.GetTableData();
  }

  onCourier_forwarder_dropDown() {
    this.Courier_forwarderdropdownSettings = {
      singleSelection: true,
      idField: 'codeCourier_forwarder',
      textField: 'codeCourier_forwarderName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }


  onCourier_forwarderItemSelect(item) {
    this.model.Courier_forwarderselectedItems = item.codeCourier_forwarder
    this.tableCourier_forwarderDropdownValues = this.model.Courier_forwarderselectedItems;
    this.GetTableData();
    this.AccountDisable = false;
  }

  onCourier_forwarderDeSelect(item) {
    this.tableCourier_forwarderDropdownValues = null;
    this.GetTableData();
  }

  onAccount_dropDown() {
    this.AccountdropdownSettings = {
      singleSelection: true,
      idField: 'codeAccount',
      textField: 'codeAccount',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  AccountItemSelect(item) {
    this.model.AccountselectedItems = item.codeAccount
    this.tableAccountDropdownValues = this.model.AccountselectedItems;
    this.GetTableData();
  }

  onAccountDeSelect(item) {
    this.tableAccountDropdownValues = null;
    this.GetTableData();
  }


  GetTableData() {
    // this.AgLoad = true;;
    const dis = this;
    let req = null;
    let filter: any = {};
    let customerdropdownValue = dis.tableCustomerDropdownValues;
    let Courier_forwarderdropdownValue = dis.tableCourier_forwarderDropdownValues;
    let AccountdropdownValue = dis.tableAccountDropdownValues;
    let localstorageUserOrg = this.authService.getLoggedUser();
    console.log("locallll::::", localstorageUserOrg)
    if (localstorageUserOrg.userOrg === 'NISSAN' || localstorageUserOrg.userOrg === 'BMX'|| localstorageUserOrg.userOrg === 'ASTRAZENECA') {
      filter['customerOrg'] = [localstorageUserOrg.userOrg];
    }
    if (customerdropdownValue !== null && customerdropdownValue !== undefined && Object.keys(customerdropdownValue).length !== 0) {
      console.log("if")
      filter['customerOrg'] = [customerdropdownValue];
    }
    console.log("filter", filter)
    if (Courier_forwarderdropdownValue !== null && Courier_forwarderdropdownValue !== undefined && Object.keys(customerdropdownValue).length !== 0) {
      filter['lspOrg'] = [Courier_forwarderdropdownValue];
    }
    if (AccountdropdownValue !== null && AccountdropdownValue !== undefined && Object.keys(customerdropdownValue).length !== 0) {
      filter['shipperAccount'] = [AccountdropdownValue];
    }
    req = dis.requestService.List('SHIPMENTPLANNING', filter);
    req.subscribe(resp => {
      const messageResult = resp['result'];
      console.log("messageResult", messageResult)
      this.RowData = messageResult;
      let rows = [];
      for (let parsedMessageResult of messageResult) {
        rows.push(parsedMessageResult);
      }
      let Data: any = [];
      Data.push(...rows);
      this.TableDropdownRows(Data)
    });
  }

  clear() {
    window.location.reload();
  }

  TableDropdownRows(rows) {
    const dis = this;
    dis.codearrayCustomer = [];
    dis.codearrayCourier_forwarder = [];
    dis.codearrayAccount = [];
    // dis.CodearrayDataCustomer = null;
    for (let i = 0; i < rows.length; i++) {
      const raws = rows[i];
      dis.codearrayCustomer[i] = { codeCustomer: raws['customerOrgId'], codeCustomerName: raws['customerOrgName'] };
      dis.codearrayCourier_forwarder[i] = { codeCourier_forwarder: raws['lspOrgId'], codeCourier_forwarderName: raws['lspOrgName'] };
      dis.codearrayAccount[i] = { codeAccount: raws['shipperAccountNumber'] };
    }
    let arrayduplicateremoveOrigin = this.removeDuplicates(dis.codearrayCustomer, "codeCustomer");
    dis.CodearrayDataCustomer = arrayduplicateremoveOrigin;
    let arrayduplicateremoveDestination = this.removeDuplicates(dis.codearrayCourier_forwarder, "codeCourier_forwarder");
    dis.CodearrayDataCourier_forwarder = arrayduplicateremoveDestination;
    let arrayduplicateremoveAccount = this.removeDuplicates(dis.codearrayAccount, "codeAccount");
    dis.CodearrayDataAccount = arrayduplicateremoveAccount;
  }

  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};
    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }



  onRowClicked(event: any) {
    // this.onRowClickedValue = event.value;
    console.log("this.onRowClickedValue", event.data.houseawbid)
    // let def = 'customStats';
    // event.api.openToolPanel(def);
    // this.moreDetails.changeMessage(this.onRowClickedValue);
    let uniqueId = event.data.forwardingConsol + event.data.forwardingShipment;
    this.popupData(event.data.houseawbid, event.data.HAWB, uniqueId)


    // $("#newModal2").modal("show");

  }

  popupData(houseAwb, hawb, uniqueId) {
    const dis = this;
    let req = null;
    let req2 = {
      houseAwb, hawb
    }
    req = dis.requestService.popup(req2);
    req.subscribe(resp => {
      let result = resp.result;
      console.log("result::::::", result)
      for (let i = 0; i < result.length; i++) {
        if (i === 0) {
          this.onRowClickedValue = result[0];
        }
      }
      console.log("messageResult", this.onRowClickedValue)
      this.SideOverlay = true;
    });
    dis.eventparsing = [];
    this.eventchainloop(uniqueId);

  }





  eventchainloop(uniqueId) {
    const dis = this;
    let raws;
    let req = null;
    dis.eventparsing = [];
    dis.OE_ED = [];
    req = this.requestService.eventChainDetails(uniqueId);
    req.subscribe(resp => {
      const messageResult = resp['result'];
      for (let i = 0; i < messageResult.length; i++) {
        raws = messageResult[i];

        if (raws.actualDate !== null && raws.actualDate.includes("T")) {
          console.log("::::::", raws.actualDate)
          // let time = raws.EVENTDATE.split('T')[0];
          let hrs = raws.actualDate.split(':')[0];
          let min = raws.actualDate.split(':')[1];
          raws.actualDate = hrs + ':' + min;
          console.log("DateTime::", raws.actualDate)
        }
        // if (raws.actualDate === null) {
        //   raws.actualDate = "Actual event is yet to happen"
        // }
        if (raws.estimatedDate !== null && raws.estimatedDate.includes("T")) {
          console.log("::::::", raws.estimatedDate)
          // let time = raws.EVENTDATE.split('T')[0];
          let hrs = raws.estimatedDate.split(':')[0];
          let min = raws.estimatedDate.split(':')[1];
          raws.estimatedDate = hrs + ':' + min;
          console.log("DateTime::", raws.estimatedDate)
        }
        if (raws.estimatedDate === null) {
          raws.estimatedDate = ""
        }
        // raws.EVENTDATE = new Date();
        // raws.EVENTDATE = (this.datePipe.transform(raws.EVENTDATE, 'dd/MM/yyyyTHH:mm') as any);
        if (raws.actualDate !== null) {
          dis.eventparsing.push(raws);
          // }
        }
        else if (raws.actualDate === null && raws.estimatedDate !== null) {
          if (raws.description === 'Departed') {
            raws.description = 'Departure'
          }
          else if (raws.description === 'Arrived') {
            raws.description = 'Arrival'
          }
          dis.OE_ED.push(raws);
        }
      }
    });
  }




  closemodal() {
    console.log("close")
    this.SideOverlay = false;

  }

  saveState() {
    // this.gridColumnApi.getColumnState();
    var columnState = JSON.stringify(this.gridColumnApi.getColumnState());
    // localStorage.setItem('myColumnState', columnState);
    sessionStorage.setItem('savedState', columnState);
    console.log('column state saved');
  }

  defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
  };

  sideBar = {
    hiddenByDefault: false,
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          allowDragFromColumnsToolPanel: true
        },
      },
      // {
      //   id: 'filters',
      //   labelDefault: 'Shipper Account',
      //   labelKey: 'filters',
      //   iconKey: 'filter',
      //   toolPanel: 'agFiltersToolPanel',
      // }
    ]
  }

  gridOptions = {
    rowData: this.GetTableData(),
    columnDefs: this.GetAgColumns(),
  }

  ngOnInit(): void {
    this.customer_dropDown();
    this.onCourier_forwarder_dropDown();
    this.onAccount_dropDown();
  }

}
