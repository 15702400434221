<div class="test-container">
    <div class="test-header">
        <div class="example-section">
            <div class="row mt-3">
                <div class="col">
                    <ng-multiselect-dropdown [placeholder]="'Customer'" [data]="CodearrayDataCustomer"
                        [(ngModel)]="CustomerselectedItems" [settings]="CustomerdropdownSettings"
                        (onSelect)="onCustomerItemSelect($event)" (onDeSelect)="onCustomerDeSelect($event)">
                    </ng-multiselect-dropdown>
                </div>
                <div class="col">
                    <ng-multiselect-dropdown [placeholder]="'Courier/ forwarder'"
                        [data]="CodearrayDataCourier_forwarder" [(ngModel)]="Courier_forwarderselectedItems"
                        [settings]="Courier_forwarderdropdownSettings" [disabled]="disabled"
                        (onSelect)="onCourier_forwarderItemSelect($event)"
                        (onDeSelect)="onCourier_forwarderDeSelect($event)">
                    </ng-multiselect-dropdown>
                </div>
                <div class="col">
                    <ng-multiselect-dropdown [placeholder]="'Account'" [data]="CodearrayDataAccount"
                        [(ngModel)]="AccountselectedItems" [settings]="AccountdropdownSettings"
                        [disabled]="AccountDisable" (onSelect)="AccountItemSelect($event)"
                        (onDeSelect)="onAccountDeSelect($event)">
                    </ng-multiselect-dropdown>
                </div>
                <div class="col-auto float-right ml-auto">
                    <a class="btn btn-secondary mb-3" style="float: right;" (click)="resetState()"> Original Layout </a>
                    <div class="view-icons">
                        <a (click)="clear()" title="Clear" class="grid-view btn btn-link"><i
                                class="fa fa-refresh"></i></a>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<div>
    <ag-grid-angular class="ag-theme-balham" [columnDefs]="columnDefs" [domLayout]="domLayout" [rowData]="RowData"
        [debug]="true" [masterDetail]="true" [sideBar]="sideBar" [enableRangeSelection]="true" [animateRows]="true"
        [pagination]="true" [defaultColDef]="defaultColDef" [paginationPageSize]="25" [enableCharts]="true"
        (gridReady)="BindData($event)" (columnMoved)="onColumnMoved($event)">
    </ag-grid-angular>
</div>

<ng-container *ngIf="SideOverlay === true">
    <div id="myNav" class="overlay" *ngIf="onRowClickedValue!=undefined">
        <div class="container">
            <div class="row" style="border-bottom: 1px solid #dee2e6;">
                <div class="col"></div>
                <div class="col mt-4" style="text-align:center;">
                    <h5 class="mt-4"><strong>HAWB#: {{onRowClickedValue.HAWB}}</strong></h5>
                </div>
                <div class="col">
                    <a href="javascript:void(0)" class="" style="text-align:right;" (click)="closemodal()">&times;</a>
                </div>
            </div>
        </div>


        <div class="overlay-content">
            <div class="container">
                <div class="leftContent">
                    <div class="ng-tns-c4-0">
                        <div class="qntyDiv">
                            <div class="cont">
                                <div class="title">Shipper : </div>
                                <div class="ng-tns-c4-0">{{onRowClickedValue.pickupCompanyName}}</div>
                            </div>
                            <div class="cont">
                                <div class="title">Forwarder : </div>
                                <div class="qntyUnitDesc" title="Pallet"> {{onRowClickedValue.forwarder}} </div>
                            </div>
                            <div class="cont">
                                <div class="title">Consignee : </div>
                                <div class="qntyUnitDesc" title="Pallet"> {{onRowClickedValue.deliveryCompanyName}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottomContent">
                    <div class="statusDiv">
                        <table class="ng-tns-c4-0" width="100%">
                            <tr class="locationHeader">
                                <td class="ng-tns-c4-0" width="10%"> Origin </td>
                                <td class="ng-tns-c4-0" width="20%" style="text-transform: capitalize;">
                                    {{onRowClickedValue.pickuppartyType}} Address</td>
                                <td class="ng-tns-c4-0" width="15%"> Port of Loading </td>
                                <td class="ng-tns-c4-0" width="15%"> Port of Discharge </td>
                                <td class="ng-tns-c4-0" width="10%"> Destination </td>
                                <td class="ng-tns-c4-0" width="20%" style="text-transform: capitalize;">
                                    {{onRowClickedValue.deliveryPartyType}} Address</td>

                            </tr>
                            <tr class="imageCont font-wt">
                                <td class="ng-tns-c4-0">
                                    <div class="inTransit bottimestatusdiv">
                                        <div class="title font-wt" style="text-transform: uppercase">
                                            Code : {{onRowClickedValue.originCode}}
                                        </div>
                                    </div>
                                </td>
                                <td class="ng-tns-c4-0">
                                    <div class="booking bottimestatusdiv">
                                        <div class="title font-wt" style="text-transform: uppercase">
                                            {{onRowClickedValue.pickupAddress1}},{{onRowClickedValue.pickupAddress2}},{{onRowClickedValue.pickupCity}},{{onRowClickedValue.pickupCountryCode}}
                                        </div>
                                    </div>
                                </td>
                                <td class="ng-tns-c4-0">
                                    <div class="inTransit bottimestatusdiv" style="text-transform: uppercase">
                                        <div class="ng-tns-c4-0 ng-star-inserted">
                                            <div class="dates font-wt ng-tns-c4-0 ng-star-inserted">code :
                                                {{onRowClickedValue.portOfLoadingCode}} </div>

                                        </div>
                                        <!-- <div class="ng-tns-c4-0 ng-star-inserted">
                                            <div class="dates font-wt ng-tns-c4-0 ng-star-inserted">Name :
                                                {{onRowClickedValue.portOfLoadingName}} </div>

                                        </div> -->
                                    </div>
                                </td>
                                <td class="ng-tns-c4-0">
                                    <div class="inTransit bottimestatusdiv" style="text-transform: uppercase">
                                        <div class="ng-tns-c4-0 ng-star-inserted">
                                            <div class="dates font-wt ng-tns-c4-0 ng-star-inserted">code :
                                                {{onRowClickedValue.portOfDischargeCode}} </div>

                                        </div>
                                        <!-- <div class="ng-tns-c4-0 ng-star-inserted">
                                            <div class="dates font-wt ng-tns-c4-0 ng-star-inserted">Name :
                                                {{onRowClickedValue.portOfDischargeName}} </div>

                                        </div> -->
                                    </div>
                                </td>
                                <td class="ng-tns-c4-0">
                                    <div class="inTransit bottimestatusdiv">
                                        <div class="title font-wt" style="text-transform: uppercase">
                                            Code : {{onRowClickedValue.airportOfDestinationCode}}
                                        </div>
                                    </div>
                                </td>

                                <td class="ng-tns-c4-0">
                                    <div class="booking bottimestatusdiv">
                                        <div class="title font-wt" style="text-transform: uppercase">
                                            {{onRowClickedValue.deliveryAddress1}},{{onRowClickedValue.deliveryAddress2}},{{onRowClickedValue.deliveryCity}},{{onRowClickedValue.deliveryCountryCode}}
                                        </div>
                                    </div>
                                </td>



                            </tr>
                        </table>
                    </div>
                </div>

            </div>

            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 EC">
                        <div class="scrollmenu">
                            <div class="row">
                                <div class="col d-flex justify-content-center mt-2">
                                    <h5 class="card-title d-flex justify-content-center">
                                        <b><u class="pl-2">Events</u></b>

                                    </h5>
                                </div>
                                <div class="col d-flex justify-content-center mt-2">
                                    <b>
                                        <div class="OE">
                                            <a id="foo" href="#">Outstanding Events and Estimated Dates</a>
                                            <table *ngIf="OE_ED.length!==0">
                                                <tr>
                                                    <th>Events </th>
                                                    <th>Estimated Date</th>
                                                </tr>
                                                <tr *ngFor="let OEEDParsing of OE_ED">
                                                    <td>{{OEEDParsing.description}}</td>
                                                    <td>{{OEEDParsing.estimatedDate}}</td>
                                                </tr>
                                            </table>
                                            <table *ngIf="OE_ED.length===0">No Records</table>
                                        </div>
                                    </b>
                                </div>
                            </div>

                            <div class="row1 mt-3 mb-4">
                                <div class="identification" *ngFor="let eventparsing of eventparsing;let i=index">
                                    <!-- <div class=""> -->

                                    <div class="step">
                                        <div>
                                            <div class="circle"></div>
                                        </div>
                                        <div>
                                            <div class="lable">
                                                <span class="label label-success"
                                                    *ngIf="eventparsing.location !== null">
                                                    {{eventparsing.location}} / {{eventparsing.description}}
                                                </span>
                                                <span class="label label-success"
                                                    *ngIf="eventparsing.location === null">
                                                    {{eventparsing.description}}
                                                </span>
                                            </div>
                                            <div class="title">ACT : {{eventparsing.actualDate}}</div>
                                            <div class="title">EST : {{eventparsing.estimatedDate}}</div>
                                            <!-- <div class="caption">ACT:{{eventparsing.estimatedDate}}</div> -->
                                        </div>
                                    </div>

                                    <div class="step">
                                        <div>
                                            <div class="progressbar">
                                                <li class="completed mt-2">

                                                    <span class="statusName" *ngIf="i+1<10"
                                                        style="margin-left: 9px;position: relative;top: -30px;">
                                                        {{i + 1}}
                                                    </span>
                                                    <span class="statusName" *ngIf="i+1>=10"
                                                        style="margin-left: 6px;position: relative;top: -30px;">
                                                        {{i + 1}}
                                                    </span>
                                                </li>
                                            </div>
                                        </div>
                                        <div>
                                            <!-- <div class="horizontalLine"></div> -->
                                        </div>
                                    </div>

                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>









        </div>
    </div>
</ng-container>