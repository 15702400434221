import { Component, OnInit } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { ResizeEvent } from 'angular-resizable-element';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;
@Component({
  selector: 'app-shipmentinstruction',
  templateUrl: './shipmentinstruction.component.html',
  styleUrls: ['./shipmentinstruction.component.scss']
})
export class ShipmentinstructionComponent implements OnInit {
  style: {};
  siDearlerOrderNo: any;
  clickedOnINV: boolean;
  clickedOnDealer: boolean;
  graphData: any;
  dateRange: string;
  periodLabel: any = 'Last 24 Hrs';
  onResizeEnd(event: ResizeEvent): void {
    console.log(event);
    this.style = {
      width: `${event.rectangle.width}px`,
      height: `${event.rectangle.height}px`
    };
  }
  columnDefs: any;
  RowData: any;
  IsColumnsToFit: boolean;
  AgLoad: boolean;

  gridApi: any;
  gridColumnApi: any;
  eventparsing: any = [];
  OE_ED: any = [];
  SideOverlay = false;
  si_houseway_waybill_no: any;
  onRowClickedValue: any;
  si_invoice_no: any;
  options: any;


  constructor(private requestService: RequestService, private spinner: NgxSpinnerService) { }
  domLayout = 'autoHeight';

  chartData() {
    let key;
    let value;
    let cData: any = [];
    for ([key, value] of Object.entries(this.graphData)) {
      if (value !== 0) {
        cData.push(
          {
            duration: key,
            Shipments: value
          }
        )
      }
    }

    console.log('CData:', cData);
    this.options = {
      // title: { text: "New Orders" },
      // subtitle: { text: 'in billion U.S. dollars' },

      data: cData,
      series: [
        {
          type: 'column',
          xKey: 'duration',
          yKeys: ['Shipments'],
          listeners: {
            nodeClick: (event) => {
              // console.log('clickedonchart:', datum);
              this.basedOnChart(event)
              // this.basedOnChart.bind(this)
              // this.gridOptions = 
              // this.gridOptions = {
              //   rowData: this.GetTableData(),
              //   columnDefs: this.GetAgColumns(),
              // }
            },
          },
          tooltip: {
            renderer: function (params) {
              return {
                title: params.xValue,
                content: params.yValue.toFixed(0),
              };
            },
          },
        },
      ],
      legend: {
        position: 'top',
      },
      axes: [
        {
          type: 'category',
          position: 'bottom',

          label: {
            // padding: 30,
            rotation: 90
          },
          // tick: { count: 20 },
        },
        {
          type: 'number',
          position: 'left'
        }
      ]
    };
  }

  BindData(params) {
    this.gridApi = params.api;
    this.gridApi.showLoadingOverlay();
    params.columnApi.autoSizeAllColumns();
    this.gridColumnApi = params.columnApi;
    // this.gridApi.getToolPanelInstance('filters').expandFilters();
    var columnState = JSON.parse(localStorage.getItem('myBookingColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }


  onColumnMoved(params) {
    console.log("params:::")
    var columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem('myBookingColumnState', columnState);
  }

  onGridReady(params) {
    var columnState = JSON.parse(localStorage.getItem('myBookingColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }

  resetState() {
    this.gridColumnApi.resetColumnState();
    console.log('column state reset');
  }

  GetAgColumns() {
    this.columnDefs = [
      // { headerName: 'Shipment Order Party', field: 'si_shipment_order_party', sortable: true },
      {
        headerName: 'Invoice No', field: 'si_invoice_no', sortable: true, suppressFiltersToolPanel: true,
        onCellClicked: this.onRowClicked.bind(this),
        cellRenderer: function (params) {
          let keyData = params.value;
          let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
          return newLink;
        }
      },
      { headerName: 'Dealer No', field: 'si_dealer_no', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Dealer Name', field: 'si_dealer_name', sortable: true, suppressFiltersToolPanel: true },
      {
        headerName: 'Dearler Order No', field: 'si_dearler_order_no', sortable: true, suppressFiltersToolPanel: true,
        onCellClicked: this.onRowClickedDealer.bind(this),
        cellRenderer: function (params) {
          let keyData = params.value;
          let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
          return newLink;
        }
      },
      {
        headerName: 'PickUp Date', field: 'si_pickUp_date', sortable: true, suppressFiltersToolPanel: true,
        cellRenderer: function (params) {
          let data = params.value
          data = data.split('T')[0];
          return data
        }
      },
      { headerName: 'DEST SSC', field: 'si_dest_ssc', sortable: true, suppressFiltersToolPanel: true },
      {
        headerName: 'HAWB No', field: 'si_houseway_waybill_no', sortable: true, suppressFiltersToolPanel: true,
        // onCellClicked: this.onRowClicked.bind(this),
        // cellRenderer: function (params) {
        //   let keyData = params.value;
        //   let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
        //   return newLink;
        // }
      },
      {
        headerName: 'Shipment Order Date', field: 'si_shipment_order_date', sortable: true, suppressFiltersToolPanel: true,
        cellRenderer: function (params) {
          let data = params.value
          data = data.split('T')[0];
          return data
        }
      },
      { headerName: 'Item No', field: 'si_item_no', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Shipment Status', field: 'si_shipment_status', sortable: true, suppressFiltersToolPanel: true, hide: true },
      { headerName: 'TotalWT Actual', field: 'si_total_wt_actual', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Total Dimen WT', field: 'si_total_dimen_wt', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Total Billing WT', field: 'si_total_billing_wt', sortable: true, suppressFiltersToolPanel: true },
      { headerName: 'Master waybill No', field: 'si_master_waybill_no', sortable: true, suppressFiltersToolPanel: true, hide: true },

      // {
      //   headerName: 'Receive Date', field: 'si_receive_date', sortable: true, suppressFiltersToolPanel: true,
      //   cellRenderer: function (params) {
      //     let data = params.value
      //     data = data.split('T')[0];
      //     return data
      //   }
      // },


    ];

  }

  onRowClicked(event) {
    const dis = this;
    this.clickedOnINV = true;
    this.clickedOnDealer = false;
    let req: any;
    this.si_invoice_no = event.data.si_invoice_no
    let req1 = {
      si_invoice_no: this.si_invoice_no
    }
    req = dis.requestService.trMoreDetailsCall(req1);
    req.subscribe(resp => {
      const messageResult = resp;
      console.log("messsss", messageResult);
      this.onRowClickedValue = messageResult;
      this.SideOverlay = true;
    });

  }

  onRowClickedDealer(event) {
    this.clickedOnINV = false;
    this.clickedOnDealer = true;
    const dis = this;
    let req: any;
    this.siDearlerOrderNo = event.data.si_dearler_order_no;
    let req1 = {
      si_dearler_order_no: this.siDearlerOrderNo
    }
    req = dis.requestService.trMoreDetailsCall(req1);
    req.subscribe(resp => {
      const messageResult = resp;
      console.log("messsss", messageResult);
      this.onRowClickedValue = messageResult;
      this.SideOverlay = true;
    });
  }

  closemodal() {
    this.SideOverlay = false;
    // document.getElementById('myNavResize').removeAttribute("style");
    // $('.overlay').removeAttr('style');
    this.style = {
      width: 500
    };
  }
  clear() {
    window.location.reload();
  }

  defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
  };

  sideBar = {
    hiddenByDefault: false,
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          allowDragFromColumnsToolPanel: true
        },
      },
      // {
      //   id: 'filters',
      //   labelDefault: 'Shipper Account',
      //   labelKey: 'filters',
      //   iconKey: 'filter',
      //   toolPanel: 'agFiltersToolPanel',
      // }
    ]
  }

  gridOptions = {
    rowData: this.GetTableData(),
    columnDefs: this.GetAgColumns(),
  }

  GetTableData() {
    // this.AgLoad = true;;
    const dis = this;
    let req = null;
    let filter: any = {};
    this.RowData = []
    let req1 = {
      dateRange: this.dateRange
    }
    req = dis.requestService.ShipmentInstruction(req1);
    req.subscribe(resp => {
      const messageResult = resp.data;
      this.RowData = messageResult;
      this.graphData = resp.graphData;
      this.chartData();
      this.spinner.hide();
    });
  }

  ngOnInit(): void {

  }

  basedOnChart(e) {
    this.spinner.show();
    console.log('clickedOnChart:', e);
    const currentDate = new Date();

    let timeFrames = {
      "Last 24 Hrs": [new Date((new Date()).setDate(currentDate.getDate() - 1)), currentDate],
      "Last 7 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7))), new Date((new Date()).setDate(currentDate.getDate() - 1))],
      "Last 15 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 7)))],
      "Last 30 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15 + 30))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15)))],
      "Last 90 Days": [new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15 + 30 + 90))), new Date((new Date()).setDate(currentDate.getDate() - (1 + 7 + 15 + 30)))],
    }
    let fromDate: any;
    let toDate: any

    if (e.datum.duration === 'Last 30 Days') {
      fromDate = new Date(timeFrames["Last 30 Days"][0]).toISOString();
      toDate = new Date(timeFrames["Last 30 Days"][1]).toISOString()
    }
    if (e.datum.duration === 'Last 90 Days') {
      fromDate = new Date(timeFrames["Last 90 Days"][0]).toISOString();
      toDate = new Date(timeFrames["Last 90 Days"][1]).toISOString()
    }
    if (e.datum.duration === 'Last 15 Days') {
      fromDate = new Date(timeFrames["Last 15 Days"][0]).toISOString();
      toDate = new Date(timeFrames["Last 15 Days"][1]).toISOString()
    }
    if (e.datum.duration === 'Last 7 Days') {
      fromDate = new Date(timeFrames["Last 7 Days"][0]).toISOString();
      toDate = new Date(timeFrames["Last 7 Days"][1]).toISOString()
    }
    if (e.datum.duration === 'Last 24 Hrs') {
      fromDate = new Date(timeFrames["Last 24 Hrs"][0]).toISOString();
      toDate = new Date(timeFrames["Last 24 Hrs"][1]).toISOString()
    }
    this.periodLabel = e.datum.duration;
    fromDate = moment(fromDate).format('YYYY-MM-DD HH:mm:ss');
    toDate = moment(toDate).format('YYYY-MM-DD HH:mm:ss');
    console.log(fromDate, toDate);
    this.dateRange = fromDate + ', ' + toDate;
    this.GetTableData();
    this.GetAgColumns();

  }

}
