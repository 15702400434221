import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MoreDetailsService {
  moreDetails = new Subject();
  refNo = undefined;
  status = undefined;
  mawb = undefined;
  checkpointcode = undefined;
  lspOrgId = undefined;
  lspOrgName = undefined;
  checkpointDescription = undefined;
  customerOrgId = undefined;
  customerOrgName = undefined;
  currentMessage = this.moreDetails.asObservable();
  toDate = undefined;
  fromDate = undefined;
  lspOrg = undefined;
  lableName = undefined;
  DeliveredcustomerOrgId = undefined;
  DeliveredcustomerOrgName = undefined;
  lspType = undefined;
  widgetStatus = undefined;
  HAWBSearchField = undefined;
  region = undefined;
  HAWBSearchFieldDelivered = undefined;
  searchCustOrgID = undefined;
  searchOriginID = undefined;
  searchDestinationID = undefined;
  searchLsp = undefined;
  dashboardHAWBSearchField = undefined;
  shipmentReferencesSearchField = null;
  destinationCode = undefined;
  originCode = undefined;
  ShipmentReferencesSearchField = undefined
  MOT = undefined
  windowReference: any;
  PNPsearchOriginID = undefined;
  PNPsearchDestinationID = undefined
  specificDetails = undefined
  constructor() { }

  changeMessage(refNo) {
    this.refNo = refNo;
  }
  checkpointCodeMessage(checkpointcode, lspOrgId, lspOrgName, checkpointDescription, customerOrgId, customerOrgName, HAWBSearchField, originCode, destinationCode, ShipmentReferencesSearchField, MOT) {
    console.log("checkpointCodeMessage", checkpointcode)
    this.checkpointcode = checkpointcode;
    this.lspOrgId = lspOrgId;
    this.lspOrgName = lspOrgName;
    this.checkpointDescription = checkpointDescription;
    this.customerOrgId = customerOrgId;
    this.customerOrgName = customerOrgName
    this.HAWBSearchField = HAWBSearchField
    this.originCode = originCode
    this.destinationCode = destinationCode
    this.ShipmentReferencesSearchField = ShipmentReferencesSearchField
    this.MOT = MOT
    this.specificDetails = undefined
    console.log("checkpointCodeMessage")
  }

  DeliveredDetailsMessage(fromDate, toDate, lableName, lspOrg, DeliveredcustomerOrgId, DeliveredcustomerOrgName, HAWBSearchField, originCode, destinationCode, ShipmentReferencesSearchField) {
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.lableName = lableName;
    this.lspOrg = lspOrg;
    this.DeliveredcustomerOrgId = DeliveredcustomerOrgId;
    this.DeliveredcustomerOrgName = DeliveredcustomerOrgName
    this.HAWBSearchFieldDelivered = HAWBSearchField
    this.originCode = originCode
    this.destinationCode = destinationCode
    this.ShipmentReferencesSearchField = ShipmentReferencesSearchField
  }

  transportRequestMessage(widgetStatus, region, lspType, status) {
    this.widgetStatus = widgetStatus;
    this.lspType = lspType
    this.region = region
    this.status = status
  }

  SearchData(searchCustOrgID, searchOriginID, searchDestinationID, searchLsp, dashboardHAWBSearchField, shipmentReferencesSearchField = null) {
    this.searchCustOrgID = searchCustOrgID;
    this.searchOriginID = searchOriginID;
    this.searchDestinationID = searchDestinationID;
    this.searchLsp = searchLsp;
    this.dashboardHAWBSearchField = dashboardHAWBSearchField;
    this.shipmentReferencesSearchField = shipmentReferencesSearchField
  }

  PNPSearchData(searchOriginID, searchDestinationID) {
    this.PNPsearchOriginID = searchOriginID,
      this.PNPsearchDestinationID = searchDestinationID
  }

  setReference(data: any) {
    this.windowReference = data;
    console.log("this...1,set", this.windowReference)
  }

  getReference() {
    console.log("this.windowReference", this.windowReference)
    return this.windowReference
  }

  specificDetailscheckpointCodeMessage(checkpointcode, lspOrgId, lspOrgName, checkpointDescription, customerOrgId, customerOrgName, HAWBSearchField, originCode, destinationCode, ShipmentReferencesSearchField, MOT, specificDetails) {
    this.checkpointcode = checkpointcode;
    this.lspOrgId = lspOrgId;
    this.lspOrgName = lspOrgName;
    this.checkpointDescription = checkpointDescription;
    this.customerOrgId = customerOrgId;
    this.customerOrgName = customerOrgName
    this.HAWBSearchField = HAWBSearchField
    this.originCode = originCode
    this.destinationCode = destinationCode
    this.ShipmentReferencesSearchField = ShipmentReferencesSearchField
    this.MOT = MOT
    this.specificDetails = specificDetails
  }

}
