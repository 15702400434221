import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CheckpointsIntransitComponent } from './components/checkpoints-intransit/checkpoints-intransit.component';
import { ConsumerOrdersExceptionsComponent } from './components/consumer-orders-exceptions/consumer-orders-exceptions.component';
import { DeliveredDashboardComponent } from './components/delivered-dashboard/delivered-dashboard.component';
import { DeliveredEUComponent } from './components/delivered-eu/delivered-eu.component';
import { DeliveredComponent } from './components/delivered/delivered.component';
import { FusionTrackTempComponent } from './components/fusion-track-temp/fusion-track-temp.component';
import { InTransitEUComponent } from './components/in-transit-eu/in-transit-eu.component';
import { InTransitComponent } from './components/in-transit/in-transit.component';
import { NewIntransitComponent } from './components/new-intransit/new-intransit.component';
import { PNPSearchComponent } from './components/pnpsearch/pnpsearch.component';
import { SearchComponent } from './components/search/search.component';
import { ShipmentBookingComponent } from './components/shipment-booking/shipment-booking.component';
import { ShipmentConfirmationComponent } from './components/shipment-confirmation/shipment-confirmation.component';
import { ShipmentinstructionComponent } from './components/shipmentinstruction/shipmentinstruction.component';
import { ShipmentpreparationComponent } from './components/shipmentpreparation/shipmentpreparation.component';
import { TrackTemperatureComponent } from './components/track-temperature/track-temperature.component';
import { TransportRequestDashboardComponent } from './components/transport-request-dashboard/transport-request-dashboard.component';
import { TransportRequestComponent } from './components/transport-request/transport-request.component';
import { WarehouseInfrastructureComponent } from './components/warehouse-infrastructure/warehouse-infrastructure.component';
import { AuthGuard } from './guard/auth.guard';
import { DashboardComponent } from './layout/dashboard/dashboard.component';
import { LoginComponent } from './layout/login/login.component';
import { customer } from './enums/roles.enum';


let rolesData = Object.values(customer).filter((v) => isNaN(Number(v)));
console.log(rolesData)

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: DashboardComponent,
    data: { roles: rolesData },
    canActivate: [AuthGuard]
  },
  {
    path: 'shipmentBooking',
    component: ShipmentBookingComponent,
    data: { roles: rolesData },
    canActivate: [AuthGuard]
  },
  {
    path: 'ShipmentPreparation',
    component: ShipmentpreparationComponent,
    data: { roles: rolesData },
    canActivate: [AuthGuard]
  },
  {
    path: 'inTransit',
    component: InTransitComponent,
    data: { roles: rolesData },
    canActivate: [AuthGuard]
  },
  {
    path: 'delivered',
    component: DeliveredComponent,
    data: { roles: rolesData },
    canActivate: [AuthGuard]
  },
  {
    path: 'checkpoint_Intransit',
    component: CheckpointsIntransitComponent,
    data: { roles: rolesData },
    canActivate: [AuthGuard]
  },
  {
    path: 'new_Checkpoint_Intransit',
    component: NewIntransitComponent,
    data: { roles: rolesData },
    canActivate: [AuthGuard]
  },
  {
    path: 'TrackTemperature',
    component: TrackTemperatureComponent,
    data: { roles: rolesData },
    canActivate: [AuthGuard]
  },
  {
    path: 'DeliveredDashboard',
    component: DeliveredDashboardComponent,
    data: { roles: rolesData },
    canActivate: [AuthGuard]
  },
  {
    path: 'TransportRequestDashboard',
    component: TransportRequestDashboardComponent,
    data: { roles: rolesData },
    canActivate: [AuthGuard]
  },
  {
    path: 'TransportRequest',
    component: TransportRequestComponent,
    data: { roles: rolesData },
    canActivate: [AuthGuard]
  },
  {
    path: 'ShipingInstruction',
    component: ShipmentinstructionComponent,
    data: { roles: rolesData },
    canActivate: [AuthGuard]
  },
  {
    path: 'warehouseInfrastructure',
    component: WarehouseInfrastructureComponent,
    data: { roles: rolesData },
    canActivate: [AuthGuard]
  },
  {
    path: 'ShipingConfirmation',
    component: ShipmentConfirmationComponent,
    data: { roles: rolesData },
    canActivate: [AuthGuard]
  },
  {
    path: 'Exceptions',
    component: ConsumerOrdersExceptionsComponent,
    data: { roles: rolesData },
    canActivate: [AuthGuard]
  },
  {
    path: 'Search',
    component: SearchComponent,
    data: { roles: rolesData },
    canActivate: [AuthGuard]
  },
  {
    path: 'PNPSearch',
    component: PNPSearchComponent,
    data: { roles: ['HM'] },
    canActivate: [AuthGuard]
  },
  {
    path: 'FusionChartsTrackTemp',
    component: FusionTrackTempComponent,
    data: { roles: rolesData },
    canActivate: [AuthGuard]
  },
  {
    path: 'inTransitEU',
    component: InTransitEUComponent,
    data: { roles: ['HM'] },
    canActivate: [AuthGuard]
  },
  {
    path: 'DeliveredEU',
    component: DeliveredEUComponent,
    data: { roles: ['HM'] },
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
