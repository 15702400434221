<div class="wrap mt-5">
    <div class="floatleft col-md-4 col-sm-6 col-12 col-lg-6 col-xl-6" *ngFor="let data of Data">
        <div class="row wrapheader">{{data.region}}</div>
        <div class="row p-4 pr-5">
            <div class="col-6">
                <div class="profile-widget" (click)="Details('New Orders',data.region,'Forwarding', 'new')">
                    <div class="profile-img">
                        <a class="avatar">
                            <span>{{data.newOrderCount}}</span>
                        </a>
                    </div>
                    <div class="row"></div>
                    <h4 class="mt-4 description"><a>Orders(New)</a>
                    </h4>
                </div>
            </div>
            <div class="col-6">
                <div class="profile-widget" (click)="Details('Active Orders',data.region,'Forwarding', 'active')">
                    <div class="profile-img">
                        <a class="avatar">
                            <span>{{data.activeOrderCount}}</span>
                        </a>
                    </div>
                    <div class="row"></div>
                    <h4 class="mt-4 description"><a>Active Orders</a>
                    </h4>
                </div>
            </div>
        </div>
        <!-- <div class="row p-4 pr-5">
            <div class="col-6">
                <div class="profile-widget" (click)="Details('Partial/Full Cancel',data.region,'Forwarding', 'cancelled')">
                    <div class="profile-img">
                        <a class="avatar">
                            <span>{{data.exceptionsCount}}</span>
                        </a>
                    </div>
                    <div class="row"></div>
                    <h4 class="mt-4 description"><a>Partial/Full Cancel</a>
                    </h4>
                </div>
            </div>
        </div> -->
    </div>
    <!-- <div class="floatright">
        <div class="row wrapheader">Region 2</div>
        <div class="row p-4 pr-5">
            <div class="col-6">
                <div class="profile-widget" (click)="Details('Orders','Express')">
                    <div class="profile-img">
                        <a class="avatar">
                            <span>22</span>
                        </a>
                    </div>
                    <div class="row"></div>
                    <h4 class="mt-4 description"><a>Orders(New)</a>
                    </h4>
                </div>
            </div>
            <div class="col-6">
                <div class="profile-widget" (click)="Details('ActiveOrders','Express')">
                    <div class="profile-img">
                        <a class="avatar">
                            <span>52</span>
                        </a>
                    </div>
                    <div class="row"></div>
                    <h4 class="mt-4 description"><a>Active Orders</a>
                    </h4>
                </div>
            </div>
        </div>
        <div class="row p-4 pr-5">
            <div class="col-6">
                <div class="profile-widget" (click)="Details('PartialORFullCancel','Express')">
                    <div class="profile-img">
                        <a class="avatar">
                            <span>12</span>
                        </a>
                    </div>
                    <div class="row"></div>
                    <h4 class="mt-4 description"><a>Partial/Full Cancel</a>
                    </h4>
                </div>
            </div>
        </div>
    </div> -->
    <div style="clear: both;"> </div>
</div>