<ng-container *ngIf="userRole === 'Express' || userRole === 'DHL'">
    <div class="test-container">
        <div class="test-header">
            <div class="example-section">
                <div class="row">
                    <div class="col"></div>
                    <div class="col-2">
                        <ng-multiselect-dropdown [placeholder]="'Customer'" [data]="CodearrayDataCustomer"
                            [(ngModel)]="CustomerselectedItems" [settings]="CustomerdropdownSettings"
                            (onSelect)="onCustomerItemSelect($event)" (onDeSelect)="onCustomerDeSelect($event)">
                        </ng-multiselect-dropdown>
                    </div>
                    <div class="col-2">
                        <input placeholder="Enter HAWB" class="form-control" type="text" name="HAWB"
                            [(ngModel)]="HAWBSearchField" (keyup.enter)="HAWBSearch()">
                    </div>
                    <div class="col-auto float-right ml-auto">
                        <div class="view-icons">
                            <a (click)="clear()" title="Clear" class="grid-view btn btn-link"><i
                                    class="fa fa-refresh"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <canvas class="p-4" id="ForwardingChart" height="100"></canvas>
    </div>
</ng-container>


<ng-container *ngIf="userRole != 'Express' || userRole != 'DHL'">
    <div class="test-container">
        <div class="test-header">
            <div class="example-section">
                <div class="row">
                    <div class="col"></div>
                    <div class="col-2">
                        <ng-multiselect-dropdown [placeholder]="'custom placeholder'" [settings]="dropdownSettings"
                            [data]="dropdownList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                    <!-- <ng-container *ngIf="userRole === 'SLB'">
                        <div class="col">
                            <ng-multiselect-dropdown [placeholder]="'Origin'" [settings]="dropdownSettingsOrigin"
                                [data]="dropdownListOrigin" [(ngModel)]="selectedItemsOrigin"
                                (onSelect)="onItemSelectOrigin($event)">
                            </ng-multiselect-dropdown>

                        </div>
                        <div class="col">
                            <ng-multiselect-dropdown [placeholder]="'Destination'"
                                [settings]="dropdownSettingsDestination" [data]="dropdownListDestination"
                                [(ngModel)]="selectedItemsDestination" (onSelect)="onItemSelectDestination($event)"
                                [disabled]="disableDestination">
                            </ng-multiselect-dropdown>
                        </div>
                        <div class="col">
                            <ng-multiselect-dropdown [placeholder]="'MOT'"
                                [settings]="dropdownSettingsMOT" [data]="dropdownListMOT"
                                [(ngModel)]="selectedItemsMOT" (onSelect)="onItemSelectMOT($event)"
                                [disabled]="disableMOT">
                            </ng-multiselect-dropdown>
                        </div>
                    </ng-container> -->
                    <ng-container *ngIf="userRole !== 'Nissan'">
                        <div class="col-2">
                            <input placeholder="Enter HAWB" class="form-control" type="text" name="HAWB"
                                [(ngModel)]="HAWBSearchField" (keyup.enter)="HAWBSearch()">
                        </div>
                    </ng-container>
                    <!-- <ng-container *ngIf="userRole === 'SLB'">
                        <div class="col">
                            <input placeholder="Enter Shipment References" class="form-control" type="text"
                                name="ShipmentReferences" [(ngModel)]="ShipmentReferencesSearchField"
                                (keyup.enter)="ShipmentReferencesSearch()">
                        </div>
                    </ng-container> -->
                    <div class="col-auto float-right ml-auto">
                        <div class="view-icons">
                            <a (click)="clear()" title="Clear" class="grid-view btn btn-link"><i
                                    class="fa fa-refresh"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- CHARTS SECTION  -->

    <div class="row mt-4">
        <canvas class="p-4" id="{{requiredChartInitialization.divId}}" height="100"></canvas>
    </div>

</ng-container>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>