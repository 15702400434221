import { Component, OnInit } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';

import { MapData } from 'src/app/classes/map-data';
import { MapDataPush } from 'src/app/classes/map-data-push';

@Component({
  selector: 'app-track-temperature',
  templateUrl: './track-temperature.component.html',
  styleUrls: ['./track-temperature.component.scss']
})
export class TrackTemperatureComponent implements OnInit {
  map = new MapDataPush();
  MapData: any = [];
  constructor(private requestService: RequestService) { }

  ngOnInit(): void {
  }


  onRowClickedValue = null;
  columnDefs: any;
  RowData: any;
  IsColumnsToFit: boolean;
  AgLoad: boolean;

  gridApi: any;
  gridColumnApi: any;
  eventparsing: any = [];
  OE_ED: any = [];
  SideOverlay = false;


  domLayout = 'autoHeight';


  BindData(params) {
    this.gridApi = params.api;
    params.columnApi.autoSizeAllColumns();
    this.gridColumnApi = params.columnApi;
    var columnState = JSON.parse(localStorage.getItem('myBookingColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }


  onColumnMoved(params) {
    console.log("params:::")
    var columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem('myBookingColumnState', columnState);
  }

  onGridReady(params) {
    var columnState = JSON.parse(localStorage.getItem('myBookingColumnState'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
  }

  resetState() {
    this.gridColumnApi.resetColumnState();
    console.log('column state reset');
  }

  GetAgColumns() {
    this.columnDefs = [

      {
        headerName: 'HAWB/Tracking No', field: 'waybillNumber', sortable: true,
        chartDataType: 'category',
        onCellClicked: this.onRowClicked.bind(this),
        // cellRenderer: 'agGroupCellRenderer'
        cellRenderer: function (params) {
          let keyData = params.value;
          let newLink = `<a href="javascript:void(0)">${keyData}</a>`;
          return newLink;
        }

      },
      { headerName: 'MAWB', field: 'masterAwb', sortable: true, hide: true },
      { headerName: 'Forwarding Consol Key', field: 'forwardingConsolKey', sortable: true, hide: true },
      { headerName: 'Forwarding Shipment Key', field: 'forwardingShipmentKey', sortable: true, hide: true },
      {
        headerName: 'Latest Reading Date', field: 'latestReadingDate', sortable: true,
        cellRenderer: function (params) {
          let keyData = params.value;
          keyData = keyData.split('.')[0].replace('T',' ')
          return keyData;
        }
      },
      { headerName: 'Longitude', field: 'latestReadingLgtd', sortable: true },
      { headerName: 'Latitude', field: 'latestReadingLttd', sortable: true },
      { headerName: 'Temp in ℃', field: 'latestReadingTemp', sortable: true },
      { headerName: 'Unit Reference', field: 'unitReference', sortable: true },
    ];

  }


  GetTableData() {
    // this.AgLoad = true;;
    const dis = this;
    let req = null;
    let filter: any = {};
    req = dis.requestService.tracktempList();
    req.subscribe(resp => {
      const messageResult = resp['result'];
      messageResult.sort((a, b) => (a.latestReadingDate > b.latestReadingDate) ? -1 : 1);
      console.log("messageResult", messageResult)
      this.RowData = messageResult;
    });
  }

  clear() {
    window.location.reload();
  }



  onRowClicked(event: any) {
    this.onRowClickedValue = event.value;
    console.log("this.onRowClickedValue", event.value)
    // let def = 'customStats';
    // event.api.openToolPanel(def);
    // this.moreDetails.changeMessage(this.onRowClickedValue);
    let uniqueId = event.data.id;
    this.popupData(uniqueId)
    // this.mapDataBuild();

    // $("#newModal2").modal("show");

  }



  popupData(uniqueId) {
    const dis = this;
    let req = null;
    let markers: any;
    req = dis.requestService.mapData(uniqueId);
    req.subscribe(resp => {
      let result = resp.result;
      console.log("Map result::::::", result)
      markers = result;
      dis.MapData.push(new MapData('satellite', false, markers));
      this.map.data = dis.MapData;
      this.SideOverlay = true;
    });
    dis.eventparsing = [];
    // this.eventchainloop(uniqueId);

  }





  eventchainloop(uniqueId) {
    const dis = this;
    let raws;
    let req = null;
    dis.eventparsing = [];
    dis.OE_ED = [];
    // req = this.requestService.eventChainDetails(uniqueId);
    // req.subscribe(resp => {
    //   const messageResult = resp['result'];
    //   for (let i = 0; i < messageResult.length; i++) {
    //     raws = messageResult[i];

    //     if (raws.actualDate !== null && raws.actualDate.includes("T")) {
    //       console.log("::::::", raws.actualDate)
    //       // let time = raws.EVENTDATE.split('T')[0];
    //       let hrs = raws.actualDate.split(':')[0];
    //       let min = raws.actualDate.split(':')[1];
    //       raws.actualDate = hrs + ':' + min;
    //       console.log("DateTime::", raws.actualDate)
    //     }
    //     // if (raws.actualDate === null) {
    //     //   raws.actualDate = "Actual event is yet to happen"
    //     // }
    //     if (raws.estimatedDate !== null && raws.estimatedDate.includes("T")) {
    //       console.log("::::::", raws.estimatedDate)
    //       // let time = raws.EVENTDATE.split('T')[0];
    //       let hrs = raws.estimatedDate.split(':')[0];
    //       let min = raws.estimatedDate.split(':')[1];
    //       raws.estimatedDate = hrs + ':' + min;
    //       console.log("DateTime::", raws.estimatedDate)
    //     }
    //     if (raws.estimatedDate === null) {
    //       raws.estimatedDate = ""
    //     }
    //     // raws.EVENTDATE = new Date();
    //     // raws.EVENTDATE = (this.datePipe.transform(raws.EVENTDATE, 'dd/MM/yyyyTHH:mm') as any);
    //     if (raws.actualDate !== null) {
    //       dis.eventparsing.push(raws);
    //       // }
    //     }
    //     else if (raws.actualDate === null && raws.estimatedDate !== null) {
    //       if (raws.description === 'Departed') {
    //         raws.description = 'Departure'
    //       }
    //       else if (raws.description === 'Arrived') {
    //         raws.description = 'Arrival'
    //       }
    //       dis.OE_ED.push(raws);
    //     }
    //   }
    // });
  }




  closemodal() {
    console.log("close")
    this.SideOverlay = false;

  }

  saveState() {
    // this.gridColumnApi.getColumnState();
    var columnState = JSON.stringify(this.gridColumnApi.getColumnState());
    // localStorage.setItem('myColumnState', columnState);
    sessionStorage.setItem('savedState', columnState);
    console.log('column state saved');
  }

  defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  sideBar = {
    hiddenByDefault: false,
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          allowDragFromColumnsToolPanel: true
        },
      },
      // {
      //   id: 'filters',
      //   labelDefault: 'Shipper Account',
      //   labelKey: 'filters',
      //   iconKey: 'filter',
      //   toolPanel: 'agFiltersToolPanel',
      // }
    ]
  }

  gridOptions = {
    rowData: this.GetTableData(),
    columnDefs: this.GetAgColumns(),
  }

}
