<ng-container *ngIf="userRole === 'Express' || userRole === 'DHL'">
    <div class="test-container">
        <div class="test-header">
            <div class="example-section">

                <div class="row">

                    <div class="col">

                    </div>
                    <div class="col-2">
                        <ng-multiselect-dropdown [placeholder]="'Customer'" [data]="CodearrayDataCustomer"
                            [(ngModel)]="CustomerselectedItems" [settings]="CustomerdropdownSettings"
                            (onSelect)="onCustomerItemSelect($event)" (onDeSelect)="onCustomerDeSelect($event)">
                        </ng-multiselect-dropdown>
                    </div>
                    <div class="col-2">
                        <input placeholder="Enter HAWB" class="form-control" type="text" name="HAWB"
                            [(ngModel)]="HAWBSearchField" (keyup.enter)="HAWBSearch()">
                    </div>
                    <div class="col-auto float-right ml-auto">
                        <div class="view-icons">
                            <a (click)="clear()" title="Clear" class="grid-view btn btn-link"><i
                                    class="fa fa-refresh"></i></a>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</ng-container>

<!-- <ng-container
    *ngIf="userRole === 'BMX'|| userRole === 'Nissan'|| userRole==='ASTRAZENECA' || userRole==='BMS'|| userRole==='Cargotec' || userRole==='CARGOTEC_FRT'||userRole==='Total'||userRole==='GSK' ||userRole==='IBM'
|| userRole==='Maxion'||userRole==='Pfizer'||userRole==='Meritor'||userRole==='Caterpillar'||userRole==='SLB'||userRole==='DELL'||userRole==='HOLOGIC'||userRole==='BlessLLP'||userRole==='LLP'||userRole==='CISCO'||userRole==='BOSCH'||userRole==='HM'||userRole==='METSO'||userRole==='NOVONORDISK'||userRole==='SALMAR'|| userRole === 'ROCHE'|| userRole === 'APPLE'|| userRole === 'ARAMCO'|| userRole === 'SHELL'"> -->
<ng-container *ngIf="userRole != 'Express' || userRole != 'DHL'">
    <div class="test-container">
        <div class="test-header">
            <div class="example-section">

                <div class="row">

                    <div class="col">

                    </div>
                    <div class="col">
                        <ng-multiselect-dropdown [placeholder]="'custom placeholder'" [settings]="dropdownSettings"
                            [data]="dropdownList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelect($event)">
                        </ng-multiselect-dropdown>
                    </div>

                    <ng-container *ngIf="filter_ver1_ui">
                        <div class="col">
                            <ng-multiselect-dropdown [placeholder]="'Origin'" [settings]="dropdownSettingsOrigin"
                                [data]="dropdownListOrigin" [(ngModel)]="selectedItemsOrigin"
                                (onSelect)="onItemSelectOrigin($event)" (onDeSelect)="onItemDeSelectOrigin($event)">
                            </ng-multiselect-dropdown>

                        </div>
                        <div class="col">
                            <ng-multiselect-dropdown [placeholder]="'Destination'"
                                [settings]="dropdownSettingsDestination" [data]="dropdownListDestination"
                                [(ngModel)]="selectedItemsDestination" (onSelect)="onItemSelectDestination($event)"
                                [disabled]="disableDestination">
                            </ng-multiselect-dropdown>
                        </div>
                        <div class="col">
                            <ng-multiselect-dropdown [placeholder]="'MOT'" [settings]="dropdownSettingsMOT"
                                [data]="dropdownListMOT" [(ngModel)]="selectedItemsMOT"
                                (onSelect)="onItemSelectMOT($event)" [disabled]="disableMOT">
                            </ng-multiselect-dropdown>
                        </div>
                    </ng-container>


                    <div class="col">
                        <input placeholder="Enter HAWB" class="form-control" type="text" name="HAWB"
                            [(ngModel)]="HAWBSearchField" (keyup.enter)="HAWBSearch()">
                    </div>

                    <div  class="col">
                       &nbsp; 
                    </div>

                    <!-- <ng-container *ngIf="filter_ver1_ui">
                        <div class="col">
                            <input placeholder="Enter Shipment References" class="form-control" type="text"
                                name="ShipmentReferences" [(ngModel)]="ShipmentReferencesSearchField"
                                (keyup.enter)="ShipmentReferencesSearch()">
                        </div>
                    </ng-container> -->



                    <!-- <div class="col-2">
                        <ng-multiselect-dropdown [placeholder]="'Customer'" [data]="CodearrayDataCustomer"
                            [(ngModel)]="CustomerselectedItems" [settings]="CustomerdropdownSettings"
                            (onSelect)="onCustomerItemSelect($event)" (onDeSelect)="onCustomerDeSelect($event)">
                        </ng-multiselect-dropdown>
                    </div> -->
                    <div class="col-auto float-right ml-auto">
                        <div class="view-icons">
                            <a (click)="clear()" title="Clear" class="grid-view btn btn-link"><i
                                    class="fa fa-refresh"></i></a>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</ng-container>

<div class="mt-5">
    <!-- d-flex -->
    <div class="row">
        <div class="col-1" style="padding: inherit;">
            <div class="ship_stat_icon_blk">
                <img _ngcontent-wtk-c88="" src="./assets/images/intransit-New/OEM-Shipper.png" width="70px"
                    height="63px" class="img1 ml-1">
            </div>
            <div class="d-flex flex-column">
                <ng-container *ngFor="let lst of RowData">
                    <div class="topdiv" *ngIf="lst.eventGroup === 'ORDER'" style="margin-bottom: 5%;">
                        <div class="marginClass col_card_height">
                            <div class="profile-widget h-100"
                                (click)="Details(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName)">
                                <div class="profile-img">
                                    <a class="avatar">
                                        <span>{{lst.Count}}</span>
                                    </a>
                                </div>
                                <div class="row"></div>
                                <h4 class="mt-4 description"><a>Orders</a>
                                </h4>

                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngFor="let lst of RowData">
                    <div class="bottomdiv" *ngIf="lst.eventGroup === 'CANCEL'">
                        <!-- style="margin-top: 142px;" -->
                        <div class="col_card_height">
                            <div class="profile-widget h-100"
                                (click)="Details(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName)">
                                <div class="profile-img">
                                    <a class="avatar">
                                        <span>{{lst.Count}}</span>
                                    </a>
                                </div>
                                <div class="row"></div>
                                <h4 class="mt-4 description"><a>Cancel</a>
                                </h4>

                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

        </div>
        <div class="col-11 flex-fill">
            <!-- style="margin-left: 5px;" -->
            <div class="row flex-no-wrap">
                <ng-container *ngFor="let lst of RowData">

                    <div class="flex-fill" *ngIf="lst.eventGroup === 'FREIGHT PROCESS'">
                        <div class="ship_stat_icon_blk">
                            <img _ngcontent-wtk-c88="" src="./assets/images/intransit-New/3plwarehouse-2.png"
                                width="110px" height="63px" class="img1 ml-1">
                        </div>
                        <div class="marginClass-2">
                            <div class="profile-widget h-100">
                                <span
                                    (click)="Details(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName)">
                                    <div class="profile-img-2" title="All Shipments">
                                        <a class="avatar">
                                            <span>{{lst.Count}}</span>
                                        </a>
                                    </div>
                                    <div class="row"></div>
                                    <h4 class="mt-4 description"><a>Freight Process</a></h4>
                                </span>
                                <ng-container *ngIf="view_ver1_ui">
                                    <div class="bottom">
                                        <div class="row">
                                            <div class="green col-6" title="Shipments with update in the last 2 hours"
                                                (click)="specificDetails(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName,'activeOrders',lst.activeOrders)">
                                                {{lst.activeOrders}}
                                            </div>
                                            <div class="red col-6"
                                                title="Shipments with no update for more than 48 hours"
                                                (click)="specificDetails(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName,'moreThanADayOrders',lst.moreThanADayOrders)">
                                                {{lst.moreThanADayOrders}}
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngFor="let lst of RowData">
                    <div class="flex-fill" *ngIf="lst.eventGroup === 'PRE-CARRIAGE'">
                        <div class="ship_stat_icon_blk">
                            <img _ngcontent-wtk-c88="" src="./assets/images/intransit-New/Pre-Carriage.png" width="90px"
                                height="63px" class="img1 ml-3">
                        </div>
                        <div class="marginClass-2">
                            <div class="profile-widget h-100">
                                <span
                                    (click)="Details(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName)">
                                    <div class="profile-img-2" title="All Shipments">
                                        <a class="avatar">
                                            <span>{{lst.Count}}</span>
                                        </a>
                                    </div>
                                    <div class="row"></div>
                                    <h4 class="mt-4 description"><a>Pre-Carriage</a>
                                    </h4>
                                </span>
                                <ng-container *ngIf="view_ver1_ui">
                                    <div class="bottom">
                                        <div class="row">
                                            <div class="green col-6" title="Shipments with update in the last 2 hours"
                                                (click)="specificDetails(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName,'activeOrders',lst.activeOrders)">
                                                {{lst.activeOrders}}
                                            </div>
                                            <div class="red col-6"
                                                title="Shipments with no update for more than 48 hours"
                                                (click)="specificDetails(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName,'moreThanADayOrders',lst.moreThanADayOrders)">
                                                {{lst.moreThanADayOrders}}
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngFor="let lst of RowData">
                    <div class="flex-fill" *ngIf="lst.eventGroup === 'EXPORT CLEARANCE'">
                        <div class="ship_stat_icon_blk">
                            <img _ngcontent-wtk-c88="" src="./assets/images/intransit-New/Gateway-Terminal.png"
                                width="100px" height="63px" class="img1 ml-4">
                        </div>
                        <div class="marginClass-2">
                            <div class="profile-widget h-100">
                                <span
                                    (click)="Details(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName)">
                                    <div class="profile-img-2" title="All Shipments">
                                        <a class="avatar">
                                            <span>{{lst.Count}}</span>
                                        </a>
                                    </div>
                                    <div class="row"></div>
                                    <h4 class="mt-4 description"><a>Export Clearance</a>
                                    </h4>
                                </span>
                                <ng-container *ngIf="view_ver1_ui">
                                    <div class="bottom">
                                        <div class="row">
                                            <div class="green col-6" title="Shipments with update in the last 2 hours"
                                                (click)="specificDetails(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName,'activeOrders',lst.activeOrders)">
                                                {{lst.activeOrders}}
                                            </div>
                                            <div class="red col-6"
                                                title="Shipments with no update for more than 48 hours"
                                                (click)="specificDetails(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName,'moreThanADayOrders',lst.moreThanADayOrders)">
                                                {{lst.moreThanADayOrders}}
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngFor="let lst of RowData">
                    <div class="flex-fill" *ngIf="lst.eventGroup === 'MAIN FREIGHT'">
                        <div class="ship_stat_icon_blk">
                            <img _ngcontent-wtk-c88="" src="./assets/images/intransit-New/DHL-Non-DHL-Carrier.png"
                                width="115px" height="63px" class="img1 ml-4">
                        </div>
                        <div class="marginClass-2">
                            <div class="profile-widget h-100">
                                <span
                                    (click)="Details(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName)">
                                    <div class="profile-img-2" title="All Shipments">
                                        <a class="avatar">
                                            <span>{{lst.Count}}</span>
                                        </a>
                                    </div>
                                    <div class="row"></div>
                                    <h4 class="mt-4 description"><a>Main Freight</a>
                                    </h4>
                                </span>
                                <ng-container *ngIf="view_ver1_ui">
                                    <div class="bottom">
                                        <div class="row">
                                            <div class="green col-6" title="Shipments with update in the last 2 hours"
                                                (click)="specificDetails(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName,'activeOrders',lst.activeOrders)">
                                                {{lst.activeOrders}}
                                            </div>
                                            <div class="red col-6"
                                                title="Shipments with no update for more than 48 hours"
                                                (click)="specificDetails(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName,'moreThanADayOrders',lst.moreThanADayOrders)">
                                                {{lst.moreThanADayOrders}}
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngFor="let lst of RowData">
                    <div class="flex-fill" *ngIf="lst.eventGroup === 'IMPORT CLEARANCE'">
                        <div class="ship_stat_icon_blk">
                            <img _ngcontent-wtk-c88="" src="./assets/images/intransit-New/Gateway-Terminal-2.png"
                                width="83px" height="63px" class="img1 ml-3">
                        </div>
                        <div class="marginClass-2">
                            <div class="profile-widget h-100">
                                <span
                                    (click)="Details(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName)">
                                    <div class="profile-img-2" title="All Shipments">
                                        <a class="avatar">
                                            <span>{{lst.Count}}</span>
                                        </a>
                                    </div>
                                    <div class="row"></div>
                                    <h4 class="mt-4 description"><a>Import Clearance</a>
                                    </h4>
                                </span>
                                <ng-container *ngIf="view_ver1_ui">
                                    <div class="bottom">
                                        <div class="row">
                                            <div class="green col-6" title="Shipments with update in the last 2 hours"
                                                (click)="specificDetails(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName,'activeOrders',lst.activeOrders)">
                                                {{lst.activeOrders}}
                                            </div>
                                            <div class="red col-6"
                                                title="Shipments with no update for more than 48 hours"
                                                (click)="specificDetails(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName,'moreThanADayOrders',lst.moreThanADayOrders)">
                                                {{lst.moreThanADayOrders}}
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngFor="let lst of RowData">
                    <div class="flex-fill" *ngIf="lst.eventGroup === 'ON-CARRIAGE'">
                        <div class="ship_stat_icon_blk">
                            <img _ngcontent-wtk-c88="" src="./assets/images/intransit-New/LastMile.png" width="90px"
                                height="63px" class="img1 ml-3">
                        </div>
                        <div class="marginClass-2">
                            <div class="profile-widget h-100">
                                <span
                                    (click)="Details(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName)">
                                    <div class="profile-img-2" title="All Shipments">
                                        <a class="avatar">
                                            <span>{{lst.Count}}</span>
                                        </a>
                                    </div>
                                    <div class="row"></div>
                                    <h4 class="mt-4 description"><a>On-Carriage</a>
                                    </h4>
                                </span>
                                <ng-container *ngIf="view_ver1_ui">
                                    <div class="bottom">
                                        <div class="row">
                                            <div class="green col-6" title="Shipments with update in the last 2 hours"
                                                (click)="specificDetails(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName,'activeOrders',lst.activeOrders)">
                                                {{lst.activeOrders}}
                                            </div>
                                            <div class="red col-6"
                                                title="Shipments with no update for more than 48 hours"
                                                (click)="specificDetails(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName,'moreThanADayOrders',lst.moreThanADayOrders)">
                                                {{lst.moreThanADayOrders}}
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngFor="let lst of RowData">
                    <div class="col-2" style="padding-left: inherit;" *ngIf="lst.eventGroup === 'LAST-MILE DELIVERY'">
                        <div class="ship_stat_icon_blk">
                            <img _ngcontent-wtk-c88="" src="./assets/images/intransit-New/3plwarehouse.jpg" width="80px"
                                height="63px" class="img1 ml-3" style="width: 85%;">
                        </div>
                        <div class="marginClass-2">
                            <div class="profile-widget h-100">
                                <span
                                    (click)="Details(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName)">
                                    <div class="profile-img-2" title="All Shipments">
                                        <a class="avatar">
                                            <span>{{lst.Count}}</span>
                                        </a>
                                    </div>
                                    <div class="row"></div>
                                    <h4 class="mt-4 description"><a>Last Mile Delivery</a>
                                    </h4>
                                </span>
                                <ng-container *ngIf="view_ver1_ui">
                                    <div class="bottom">
                                        <div class="row">
                                            <div class="green col-6" title="Shipments with update in the last 2 hours"
                                                (click)="specificDetails(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName,'activeOrders',lst.activeOrders)">
                                                {{lst.activeOrders}}
                                            </div>
                                            <div class="red col-6"
                                                title="Shipments with no update for more than 48 hours"
                                                (click)="specificDetails(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName,'moreThanADayOrders',lst.moreThanADayOrders)">
                                                {{lst.moreThanADayOrders}}
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </div>

        </div>
    </div>
</div>

<div class="row">
    <ng-container *ngFor="let lst of RowData">
        <div class="col-6" *ngIf="lst.eventGroup === 'OTHERS'">
            <div class="flex-fill">
                <div class="marginClass-3">
                    <div class="profile-widget" style="height: 88px;"
                        (click)="Details(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName)">
                        <div class="profile-img-3">
                            <a class="avatar">
                                <span>{{lst.Count}}</span>
                            </a>
                        </div>
                        <div class="row"></div>
                        <h4 class="description"><a>Others</a>
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngFor="let lst of RowData">

        <div class="col-6" *ngIf="lst.eventGroup === 'EXCEPTIONS'">
            <div class="flex-fill">
                <div class="marginClass-3">
                    <div class="profile-widget" style="height: 88px;"
                        (click)="Details(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName)">
                        <div class="profile-img-3">
                            <a class="avatar">
                                <span>{{lst.Count}}</span>
                            </a>
                        </div>
                        <div class="row"></div>
                        <h4 class="description"><a>Exceptions</a>
                        </h4>
                    </div>
                </div>
            </div>
        </div>

        <!-- <ng-container *ngIf="userRole==='HM'">
            <div class="flex-fill" *ngIf="lst.eventGroup === 'NOU'">
                <div class="marginClass-3">
                    <div class="profile-widget" style="height: 88px;"
                        (click)="Details(lst.eventGroupId,lst.lspOrgId,lst.lspOrgName,lst.eventGroup,lst.customerOrgId,lst.customerOrgName)">
                        <div class="profile-img-3">
                            <a class="avatar">
                                <span>{{lst.Count}}</span>
                            </a>
                        </div>
                        <div class="row"></div>
                        <h4 class="description"><a>No Recent Events</a>
                        </h4>

                    </div>
                </div>
            </div>
        </ng-container> -->
    </ng-container>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>