import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  footerText = '';

  constructor() { }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.footerText = 'Powered by DHL';
  }
}
