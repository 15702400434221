import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class TabService {
  currentTab!: Tab;
  activeTabs: Tab[] = [];
  configTile: any;
  configLink: any;
  activeTabResp: any = [];
  private tabTitle = new BehaviorSubject('');
  CurrentTab = this.tabTitle.asObservable();

  constructor(private router: Router) {
    if (this.existingTabs() === null) {
      this.openTab({ title: 'Home', routerLink: '' });
    } else {
      this.activeTabs = this.existingTabs();
    }
  }

  existingTabs() {
    // let store: any = sessionStorage.getItem('Tab');
    return JSON.parse(sessionStorage.getItem('Tab') as any);
  }

  ifTabExists(currentTab: any) {
    let activePaths = [];

    for (let active of this.activeTabs) {
      activePaths.push(active.routerLink);
    }
    return activePaths.includes(currentTab.routerLink);
  }

  openTab(currentTab: any) {
    if (!this.ifTabExists(currentTab)) {
      this.activeTabs.push(currentTab);
    }
    this.tabTitle.next('');
    this.router.navigate([currentTab.routerLink]);
    this.sessionTabs(this.activeTabs)
  }

  getTabs() {
    return this.activeTabs;
  }

  closeTab(index: any) {
    this.activeTabs.splice(index, 1);
    this.tabTitle.next('');
    this.router.navigate(['']);
    this.sessionTabs(this.activeTabs);
  }

  sessionTabs(tabs: any) {
    sessionStorage.setItem('Tab', JSON.stringify(tabs));
  }

}

export class Tab {
  title: string;
  routerLink: string;

  constructor(title: string, routerLink: string) {
    this.title = title;
    this.routerLink = routerLink;
  }
}

