import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MoreDetailsService } from 'src/app/services/more-details.service';
import { RequestService } from 'src/app/services/request.service';

@Component({
  selector: 'app-transport-request-dashboard',
  templateUrl: './transport-request-dashboard.component.html',
  styleUrls: ['./transport-request-dashboard.component.scss']
})
export class TransportRequestDashboardComponent implements OnInit {
  Data: any;
  constructor(private requestService: RequestService, private messageService: MoreDetailsService,
    private router: Router) { }

  ngOnInit(): void {
    this.dashboardCount()
  }

  Details(widgetStatus, region, lspType, status) {
    this.messageService.transportRequestMessage(widgetStatus, region,lspType,status);
    this.router.navigate(['/TransportRequest'])
  }
  dashboardCount() {
    const dis = this;
    let req = null;
    req = dis.requestService.purchaseOrdersDashboardCount();
    req.subscribe(resp => {
      const messageResult = resp;
      this.Data = messageResult
      console.log("messageResult", messageResult)
    });
  }


}
